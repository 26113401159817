import React, { forwardRef } from 'react';

import Spinner from 'components/Spinner';

import AvatarImage from './AvatarImage';
import AvatarStatus from './AvatarStatus';
import { StAvatar } from './StAvatar';

const Avatar = forwardRef(({ isAdmin, user = {}, size = 50, hideStatus = false, loading = false, ...props }, ref) => {
    const width = size;
    const height = size;

    return (
        <StAvatar ref={ref} data-place="avatar" admin={isAdmin} style={{ width, height }} {...props}>
            {loading && (
                <Spinner color="yellow" size={size} css={{ position: 'absolute', top: 0, right: 0, zIndex: 1 }} />
            )}
            <AvatarImage width={width} height={height} user={user} />
            {!hideStatus && user?.id ? <AvatarStatus small={size < 28} user={user} /> : null}
        </StAvatar>
    );
});

export default Avatar;
