import React, { useCallback, useMemo } from 'react';

import { useHotkeys } from '@blueprintjs/core';

import { Button } from 'components/Button';
import Kbd, { KeyCombo } from 'components/Kbd';
import { ToastAction } from 'components/Toast';

function UndoButton({ onClick }) {
    const undoAction = useCallback(onClick, [onClick]);

    const hotkeys = useMemo(() => {
        return [
            {
                combo: 'mod+z',
                global: true,
                label: 'Undo',
                group: 'Table',
                onKeyDown: undoAction,
            },
        ];
    }, [undoAction]);

    useHotkeys(hotkeys);

    return (
        <ToastAction altText="Undo">
            <Button
                className="flex-shrink"
                size={24}
                border
                id="undo-btn"
                text="Undo"
                onClick={undoAction}
                rightElm={
                    <KeyCombo>
                        <Kbd>cmd</Kbd>
                        <Kbd>z</Kbd>
                    </KeyCombo>
                }
            />
        </ToastAction>
    );
}

export default UndoButton;
