import { runInAction } from 'mobx';

import api from 'api';
import { CURRENT_USER_ID } from 'constants/global';
import { failRequest, toUrl } from 'utils';

import { mainStore } from 'store/models/MainStore';
import { utilsStore } from 'store/models/UtilsStore';

export async function toggleSubscriptionVotingBoard(email) {
    if (!CURRENT_USER_ID) {
        utilsStore.setHistoryAction({ subscribe: { id: this.id } });
        return;
    }

    const apiFn = this.isUserSubscribed ? api.delete : api.post;

    runInAction(() => {
        if (this.isUserSubscribed) {
            mainStore.currentUser.board_subscriptions = mainStore.currentUser.board_subscriptions.filter(
                (publicId) => publicId !== this.id,
            );
        } else {
            mainStore.currentUser.board_subscriptions.push(this.id);
        }
    });

    try {
        const { data } = await apiFn(`${this.apiEndpoint}/subscribe`, toUrl({ email }));
        data &&
            runInAction(() => {
                mainStore.currentUser.board_subscriptions = data.board_subscriptions;
            });
    } catch (e) {
        failRequest(e);
    }
}
