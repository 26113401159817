import { makeAutoObservable, observable } from 'mobx';

import api from 'api';
import { failRequest, toUrl } from 'utils';

import { RELEASE_NOTE_TYPE } from 'pages/Board/ReleaseNotes/constants';

import { mainStore } from 'store/models/MainStore';

export default class ReleaseNoteItem {
    uuid = null;
    id = null;
    type = '';
    status_id = null;
    voting_announce_id = null;
    title = '';
    description = '';
    position = null;
    enabled = false;
    settings = observable.box({});
    created = '';
    updated = '';
    ideas = null;

    /**
     * @type {ReleaseNote}
     */
    parent = null;

    /**
     * @param {Object} data
     * @param {ReleaseNote} parent
     */
    constructor(data, parent) {
        makeAutoObservable(this, { announce: false });

        this.updateModel(data);
        this.uuid = `${data.id}-${data.updated}`;

        this.parent = parent;
    }

    updateModel(data) {
        const { settings, ...otherData } = data;
        Object.assign(this, otherData);

        // Ensure this.history is an observable box
        if (!this.settings) {
            this.settings = observable.box(null);
        }

        // If data.history exists and is an object, set it
        if (settings && typeof settings === 'object') {
            this.settings.set(settings);
        }
    }

    get apiEndpoint() {
        return `${this.parent?.apiEndpoint}/items/${this.id}`;
    }

    get stats() {
        return this.parent?.stats?.[this.id] || null;
    }

    get status() {
        if (this.type !== RELEASE_NOTE_TYPE.STATUS || !this.parent.board) return null;

        return this.parent.board.ideaStatuses.find((status) => status.id === this.status_id) || null;
    }

    get announce() {
        if (!this.voting_announce_id || !mainStore.announcesIds.has(this.voting_announce_id)) return null;

        return mainStore.announcesIds.get(this.voting_announce_id);
    }

    get isEmpty() {
        if (this.type === RELEASE_NOTE_TYPE.ANNOUNCE) {
            const settings = this.settings.get();
            const emptyButton = !(settings.button_title && settings.button_url);
            return this.announce?.isEmpty && !this.announce?.idea_id && emptyButton;
        }

        return !!(
            this.type === RELEASE_NOTE_TYPE.CALL_TO_ACTION &&
            !this.title &&
            (!this.description || !this.description.replace('<p></p>', ''))
        );
    }

    async updateSettings(field, value) {
        try {
            const { data } = await api.post(`${this.apiEndpoint}/settings`, toUrl({ [field]: value }));
            await this.parent?.getStats();
            return data;
        } catch (e) {
            failRequest(e);
            await Promise.reject(e);
        }
    }

    async update(requestData) {
        try {
            return await api.put(this.apiEndpoint, toUrl(requestData));
        } catch (e) {
            failRequest(e);
            await Promise.reject(e);
        }
    }

    async movePosition(position) {
        this.parent.moveItems({ fromIndex: this.position - 1, toIndex: position - 1 });

        try {
            await api.post(`${this.apiEndpoint}/shift`, toUrl({ position }));
        } catch (e) {
            failRequest(e);
        }
    }

    async remove() {
        try {
            await api.delete(this.apiEndpoint);
        } catch (e) {
            failRequest(e);
        }
    }
}
