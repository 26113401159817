import React from 'react';

import { AnchorButton } from 'components/Button';

import styles from './YouTube.module.sass';

export default function YouTube({ videoId, link = null, icon = null, isButton, rightIcon, onAnalytics, ...props }) {
    if (!link && !icon) {
        return (
            <span className={styles.video_responsive_padding}>
                <span className={styles.video_responsive_wrapper}>
                    <iframe
                        className={styles.video_responsive}
                        title="youtube"
                        width="560"
                        height="315"
                        src={`https://www.youtube.com/embed/${videoId}`}
                        style={{ border: 0 }}
                        allowFullScreen
                    />
                </span>
            </span>
        );
    }

    return (
        <AnchorButton
            className="flex-shrink"
            border={isButton}
            likeLink={!isButton}
            target="_blank"
            href={`https://youtu.be/${videoId}`}
            onClick={onAnalytics ? () => onAnalytics(videoId) : undefined}
            icon={icon}
            text={link}
            rightElm={rightIcon && typeof rightIcon !== 'string'}
            rightIcon={(typeof rightIcon === 'string' && rightIcon) || undefined}
            {...props}
        />
    );
}
