import api from 'api';
import { REST } from 'constants/ApiPaths';

import failRequest from 'utils/failRequest';
import logEvent from 'utils/logEvent';
import toUrl from 'utils/toUrl';

export async function changePublicName(publicName) {
    logEvent('Public name change');

    const putData = toUrl({ publicName });

    try {
        await api.put(REST.workspace.votingSettings, putData);
    } catch (e) {
        failRequest(e);
    }
}
