import api from 'api';
import { failRequest } from 'utils';

import Announce from 'store/models/Announce';
import { mainStore } from 'store/models/MainStore';

import toUrl from 'utils/toUrl';

export async function createIdeaAnnounce(announce) {
    const requestData = toUrl({
        idea_id: this.id,
        ...announce,
    });

    try {
        this.set({ initialOpenAnnounce: true });
        const { data } = await api.post(`${this.board.apiEndpoint}/announces`, requestData);
        if (mainStore.announcesIds.has(data.id)) {
            mainStore.announcesIds.get(data.id);
        } else {
            mainStore.announcesIds.set(data.id, new Announce(data));
        }
    } catch (e) {
        failRequest(e);
    }
}
