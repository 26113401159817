import React, { useState } from 'react';

import { observer } from 'mobx-react-lite';

import { Button } from 'components/Button';
import { CustomIconName } from 'components/CustomIcon';
import Spinner from 'components/Spinner';
import Tooltip from 'components/Tooltip';

export const ResetAllSkippedUsers = observer(function ResetAllSkippedUsers({ issue }) {
    const [loading, setLoading] = useState(false);
    if (issue.skippedUsersIds.length < 2) return null;

    async function handleResume() {
        setLoading(true);
        await issue.resumeEvaluation({ all: 1 });
        setLoading(false);
    }

    return (
        <Tooltip content="All users who skipped issue evaluation have to evaluate this issue anew">
            <Button
                disabled={loading}
                rightIcon={loading ? undefined : CustomIconName.REWIND}
                rightElm={loading ? <Spinner size={16} /> : null}
                size={24}
                onClick={handleResume}
                border
                text="Reset all skipped evaluations"
            />
        </Tooltip>
    );
});
