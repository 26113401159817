import React from 'react';

import { Checklist } from './Checklist';

export default function IssueChecklists({ checklists }) {
    if (!checklists?.length) {
        return null;
    }

    return (
        <>
            {checklists.map((list, i) => (
                <Checklist key={i} {...list} />
            ))}
            <div />
        </>
    );
}
