import React, { useCallback } from 'react';

import isUnsavedIssue from 'utils/isUnsavedIssue';

import { CustomIconName } from 'components/CustomIcon';
import { DropdownMenuItem } from 'components/DropdownMenu';
import Kbd, { KeyCombo } from 'components/Kbd';
import { useToast } from 'components/Toast';
import Tooltip from 'components/Tooltip';

export default function RemoveBtn({ issue }) {
    const { toast } = useToast();
    const votingIssueLink = issue?.idea?.internalHref;

    const infoMessage = useCallback(() => {
        votingIssueLink &&
            toast({
                description: (
                    <>
                        Issue <b>{issue.name}</b> was removed. Public part is still visible on Voting Board.
                    </>
                ),
            });
    }, [issue?.name, toast, votingIssueLink]);

    if (isUnsavedIssue(issue)) {
        return null;
    }

    return (
        <Tooltip side="left" content="Permanently delete">
            <DropdownMenuItem
                icon={CustomIconName.TRASH}
                onClick={() => issue.deleteFromBoard(infoMessage)}
                text="Remove Issue permanently"
                rightElm={
                    <KeyCombo>
                        <Kbd>alt</Kbd>
                        <Kbd>del</Kbd>
                    </KeyCombo>
                }
            />
        </Tooltip>
    );
}
