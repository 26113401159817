import { CustomIcon, CustomIconName } from 'components/CustomIcon';
import Tooltip from 'components/Tooltip';

export default function BoardError({ message, error, side = 'top' }) {
    if (error || message) {
        const content = message || (
            <>
                <p>
                    This board is broken. The platform returns the error: <br />
                    <em>{error}</em>
                </p>
                <p>Please fix the error and update the board data</p>
            </>
        );

        return (
            <Tooltip content={content} side={side}>
                <CustomIcon color="orange" size={14} icon={CustomIconName.WARNING} />
            </Tooltip>
        );
    }
    return null;
}
