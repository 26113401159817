import { runInAction } from 'mobx';

import api from 'api';

import failRequest from 'utils/failRequest';
import logEvent from 'utils/logEvent';
import toUrl from 'utils/toUrl';

export async function updateRequest({ request, message = null, assignee_id = null }) {
    logEvent('Update request');
    const oldAssigneeId = request.assignee_id;
    if (assignee_id) {
        runInAction(() => {
            request.assignee_id = assignee_id;
        });
    }

    try {
        await api.put(
            `/requests/${request.id}`,
            toUrl({ board_id: this.board.id, issue_id: this.id, message, assignee_id })
        );
    } catch (error) {
        failRequest(error);
        if (assignee_id) {
            runInAction(() => {
                request.assignee_id = oldAssigneeId;
            });
        }
    }
}
