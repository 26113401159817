/**
 * Parse events stream data from json stringify
 *
 * @param {string} rawData
 * @return {Object|null}
 */
export function getParsedData(rawData) {
    try {
        return JSON.parse(rawData);
    } catch (e) {
        return null;
    }
}
