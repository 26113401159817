import React from 'react';

import { StButton } from 'atoms/StButton';

import Tooltip from 'components/Tooltip';

const CurrentUserMark = () => (
    <Tooltip side="top" align="end" content="The member you are now logged-in as">
        <StButton as="span" size={20} color="green" className="flex-shrink">
            You
        </StButton>
    </Tooltip>
);

export default CurrentUserMark;
