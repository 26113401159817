import React from 'react';

import classNames from 'classnames';

import { mainStore } from 'store/models/MainStore';

import { StButton } from 'atoms/StButton';

import highlightText from 'utils/highlightText';

import Avatar from 'components/Avatar';
import CurrentUserMark from 'components/CurrentUserMark';
import { CustomIcon, CustomIconName } from 'components/CustomIcon';

import styles from './UserListItem.module.sass';

export default function UserListItem({ modifiers, item, handleClick, query = '', active, dismiss }) {
    const name = item.name || item.email;

    const classes = classNames(styles.item, {
        'bp5-popover-dismiss': dismiss,
        'bp5-menu-item': !dismiss,
    });

    return (
        <StButton
            active={modifiers.active}
            role="button"
            minimal
            block
            className={classes}
            key={item.id}
            onClick={handleClick}
        >
            <Avatar hideStatus user={item} size={18} />
            <span className="t-crop flex-grow">{highlightText(name, query)}</span>
            {item.id === mainStore.currentUser?.id && <CurrentUserMark />}
            {active ? <CustomIcon icon={CustomIconName.TICK} color="green" /> : null}
        </StButton>
    );
}
