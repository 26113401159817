import { runInAction } from 'mobx';

export async function fillWithInterval(data, field, abortController) {
    let i = 0;

    const intervalId = setInterval(() => {
        runInAction(() => {
            this[field] = data.slice(0, i);
        });
        if (abortController?.signal?.aborted || ++i > data.length) clearInterval(intervalId);
    }, 40 * Math.random());
}
