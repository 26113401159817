import {
    grayDark,
    grayDarkA,
    amberDark,
    blueDark,
    brownDark,
    crimsonDark,
    cyanDark,
    grassDark,
    greenDark,
    indigoDark,
    limeDark,
    mintDark,
    orangeDark,
    pinkDark,
    plumDark,
    purpleDark,
    redDark,
    redDarkA,
    skyDark,
    tealDark,
    tomatoDark,
    violetDark,
    yellowDark,
    yellowDarkA,
} from '@radix-ui/colors';

import { createTheme } from 'stitches.config';

export const colorsDark = createTheme({
    colors: {
        ...grayDark,
        ...grayDarkA,
        ...amberDark,
        ...blueDark,
        ...brownDark,
        ...crimsonDark,
        ...cyanDark,
        ...grassDark,
        ...greenDark,
        ...indigoDark,
        ...limeDark,
        ...mintDark,
        ...orangeDark,
        ...pinkDark,
        ...plumDark,
        ...purpleDark,
        ...redDark,
        ...redDarkA,
        ...skyDark,
        ...tealDark,
        ...tomatoDark,
        ...violetDark,
        ...yellowDark,
        ...yellowDarkA,

        'stripe-l-background': '$whiteA3',
        'stripe-l-background-open': '$whiteA4',
        'stripe-l-border': '$whiteA6',
        'stripe-l-header-hover': '$whiteA4',
        'stripe-l-border-open': '$whiteA7',
        'stripe-l-chevron': '$whiteA8',

        'code-bg': '#0d1117',
        'code-text': '#c9d1d9',
        'code-title': '#d2a8ff',
        'code-string': '#a5d6ff',
        'code-comment': '#8b949e',
        'code-attr': '#79c0ff',
        'code-keyword': '#ff7b72',
        'code-name': '#7ee787',
    },
});
