import { runInAction } from 'mobx';

import restApi from 'api';
import { failRequest, toUrl } from 'utils';

import criteriaStore from 'store/models/CriteriaStore';

import arrayMoveMutable from 'utils/arrayMoveMutable';
import logEvent from 'utils/logEvent';

export async function sortCriterion(oldIndex, index) {
    logEvent('Criteria Sort');
    const criterion = this.criterions[oldIndex];
    const request = { position: index + 1 };

    runInAction(() => {
        arrayMoveMutable(this.criterions, oldIndex, index).forEach((item, index) => {
            const criterionBoard = criteriaStore.criteriaBoardsIds.get(this.getBoardCriterionId(item.id));
            criterionBoard && (criterionBoard.position = index + 1);
        });
    });

    try {
        await restApi.post(`${this.apiEndpoint}/criteria/${criterion.id}/shift`, toUrl(request));
    } catch (e) {
        failRequest(e);
    }
}
