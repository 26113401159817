import api from 'api';
import { failRequest, toUrl } from 'utils';

export async function getTitleByAI(abortController, sendDescription) {
    try {
        const requestData = sendDescription ? toUrl({ description: this.aiText }) : undefined;
        const { data } = await api.post(`/boards/${this.board.id}/ideas/chatgpt-title`, requestData, {
            signal: abortController.signal,
            params: { issue_id: this.id },
        });
        if (data !== this.aiTitle) {
            this.fillWithInterval(data, 'aiTitle', abortController);
        }
    } catch (e) {
        failRequest(e);
        return e.name;
    }
}
