import api from 'api';
import { failRequest, toUrl } from 'utils';

export async function getTitleByAI(abortController, sendDescription) {
    try {
        const dataRequest = sendDescription ? toUrl({ description: this.aiText }) : undefined;
        const { data } = await api.post(`/boards/${this.board.id}/ideas/chatgpt-title`, dataRequest, {
            signal: abortController.signal,
            params: {
                idea_id: this.id,
            },
        });
        if (data !== this.aiName) {
            this.fillWithInterval(data, 'aiName', abortController);
        }
    } catch (e) {
        failRequest(e);
        return e.name;
    }
}
