import { runInAction } from 'mobx';

import api from 'api';

import failRequest from 'utils/failRequest';

import { convertDataToArray } from 'components/FormField/convertDataToArray';

/**
 * Get filter form for editing or creating
 *
 * @return {Promise<void>}
 */
export async function getForm() {
    runInAction(() => (this.form = true));

    try {
        const params = {
            id: this.id,
        };
        if (this.board_id) params.board_id = this.board_id;
        if (this.report_id) params.report_id = this.report_id;

        const { data } = await api.get(`search/fields`, { params });
        const fields = data.fields.map((field) => {
            field.data = convertDataToArray(field.data);
            return field;
        });
        runInAction(() => (this.form = fields));
    } catch (e) {
        failRequest(e);
        runInAction(() => (this.form = null));
    }
}
