import api from 'api';

import failRequest from 'utils/failRequest';
import logEvent from 'utils/logEvent';
import toUrl from 'utils/toUrl';

export async function resolveRequest({ id, remove_votes = false }) {
    logEvent('Resolve request', { remove_votes });

    try {
        await api.post(`/requests/${id}/resolve`, toUrl({ remove_votes, board_id: this.board.id, issue_id: this.id }));
    } catch (error) {
        failRequest(error);
    }
}
