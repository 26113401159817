import Axios from 'axios';

import { rootApi } from 'api';
import { CURRENT_USER_ID, IS_IFRAME } from 'constants/global';
import { failRequest } from 'utils';

import dictionaryStore from 'store/models/DictionaryStore';
import { Idea } from 'store/models/Idea';
import { issuesList } from 'store/models/MainStore';
import { mainStore } from 'store/models/MainStore';
import { utilsStore } from 'store/models/UtilsStore';
import Workspace from 'store/models/Workspace';

function getRequests() {
    let boardsUrl = '/storage/boards';
    let dictionaryUrl = '/storage/dictionaries';

    if (IS_IFRAME) {
        boardsUrl += `?public_boards=${window.publicBoard?.public_id}`;
        dictionaryUrl += `?public_boards=${window.publicBoard?.public_id}`;
    }

    return ['/storage/main', boardsUrl, dictionaryUrl, '/storage/votes'];
}

export async function fetchVoting() {
    try {
        const requests = getRequests();

        const [mainResponse, boardResponse, dictResponse, votesResponse] = await Axios.all(
            requests.map((url) => rootApi.get(url)),
        );

        dictionaryStore.fillCollection(dictResponse.data);
        this.users.setCurrentUser({ id: CURRENT_USER_ID, ...mainResponse.data.me });
        this.workspace = new Workspace(mainResponse.data.workspace, this);
        this.organization.updateModel(mainResponse.data.organization);
        this.boardsList.set(boardResponse.data);

        if (window.idea) {
            utilsStore.toggleCardHidden(false);
            issuesList.setActiveIssue(new Idea({ ...window.idea, boardId: window.publicBoard.public_id }));
        }

        mainStore.setVotingVotes(votesResponse.data);

        this.setReady();
    } catch (error) {
        failRequest(error);
    }
}
