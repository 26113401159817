import api from 'api';

import failRequest from 'utils/failRequest';
import toUrl from 'utils/toUrl';

export async function setColumn({ field, activeColumn, view }) {
    const postData = { id: field.id, activeColumn, grid: view };

    try {
        await api.post(`${this.apiEndpoint}/column`, toUrl(postData));
    } catch (e) {
        failRequest(e);
    }
}
