import React from 'react';

import { observer } from 'mobx-react-lite';

import { mainStore } from 'store/models/MainStore';

import BoardError from 'components/BoardError';
import ButtonLink from 'components/ButtonLink';
import Dot from 'components/Dot';
import Emoji from 'components/Emoji';

import { CountIssuesTag } from './CountIssuesTag';

import styles from './BoardItem.module.sass';

export const BoardItemCounter = ({ count, error }) => {
    if (error) {
        return (
            <div className={styles.error}>
                <BoardError error={error} side="left" />
            </div>
        );
    }
    return <CountIssuesTag slim className={styles.unvoted} count={count} />;
};

const BoardItemDot = observer(({ board }) => {
    if (board.error) {
        return (
            <div className={styles.error}>
                <BoardError error={board.error} side="left" />
            </div>
        );
    }

    if (board.needEvaluate) {
        return <Dot color="red" style={{ width: 8, height: 8, position: 'absolute', top: 0, right: 0 }} />;
    }

    return null;
});

function BoardItem({ board, index }) {
    const isActive = String(mainStore.activeBoard?.id) === String(board.id);

    return (
        <ButtonLink
            aria-selected={isActive}
            combo={index < 9 ? index + 1 : undefined}
            to={board.mainPage}
            isActive={isActive}
            slim
            tooltip={board.fullName}
            leftElm={<Emoji emoji={board.emoji} className="t-size-l" />}
            rightElm={<BoardItemDot board={board} />}
        />
    );
}

export default observer(BoardItem);
