import React from 'react';

import { observer } from 'mobx-react-lite';

import { mainStore } from 'store/models/MainStore';

import { StAvatarStatus } from './StAvatarStatus';

function AvatarStatus({ user, small }) {
    if (user.platform_id) return null;

    return <StAvatarStatus small={small} online={mainStore.onlineUsers.includes(user.id)} />;
}

export default observer(AvatarStatus);
