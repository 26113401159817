import { autorun, makeAutoObservable, reaction, toJS } from 'mobx';

import { IS_IFRAME_CUSTOM_AUTH, IS_IFRAME_NEW_IDEA, IS_VOTING_BOARD, REMOTE_AUTH_PAGE } from 'constants/global';
import { VIEWS } from 'constants/Pages';
import { PLATFORM_CREATION_ID, PLATFORM_CREATION_YT } from 'constants/Providers';
import { switchTheme, THEMES_IDS } from 'themes';

import { settingsStore } from 'modules/SettingsDialog/SettingsStore';

import { issuesList } from 'store/models/MainStore';
import { mainStore } from 'store/models/MainStore';

import appStorage, { APP_STORAGE_KEYS } from 'utils/AppStorage';
import { FAST_INPUT, IS_APP, SHOW_TOOLTIPS, SLIM_BOARD } from 'utils/consts';
import delay from 'utils/delay';
import { getAuthButtonUrl } from 'utils/getAuthButtonUrl';
import { getRandomIntInclusive } from 'utils/getRandom';
import locationReload from 'utils/locationReload';
import logEvent from 'utils/logEvent';

const DEFAULT_SETTINGS = {
    [SHOW_TOOLTIPS]: true,
    [FAST_INPUT]: true,
    [SLIM_BOARD]: false,
    issueHiddenTop: true,
    issueHiddenScore: true,
    issueHiddenAlignment: true,

    //issue final score Stripe
    alignmentOpen: false,
    alignmentSlim: false,

    //Matrix View - grid or diagram
    diagram: true,

    // Final score users
    limitUsers: false,

    /** @type {THEMES_IDS} */
    theme: IS_VOTING_BOARD ? THEMES_IDS.light : THEMES_IDS.dark,

    // criteria manager
    groupByCriteria: true,
    hideUnlinkedCriteria: true,
};

const pathRedirect = (function initRedirect() {
    if (!IS_APP) {
        return false;
    }
    const demoBoardPath = window.demo_board;
    return appStorage.get(APP_STORAGE_KEYS.board_init) || demoBoardPath;
})();

export class UtilsStore {
    userKey = window.userKey || null;
    settings = { ...DEFAULT_SETTINGS };
    blockedTableNav = false;
    pathRedirect = pathRedirect;
    historyAction = appStorage.get(APP_STORAGE_KEYS.voting_action, IS_IFRAME_NEW_IDEA ? { create: null } : null);
    authDialog = null;
    forceLoader = false;
    forceYT = false;
    blocked = false;
    newVersion = false;
    showingTypeform = false;
    openMergeIdeas = false;
    openCriteriaBackup = false;
    openBoardsSearch = false;
    openTemplateOmnibar = false;
    openAnnounceHistory = false;
    openIdeaHistory = false;
    openBoardProgress = false;
    isFocusComment = false;
    fileUploading = false;
    isTrialBannerVisible = false;
    showOptIn = false;

    constructor() {
        appStorage.remove(APP_STORAGE_KEYS.voting_action);

        this.setSettings();

        this.forceLoader = this.pathRedirect && !!PLATFORM_CREATION_ID && !PLATFORM_CREATION_YT;
        this.forceYT = this.pathRedirect && !!PLATFORM_CREATION_YT;

        makeAutoObservable(this);
    }

    setOpenTemplateOmnibar(open = false) {
        this.openTemplateOmnibar = open;
    }

    setOpenBoardsSearch(openBoardsSearch = false) {
        this.openBoardsSearch = openBoardsSearch;
    }

    clearUserActions() {
        this.historyAction = null;
        appStorage.remove(APP_STORAGE_KEYS.voting_action);
    }

    toggleFocusComment(state) {
        this.isFocusComment = state;
    }

    setSettings() {
        const settings = appStorage.get(APP_STORAGE_KEYS.config) || {};
        // Clear unused settings
        const clearSettings = Object.entries(settings).reduce((res, [key, value]) => {
            if (typeof DEFAULT_SETTINGS[key] !== 'undefined') {
                res[key] = value;
            }
            return res;
        }, {});
        this.settings = { ...DEFAULT_SETTINGS, ...clearSettings };
    }

    clearLoaders() {
        this.blocked = false;
        this.forceLoader = false;
    }

    setForceLoader(forceLoader) {
        this.forceLoader = forceLoader;
    }

    setForceYT(forceYT) {
        this.forceYT = forceYT;
    }

    newVersionAlert = () => {
        if (this.newVersion) return;

        this.blocked = true;
        this.newVersion = true;

        const isNotVisiblePage = !document.hasFocus() && document.hidden;

        logEvent('A new version is available', { isNotVisiblePage });

        if (isNotVisiblePage) {
            const timer = getRandomIntInclusive(4, 20);

            delay(timer * 1000).then(() => {
                if (document.hasFocus() || !document.hidden) return;

                logEvent('New version auto-reload');
                locationReload();
            });
        }
    };

    get isSlim() {
        return this.settings[SLIM_BOARD];
    }

    toggleSlimMenu() {
        this.updateSettings({ field: SLIM_BOARD, value: !this.isSlim });
    }

    updateSettings({ field, value }) {
        if (this.settings[field] === value) return;

        if (field !== 'issueVotingField') {
            logEvent('Change switcher setting', { field, value });
        }

        this.settings[field] = value;
    }

    fillSettings = (settings) => {
        logEvent('Change switcher setting', settings);
        Object.assign(this.settings, settings);
    };

    toggleTabNav = (disableTableNav) => {
        this.blockedTableNav = disableTableNav;
    };

    toggleVotingOptIn = (state) => {
        this.showOptIn = state || false;
    };

    get disableTableNav() {
        return settingsStore.isOpen || this.blockedTableNav;
    }

    clearRedirect() {
        appStorage.remove(APP_STORAGE_KEYS.board_init);
        window.demo_board = undefined;
        this.pathRedirect = undefined;
    }

    setOpenMergeIdeas(state = true) {
        this.openMergeIdeas = state;
        !!state && logEvent('Start merge');
    }

    setOpenCriteriaBackup(state = true) {
        this.openCriteriaBackup = state;
    }

    setOpenAnnounceHistory(state = true) {
        this.openAnnounceHistory = state;
    }

    setOpenIdeaHistory(state = true) {
        this.openIdeaHistory = state;
    }

    setOpenBoardProgress(state = true) {
        this.openBoardProgress = state;
    }

    get showAuth() {
        return this.authDialog;
    }

    set showAuth(status) {
        this.authDialog = status;
    }

    setHistoryAction(action) {
        utilsStore.historyAction = action;
        appStorage.set(APP_STORAGE_KEYS.voting_action, action);

        if (IS_IFRAME_CUSTOM_AUTH && window.parent) {
            window.parent.postMessage('showAuth', '*');
        } else if (REMOTE_AUTH_PAGE) {
            const url = getAuthButtonUrl({ isLogin: false, authClient: 'keycloak' });
            window.location.replace(url);
        } else {
            this.showAuth = true;
        }
    }

    clearHistoryActions() {
        this.historyAction = null;
    }

    get cardIsHidden() {
        if (!issuesList.activeIssue?.id) {
            return true;
        }
        if ([VIEWS.TOP, VIEWS.REPORT].includes(mainStore.page)) {
            return this.settings.issueHiddenTop;
        } else if ([VIEWS.USERS_ALIGNMENT, VIEWS.CRITERIA_ALIGNMENT, VIEWS.TOTAL_ALIGNMENT].includes(mainStore.page)) {
            return utilsStore.settings.issueHiddenAlignment;
        }
        return this.settings.issueHiddenScore;
    }

    setTheme = (theme) => {
        logEvent('User set theme', { theme });
        this.updateSettings({ field: 'theme', value: +theme });
    };

    toggleCardHidden(value) {
        if (!this.cardIsHidden) {
            document.activeElement.blur();
        }
        if ([VIEWS.TOP, VIEWS.REPORT].includes(mainStore.page)) {
            this.updateSettings({
                field: 'issueHiddenTop',
                value: value === undefined ? !utilsStore.settings.issueHiddenTop : value,
            });
        } else if ([VIEWS.USERS_ALIGNMENT, VIEWS.CRITERIA_ALIGNMENT, VIEWS.TOTAL_ALIGNMENT].includes(mainStore.page)) {
            this.updateSettings({
                field: 'issueHiddenAlignment',
                value: value === undefined ? !utilsStore.settings.issueHiddenAlignment : value,
            });
        } else {
            this.updateSettings({
                field: 'issueHiddenScore',
                value: value === undefined ? !utilsStore.settings.issueHiddenScore : value,
            });
        }
    }

    toggleTypeform(status) {
        this.showingTypeform = status;
    }

    setTrialBanner(status) {
        this.isTrialBannerVisible = status;
    }

    get isDarkColor() {
        return [1, 3, 5].includes(this.settings.theme);
    }

    get isOpenedSideSettings() {
        return this.openMergeIdeas || this.openAnnounceHistory || this.openIdeaHistory || this.openBoardProgress;
    }

    closeSideSettings = () => {
        this.openMergeIdeas && (this.openMergeIdeas = false);
        this.openAnnounceHistory && (this.openAnnounceHistory = false);
        this.openIdeaHistory && (this.openIdeaHistory = false);
        this.openBoardProgress && (this.openBoardProgress = false);
        this.openCriteriaBackup && (this.openCriteriaBackup = false);
    };

    setFileUploading(state) {
        this.fileUploading = state;
    }
}

export const utilsStore = new UtilsStore();

if (!IS_VOTING_BOARD) {
    autorun(() => {
        const themeId = utilsStore.settings.theme;
        const theme = switchTheme(themeId);

        appStorage.set(APP_STORAGE_KEYS.user_colors, {
            bg1: theme.uiColors.background,
            primary11: theme.uiColors.color,
        });
    });
}

reaction(
    () => toJS(utilsStore.settings),
    (settings) => appStorage.set(APP_STORAGE_KEYS.config, settings),
);
