import React from 'react';

import openChat from 'utils/openChat';

const PriceCalcFooterLinks = () => (
    <div className="t-mutted">
        Questions? Custom Pricing?{' '}
        <u className="interactive" onClick={openChat}>
            Contact us
        </u>
    </div>
);

export default PriceCalcFooterLinks;
