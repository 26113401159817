import React from 'react';

import { Classes } from '@blueprintjs/core';
import { observer } from 'mobx-react-lite';

import { AsanaProjects } from 'modules/InsideIssue/components';

import { mainStore } from 'store/models/MainStore';

import { DICTIONARY_STATUSES, DICTIONARY_TYPES } from 'utils/consts';

import CellWrapper from 'components/CellWrapper/CellWrapper';
import { CustomIcon, CustomIconName } from 'components/CustomIcon';
import { ScoreCell } from 'components/MasterTable/components/TotalValue/ScoreCell';
import RowNumber from 'components/RowNumber';
import VotingPercent from 'components/VotingPercent';

import CellIssueName from './CellIssueName';
import { IssueAssignee } from './IssueAssignee/IssueAssignee';
import { IssueDictValue } from './IssueDictValue/IssueDictValue';
import { IssueReporter } from './IssueReporter/IssueReporter';
import ResumeColumn from './ResumeColumn';
import SkipColumn from './SkipColumn';
import SourceCell from './SourceCell';
import { TotalScoreTitle } from './TotalScoreTitle/TotalScoreTitle';
import { UserTotalValue } from './UserTotalValue/UserTotalValue';

export const NAME_COLUMN = {
    name: 'Name',
    id: 'name',
    format: (issue, index, { hideTop, disabled }) => (
        <CellIssueName hideTop={hideTop} disabled={disabled} issue={issue} index={index} />
    ),
    width: 350,
    editable: true,
};

export const TYPE_COLUMN = {
    name: 'Type',
    id: 'type',
    format: (issue) => <IssueDictValue issue={issue} dictionary={DICTIONARY_TYPES} />,
    width: 85,
    editable: true,
};

export const PREVIEW_TYPE_COLUMN = {
    name: 'Type',
    id: 'type_id',
    width: 85,
    format: (issue) => <CellWrapper>{issue.type_id || issue.type}</CellWrapper>,
};

export const ASSIGNEE_COLUMN = {
    name: 'Assignee',
    id: 'assignee',
    format: (issue) => <IssueAssignee issue={issue} />,
    width: 150,
    editable: true,
};

export const PREVIEW_STATUS_COLUMN = {
    name: 'Status',
    id: 'status_id',
    width: 100,
    format: (issue) => <CellWrapper>{issue.status_id || issue.status || issue.customFields?.status}</CellWrapper>,
};

export const PREVIEW_DESC_COLUMN = {
    name: 'Description',
    id: 'description',
    width: 700,
    format: (idea) => (
        <CellWrapper>
            <div className="t-crop clear-p" dangerouslySetInnerHTML={{ __html: idea.description }} />
        </CellWrapper>
    ),
};

const INDEX_COLUMN = {
    name: <CustomIcon size={16} icon={CustomIconName.HASHTAG} />,
    id: 'row_number',
    width: 48,
    format: (issue) => (
        <CellWrapper isNumber>
            <BoardIndexColum issue={issue} />
        </CellWrapper>
    ),
};

const REPORT_INDEX_COLUMN = {
    name: <CustomIcon icon={CustomIconName.HASHTAG} />,
    id: 'report_row_number',
    width: 40,
    format: (issue) => (
        <CellWrapper isNumber>
            <ReportIndexColum issue={issue} />
        </CellWrapper>
    ),
};

export const COLUMNS = [
    INDEX_COLUMN,
    REPORT_INDEX_COLUMN,
    NAME_COLUMN,
    TYPE_COLUMN,
    {
        id: 'issuetype',
        format: (issue) => <IssueDictValue issue={issue} dictionary={DICTIONARY_TYPES} />,
        width: 85,
        editable: true,
    },
    ASSIGNEE_COLUMN,
    {
        id: 'reporter',
        format: (issue) => <IssueReporter issue={issue} />,
        width: 150,
        editable: true,
    },
    {
        id: 'members',
        format: (issue) => {
            if (!issue.membersList || issue.membersList.length === 0) {
                return <CellWrapper>{' - '}</CellWrapper>;
            }
            const list = issue.membersList.map((el) => el && el.name);
            return (
                <CellWrapper>
                    <div title={list.join(', ')} className="bp5-text-overflow-ellipsis">
                        {list.join(', ')}
                    </div>
                </CellWrapper>
            );
        },
        width: 150,
    },
    {
        id: 'status',
        format: (issue) => <IssueDictValue issue={issue} dictionary={DICTIONARY_STATUSES} />,
        width: 85,
        editable: true,
    },
    {
        id: 'type_id',
        format: (issue) => <IssueDictValue issue={issue} dictionary={DICTIONARY_TYPES} />,
        width: 85,
        editable: true,
    },
    {
        id: 'status_id',
        format: (issue) => <IssueDictValue issue={issue} dictionary={DICTIONARY_STATUSES} />,
        width: 85,
        editable: true,
    },
    {
        id: 'projects',
        format: (issue) => (
            <CellWrapper>
                <div className={Classes.TEXT_OVERFLOW_ELLIPSIS}>
                    <AsanaProjects issue={issue} onlyList />
                </div>
            </CellWrapper>
        ),
        width: 85,
    },
    {
        id: 'voting_percent',
        tooltip: 'Shows how many users evaluated the issue',
        format: (issue) => (
            <CellWrapper>
                <VotingPercent minimal issue={issue} />
            </CellWrapper>
        ),
        width: 70,
    },
    {
        id: 'provider',
        format: (issue) => <SourceCell issue={issue} />,
        width: 40,
    },
    {
        name: <TotalScoreTitle />,
        id: 'totalValue',
        format: (issue) => <ScoreCell issue={issue} />,
        width: 60,
        interactive: true,
    },
    {
        id: 'idea_votes_count',
        format: (issue) => <UserTotalValue issue={issue} />,
        width: 65,
        interactive: true,
    },
    {
        id: 'alignmentValue',
        format: ({ alignmentValue }) => <CellWrapper isNumber>{alignmentValue}</CellWrapper>,
        width: 100,
    },
];

const BoardIndexColum = observer(({ issue }) => {
    return <RowNumber issue={issue} row_number={issue.row_number} />;
});

const ReportIndexColum = observer(({ issue }) => {
    if (!mainStore.report) return null;

    // TODO: https://concertwithme.atlassian.net/browse/DCLS-6798
    // const row_number = report.considerBlockers
    //     ? report.orderedIssuesIdsByBlocks.get(issue.id)
    //     : report.orderedIssuesIds.indexOf(issue.id) + 1;

    const index = mainStore.report.orderedIssuesIds.indexOf(`${issue.id}-${issue.boardId}`);

    return <RowNumber issue={issue} row_number={index + 1} />;
});

export const SKIP_COLUMN = {
    name: null,
    id: 'issue-actions',
    format: (issue, rowIndex) => issue && <SkipColumn rowIndex={rowIndex} issue={issue} />,
    width: 65,
    disableSort: true,
};

export const RESUME_COLUMN = {
    name: null,
    id: 'resume_issue',
    format: (issue) =>
        issue && (
            <CellWrapper>
                <ResumeColumn issue={issue} userId={mainStore.currentUser.id} />
            </CellWrapper>
        ),
    width: 40,
    disableSort: true,
};
