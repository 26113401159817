import restApi from 'api';
import { IS_VOTING_BOARD } from 'constants/global';
import { toUrl } from 'utils';

export async function afterSaveLanguage({ language, old_language, board_id }) {
    const boardIdField = IS_VOTING_BOARD ? 'publicId' : 'boardId';
    await restApi.post('/boards/voting-translate', toUrl({ language, old_language, [boardIdField]: board_id }));

    if (IS_VOTING_BOARD) {
        await this.fetchBoards(board_id ? { public_boards: board_id } : undefined);
        await this.fetchBoardsDictionaries(board_id ? { publicId: board_id } : undefined);
    }
}
