import React from 'react';

import { ChecklistItem } from './ChecklistItem';

export function Checklist({ items, name }) {
    return (
        <>
            <b className="d-b">{name}</b>
            <div className="h-5" />
            {items.map((item) => (
                <ChecklistItem key={item.name} item={item} />
            ))}
        </>
    );
}
