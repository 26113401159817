import React from 'react';

import { observer } from 'mobx-react-lite';

import { mainStore } from 'store/models/MainStore';

import { EmptyValue } from 'components/UserBlock/EmptyValue';

import { UserSelector } from './UserSelector';
import { UserView } from './UserView';

const UserBlock = observer(({ simple, issue, user, editable, field = 'assignee', tooltip }) => {
    if (editable) {
        return (
            <UserSelector
                simple={simple}
                issue={issue}
                tooltip={tooltip}
                field={field}
                users={mainStore.users.activeOrderedUsers}
                user={user}
            />
        );
    }

    let emptyValue;
    if (!user) {
        emptyValue = field === 'reporter' ? <EmptyValue text="Not set" /> : <EmptyValue text="Unassigned" />;
    }

    const userView = !user ? emptyValue : <UserView user={user} />;

    if (user) {
        return (
            <div style={{ lineHeight: '24px' }} className="b-minimize" title={user.name}>
                {userView}
            </div>
        );
    }

    return userView;
});

export default UserBlock;
