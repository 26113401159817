import restApi from 'api';
import { failRequest, toUrl } from 'utils';

export async function unlinkBot(bot) {
    try {
        await restApi.post(`/user/unlink-bot`, toUrl({ bot_user_id: bot.id }));
    } catch (e) {
        failRequest(e);
    }
}
