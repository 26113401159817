import { CRITERION_TYPE } from 'constants/Criterion';

export const FORMULA_RADIO_DATA = [
    { value: CRITERION_TYPE.value, tooltip: 'A criterion will increase a task’s total score' },
    { value: CRITERION_TYPE.effort, tooltip: 'A criterion will decrease a task’s total score' },
    {
        value: CRITERION_TYPE.ignore,
        tooltip:
            'Ignore Criteria for Priority Score calculation. You can pass some additional information into that field for future syncing with task tracker or using as a custom field in the Backlog table without influencing priority score',
    },
    {
        value: CRITERION_TYPE.multiplier,
        tooltip:
            'Multiplier for the Sum and Multiply formula. The result of calculating other criteria is multiplied by this value. This criterion is ignored in other formulas.',
    },
];
export const FORMULA_AVG = 'avg';
export const FORMULA_RICE = 'rice';
export const FORMULA_SUM = 'sum';
export const FORMULA_WSJF = 'wsjf';
export const FORMULA_AVG_M = 'avg_multi';

export const FORMULA_NAMES = {
    [FORMULA_AVG]: 'Sum',
    [FORMULA_RICE]: 'Multiply and Divide',
    [FORMULA_WSJF]: 'Sum and Divide',
    [FORMULA_AVG_M]: 'Sum and Multiply',
};

/**
 * Get formula name by key
 *
 * @param {string} key
 * @return {string}
 */
export function getFormulaNameByKey(key) {
    if (!key) return '';

    return FORMULA_NAMES[key] ?? key;
}
