import React from 'react';

import { Icon } from '@blueprintjs/core';
import { observer } from 'mobx-react-lite';

import { StButton } from 'atoms/StButton';

function ColorLabel({ className, name, color, onClick, large, small, active, interactive }) {
    return (
        <StButton
            as={!active && interactive ? undefined : 'span'}
            size={large ? 28 : small ? 18 : 20}
            minimal={interactive && !active && !large}
            onClick={!active ? onClick : undefined}
            color={color}
            className={className}
            noneInteractive={!interactive}
            active={active}
            aria-selected={active}
        >
            <span className="bp5-text-overflow-ellipsis">{name}</span>
            {!name && <Icon icon="stop" />}
        </StButton>
    );
}

export default observer(ColorLabel);
