import { runInAction } from 'mobx';

import logEvent from 'utils/logEvent';

export async function closeBanner(banner) {
    logEvent('Close Board Callout', { id: banner });
    const closedNotes = [...(this.settings.closedNotes || []), banner];

    runInAction(() => {
        this.settings.closedNotes = closedNotes;
    });

    await this.setSettings({ closedNotes });
}
