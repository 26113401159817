export const BaseStyleConfig = {
    fontSizes: {
        100: '12px',
        200: '13px',
        300: '14px',
        400: '16px',
        500: '18px',
        600: '20px',
        700: '24px',
    },
    fontWeights: {
        1: 400,
        2: 500,
        3: 600,
        4: 800,
    },
    fonts: {
        inter: '"Inter", system-ui, -apple-system, "BlinkMacSystemFont", "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Open Sans", "Helvetica Neue", sans-serif',
        code: 'Fira Code, "SFMono Regular", Consolas, "Liberation Mono", Menlo, menlo, Courier, monospace',
    },
    transitions: {
        duration: '0.2s',
    },
    filter: {
        filterInvert: 'invert(0)',
    },
    radii: {
        2: '2px',
        4: '4px',
        8: '8px',
    },
    sizes: {
        1: '4px',
        2: '8px',
        3: '10px',
        4: '12px',
        5: '14px',
        6: '16px',
        7: '20px',
        8: '30px',
        9: '40px',
        10: '60px',
    },
    space: {
        1: '2px',
        2: '4px',
        3: '6px',
        4: '8px',
        5: '10px',
        6: '12px',
        7: '14px',
        8: '16px',
    },
};
