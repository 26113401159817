import { runInAction } from 'mobx';

import api from 'api';

import failRequest from 'utils/failRequest';

export async function uploadVotingCSV({ files, save = false }) {
    try {
        const formData = new FormData();
        Array.from(files).forEach((file) => formData.append('file', file));
        formData.append('preview', Number(!save));
        formData.append('save', Number(save));

        runInAction(() => {
            this.voting_users_import_running = save;
        });

        const { data } = await api.post(`${this.apiEndpoint}/voting-users/csv`, formData);
        return data;
    } catch (error) {
        failRequest(error);
        return await Promise.reject(error);
    }
}
