import React from 'react';

import { observer } from 'mobx-react-lite';
import { FormattedMessage } from 'react-intl';

import { getAuthButtonUrl } from 'utils/getAuthButtonUrl';

import { AnchorButton } from 'components/Button';
import { CustomIconName } from 'components/CustomIcon';

const SsoButton = ({ isLogin, isUpdater, serviceName, authClient, icon = CustomIconName.KEY }) => {
    return (
        <AnchorButton
            block
            icon={icon}
            iconSize={24}
            size={42}
            color="white"
            href={getAuthButtonUrl({ isLogin, authClient })}
            text={
                isUpdater ? (
                    <FormattedMessage
                        id="auth.change.sso_btn"
                        defaultMessage="Change email via {serviceName}"
                        values={{ serviceName }}
                    />
                ) : !isLogin ? (
                    <FormattedMessage
                        id="auth.signup.sso_btn"
                        defaultMessage="Sign Up with {serviceName}"
                        values={{ serviceName }}
                    />
                ) : (
                    <FormattedMessage
                        id="auth.sso_btn"
                        defaultMessage="Log In with {serviceName}"
                        values={{ serviceName }}
                    />
                )
            }
        />
    );
};

export default observer(SsoButton);
