import api from 'api';
import { VIEWS } from 'constants/Pages';

import { issuesList } from 'store/models/MainStore';
import { mainStore } from 'store/models/MainStore';

import failRequest from 'utils/failRequest';
import logEvent from 'utils/logEvent';

export async function sendSubscribe({ question }) {
    logEvent('Send subscribe on question');

    if (mainStore.page === VIEWS.EVALUATION) {
        this.setRemoved(true);
        this.board.getNextIssue(issuesList.row);
    }

    try {
        await api.post(`/requests/${question.id}/subscribe`, null, {
            params: { board_id: question.board_id, issue_id: question.issue_id },
        });
    } catch (error) {
        failRequest(error);
    }
}
