import api from 'api';
import { failRequest } from 'utils';

import { UNSAVED_MODEL_ID } from 'utils/consts';

export async function getVoteStats() {
    if (!this.isDone || this.isRemoved || this.id === UNSAVED_MODEL_ID) return;

    try {
        const { data } = await api.get(`${this.apiCurrentEndpoint}/stats `);
        this.fillVotesStats(data);
    } catch (e) {
        failRequest(e);
    }
}
