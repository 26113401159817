import React from 'react';

import Avatar from 'components/Avatar';
import AvatarTooltip from 'components/AvatarTooltip';
import Flex from 'components/Flex';

export function AlignmentTableRow({ user, slim, children }) {
    return (
        <tr>
            <td className="t-nw b-va">
                <Flex gap={4}>
                    {slim ? (
                        <AvatarTooltip tooltipProps={{ position: 'left' }} user={user} size={20} />
                    ) : (
                        <Avatar user={user} size={20} />
                    )}
                    {!slim && <span>{user.name}</span>}
                </Flex>
            </td>
            {children}
        </tr>
    );
}
