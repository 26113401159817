import React, { useEffect } from 'react';

import { observer } from 'mobx-react-lite';

import { mainStore } from 'store/models/MainStore';
import paymentPlans from 'store/models/PaymentPlans';

import { StHr } from 'atoms/StHr';
import { StPriceCalcWrapper } from 'atoms/StPriceCalcWrapper';

import formatPriceWithCurrency from 'utils/formatPriceWithCurrency';
import getCntText from 'utils/getCntText';
import getCurrencySymbolByCode from 'utils/getCurrencySymbolByCode';

import { CustomIcon, CustomIconName } from 'components/CustomIcon';
import Flex from 'components/Flex';
import CalcDescription from 'components/SubscriptionsPlans/CalcDescription';

import CalcPlanName from './components/CalcPlanName';
import CalcUsersIncluded from './components/CalcUsersIncluded';
import CalcUsersOverLimit from './components/CalcUsersOverLimit';
import CalcYear from './components/CalcYear';
import EmailCalculator from './EmailCalculator';
import MainPlanTotal from './MainPlanTotal';
import PriceCalcFooterLinks from './PriceCalcFooterLinks';
import { calcDisc, getSubtotal } from './utils';

function PlanCheckoutCalculation() {
    useEffect(() => {
        mainStore.organization.fetchOrganization();
    }, []);

    if (!paymentPlans.monthPlan) {
        return <StPriceCalcWrapper column align="none" disabled />;
    }

    const planM = paymentPlans.monthPlan;
    const plan = paymentPlans.activePlan;
    const quantity = paymentPlans.people;
    const plans = paymentPlans.plans;

    const discount = calcDisc(plan, quantity, plan.interval, plans);
    const subtotal = getSubtotal(paymentPlans.monthPlan, quantity);

    return (
        <StPriceCalcWrapper column align="none">
            <Flex column gap={10} align="none" css={{ padding: 16 }}>
                <Flex spaceBetween>
                    <Flex gap={4}>
                        <CustomIcon icon={CustomIconName.TEAM_ALT} />
                        <span>Account Users</span>
                    </Flex>
                    <b>{getCntText(quantity, ['user', 'users'])}</b>
                </Flex>

                <StHr />

                <CalcPlanName planM={planM} active={plan} quantity={quantity} />

                <CalcUsersOverLimit planM={planM} quantity={quantity} />

                <CalcUsersIncluded quantity={quantity} planM={planM} />

                <CalcYear type={plan.interval} quantity={quantity} active={plan} planM={planM} />

                {plan.interval !== 'monthly' && subtotal && (
                    <Flex spaceBetween>
                        <b>Subtotal</b>
                        <b>{formatPriceWithCurrency({ price: subtotal, currencyCode: plan.currency })}</b>
                    </Flex>
                )}

                <StHr />

                <Flex spaceBetween gap={4}>
                    <span>Pay</span>
                    <b>{plan.interval === 'monthly' ? 'Monthly' : 'Yearly'}</b>
                </Flex>

                <Flex spaceBetween className={plan.interval === 'monthly' ? 'o-6' : 't-intent-yellow'}>
                    <span>{plan.interval === 'monthly' ? 'Yearly Discount will be' : 'Yearly Discount'}</span>
                    <b>
                        {discount &&
                            formatPriceWithCurrency({
                                price: discount,
                                currency: '- ' + getCurrencySymbolByCode(plan.currency),
                            })}
                    </b>
                </Flex>

                <StHr />

                <MainPlanTotal />

                <div className="flex-grow" />

                <EmailCalculator />

                <div className="flex-grow" />

                <CalcDescription />

                <StHr />

                <PriceCalcFooterLinks />
            </Flex>
        </StPriceCalcWrapper>
    );
}

export default observer(PlanCheckoutCalculation);
