import api from 'api';
import { sendToSentry } from 'utils';

import failRequest from 'utils/failRequest';

export async function remove() {
    if (this.is_demo && this.board.demoIssuesCount > 0) {
        this.board.toggleDemoRemover(true);
    }
    try {
        await api.delete(this.apiCurrentEndpoint);
    } catch (error) {
        failRequest(error);
        sendToSentry('Fail remove Issue', { path: this.apiCurrentEndpoint, error });
    }
}
