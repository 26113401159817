import { DateTime } from 'luxon';

/**
 * Converts a date to a DateTime object in the specified timezone.
 *
 * @param {string|Date} date
 * @param {string} zone
 * @returns {DateTime} A Luxon DateTime object in the specified timezone.
 * @throws {Error} If the input is neither a string nor a Date object.
 *
 * @example
 * // Using an ISO string
 * const dateTime1 = getDateTimeForTimezone('2023-04-01T12:00:00Z', 'America/New_York');
 *
 * // Using a Date object
 * const dateTime2 = getDateTimeForTimezone(new Date(), 'Europe/London');
 */
export default function getDateTimeForTimezone(date, zone) {
    if (typeof date === 'string') {
        return DateTime.fromISO(date).setZone(zone);
    }

    if (date instanceof Date) {
        return DateTime.fromJSDate(date).setZone(zone);
    }

    throw new Error('Invalid date input. Expected string or Date object.');
}
