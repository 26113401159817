import { memo } from 'react';

import { StSpinnerHead, StSpinner, StSpinnerAnimation, StSpinnerTrack } from './atoms';
import { MIN_STROKE_WIDTH, PATH_LENGTH, SPINNER_TRACK, STROKE_WIDTH } from './constants';
import { clamp, getViewBox } from './utils';

const Spinner = memo(({ size = 50, color, value = null, ...props }) => {
    const hasValue = value != null;

    const strokeWidth = Math.min(MIN_STROKE_WIDTH, (STROKE_WIDTH * 100) / size);
    const strokeOffset = PATH_LENGTH - PATH_LENGTH * (value == null ? 0.25 : clamp(value, 0, 1));

    return (
        <StSpinner
            {...props}
            aria-valuemax="100"
            aria-valuemin="0"
            aria-valuenow={(value || 0) * 100}
            role="progressbar"
            size={size}
        >
            <StSpinnerAnimation disable={hasValue}>
                <svg width={size} height={size} strokeWidth={strokeWidth.toFixed(2)} viewBox={getViewBox(strokeWidth)}>
                    <StSpinnerTrack d={SPINNER_TRACK} />
                    <StSpinnerHead
                        color={color}
                        d={SPINNER_TRACK}
                        pathLength="280"
                        strokeDasharray="280 280"
                        strokeDashoffset={strokeOffset}
                    />
                </svg>
            </StSpinnerAnimation>
        </StSpinner>
    );
});

Spinner.displayName = 'Spinner';

export default Spinner;
