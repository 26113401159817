import { useEffect } from 'react';

import getKeyCode from 'utils/getKeyCode';
import isActionElement from 'utils/isActionElement';
import logEvent from 'utils/logEvent';

export const isOSX = /Mac OS X/.test(window.navigator.userAgent);

const KeyBindingUtil = {
    isCtrlKeyCommand(e) {
        return !!e.ctrlKey && !e.altKey;
    },

    hasCommandModifier(e) {
        return isOSX ? !!e.metaKey && !e.altKey : KeyBindingUtil.isCtrlKeyCommand(e);
    },
};

export function useOneHotKey(key, callback, force = false) {
    useEffect(() => {
        function hotKey(event) {
            if (KeyBindingUtil.hasCommandModifier(event) || (!force && isActionElement(event))) {
                return;
            }

            const keyCode = getKeyCode(event);
            if (keyCode === key) {
                logEvent('Use single hot key', { key });
                event.preventDefault();
                callback();
            }
        }
        document.addEventListener('keydown', hotKey);
        return () => {
            document.removeEventListener('keydown', hotKey);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [key]);
}
