import { runInAction } from 'mobx';

import api from 'api';
import { failRequest } from 'utils';

export async function getUnreadCount() {
    try {
        const { data } = await api.get(`${this.apiEndpoint}/announces/stats`);
        runInAction(() => {
            this.unread_count = data?.unread_count || 0;
        });
    } catch (e) {
        failRequest(e);
    }
}
