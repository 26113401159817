import { makeAutoObservable, observable, runInAction } from 'mobx';

import api from 'api';
import { CURRENT_ORG_ID, CURRENT_USER_ID } from 'constants/global';
import { PLATFORM_CREATION_YT, PROVIDER_DUCALIS } from 'constants/Providers';

import { utilsStore } from 'store/models/UtilsStore';

import failRequest from 'utils/failRequest';
import logEvent from 'utils/logEvent';
import runTaskWorker from 'utils/runTaskWorker';
import toUrl from 'utils/toUrl';

import { CustomIconName } from 'components/CustomIcon';
import { convertDataToArray } from 'components/FormField/convertDataToArray';
import { toast } from 'components/Toast';

import { mainStore } from './MainStore';
import { prepareFormData } from './ProviderForm';

export class Platform {
    _links = {};
    auth_type = undefined;
    error = null;
    hasExternalProviders = false;
    id = null;
    name = '';
    provider = '';
    provider_type = '';
    settings = null;
    filtersLoading = false;
    filters = [];
    statuses = [];
    preview = null;
    previewLoading = null;
    supportsExport = false;
    ideaStatusTrigger = false;

    constructor(data) {
        makeAutoObservable(this, {
            _links: observable.struct,
            statuses: observable.struct,
        });

        this.fillModel(data);

        if (this.id === PLATFORM_CREATION_YT) {
            this.getSettings();
        }
    }

    fillModel(data) {
        Object.assign(this, data);
    }

    get users() {
        return mainStore.users.users.filter((user) => user.platformIDs.includes(this.id));
    }

    get isActive() {
        return this.hasAccess && !this.error;
    }

    get hasAccess() {
        const user = mainStore.currentUser;
        if (!user) {
            return false;
        }
        return user.platformIDs?.includes(this.id) || false;
    }

    get isDucalis() {
        return this.provider === PROVIDER_DUCALIS;
    }

    get boardsCount() {
        return mainStore.boardsList.boards.filter((board) => board.platform_id === this.id).length;
    }

    async remove({ approved = null }) {
        runInAction(() => {
            this.loading = true;
        });
        logEvent('REMOVE_PLATFORM', { platform: this.id, approved });
        try {
            await api.delete(`/platforms/${this.id}`, { data: toUrl({ approved }) });
            mainStore.platformsList.setConfirmation(false);
            mainStore.platformsList.removeSingle(this.id);
            toast({
                icon: CustomIconName.TICK,
                description: 'Platform was successfully removed',
                color: 'green',
                duration: 7000,
            });
        } catch (e) {
            failRequest(e);
        } finally {
            runInAction(() => {
                this.loading = false;
            });
        }
    }

    async getSettings() {
        try {
            const { data } = await api.get(`/platforms/${this.id}/youtrack-settings`);
            runInAction(() => {
                const fields = data.fields.map((field) => {
                    field.data = convertDataToArray(field.data);
                    return field;
                });
                this.settings = { ...data, fields };
            });
        } catch (e) {
            failRequest(e);
        }
    }

    async setSettings({ field, value, save }) {
        const fields = this.settings.fields.map((el) => {
            if (el.name === field.name) {
                return { ...el, value };
            }
            return el;
        });
        const newSettings = {
            ...this.settings,
            fields,
            errors: [],
            hasChanges: true,
        };
        if (save) {
            try {
                runInAction(() => {
                    this.settings.fields = fields;
                    this.settings.errors = [];
                    this.settings.hasChanges = true;
                    this.settings.loading = true;
                });
                const form = prepareFormData(newSettings);
                const { data } = await api.post(`/platforms/${this.settings.model.id}/youtrack-settings`, toUrl(form), {
                    headers: { 'X-Reload-Form': 1 },
                });
                runInAction(() => {
                    this.settings = { ...this.settings, ...data };
                });
            } catch (e) {
                if (e.response && e.response.data && e.response.status === 422) {
                    runInAction(() => {
                        this.settings = { ...this.settings, ...e.response.data };
                    });
                } else {
                    failRequest(e);
                }
            }
        } else {
            runInAction(() => {
                this.settings = newSettings;
            });
        }
    }

    async saveSettings({ onDone }) {
        try {
            runInAction(() => {
                this.settings.loading = true;
            });

            const platform_id = this.settings.model.id;
            const form = prepareFormData(this.settings);
            await api.put(`/platforms/${this.settings.model.id}/youtrack-settings`, toUrl(form));

            onDone && onDone();
            runInAction(() => {
                this.settings = null;
            });

            const data = {
                method: utilsStore.forceYT ? 'updatePlatformData' : 'updateJiraData',
                params: { organization_id: CURRENT_ORG_ID, platform_id },
            };

            if (utilsStore.forceYT) {
                data.params.user_id = CURRENT_USER_ID;
                data.params.notify = 1;
                if (utilsStore.pathRedirect && /\/integration\/(\d+)\//i.test(utilsStore.pathRedirect)) {
                    const [, id] = utilsStore.pathRedirect.match(/\/integration\/(\d+)\//i);
                    if (id) {
                        data.params.board_id = id;
                    }
                }
            }

            utilsStore.setForceYT(false);

            const taskRunner = new runTaskWorker({
                data,
                onStart: () => utilsStore.setForceLoader(true),
                onComplete: () => {
                    mainStore.fetchAll();
                    utilsStore.setForceLoader(false);
                },
                onFail: () => {
                    mainStore.fetchAll();
                    utilsStore.setForceLoader(false);
                },
            });

            taskRunner.run();
        } catch (e) {
            if (e.response && e.response.data && e.response.status === 422) {
                runInAction(() => {
                    this.settings = { ...this.settings, ...e.response.data };
                });
            } else {
                failRequest(e);
                runInAction(() => {
                    this.settings.loading = false;
                });
            }
        }
    }
}
