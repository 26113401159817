import { showReportDialog } from '@sentry/react';

import { CURRENT_USER } from 'constants/global';

export default function openChat(event) {
    if (window.$crisp) {
        event?.preventDefault();
        window.$crisp.push(['do', 'chat:open']);
    } else if (window.$chatwoot) {
        event?.preventDefault();
        window.$chatwoot.toggle('open');
    } else {
        const { name, email } = CURRENT_USER || {};
        showReportDialog({ eventId: undefined, user: { email, name } });
    }
}
