import { CURRENT_USER_IS_ADMIN, DB_NAME } from 'constants/global';

import * as crisp from 'utils/crisp';
import debugLog from 'utils/debugLog';

import { sendAmplitudeData } from './amplitude';

/**
 * Point for send user events to Amplitude or GTM dataLayer
 *
 * @param {string} message
 * @param {object|null} props
 */
export default function logEvent(message, props = null) {
    props = (props && { ...props }) || {};
    if (window.location && window.location.pathname) {
        props.pathname = window.location.pathname;
    }

    const clearProps = Object.entries(props).reduce((res, [key, value]) => {
        if (value === undefined) {
            return res;
        }

        if (value === null) {
            res[key] = String(value);
        } else {
            res[key] = value;
        }
        return res;
    }, {});

    if (!CURRENT_USER_IS_ADMIN) {
        try {
            sendAmplitudeData(message, clearProps);
            crisp.sendSessionEvent(message, clearProps, 'black');
            window._paq && window._paq.push(['trackEvent', message, DB_NAME]);
        } catch (e) {
            console.error('Fail logEvent', e);
        }
    }

    process.env.REACT_APP_ENV !== 'prod' && debugLog('logEvent', { message, eventProperties: clearProps });
}
