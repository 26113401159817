export function fixTipTapContent(html) {
    let container = document.createElement('template');
    container.innerHTML = html;

    let el;
    // Move all images out of paragraphs.
    while ((el = container.content.querySelector('p > img'))) {
        let parent = el.parentNode;
        parent.after(el);
        if (parent.innerHTML.trim().length === 0) {
            parent.remove();
        }
    }

    return container.innerHTML;
}
