function compareValues(valA, valB, isAsc, multiplier) {
    if (valA === valB) return 0;

    if (valA == null) return multiplier;
    if (valB == null) return -multiplier;

    if (typeof valA === 'number' && typeof valB === 'number') {
        return isAsc ? valA - valB : valB - valA;
    }

    if (isAsc) return String(valA).localeCompare(valB);

    return String(valB).localeCompare(valA);
}

function orderBy(list, iteratorFn, sortDirection = 'asc') {
    // Create a mapping of values to their original indices
    const indexMap = {};
    list.forEach((item, index) => {
        indexMap[index] = iteratorFn(item);
    });

    const isAsc = sortDirection === 'asc';
    const multiplier = isAsc ? 1 : -1;

    const sortedIndexes = Object.keys(indexMap).sort((keyA, keyB) =>
        compareValues(indexMap[keyA], indexMap[keyB], isAsc, multiplier),
    );

    return sortedIndexes.map((index) => list[index]);
}

export default orderBy;
