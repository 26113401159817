import { runInAction } from 'mobx';

import api from 'api';
import { failRequest } from 'utils';

import toUrl from 'utils/toUrl';

export async function setFacilitator(user_id, criterion_id) {
    !criterion_id &&
        runInAction(() => {
            this.facilitators.add(user_id);
        });

    try {
        const requestData = toUrl({ user_id, criterion_id });
        await api.post(`${this.apiEndpoint}/facilitator`, requestData);
    } catch (e) {
        failRequest(e);
    }
}
