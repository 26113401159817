import { makeAutoObservable, action } from 'mobx';

import { PROVIDER_DUCALIS } from 'constants/Providers';

import { Platform } from './Platform';

export default class Platforms {
    platformsIds = new Map();
    needConfirmation = null;

    constructor() {
        makeAutoObservable(this, { getPlatformById: action });
    }

    set = (platforms) => {
        const currentIds = new Set(Array.from(this.platformsIds.keys()));

        platforms.forEach((platform) => {
            if (!this.platformsIds.has(platform.id)) {
                this.platformsIds.set(platform.id, new Platform(platform));
            } else {
                this.platformsIds.get(platform.id).fillModel(platform);
                currentIds.delete(platform.id);
            }
        });

        currentIds.forEach((id) => this.platformsIds.delete(id));
    };

    get platforms() {
        return Array.from(this.platformsIds.values());
    }

    setConfirmation(needConfirmation) {
        this.needConfirmation = needConfirmation;
    }

    /**
     * @returns {undefined|Platform}
     */
    get ducalisPlatform() {
        return this.platforms.find((el) => el.provider === PROVIDER_DUCALIS);
    }

    clearLoadingStatus() {
        this.platforms.forEach((el) => (el.loading = false));
    }

    get removingPlatform() {
        return this.needConfirmation ? this.platformsIds.get(this.needConfirmation) : undefined;
    }

    removeSingle(platformId) {
        this.platformsIds.delete(platformId);
    }

    update(data) {
        if (this.platformsIds.has(data.id)) {
            this.platformsIds.get(data.id).fillModel(data);
        } else {
            this.platformsIds.set(data.id, new Platform(data));
        }
    }
}
