import React from 'react';

import { observer } from 'mobx-react-lite';

import { IS_PUBLIC_BOARD } from 'constants/global';

import Flex from 'components/Flex';
import ProgressSpinner from 'components/ProgressSpinner';
import { ProgressButton } from 'components/ProgressSpinner/ProgressSpinner';
import Tooltip from 'components/Tooltip';

import UnevaluatedUsersTooltipContent from './UnevaluatedUsersTooltipContent';

function VotingPercent({ issue, minimal, grow = true }) {
    const progress = (
        <Flex gap={4} grow={grow}>
            <ProgressSpinner progress={issue.voting_percent} size={16} />
            <ProgressButton minimal={minimal} progress={issue.voting_percent} />
        </Flex>
    );

    if (IS_PUBLIC_BOARD) {
        const tooltip =
            issue.voting_percent < 100
                ? 'Task is not evaluated yet by all teammates'
                : 'All board members evaluated the issue';
        return (
            <Tooltip align="top" content={tooltip}>
                {progress}
            </Tooltip>
        );
    }

    if (issue.voting_percent < 100) {
        return <Tooltip content={<UnevaluatedUsersTooltipContent issue={issue} />}>{progress}</Tooltip>;
    }

    return (
        <Tooltip side="top" content="All board members evaluated the issue">
            {progress}
        </Tooltip>
    );
}

export default observer(VotingPercent);
