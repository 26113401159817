import React from 'react';

import { observer } from 'mobx-react-lite';

import { ResetAllSkippedUsers } from 'modules/InsideIssue/components/FinalScore/components/ResetAllSkippedUsers';
import { SkippedUser } from 'modules/InsideIssue/components/FinalScore/components/SkippedUser';

import { mainStore } from 'store/models/MainStore';

import Flex from 'components/Flex';

function SkippedUsers({ issue }) {
    if (!issue.skippedUsersIds?.length) {
        return null;
    }
    return (
        <Flex column gap={2} align="none" style={{ marginBottom: 8 }}>
            <div className="o-6" style={{}}>
                Evaluation skipped by
            </div>
            {issue.skippedUsersIds.map((userId) => (
                <SkippedUser key={userId} userId={userId} issue={issue} />
            ))}
            {mainStore.currentUser?.isAdmin && <ResetAllSkippedUsers issue={issue} />}
        </Flex>
    );
}

export default observer(SkippedUsers);
