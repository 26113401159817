import React, { useEffect, useRef, useState } from 'react';

import { observer } from 'mobx-react-lite';
import { FormattedMessage } from 'react-intl';

import api from 'api';
import {
    ADFS_AUTH,
    IS_IFRAME,
    IS_VOTING_BOARD,
    KEYCLOAK_AUTH,
    REMOTE_AUTH_PAGE,
    SERVICE_DOMAIN_NAME,
    SSO_LOGIN_ONLY,
} from 'constants/global';
import { getRedirectAuthUrl } from 'utils';

import { useGlobalErrorNotifications } from 'hooks/useGlobalErrorNotifications';

import RemoteAuthButton from 'modules/Auth/AuthChild/RemoteAuthButton';

import { mainStore } from 'store/models/MainStore';
import screenStore from 'store/models/ScreenStore';

import { AUTH_CODE_PLACEHOLDER } from 'utils/consts';
import failRequest from 'utils/failRequest';
import getErrorText from 'utils/getErrorText';
import locationReload from 'utils/locationReload';
import toUrl from 'utils/toUrl';

import Flex from 'components/Flex';
import Space from 'components/Space';
import SsoButton from 'components/SsoButton/SsoButton';

import AuthFormLogo from './AuthFormLogo';
import CodeEnter from './CodeEnter';
import EmailInput from './EmailInput/EmailInput';
import GoogleBtn from './GoogleBtn';
import LoginError from './LoginError';

import styles from './AuthBody.module.sass';

function updateSessionId(session_id) {
    try {
        window.parent.postMessage('ups:' + session_id, '*');
    } catch (error) {}
}

function AuthChild({ logo, isMobile, isLogin, isSignup, setInviteError, slim = false }) {
    useGlobalErrorNotifications();

    const [codeLoader, setCodeLoader] = useState(false);
    const [block, setBlock] = useState(false);
    const [email, setEmail] = useState(null);
    const [preEmail, setPreEmail] = useState(null);
    const [errorText, setErrorText] = useState(window.login_error || '');
    const [code, setCode] = useState(AUTH_CODE_PLACEHOLDER);
    const inputRef = useRef();

    async function onSend(value) {
        if (block) return;

        setPreEmail(value);
        setBlock(true);
        await sendEmail(value);
        setBlock(false);
    }

    function clearAll() {
        setEmail(null);
        setCode(AUTH_CODE_PLACEHOLDER);
        setErrorText('');
    }

    useEffect(() => {
        const codeString = code.join('');
        async function validateCode(code) {
            setCodeLoader(true);
            try {
                const { data } = await api.post(
                    '/auth/code',
                    toUrl({
                        code,
                        email,
                        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
                        lang: window.navigator.language || 'en-US',
                    }),
                );
                if (data?.widget_session_id) updateSessionId(data.widget_session_id);
                locationReload(data?.widget_session_id);
            } catch (e) {
                setCodeLoader(false);
                failRequest(e);
            }
        }
        codeString.length === code.length && validateCode(codeString);
    }, [code, email]);

    if (REMOTE_AUTH_PAGE) {
        return (
            <Flex
                style={{ width: '100%', maxWidth: 375, margin: '0 auto' }}
                align={slim ? 'none' : 'center'}
                column
                data-slim={slim}
                className={styles.body}
            >
                <Space height={20} />
                <AuthFormLogo />
                <Space height={12} />
                <h2 className="t-c ma">
                    {IS_VOTING_BOARD ? (
                        <FormattedMessage id="auth.title" defaultMessage="Authorize to Vote or Add Ideas" />
                    ) : (
                        'Authorize to your account'
                    )}
                </h2>
                {!screenStore.isMobile ? <Space height={12} /> : null}
                <RemoteAuthButton isLogin={isLogin} />
                <Space height={20} />
                <p className="t-c t-mutted">
                    <FormattedMessage
                        id="auth.terms_links"
                        defaultMessage="By signing up, you agree <br></br> to our <at>Terms and Conditions</at> and <ap>Privacy Policy</ap>"
                        values={{
                            br: () => <br />,
                            at: (children) => (
                                <a target="_blank" href="https://hello.ducalis.io/terms-and-conditions-ducalis-io">
                                    {children}
                                </a>
                            ),
                            ap: (children) => (
                                <a target="_blank" href="https://hello.ducalis.io/privacy-policy-ducalis-io">
                                    {children}
                                </a>
                            ),
                        }}
                    />
                </p>
            </Flex>
        );
    }

    async function sendEmail(email) {
        try {
            const redirect = getRedirectAuthUrl();
            const { status } = await api.post(
                '/auth/send-magic',
                toUrl({ email, lang: window.navigator.language || 'en-US' }),
                {
                    params: {
                        redirect,
                        scenario: isLogin ? 'login' : 'default',
                    },
                },
            );
            if (status === 202) {
                window.location.href = redirect || '/';
            } else {
                setEmail(email);
            }
        } catch (e) {
            if (e.response?.status === 409) {
                setInviteError({
                    email: e.response.data.email,
                    type: 'email',
                    invitedEmail: e.response.data.invitedEmail,
                });
            } else if (e.response?.status === 422) {
                setErrorText(getErrorText(e));
            } else {
                failRequest(e);
            }
            setEmail(null);
        }
    }

    const disableGoogle = KEYCLOAK_AUTH || ADFS_AUTH || SSO_LOGIN_ONLY || IS_IFRAME;

    return (
        <Flex align={slim ? 'none' : 'center'} column data-slim={slim} className={styles.body}>
            {logo}

            {isSignup && !email && (
                <>
                    <Flex column gap={2} css={{ width: '100%', maxWidth: 375 }}>
                        {!disableGoogle && <GoogleBtn isSignup setInviteError={setInviteError} />}
                        {KEYCLOAK_AUTH && <SsoButton isLogin={false} authClient="keycloak" serviceName="Keycloak" />}
                        {ADFS_AUTH && <SsoButton isLogin={false} authClient="adfs" serviceName="ADFS" />}
                    </Flex>

                    {(KEYCLOAK_AUTH || ADFS_AUTH || !IS_IFRAME) && !SSO_LOGIN_ONLY && (
                        <div style={{ width: '100%', maxWidth: 375 }}>
                            <div className="separator t-c">
                                <span>
                                    <FormattedMessage id="global.or" defaultMessage="or" />
                                </span>
                            </div>
                        </div>
                    )}
                </>
            )}

            {!isSignup && !email && (
                <>
                    <Space height={16} />
                    <h2 className="t-c ma">
                        {isLogin ? (
                            <FormattedMessage
                                id="auth.login.title"
                                defaultMessage="Welcome back. Log in to your account"
                            />
                        ) : IS_VOTING_BOARD ? (
                            <FormattedMessage id="auth.title" defaultMessage="Authorize to Vote or Add Ideas" />
                        ) : (
                            'Authorize to your account'
                        )}
                    </h2>
                    {(!isLogin && (
                        <p className="t-c t-bw limit limit-500">
                            <FormattedMessage
                                id="auth.subtitle"
                                defaultMessage="Use an email associated with your {publicName} account."
                                values={{
                                    publicName: mainStore.workspace.publicName || SERVICE_DOMAIN_NAME,
                                }}
                            />
                        </p>
                    )) || <Space height={12} />}

                    <Flex column gap={2} css={{ width: '100%', maxWidth: 375 }}>
                        {!disableGoogle && <GoogleBtn isLogin={isLogin} />}
                        {KEYCLOAK_AUTH && <SsoButton isLogin={isLogin} authClient="keycloak" serviceName="Keycloak" />}
                        {ADFS_AUTH && <SsoButton isLogin={isLogin} authClient="adfs" serviceName="ADFS" />}
                    </Flex>

                    {((KEYCLOAK_AUTH || ADFS_AUTH || !IS_IFRAME) && !SSO_LOGIN_ONLY && (
                        <div style={{ width: '100%', maxWidth: 375 }}>
                            <div className="separator t-c">
                                <span>
                                    <FormattedMessage id="global.or" defaultMessage="or" />
                                </span>
                            </div>
                        </div>
                    )) || <Space height={20} />}
                </>
            )}
            {SSO_LOGIN_ONLY && <LoginError errorText={errorText} />}
            {!SSO_LOGIN_ONLY && !email && (
                <EmailInput
                    clearError={() => setErrorText('')}
                    inputRef={inputRef}
                    isMobile={isMobile}
                    defaultValue={preEmail || (isSignup ? window.invite_email : '')}
                    errorText={errorText}
                    onSend={onSend}
                />
            )}
            {!SSO_LOGIN_ONLY && email && (
                <CodeEnter
                    slim={slim}
                    code={code}
                    setCode={setCode}
                    email={email}
                    codeLoader={codeLoader}
                    clearAll={clearAll}
                />
            )}
            {!email && (
                <>
                    <Space height={6} />
                    <p className="t-c t-mutted">
                        {isLogin ? (
                            <FormattedMessage
                                id="auth.login.no_account"
                                defaultMessage="Don’t have an account? <at>Sign Up</at>"
                                values={{
                                    at: (children) => <a href="/signup">{children}</a>,
                                }}
                            />
                        ) : (
                            <FormattedMessage
                                id="auth.terms_links"
                                defaultMessage="By signing up, you agree <br></br> to our <at>Terms and Conditions</at> and <ap>Privacy Policy</ap>"
                                values={{
                                    br: () => <br />,
                                    at: (children) => (
                                        <a
                                            target="_blank"
                                            href="https://hello.ducalis.io/terms-and-conditions-ducalis-io"
                                        >
                                            {children}
                                        </a>
                                    ),
                                    ap: (children) => (
                                        <a target="_blank" href="https://hello.ducalis.io/privacy-policy-ducalis-io">
                                            {children}
                                        </a>
                                    ),
                                }}
                            />
                        )}
                    </p>
                </>
            )}
        </Flex>
    );
}

export default observer(AuthChild);
