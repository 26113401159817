import { SERVICE_NAME } from 'constants/global';

import { PAYWALL_LIMITS } from 'utils/consts';

export const PAYWALLS = {
    [PAYWALL_LIMITS.GRID_FILTER]: {
        text: 'help you view specific sets of issues. You can save filters.',
        name: 'Custom Filters',
        link: 'how-to-create-and-use-filters-in-ducalis/',
        video: 'https://res.cloudinary.com/ducalis/video/upload/v1674470554/grid_filter.mp4',
    },
    [PAYWALL_LIMITS.EVALUATION_CHANGE]: {
        text: 'helps to keep scores private during the Evaluation period.',
        name: 'Evaluation Poker',
        link: 'evaluation-poker-overview/',
        youtube: 'R9vVKCJ6Awc',
    },
    [PAYWALL_LIMITS.RESTRICT_SCORES_RE_EVALUATION]: {
        text: 'prevent members from editing their scores after seeing others scores',
        name: 'Restrict scores re-evaluation',
        link: 'keeping-scores-private-when-the-team-is-evaluating/#block-users-from-editing-scores-after-reveal',
        youtube: 'bjPGJi_pW1o',
    },
    [PAYWALL_LIMITS.CUSTOM_VOTE]: {
        text: 'can be changed by everybody, nobody or board facilitators.',
        name: 'Editable Team Final Score',
        link: 'the-issue-re-evaluation-block-and-issue-re-evaluation/#changing-the-criteria-final-scores',
        video: 'https://res.cloudinary.com/ducalis/video/upload/v1674473199/custom_vote.mp4',
    },
    [PAYWALL_LIMITS.VOTES_RESTORE]: {
        text: 'help restore scores if they expire whilst you still need them or following accidental deletions.',
        name: 'Score backups',
        link: 'score-backups/',
    },
    [PAYWALL_LIMITS.VOTES_RESET]: {
        text: 'gives your scores a “lifetime” – after N sprints scores have to be re-evaluated.',
        name: 'Score expiration',
        link: 'prioritization-habit-sprints-and-score-expirations/',
    },
    [PAYWALL_LIMITS.VOTES_TEAM_RESET]: {
        text: 'allows not to change scores for different criteria at the same frequency.',
        name: 'Score expiration by Criteria',
        link: 'prioritization-habit-sprints-and-score-expirations/',
    },
    [PAYWALL_LIMITS.PRIVATE_BOARDS]: {
        text: 'helps limit board access to your team or specific users.',
        name: 'Private board',
        link: 'voting-board-privacy/',
        video: 'https://res.cloudinary.com/ducalis/video/upload/v1719904781/private-prioritization-board.mp4',
    },
    [PAYWALL_LIMITS.CUSTOMISE_COLUMNS]: {
        text: 'helps view specific data and show columns that have the relevant information ',
        name: 'Columns customization',
        link: 'customizing-columns-in-table-view/',
        video: 'https://res.cloudinary.com/ducalis/video/upload/v1719221411/t4ary2lfa3apljclaizm.mp4',
    },
    [PAYWALL_LIMITS.SKIP_ISSUE_VOTE]: {
        text: 'allows you to skip evaluating issues without affecting the overall progress of the evaluation process.',
        name: 'Skip issue evaluation',
        link: 'skipping-issues-during-evaluation/',
        video: 'https://res.cloudinary.com/ducalis/video/upload/v1674475026/skip_issue_vote.mp4',
    },
    [PAYWALL_LIMITS.IDEA_TEMPLATE]: {
        text: 'helps guide your customers on how to create well-structured and easy-to-understand requests.',
        name: 'Idea Template',
        link: 'template-for-new-public-idea/',
        video: 'https://res.cloudinary.com/ducalis/video/upload/v1719905304/idea-template.mp4',
    },
    [PAYWALL_LIMITS.VOTING_APPEARANCE]: {
        text: ' – add your logo, your color, theme and language to match your brand.',
        name: 'Voting Board Branding',
        link: 'customizing-voting-boards/',
        video: 'https://res.cloudinary.com/ducalis/video/upload/v1719904453/appearance.mp4',
    },
    [PAYWALL_LIMITS.IDEA_STATUS_SYNC]: {
        text: 'helps keep ideas statuses between Voting board and task tracker in sync.',
        name: 'Status Auto-Update Rules',
        link: 'status-auto-update-rules/',
        video: 'https://res.cloudinary.com/ducalis/video/upload/v1719222425/status-auto-update-rules.mp4',
    },
    [PAYWALL_LIMITS.VOTING_VOTES_LABELS]: {
        text: 'allows customers to leave a single vote on an idea, or more than one vote.',
        name: 'Upvotes and Votes’ option',
        link: 'upvotes-and-votes-display-for-the-voting-board/',
        video: 'https://res.cloudinary.com/ducalis/video/upload/v1719905568/voting-scale.mp4',
    },
    [PAYWALL_LIMITS.PRIVATE_VOTING_BOARD]: {
        text: 'allows to set voting board not visible and accessible to every person and restrict access to a specified group of people.',
        name: 'Private board',
        link: 'voting-board-privacy/',
    },
    [PAYWALL_LIMITS.VOTING_ADD_TO_BACKLOG]: {
        text: 'allows to add, create, merge public ideas to your internal backlog.',
        name: 'Add idea to backlog',
        link: 'adding-public-ideas-to-your-internal-backlog/',
        video: 'https://res.cloudinary.com/ducalis/video/upload/v1674483190/voting_add_to_backlog.mp4',
    },
    [PAYWALL_LIMITS.VOTING_CUSTOM_STATUSES]: {
        name: 'Public idea statuses',
        text: 'help keep customers in the loop on the progress of ideas you are working on, show customers who voted for the idea the stage it is.',
        link: 'public-idea-statuses/',
        video: 'https://res.cloudinary.com/ducalis/video/upload/v1674483353/voting_custom_statuses.mp4',
    },
    [PAYWALL_LIMITS.VOTING_VOTE_BEHALF]: {
        name: 'Voting on Behalf',
        text: 'allows to vote on behalf of a user if you received feedback of their interest for a particular feature on a call, meeting, chat.',
        link: 'votes-commenting-mentions-on-ideas/#vote-on-behalf-of-your-customers',
        video: 'https://res.cloudinary.com/ducalis/video/upload/v1674483538/voting_vote_behalf.mp4',
    },
    [PAYWALL_LIMITS.VOTING_ANALYTICS]: {
        name: 'Changelog Analytics',
        text: 'helps understand how your customers interact with public ideas: view and read them.',
        link: 'public-ideas-analytics/',
        img: 'https://res.cloudinary.com/ducalis/image/upload/v1674484943/voting_analytics.png',
    },
    [PAYWALL_LIMITS.PUSH_ISSUE]: {
        name: 'Push to task tracker',
        text: `allows you to send issues you create in ${SERVICE_NAME} to your connected task tracker.`,
        link: 'https://help.ducalis.io/article-tags/push-to-tracker/',
        video: 'https://res.cloudinary.com/ducalis/video/upload/v1674485348/push_issue.mp4',
    },
    [PAYWALL_LIMITS.TWO_WAY_SYNC_CUSTOM_FIELDS]: {
        name: 'Add fields to back sync',
        text: 'allows to push score, score status, criteria score, top priorities and etc. into task tracker.',
        link: 'two-way-sync-overview/#adding-more-fields',
        img: 'https://res.cloudinary.com/ducalis/image/upload/v1674485535/two_way_sync_custom_fields.png',
    },
    [PAYWALL_LIMITS.ISSUE_DEPENDENCIES]: {
        name: 'Issue dependencies',
        text: '- create relations between issues to know when work is ready to begin, such as blocking, blocked and related.',
        link: 'issue-dependencies/',
    },
    [PAYWALL_LIMITS.RELEASE_WEBHOOKS]: {
        name: 'Release Webhooks',
        text: 'allows to receive real-time updates including release status for managing public ideas statuses and sending release notes automatically',
        link: 'setup-webhooks-to-automate-voting-board-events/',
    },
    [PAYWALL_LIMITS.CRITERIA_SCORE_BACKUPS]: {
        name: 'Criteria Score Backups',
        text: 'allows to restore deleted criteria and their respective scores',
    },
    [PAYWALL_LIMITS.TWO_WAY_SYNC_FIELDS_MAP]: {
        name: 'Field mapping',
        text: `helps you determine how ${SERVICE_NAME} fields map to your task tracker (Jira or ClickUp) fields, allowing you to use existing fields without creating new ones.`,
        link: 'https://help.ducalis.io/knowledge-base/field-mapping/',
    },
    [PAYWALL_LIMITS.VOTES_MANUAL_RESET]: {
        name: 'Reset all issues scores',
        text: 'enables manual clearing of all scores for issues, resulting in a state of no evaluation for the issues',
        link: 'resetting-evaluation-scores/',
    },
    [PAYWALL_LIMITS.VOTING_GTM]: {
        name: 'Google Tag Manager Integration',
        text: `enables the connection with ${SERVICE_NAME} to track data about ducalis-hosted public voting board, e.g. Voting board views number.`,
        link: 'https://help.ducalis.io/knowledge-base/add-the-google-tag-manager-code-to-voting-boards/',
    },
};
