let hasLocalStorage = 'localStorage' in window;
let ls, testKey;

if (hasLocalStorage) {
    testKey = 'localstorage.prefix.test-key';
    try {
        ls = window.localStorage;
        ls.setItem(testKey, 'foo');
        ls.removeItem(testKey);
    } catch (e) {
        hasLocalStorage = false;
        console.warn('localStorage not found. Component state will not be stored to localStorage.');
    }
}

export default class AppStorage {
    appName = '';
    hasStorage = hasLocalStorage;
    /**
     * @constructor
     * @param {String} appName      Name of the application
     */
    constructor(appName) {
        this.appName = appName;
    }

    /**
     * Returns the final key used for storing the
     * value in the `localStorage`.
     *
     * @param  {String} key     Storage key
     * @return {String} The generated key name for the app
     */
    getKey(key) {
        return `${this.appName}.${key}`;
    }

    /**
     * Sets a given key and value in the `localStorage`.
     * Uses lz-string compression before storage.
     *
     * @param {String} key  Storage key
     * @param {any} value   Value to store
     */
    set(key, value) {
        if (!this.hasStorage) return;

        const itemKey = this.getKey(key);
        try {
            const val = JSON.stringify(value);
            if (typeof val !== 'undefined') {
                localStorage.setItem(itemKey, val);
            }
        } catch (ex) {
            console.warn(`something went wrong while trying to store value for ${key}.`, ex); // eslint-disable-line no-console
        }
    }

    /**
     * Retrieves the value from the `localStorage`.
     *
     * @param {string} key  Storage key
     * @param {any} [defValue]  Default value if storage doesn't exist
     * @return {any}      Stored Value
     */
    get(key, defValue = null) {
        if (!this.hasStorage) return defValue;
        const itemKey = this.getKey(key);
        const item = localStorage.getItem(itemKey);

        if (item === null) return defValue;

        let val;
        try {
            val = JSON.parse(item);
        } catch (ex) {
            val = defValue;
        }

        return val;
    }

    /**
     * Removes the given key(s) from the storage.
     *
     * @param  {String|Array<String>} keys  Key(s) to remove from storage
     */
    remove(keys) {
        if (!this.hasStorage) return;
        if (!Array.isArray(keys)) keys = [keys]; // eslint-disable-line no-param-reassign
        keys.forEach((k) => localStorage.removeItem(this.getKey(k)));
    }
}
