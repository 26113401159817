import flatObject from 'utils/flatObject';
import { ravenCaptureException } from 'utils/sentry';

export function sendSessionEvent(eventType, eventProperties, color = 'blue') {
    if (window.$crisp) {
        const data = flatObject(eventProperties);
        try {
            window.$crisp.push(['set', 'session:event', [[[eventType, data, color]]]]);
        } catch (e) {
            console.error('Fail crisp session:event', { eventType, eventProperties, data, color });
            ravenCaptureException(e);
        }
    }
}
