import { runInAction } from 'mobx';

import api from 'api';

import arrayMoveMutable from 'utils/arrayMoveMutable';
import failRequest from 'utils/failRequest';
import toUrl from 'utils/toUrl';

export async function sortColumn({ fieldId, oldIndex, newIndex, view }) {
    const postData = { id: fieldId, grid: view, position: newIndex + 1 };

    const oldColumns = { ...this.activeColumns[view] };

    runInAction(() => {
        const columns = arrayMoveMutable(Object.values(this.activeColumns[view]), oldIndex, newIndex);
        this.activeColumns[view] = columns.reduce((res, item, index) => {
            res[index + 1] = item;
            return res;
        }, {});
    });

    try {
        await api.post(`${this.apiEndpoint}/column`, toUrl(postData));
    } catch (e) {
        failRequest(e);
        runInAction(() => {
            this.activeColumns[view] = oldColumns;
        });
    }
}
