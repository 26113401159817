import { memo } from 'react';

import { CustomIcon, CustomIconName } from 'components/CustomIcon';
import Tooltip from 'components/Tooltip';

const HiddenContent = memo(({ tooltip = 'Nobody can see scores till', revealAt }) => {
    return (
        <Tooltip content={`${tooltip} ${revealAt}`}>
            <CustomIcon icon={CustomIconName.EYE_SLASH} className="o-1" />
        </Tooltip>
    );
});

export default HiddenContent;
