import { rootApi } from 'api';

import { mainStore } from 'store/models/MainStore';
import paymentPlans from 'store/models/PaymentPlans';

import failRequest from 'utils/failRequest';
import formatPriceWithCurrency from 'utils/formatPriceWithCurrency';
import toUrl from 'utils/toUrl';

export async function runUpgrade({ plan_id, quantity, package_plan_id }, onComplete, onFinally) {
    try {
        const requestData = { plan_id, quantity, package_plan_id };
        await rootApi.post('/billing/subscribe', toUrl(requestData));
        onComplete && onComplete();
    } catch (e) {
        failRequest(e);
    } finally {
        onFinally();
    }
}

export function getTieredPrice(item) {
    if (item.up_to) {
        return item.flat_amount;
    }
    return item.unit_amount;
}

export function getContinueText(status) {
    if (status === 'free') {
        return `Start Free Trial`;
    }
    return 'Continue Trial';
}

export function calcDisc(active, people, type, plans) {
    if (active.id === 1) {
        return null;
    }
    let planY, planM;
    if (type === 'monthly') {
        planY = plans.find((el) => el.name === active.name && el.interval !== type);
        planM = active;
    } else {
        planM = plans.find((el) => el.name === active.name && el.interval !== type);
        planY = active;
    }
    if (planM.billing_scheme === 'tiered') {
        if (people > planM.tiers[0].up_to) {
            return people * planM.tiers[1].unit_amount * 12 - people * planY.tiers[1].unit_amount;
        }
        return planM.tiers[0].flat_amount * 12 - planY.tiers[0].flat_amount;
    }
    return people * planM.amount * 12 - people * planY.amount;
}

export function getSubtotal(planM, quantity) {
    if (planM.billing_scheme === 'tiered') {
        return quantity > planM.tiers[0].up_to
            ? 12 * quantity * planM.tiers[1].unit_amount
            : 12 * planM.tiers[0].flat_amount;
    }

    return 12 * quantity * planM.amount;
}

export function getBtnText({ plan, status, hasChange, payNowPrice }) {
    if (payNowPrice) {
        const price = formatPriceWithCurrency({
            currencyCode: paymentPlans.active?.currency,
            price: payNowPrice,
        });
        return `Pay ${price}`;
    }

    if (plan?.id === 1 && status !== 'free') {
        return 'Downgrade';
    }

    if (!hasChange) {
        return 'No Changes';
    }

    if (status === 'paying' || mainStore.organization.has_payment_methods) {
        return 'Upgrade plan';
    }

    return 'Continue Checkout';
}
