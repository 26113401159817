import { runInAction } from 'mobx';

import api from 'api';

import failRequest from 'utils/failRequest';
import toUrl from 'utils/toUrl';

/**
 * Close banner for user
 *
 * @param {string} noteName
 * @return {Promise<void>}
 */
export async function closeNote(noteName) {
    const closed_notes = [...this.closed_notes];

    runInAction(() => {
        this.closed_notes.push(noteName);
    });

    try {
        await api.put(`/users/${this.id}`, toUrl({ closed_notes: [...closed_notes, noteName] }));
    } catch (e) {
        failRequest(e);
        runInAction(() => {
            this.closed_notes = closed_notes;
        });
    }
}
