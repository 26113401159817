import React from 'react';

import { observer } from 'mobx-react-lite';

import { THEMES_MENU } from 'themes';

import { StButton } from 'atoms/StButton';

import { CustomIcon, CustomIconName } from 'components/CustomIcon';
import { DropdownMenuItem } from 'components/DropdownMenu';

function ThemeMenuItems({ activeId, onItemSelect }) {
    return THEMES_MENU.map((theme) => (
        <DropdownMenuItem
            key={theme.id}
            leftElm={
                <StButton as="span" size={18} style={theme.css}>
                    Aa
                </StButton>
            }
            text={theme.label}
            onClick={() => onItemSelect(theme.id)}
            rightElm={theme.id === activeId ? <CustomIcon icon={CustomIconName.TICK} /> : null}
        />
    ));
}

export default observer(ThemeMenuItems);
