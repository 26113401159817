import { styled } from 'stitches.config';

export const StLogoString = styled('h1', {
    whiteSpace: 'nowrap',
    minWidth: 0,
    fontWeight: 900,
    flexShrink: 0,
    fontSize: 24,
    lineHeight: 1.5,
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    maxWidth: '500',
    margin: 0,

    variants: {
        size: {
            small: {
                lineHeight: 1.45,
                fontSize: 20,
            },
        },

        header: {
            true: {
                fontWeight: 600,
                fontStyle: 'normal',
                fontSize: 26,
                letterSpacing: '0em',
                lineHeight: 1.2,
                transition: 'all .2s',

                '@tablet': {
                    fontSize: 21,
                },
            },
        },
    },
});
