import api from 'api';

import { mainStore } from 'store/models/MainStore';

import failRequest from 'utils/failRequest';
import logEvent from 'utils/logEvent';
import toUrl from 'utils/toUrl';

export async function removeRequest({ id }, parent) {
    logEvent('Remove request');

    mainStore.removeQuestion(id);
    const url = parent && parent.id !== id ? `/requests/${parent.id}/messages/${id}` : `/requests/${id}`;

    try {
        await api.delete(url, {
            data: toUrl({ board_id: this.board.id, issue_id: this.id }),
        });
    } catch (error) {
        failRequest(error);
    }
}
