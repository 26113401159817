import { CustomIconName } from 'components/CustomIcon';

export const SIDEBAR_BTN_PROPS = {
    minimal: true,
    size: 34,
    icon: CustomIconName.PLUS,
};

export const DASHBOARD_BTN_PROPS = {
    color: 'primary',
    border: true,
    size: 34,
    text: 'New Board',
};

export const TEMPLATES = [
    {
        id: 'features_rice',
        text: 'RICE',
        icon: CustomIconName.RICE,
        label: 'Popular',
        link: 'https://hello.ducalis.io/knowledge-base/rice-scoring-prioritization-framework',
    },
    {
        id: 'features_wsjf',
        text: 'WSJF',
        icon: CustomIconName.WSJF,
        label: 'Agile / Scrum',
        link: 'https://hello.ducalis.io/knowledge-base/wsjf-agile-prioritization-framework',
    },
    {
        id: 'features_ice',
        text: 'ICE',
        icon: CustomIconName.ICE,
        label: 'Easy',
        link: 'https://hello.ducalis.io/knowledge-base/ice-score-framework',
    },
    {
        id: 'features_aarrr',
        text: 'AARRR',
        icon: CustomIconName.AARRR,
        label: 'Startups',
        link: 'https://hello.ducalis.io/prioritization-frameworks/aarrr',
    },
    {
        id: 'weighted-decision-matrix',
        text: 'Weighted Decision',
        icon: CustomIconName.WEIGHT,
        label: 'For Pros',
        link: 'https://hello.ducalis.io/prioritization-frameworks/prioritization-by-weighted-decision-matrix',
    },
];
