import api from 'api';

import failRequest from 'utils/failRequest';

export async function getAllVotersCount() {
    try {
        const { headers } = await api.get(`${this.apiEndpoint}/voting-users`);
        this.setAllVotersCount(headers && Number(headers['x-pagination-total-count'] || 0));
    } catch (error) {
        failRequest(error);
    }
}
