import { Button } from 'components/Button';
import Spinner from 'components/Spinner';

export function getColor(value) {
    switch (true) {
        case value >= 75:
            return 'green';
        case value >= 40:
            return 'orange';
        default:
            return 'red';
    }
}

export const ProgressSpinner = ({ progress = 0, size }) => {
    const color = getColor(progress);
    return <Spinner color={color} size={size} value={progress / 100} />;
};

export const ProgressButton = ({ progress = 0, minimal, ...props }) => {
    if (minimal) return <span className="t-crop">{progress}%</span>;
    const color = getColor(progress);
    return <Button as="span" size={20} {...props} color={color} text={`${progress}%`} />;
};
