import api from 'api';

import delay from 'utils/delay';
import failRequest from 'utils/failRequest';
import toUrl from 'utils/toUrl';

export async function setSyncFields(requestData) {
    try {
        const { data } = await api.put(`${this.apiEndpoint}/sync-fields`, toUrl(requestData));
        const fields = {
            selectedSyncFields: data.selected_fields,
            defaultSyncFields: data.default_fields,
        };
        delay(0).then(() => this.fillSyncFields(fields));
    } catch (e) {
        failRequest(e);
    }
}
