import { runInAction } from 'mobx';

import api from 'api';
import { failRequest, toUrl } from 'utils';

import runTaskWorker from 'utils/runTaskWorker';

export async function runCannyImport({ token, cannyBoard }) {
    const onFinally = () => {
        runInAction(() => {
            this.cannyLoader = false;
        });
    };

    try {
        const { data } = await api.post(`${this.apiEndpoint}/canny`, toUrl({ token, cannyBoard }));
        runInAction(() => {
            this.cannyLoader = true;
        });
        new runTaskWorker({
            data: { canny: cannyBoard },
            taskId: data,
            onComplete: onFinally,
            onFail: onFinally,
        });
    } catch (e) {
        failRequest(e);
    }
}
