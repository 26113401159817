import {
    grayA,
    gray,
    amber,
    blue,
    brown,
    crimson,
    cyan,
    grass,
    green,
    indigo,
    lime,
    mint,
    orange,
    pink,
    plum,
    purple,
    red,
    redA,
    sky,
    teal,
    tomato,
    violet,
    yellow,
    yellowA,
} from '@radix-ui/colors';

import { createTheme } from 'stitches.config';

export const colorsLight = createTheme({
    colors: {
        ...grayA,
        ...gray,
        ...amber,
        ...blue,
        ...brown,
        ...crimson,
        ...cyan,
        ...grass,
        ...green,
        ...indigo,
        ...lime,
        ...mint,
        ...orange,
        ...pink,
        ...plum,
        ...purple,
        ...red,
        ...redA,
        ...sky,
        ...teal,
        ...tomato,
        ...violet,
        ...yellow,
        ...yellowA,

        'stripe-l-background': '$blackA3',
        'stripe-l-background-open': '$blackA4',
        'stripe-l-border': '$blackA6',
        'stripe-l-header-hover': '$blackA4',
        'stripe-l-border-open': '$blackA7',
        'stripe-l-chevron': '$blackA8',

        'code-bg': '#fff',
        'code-text': '#24292e',
        'code-title': '#6f42c1',
        'code-string': '#032f62',
        'code-comment': '#6a737d',
        'code-attr': '#005cc5',
        'code-keyword': '#d73a49',
        'code-name': '#22863a',
    },
});
