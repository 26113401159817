import React, { useRef } from 'react';

import { observer } from 'mobx-react-lite';
import { FormattedMessage } from 'react-intl';

import { Button } from 'components/Button';
import Flex from 'components/Flex';
import Space from 'components/Space';
import Spinner from 'components/Spinner';

import CodeInput from './CodeInput';

function CodeEnter({ clearAll, email, codeLoader, code, setCode, slim }) {
    const refs = useRef(new Map()).current;

    function onPaste(event) {
        event.preventDefault();
        let pasteText = (event.clipboardData || window.clipboardData).getData('text');
        pasteText && setCode((state) => state.map((el, i) => pasteText?.[i] || ''));
    }

    function setCodeNumber(e, i) {
        const newCode = [...code];
        newCode[i] = e.target.value.slice(-1);
        setCode(newCode);
        const el = refs.get(i + 1);
        (el && el.focus()) || e.target.blur();
    }

    return (
        <>
            {slim ? <Space height={8} /> : <Space height={60} />}
            <p className="flex flex-a-center">
                <span>
                    <FormattedMessage
                        id="auth.code_email_title"
                        defaultMessage="Confirmation code sent to <b>{email}</b>"
                        values={{
                            b: (children) => <b>{children}</b>,
                            email,
                        }}
                    />
                </span>
                <Space width={6} />
                {codeLoader && <Spinner size={18} />}
            </p>
            {slim ? <Space height={4} /> : <Space height={30} />}
            <div className="limit limit-450 grid-code">
                {code.map((item, i) => (
                    <CodeInput
                        disabled={codeLoader}
                        setRef={(inst) => (inst === null ? refs.delete(i) : refs.set(i, inst))}
                        index={i}
                        key={i}
                        value={item}
                        onChange={setCodeNumber}
                        onPaste={onPaste}
                    />
                ))}
            </div>
            {slim ? <Space height={8} /> : <Space height={60} />}
            <p className="t-c">
                <FormattedMessage
                    id="auth.code_email_subtitle"
                    defaultMessage="Didn’t get a code (may take up to 5 mins)?"
                />
            </p>
            <Flex center>
                <Button
                    data-place="auth-change-email"
                    likeLink
                    color="blue"
                    onClick={clearAll}
                    text={<FormattedMessage id="auth.change_email" defaultMessage="Change email" />}
                />
            </Flex>
        </>
    );
}

export default observer(CodeEnter);
