import { newDarkTheme, newLightTheme, theme } from 'stitches.config';

import { colorsDark } from './colorsDark';
import { colorsLight } from './colorsLight';

/**
 * @typedef AppTheme
 * @type {Object}
 * @property {number} id
 * @property {boolean} dark
 * @property {string} className
 * @property {string} name
 * @property {Object} uiColors
 */

/**
 * Enum for Theme state ID.
 *
 * @readonly
 * @enum {number}
 */
export const THEMES_IDS = {
    darkClassic: 1,
    light: 2,
    dark: 3,
};

/**
 * Generate theme state collection
 *
 * @return {Map<THEMES_IDS, AppTheme>}
 */
function buildThemesCollection() {
    const themes = new Map();
    themes.set(THEMES_IDS.darkClassic, {
        id: THEMES_IDS.darkClassic,
        dark: true,
        className: theme.className,
        name: 'Dark Classic',
        uiColors: { background: '#2c3741', color: 'hsla(48, 100%, 50%, 0.934)' },
    });
    themes.set(THEMES_IDS.light, {
        id: THEMES_IDS.light,
        dark: false,
        className: newLightTheme.className,
        name: 'Light',
        uiColors: {
            background: 'hsl(0, 0%, 99%)',
            color: 'hsl(30, 100%, 34%)',
        },
    });
    themes.set(THEMES_IDS.dark, {
        id: THEMES_IDS.dark,
        dark: true,
        className: newDarkTheme.className,
        name: 'Dark',
        uiColors: {
            background: 'hsl(0, 0%, 8.5%)',
            color: 'hsl(48, 100%, 47%)',
        },
    });
    return themes;
}

/** @type {Map<THEMES_IDS, AppTheme>} */
export const THEMES = buildThemesCollection();

export const THEMES_MENU = Array.from(THEMES.values()).map((theme) => {
    return { id: theme.id, label: theme.name, css: theme.uiColors };
});

/**
 * Set theme by themeId and return activated theme
 *
 * @param {THEMES_IDS} themeId
 * @return {AppTheme}
 */
export function switchTheme(themeId) {
    const theme = THEMES.get(themeId);
    const themes = Array.from(THEMES.values());

    document.body.classList.remove(...themes.map((el) => el.className));
    document.body.classList.add(theme.className);

    if (theme.dark) {
        document.body.classList.remove(colorsLight);
        document.body.classList.add(colorsDark);
        document.body.style.colorScheme = 'dark';
    } else {
        document.body.classList.remove(colorsDark);
        document.body.classList.add(colorsLight);
        document.body.style.colorScheme = 'light';
    }

    return theme;
}
