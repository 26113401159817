import { runInAction, toJS } from 'mobx';

import restApi from 'api';
import { REST } from 'constants/ApiPaths';

import failRequest from 'utils/failRequest';
import logEvent from 'utils/logEvent';
import toUrl from 'utils/toUrl';

export async function setVotingSettings(data) {
    logEvent('Voting settings change', { data });

    runInAction(() => {
        this.public_voting_settings = Object.assign({}, this.public_voting_settings, data);
    });

    try {
        await restApi.put(REST.workspace.votingSettings, toUrl(data));
    } catch (e) {
        failRequest(e);
        await Promise.reject(e);
    }
}

export async function setBotSettings(provider, data) {
    logEvent('Bot settings change', { data });

    const oldData = this.ideas_bots_settings ? toJS(this.ideas_bots_settings[provider]) : null;

    runInAction(() => {
        if (this.ideas_bots_settings) {
            Object.assign(this.ideas_bots_settings[provider], data);
        } else {
            this.ideas_bots_settings = { [provider]: data };
        }
    });

    try {
        await restApi.put(`${REST.workspace.botSettings}/${provider}`, toUrl(data));
    } catch (e) {
        runInAction(() => {
            oldData ? Object.assign(this.ideas_bots_settings[provider], oldData) : (this.ideas_bots_settings = null);
        });
        failRequest(e);
    }
}

export async function setVotingEmailSettings(requestData) {
    logEvent('Voting Email settings change');

    runInAction(() => {
        this.voting_email_settings = Object.assign({}, this.voting_email_settings, requestData);
    });

    try {
        await restApi.put(REST.workspace.votingEmailSettings, toUrl(requestData));
    } catch (e) {
        if (e.response?.status === 422) {
            return e.response.data?.reduce((res, el) => {
                res[el.field] = el.message;
                return res;
            }, {});
        }
        failRequest(e);
    }
}
