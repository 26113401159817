import { IS_PUBLIC_BOARD } from 'constants/global';

import { mainStore } from 'store/models/MainStore';

export function prepareServerIssuesResponse(issues) {
    return Object.entries(issues).reduce((res, [boardPublicId, list]) => {
        const boardId = IS_PUBLIC_BOARD ? boardPublicId : mainStore.getRealBoardIdByPublicId(boardPublicId);

        if (!boardId) return res;

        list.forEach((issue) => {
            issue.boardId = boardId;
            res.push(issue);
        });
        return res;
    }, []);
}
