import React from 'react';

import classNames from 'classnames';

import { styled } from 'stitches.config';

import './Emoji.sass';

const StEmoji = styled('span', {
    variants: {
        interactive: {
            true: {
                cursor: 'pointer',
                transition: 'all .2s',
                fontSize: '1.2em',
                padding: 5,
                borderRadius: 4,
                display: 'inline-block',
                lineHeight: 1,
                '&:hover': {
                    bc: 'rgba(0, 0, 0, 0.1);',
                },
            },
        },
    },
});
export default function Emoji({ interactive, onClick, emoji, style, className, ...props }) {
    const classes = classNames('emoji', className);
    return (
        <StEmoji
            interactive={interactive}
            className={classes}
            aria-label={emoji}
            role="img"
            style={style}
            onClick={onClick}
            {...props}
        >
            {emoji}
        </StEmoji>
    );
}
