import React from 'react';

/**
 * Parse string for text highlightning
 *
 * @param {string} text
 */
function escapeRegExpChars(text) {
    // eslint-disable-next-line no-useless-escape
    return text.replace(/([.*+?^=!:${}()|\[\]\/\\])/g, '\\$1');
}

/**
 * Highlights query in text
 *
 * @param {string} text
 * @param {string} query
 * @param {string} tag
 */
export default (text, query, tag = 'strong') => {
    if (!query || query === '' || !text) {
        return text;
    }
    let lastIndex = 0;
    const words = query
        .split(/\s+/)
        .filter((word) => word.length > 0)
        .map(escapeRegExpChars);
    if (words.length === 0) {
        return [text];
    }
    const regexp = new RegExp(words.join('|'), 'gi');
    const tokens = [];

    const str = String(text);

    const Tag = tag;

    while (true) {
        const match = regexp.exec(str);
        if (!match) {
            break;
        }
        const length = match[0].length;
        const before = str.slice(lastIndex, regexp.lastIndex - length);
        if (before.length > 0) {
            tokens.push(before);
        }
        lastIndex = regexp.lastIndex;
        tokens.push(<Tag key={lastIndex}>{match[0]}</Tag>);
    }
    const rest = str.slice(lastIndex);
    if (rest.length > 0) {
        tokens.push(rest);
    }
    return tokens;
};
