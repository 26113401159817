// see http://stackoverflow.com/a/18473154/3124288 for calculating arc path
export const R = 45;
export const SPINNER_TRACK = `M 50,50 m 0,-${R} a ${R},${R} 0 1 1 0,${R * 2} a ${R},${R} 0 1 1 0,-${R * 2}`;

// unitless total length of SVG path, to which stroke-dash* properties are relative.
// https://developer.mozilla.org/en-US/docs/Web/SVG/Attribute/pathLength
// this value is the result of `<path d={SPINNER_TRACK} />.getTotalLength()` and works in all browsers:
export const PATH_LENGTH = 280;

export const STROKE_WIDTH = 4;
export const MIN_STROKE_WIDTH = 16;
