import restApi from 'api';

import failRequest from 'utils/failRequest';
import logEvent from 'utils/logEvent';
import toUrl from 'utils/toUrl';
import { trackCrispEvent } from 'utils/trackCrispEvent';

export async function setCriterion({ criterion, criterionId, request, fail }) {
    logEvent('TEAM_CRITERION_SET', { criterion });
    trackCrispEvent('Criteria Changed', { action: 'save criterion' });

    try {
        const { headers } = await restApi.put(`${this.apiEndpoint}/criteria/${criterionId}`, toUrl(request));
        return headers['boardcriterion_history'];
    } catch (e) {
        if (e?.response?.status === 422 && fail) {
            fail(e.response.data);
        } else {
            failRequest(e);
        }
    }
}
