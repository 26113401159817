import React from 'react';

import Dot from 'components/Dot';

export default function StripeDotSubtitle({ children }) {
    if (!children) return null;

    return (
        <>
            <Dot className="o-4" />
            {children}
        </>
    );
}
