import { FormattedMessage } from 'react-intl';

import api from 'api';

import failRequest from 'utils/failRequest';
import toUrl from 'utils/toUrl';

import { Button } from 'components/Button';
import Flex from 'components/Flex';
import Space from 'components/Space';

async function sendMagic(email) {
    try {
        await api.post(`/auth/send-magic?&scenario=default`, toUrl({ email, clearInvite: 1 }));
        window.location.href = '/';
    } catch (e) {
        failRequest(e);
    }
}

async function clearInvite() {
    try {
        await api.get(`/auth/clear-invite`);
        window.location.href = '/login/auth?authclient=google&scenario=default';
    } catch (e) {
        failRequest(e);
    }
}

function InviteError({ email, type, invitedEmail, setInviteError }) {
    async function handleClick() {
        if (type === 'email') {
            await sendMagic(email);
        } else {
            await clearInvite();
        }
    }

    return (
        <div className="flex flex-column flex-center flex-a-center">
            <p className="t-c" style={{ fontSize: 24 }}>
                <FormattedMessage
                    id="auth.invite-error"
                    values={{
                        br: () => <br />,
                        invitedEmail,
                        email,
                        b: (children) => <b>{children}</b>,
                    }}
                />
            </p>
            <Space height={20} />
            <Flex gap={6}>
                <Button border data-place="invite-relogin" color="primary" onClick={() => setInviteError({})}>
                    <FormattedMessage
                        id="auth.invite-error.relogin"
                        defaultMessage={`Relogin as <b>{invitedEmail}</b>`}
                        values={{
                            invitedEmail,
                            b: (children) => <b>{children}</b>,
                        }}
                    />
                </Button>
                <FormattedMessage id="global.or" defaultMessage="or" />
                <Button border data-place="skip-invite" color="green" onClick={handleClick}>
                    <FormattedMessage
                        id="auth.invite-error.continue"
                        defaultMessage={`Continue as <b>{email}</b>`}
                        values={{
                            email,
                            b: (children) => <b>{children}</b>,
                        }}
                    />
                </Button>
            </Flex>
        </div>
    );
}

export default InviteError;
