import React, { forwardRef, useContext } from 'react';

import { HotkeysContext } from '@blueprintjs/core';
import { FormattedMessage } from 'react-intl';

import { Button } from 'components/Button';
import { CustomIconName } from 'components/CustomIcon';
import DotDivider from 'components/DotDivider';
import Kbd, { KeyCombo } from 'components/Kbd';
import Tooltip from 'components/Tooltip';

function HotkeysHelper({ iconOnly, className, ...props }, ref) {
    const [data, dispatch] = useContext(HotkeysContext);

    if (!data?.hotkeys?.length) return null;

    let btn;
    if (iconOnly) {
        btn = (
            <Button
                ref={ref}
                onClick={() => dispatch({ type: 'OPEN_DIALOG' })}
                minimal
                icon={CustomIconName.HASH}
                size={34}
                {...props}
            />
        );
    } else {
        btn = (
            <Button
                ref={ref}
                block
                className={className}
                onClick={() => dispatch({ type: 'OPEN_DIALOG' })}
                minimal
                text={<FormattedMessage id="sidebar.shortcuts" defaultMessage="Shortcuts" />}
                icon={CustomIconName.HASH}
                {...props}
            />
        );
    }

    return (
        <>
            <Tooltip
                side="right"
                align="end"
                content={
                    <>
                        <FormattedMessage id="sidebar.shortcuts" defaultMessage="Shortcuts" />
                        <DotDivider />
                        <KeyCombo>
                            <Kbd>?</Kbd>
                        </KeyCombo>
                    </>
                }
            >
                {btn}
            </Tooltip>
        </>
    );
}

export default forwardRef(HotkeysHelper);
