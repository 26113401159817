export default function LoginError({ errorText }) {
    if (!errorText) return null;

    return (
        <div
            style={{ marginBottom: 16 }}
            className="help-block t-err"
            data-place="login-error"
            dangerouslySetInnerHTML={{ __html: errorText }}
        />
    );
}
