export const VALUE_NULL = 'null';

const toUrl = (obj, parent) => {
    let url, el, param, k;

    parent = parent || '';
    url = [];

    for (k in obj) {
        if (obj.hasOwnProperty(k)) {
            el = obj[k];

            param = k;
            if (parent) {
                param = parent + '[' + k + ']';
            }
            if (typeof el !== 'object' && el !== null && el !== undefined) {
                if (el === VALUE_NULL) {
                    el = '';
                }
                if (typeof el === 'boolean') {
                    el = +el;
                }
                url.push(encodeURIComponent(param) + '=' + encodeURIComponent(el));
            } else if (Array.isArray(el) && el?.length === 0) {
                url.push(encodeURIComponent(param) + '=' + encodeURIComponent(el));
            } else if (el) {
                url.push(toUrl(el, param));
            }
        }
    }

    return url.join('&');
};

export default toUrl;
