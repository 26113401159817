import React from 'react';

import { observer } from 'mobx-react-lite';

import CellWrapper from 'components/CellWrapper';

import EmptyUserTotalValue from './EmptyUserTotalValue';

export const UserTotalValue = observer(({ issue }) => {
    let content;
    if (issue.idea_votes_count === null) {
        content = <EmptyUserTotalValue issue={issue} />;
    } else {
        content = issue.idea_votes_count;
    }
    return <CellWrapper isNumber>{content}</CellWrapper>;
});
