import api from 'api';
import { failRequest } from 'utils';

import toUrl from 'utils/toUrl';

export async function move(targetBoard, validation, duplicate) {
    try {
        const { data } = await api.post(
            `${this.apiCurrentEndpoint}/copy`,
            toUrl({ validation, move: !duplicate, targetBoard })
        );
        return data;
    } catch (e) {
        failRequest(e);
        return await Promise.reject(e);
    }
}
