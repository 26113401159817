import { runInAction } from 'mobx';

import api from 'api';

import failRequest from 'utils/failRequest';
import toUrl from 'utils/toUrl';

export async function setSyncSettings(data) {
    runInAction(() => {
        if (!this.sync?.settings) {
            this.sync.settings = data;
        } else {
            Object.assign(this.sync.settings, data);
        }
    });

    try {
        await api.put(`${this.apiEndpoint}/sync-settings`, toUrl(data));
    } catch (e) {
        failRequest(e);
    }
}
