import api from 'api';
import { failRequest } from 'utils';

export async function createReleaseNoteTemplate() {
    try {
        const { data } = await api.get(`${this.apiEndpoint}/release-notes/master `);
        this.updateReleaseNote(data);
    } catch (e) {
        failRequest(e);
        await Promise.reject(e);
    }
}
