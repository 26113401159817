const ACTIVE = 'active';
const FUTURE = 'future';

export const getSprintStatusIntent = (state) => {
    switch (state) {
        case ACTIVE:
            return 'green';
        case FUTURE:
            return 'gray';
        default:
            return 'none';
    }
};
export const getSprintStatusText = (sprint) => {
    switch (sprint && sprint.state) {
        case ACTIVE:
            return `Active sprint: ${sprint.name}`;
        case FUTURE:
            return `Future sprint: ${sprint.name}`;
        default:
            return 'Backlog';
    }
};
