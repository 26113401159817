import restApi from 'api';
import { failRequest, toUrl } from 'utils';

export async function linking(issue, isLinking = false) {
    try {
        await restApi.post(
            `/issue/link-issue`,
            toUrl({ issue_id: this.id, linked_issue_id: issue.id, type: isLinking ? 'link' : 'block' }),
        );
    } catch (e) {
        failRequest(e);
    }
}
