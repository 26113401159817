import React from 'react';

import { CustomIcon, CustomIconName } from '../CustomIcon';
import Tooltip from '../Tooltip';

export function SystemStatusIcon({ item }) {
    return (
        <Tooltip
            content={
                item.system_status !== 'done'
                    ? '<b>Start Column (Inbox)</b>: This is where all new ideas land. Think of it as an idea inbox.'
                    : '<b>Final Column (Done)</b>: Completed ideas go here. Moving them here automatically adds them to the Changelog.'
            }
        >
            <CustomIcon icon={item.system_status === 'done' ? CustomIconName.BULLHORN : CustomIconName.INBOX} />
        </Tooltip>
    );
}
