import api from 'api';

import failRequest from 'utils/failRequest';

export async function removeDemoIssues() {
    try {
        await api.post(`${this.apiEndpoint}/issues/demo-delete`);
    } catch (e) {
        failRequest(e);
    }
}
