import api from 'api';
import { failRequest } from 'utils';

import toUrl from 'utils/toUrl';

export async function merge(idea_id) {
    try {
        await api.post(`${this.apiCurrentEndpoint}/idea-merge`, toUrl({ idea_id }));
    } catch (e) {
        failRequest(e);
    }
}
