import React, { useCallback, useEffect, useState } from 'react';

import { EMPTY_ARRAY } from 'constants/atoms';

import logEvent from 'utils/logEvent';
import { VALUE_NULL } from 'utils/toUrl';

import { Button } from 'components/Button';
import CellWrapper from 'components/CellWrapper';
import { CustomIcon, CustomIconName } from 'components/CustomIcon';
import UserSelectorBase from 'components/UserSelectorBase';

import { EmptyValue } from './EmptyValue';
import { UserView } from './UserView';

export function UserSelector({ issue, user, users, field, minimal, simple }) {
    const [items, setItems] = useState([]);

    useEffect(() => {
        const items = (users || EMPTY_ARRAY).filter((el) => !(el.status === 0 || el.id === -1));
        setItems(items);
    }, [users]);

    const currentUserId = user?.id;

    const onSelect = (newUser) => {
        const value = newUser.id !== currentUserId ? newUser.id : VALUE_NULL;
        logEvent(`Change issue ${field}`, { [field]: value });
        issue.update({ [`${field}_id`]: value });
    };

    const emptyValue = useCallback(() => {
        if (field === 'reporter') {
            return <EmptyValue text="Not set" />;
        }
        return <EmptyValue text="Unassigned" />;
    }, [field]);

    let btn = simple ? (
        <CellWrapper editable tabIndex={0} isButton>
            <span className="bp5-text-overflow-ellipsis">{(user && user.name) || emptyValue()}</span>
        </CellWrapper>
    ) : (
        <Button
            block
            border
            data-place={field}
            size={24}
            rightElm={<CustomIcon icon={CustomIconName.CHEVRON_DOWN} size={14} />}
        >
            {(user && <UserView user={user} interactive minimal={minimal} />) || emptyValue()}
        </Button>
    );

    return (
        <UserSelectorBase user={user} items={items} onSelect={onSelect}>
            {btn}
        </UserSelectorBase>
    );
}
