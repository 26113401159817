import React, { forwardRef } from 'react';

import classNames from 'classnames';

import { StButton } from 'atoms/StButton';

import styles from './BoardItem.module.sass';

export const CountIssuesTag = forwardRef(({ count, className, large, simple, slim, ...props }, forwardedRef) => {
    if (!count) {
        return null;
    }

    const classes = classNames(className, styles.counter, {
        [styles.counter__large]: large,
        [styles.counter__slim]: slim,
    });

    if (simple && !slim) {
        return (
            <StButton ref={forwardedRef} as="span" className="flex-center" size={20} {...props}>
                {count}
            </StButton>
        );
    }

    return (
        <span ref={forwardedRef} className={classes} {...props}>
            {count}
        </span>
    );
});
