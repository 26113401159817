import React from 'react';

import Flex from 'components/Flex';

export default function CalcUsersIncluded({ planM, quantity }) {
    if (planM.billing_scheme !== 'tiered' || quantity > planM.tiers[0].up_to) {
        return null;
    }
    return (
        <Flex spaceBetween>
            <span>{planM.tiers[0].up_to} users included</span>
        </Flex>
    );
}
