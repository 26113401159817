import getCurrencySymbolByCode from 'utils/getCurrencySymbolByCode';

export function formatPriceWithCurrencySymbol(price = null, currencyCode = 'usd', currency) {
    if (price === null) return null;

    const formattedPrice = price.toLocaleString('en-US');

    return `${currency || getCurrencySymbolByCode(currencyCode)}${formattedPrice}`;
}
export default function formatPriceWithCurrency({ price = null, currency, currencyCode = 'usd' }) {
    if (price === null) return null;

    return `${formatPriceWithCurrencySymbol(price, currencyCode, currency)} ${currencyCode.toUpperCase()}`;
}
