import Axios from 'axios';

import { rootApi } from 'api';
import { CURRENT_USER_IS_ADMIN } from 'constants/global';
import { failRequest } from 'utils';

import criteriaStore from 'store/models/CriteriaStore';
import dictionaryStore from 'store/models/DictionaryStore';
import filtersCollection from 'store/models/Filters';
import { educationBanners } from 'store/models/MainStore';
import { issuesList } from 'store/models/MainStore';
import { mainStore } from 'store/models/MainStore';
import { ORG_DB_STORES } from 'store/updateOrgDB';

import { calcPerf } from 'utils/calcPerf';
import debugLog from 'utils/debugLog';
import delay from 'utils/delay';
import { prepareServerIssuesResponse } from 'utils/prepareServerIssuesResponse';

function yieldToMain() {
    return new Promise((resolve) => {
        setTimeout(resolve, 0);
    });
}

async function fillDbAfterFetch(
    db,
    {
        organization,
        boardsResponse,
        reportsResponse,
        usersResponse,
        platformsResponse,
        preparedList,
        issuesScore,
        releaseNotesResponse,
        headers,
        dictResponse,
        criteriaResponse,
        criteriaBoardsResponse,
    },
) {
    process.env.REACT_APP_ENV !== 'prod' && debugLog('START Fill DB after fetch');
    await db.saveOrganization(organization);
    await db.saveList(platformsResponse.data, 'platforms');
    await db.saveList(boardsResponse.data, 'boards');
    await db.saveList(reportsResponse.data, 'reports');
    await db.saveList(usersResponse.data.users, 'users');
    await db.saveList(preparedList, 'issues');
    await db.saveList(issuesScore, 'issuesScore');
    await db.saveList(releaseNotesResponse.data, 'releaseNotes');
    await db.saveList(criteriaResponse.data, ORG_DB_STORES.criteria);
    await db.saveList(criteriaBoardsResponse.data, ORG_DB_STORES.criteriaBoards);
    await db.saveMeta(headers['x-version']);
    await dictionaryStore.fillDB(dictResponse.data);

    calcPerf('END Fill DB from fetch', window.globalStart, window.globalStart - window.beforeGlobalStart);
    window.beforeGlobalStart = performance.now();

    await yieldToMain();
    process.env.REACT_APP_ENV !== 'prod' && debugLog('END Fill DB after fetch');
}

async function fetchSecondLevelData() {
    const requests = ['/storage/voted-percents', '/storage/requests', '/storage/issues-links'];

    const [votedPercentsResponse, commentsResponse, linkedIssuesResponse] = await Axios.all(
        requests.map((url) => rootApi.get(url)),
    );

    mainStore.fillQuestions(commentsResponse.data);
    mainStore.fillLinkedIssues(linkedIssuesResponse.data);
    mainStore.fillAllVotedPercents(votedPercentsResponse.data);

    await mainStore.db.saveList(commentsResponse.data, 'comments');
    await mainStore.db.saveList(linkedIssuesResponse.data, 'issuesLinks');
    await mainStore.db.saveList(votedPercentsResponse.data, 'votedPercents');
}

export async function fetchAll() {
    try {
        calcPerf('Start fetch', window.globalStart, window.globalStart - window.beforeGlobalStart);
        window.beforeGlobalStart = performance.now();

        const requests = [
            // NEW
            '/storage/organization',
            '/storage/platforms',
            '/storage/users',
            '/storage/boards',
            '/storage/reports',
            '/storage/dictionaries',
            '/storage/issues',
            '/storage/issues-scores',
            '/storage/voting-votes',
            '/storage/release-notes',
            '/storage/criteria',
            '/storage/boards-criteria',
            '/storage/searches',
        ];

        const [
            orgResponse,
            platformsResponse,
            usersResponse,
            boardsResponse,
            reportsResponse,
            dictResponse,
            issuesResponse,
            issuesScoreResponse,
            votingVotesResponse,
            releaseNotesResponse,
            criteriaResponse,
            criteriaBoardsResponse,
            searchesResponse,
        ] = await Axios.all(requests.map((url) => rootApi.get(url)));

        calcPerf('End fetch', window.globalStart, window.globalStart - window.beforeGlobalStart);
        window.beforeGlobalStart = performance.now();

        process.env.REACT_APP_ENV !== 'prod' && debugLog('START fetch > fill');

        criteriaStore.fillData(criteriaResponse.data, criteriaBoardsResponse.data);
        this.organization.updateModel(orgResponse.data);
        this.platformsList.set(platformsResponse.data);
        dictionaryStore.fillCollection(dictResponse.data);
        // USERS
        this.users.set(usersResponse.data.users || []);
        usersResponse.data.me && this.users.setCurrentUser(usersResponse.data.me);

        filtersCollection.fillCollection(searchesResponse.data);

        this.boardsList.set(boardsResponse.data);

        // Required - after boardsList.set
        const issuesScore = prepareServerIssuesResponse(issuesScoreResponse.data);
        issuesList.fillIssuesData(issuesScore);

        const preparedList = prepareServerIssuesResponse(issuesResponse.data);
        issuesList.fillData(preparedList);

        this.fillReleaseNotes(releaseNotesResponse.data);
        this.setVotingVotes(votingVotesResponse.data);
        this.fillReports(reportsResponse.data);

        process.env.REACT_APP_ENV !== 'prod' && debugLog('END fetch');
        this.setReady(true);

        calcPerf('End fill from fetch', window.globalStart, window.globalStart - window.beforeGlobalStart);
        window.beforeGlobalStart = performance.now();

        delay(0).then(() => this.fetchIdeas());
        delay(0).then(() => this.fetchWatchers());
        delay(0).then(() => educationBanners.fetchAll());
        delay(0).then(fetchSecondLevelData);

        if (!CURRENT_USER_IS_ADMIN)
            delay(0).then(() =>
                fillDbAfterFetch(this.db, {
                    organization: orgResponse.data,
                    boardsResponse,
                    reportsResponse,
                    usersResponse,
                    platformsResponse,
                    preparedList,
                    issuesScore,
                    releaseNotesResponse,
                    headers: orgResponse.headers,
                    dictResponse,
                    criteriaResponse,
                    criteriaBoardsResponse,
                }),
            );
    } catch (e) {
        failRequest(e);

        delay(0).then(() => educationBanners.fetchAll());
        await this.fetchIdeas();
        delay(0).then(() => this.fetchWatchers());
    }
}
