import { runInAction } from 'mobx';

import api from 'api';
import { failRequest, toUrl } from 'utils';

import { mainStore } from 'store/models/MainStore';

export async function save() {
    const { name, emoji, show_only_top, boardIds } = this;

    const fn = this.id !== -1 ? api.put : api.post;
    runInAction(() => (this.loader = true));
    try {
        const { data } = await fn(this.apiEndpoint, toUrl({ name, emoji, board_ids: boardIds, show_only_top }));
        if (this.id === -1) {
            const report = mainStore.reportsIds.get(data.id);
            if (!report) {
                runInAction(() => {
                    this.id = data.id;
                    mainStore.reportsIds.set(data.id, this);
                });
            }
        }
        return data;
    } catch (error) {
        failRequest(error);
        return false;
    } finally {
        runInAction(() => (this.loader = false));
    }
}
