import { styled } from 'stitches.config';

export const StCellWrapperBtnDropdown = styled('div', {
    button: {
        display: 'none',
        opacity: 0,

        '&[data-state="open"],&[data-show="true"],&[aria-expanded="true"]': {
            display: 'flex',
            opacity: 1,
        },
    },
    '&[aria-expanded="true"]': {
        button: {
            display: 'flex',
            opacity: 1,
        },
    },
});
