import { forwardRef } from 'react';

import { AnchorButton } from 'components/Button';

function HelpArticleLink({ article, children, className, ...props }, forwardedRef) {
    return (
        <AnchorButton
            size={20}
            likeLink
            ref={forwardedRef}
            active
            className={className}
            target="_blank"
            href={/^http/.test(article) ? article : `https://help.ducalis.io/knowledge-base/${article}`}
            {...props}
        >
            {children}
        </AnchorButton>
    );
}

export default forwardRef(HelpArticleLink);
