import { makeAutoObservable } from 'mobx';

import { generateDefaultMention } from 'utils/generateDefaultMention';

class VotingComment {
    issue = null;
    comment = null;
    message = '';
    isReply = false;

    constructor() {
        makeAutoObservable(this);
    }

    replyTo(comment) {
        this.message = comment ? `<p>${generateDefaultMention(comment.user)}&nbsp</p>` : '';
        this.comment = comment;
        this.isReply = true;
    }

    setMessage = (message) => {
        this.message = message;
    };

    clear() {
        this.message = '';
        this.comment = null;
        this.isReply = false;
    }
}

export const votingComment = new VotingComment();
