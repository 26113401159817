import React from 'react';

const RemoveTooltip = ({ name, isIdeaStatus, isDone }) => {
    if (isIdeaStatus && isDone) {
        return (
            <>
                <b>{name}</b> status is the end of the Idea workflow. You can rename it. You can't delete it.
            </>
        );
    }

    if (isIdeaStatus && !isDone) {
        return (
            <>
                <b>{name}</b> status is the beginning of the Idea workflow. You can rename it. You can't delete it.
            </>
        );
    }

    if (isDone) {
        return (
            <>
                <b>{name}</b> status is the end of the issue workflow. You can rename it or change the status color. You
                can't delete it. Completed issues are moved from Backlog to the Done filter
            </>
        );
    }

    return (
        <>
            <b>{name}</b> status is the beginning of the issue workflow. You can rename it or change the status color.
            You can't delete it.
        </>
    );
};

export default RemoveTooltip;
