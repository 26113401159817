import { runInAction } from 'mobx';

import api from 'api';

import failRequest from 'utils/failRequest';

export async function removeDemoIdeas() {
    runInAction(() => {
        this.ideas.forEach((idea) => {
            idea.is_demo && (idea.isRemoved = true);
        });
    });
    try {
        await api.post(`${this.apiEndpoint}/ideas/demo-delete`);
    } catch (e) {
        failRequest(e);
    }
}
