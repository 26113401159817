import React from 'react';

import { Classes } from '@blueprintjs/core';
import classNames from 'classnames';
import { observer } from 'mobx-react-lite';

import highlightText from 'utils/highlightText';

import DictionaryValueItem from 'components/DictionaryValueItem';

import styles from './DictionaryMultiSelectMenuItem.module.sass';

import DictionaryColorsMenu from '../DictionaryColorsMenu';

export const DictionaryMultiSelectMenuItem = observer(
    ({ item, modifiers, handleClick, query, setActive, setBlock }) => {
        const classesItem = classNames(Classes.MENU_ITEM, Classes.POPOVER_DISMISS, styles.menuItemWrapper, {
            [Classes.ACTIVE]: modifiers.active,
        });

        const isDefault = item.system_status === 'todo';
        return (
            <li aria-current={modifiers.active} className={styles.menuItem} onClick={setActive}>
                <div className={classesItem} onClick={() => handleClick(item)}>
                    <DictionaryValueItem color={item.color} name={highlightText(item.name, query)}>
                        {isDefault && <span className="o-4">(Default)</span>}
                    </DictionaryValueItem>
                </div>
                <div className={styles.label}>
                    <DictionaryColorsMenu item={item} setBlock={setBlock} />
                </div>
            </li>
        );
    }
);
