import React, { useEffect, useRef } from 'react';

import { FormattedMessage } from 'react-intl';
import LayoutWithAsideBoardsList from 'routLayouts/LayoutWithAsideBoardsList';

import {
    CURRENT_USER_ID,
    IS_IFRAME,
    IS_PUBLIC_BOARD,
    IS_VOTING_BOARD,
    MAIN_SITE_URL,
    SERVICE_DOMAIN_NAME,
} from 'constants/global';
import { VIEWS } from 'constants/Pages';

import AuthChild from 'modules/Auth/AuthChild';
import AuthFormLogo from 'modules/Auth/AuthChild/AuthFormLogo';

import { mainStore } from 'store/models/MainStore';

import delay from 'utils/delay';
import locationReload from 'utils/locationReload';
import openChat from 'utils/openChat';
import { sendToSentry } from 'utils/sentry';

import { AnchorButton, Button } from 'components/Button';
import ButtonLink from 'components/ButtonLink';
import { CustomIconName } from 'components/CustomIcon';
import Emoji from 'components/Emoji';
import Flex from 'components/Flex';

export default function NotFound({ board = false, contentOnly, mainSite }) {
    const code = useRef(window.is404?.code).current;
    const is404 = +code === 404;

    useEffect(() => {
        const page = code || VIEWS.ERROR_404;
        sendToSentry(page);
        mainStore.updatePageTitle({ title: window.is404?.title, page });
        delay(300).then(() => (window.is404 = undefined));
    }, [code]);

    const emoji = code ? '🛰️' : '🤔';

    if (IS_VOTING_BOARD && !is404 && !CURRENT_USER_ID) {
        return (
            <div className="flex b-of flex-grow">
                <main className="main flex flex-center">
                    <AuthChild logo={<AuthFormLogo />} />
                </main>
            </div>
        );
    }

    let desc;
    if (IS_VOTING_BOARD && !is404) {
        if (board) {
            desc = (
                <FormattedMessage
                    id="not_found.board"
                    defaultMessage="Looks like you don’t have access to this board as it is currently not shared with you. <br></br>Only specific users can access."
                    values={{
                        br: () => <br />,
                    }}
                />
            );
        } else {
            desc = (
                <FormattedMessage
                    id="not_found.all"
                    defaultMessage="Looks like you don’t have access to Vote or Add Ideas. <br></br>Only specific users can access."
                    values={{
                        br: () => <br />,
                    }}
                />
            );
        }
    } else if (!is404 && board) {
        desc = (
            <>
                You don't have access to this board. <br />
                Ask an admin to add you to the board members.
            </>
        );
    } else if (is404 && board) {
        desc = (
            <>
                Houston, We Have a Problem. <br />
                This board doesn't exist.
            </>
        );
    } else if (window.is404?.message) {
        desc = (
            <>
                Houston, We Have a Problem. <br />
                {window.is404?.message}
            </>
        );
    } else {
        desc = "So sorry, we couldn't find what you were looking for...";
    }

    let mainBtn;
    if (IS_VOTING_BOARD && (!is404 || IS_IFRAME)) {
        mainBtn = !IS_IFRAME ? (
            <Flex column gap={16}>
                {mainStore.currentUser?.id ? (
                    <b>
                        {mainStore.currentUser.has_fake_email
                            ? mainStore.currentUser.name
                            : mainStore.currentUser.email}
                    </b>
                ) : null}
                <AnchorButton
                    icon={CustomIconName.ARROW_LEFT}
                    href={`/login/logout?redirect=${window.location.pathname}`}
                    border
                    text="Change Email"
                />
            </Flex>
        ) : null;
    } else if (mainSite) {
        mainBtn = <AnchorButton color="primary" href={MAIN_SITE_URL} border text={`Back to ${SERVICE_DOMAIN_NAME}`} />;
    } else if (IS_PUBLIC_BOARD && contentOnly) {
        mainBtn = <Button onClick={() => locationReload()} border color="primary" href="/" text="Reload the page" />;
    } else if (mainStore.activeBoard?.id) {
        mainBtn = (
            <div className="limit limit-200 ma">
                <ButtonLink
                    color="primary"
                    center
                    border
                    minimal={false}
                    to={mainStore.activeBoard.mainPage}
                    text={IS_VOTING_BOARD ? 'Go to main page' : 'Go to Backlog'}
                />
            </div>
        );
    } else {
        mainBtn = (
            <AnchorButton
                icon={CustomIconName.DASHBOARD}
                color="primary"
                border
                size={42}
                href="/"
                text="Back to dashboard"
            />
        );
    }

    const title = code ? `Whoopsy! ${code}` : 'Whoopsy!';

    const main = (
        <main className="main flex flex-center">
            <div className="ma limit limit-600 t-c">
                <h1 className="ma">
                    {title} <Emoji emoji={emoji} />
                </h1>
                <h2>{desc}</h2>
                <div className="h-10" />
                <Flex center>{mainBtn}</Flex>
                <div className="h-30" />
                <div className="h-30" />
                {!IS_VOTING_BOARD && (
                    <Flex className="o-6" gap={2} center>
                        Having trouble?
                        <Button size={24} minimal border color="blue" onClick={openChat} text="Send us a message" />
                    </Flex>
                )}
            </div>
        </main>
    );

    if (IS_PUBLIC_BOARD || !CURRENT_USER_ID || contentOnly || mainStore.activeBoard?.id) {
        return (
            <div className="flex b-of flex-grow" style={{ borderLeft: '1px solid var(--colors-bg2)' }}>
                {main}
            </div>
        );
    }

    return <LayoutWithAsideBoardsList>{main}</LayoutWithAsideBoardsList>;
}
