import { Suspense, lazy } from 'react';

import BillingApp from 'apps/BillingApp';
import NotFoundApp from 'apps/NotFoundApp';
import { createRoot } from 'react-dom/client';

import { CURRENT_USER_ID, IS_VOTING_BOARD } from 'constants/global';

import LoginApp from 'pages/Login';
import Signup from 'pages/Signup';

import 'utils/polyfills';

import './sass/main.sass';

const App = lazy(() => import(/* webpackChunkName: 'App' */ './App'));
const VotingApp = lazy(() => import(/* webpackChunkName: 'VotingApp' */ 'apps/VotingApp'));

const root = document.getElementById('root');
const signupPage = document.getElementById('signup-page');
const loginForm = document.getElementById('login-form');

if (loginForm) {
    const loginPageRoot = createRoot(loginForm);
    loginPageRoot.render(<LoginApp />);
}

if (signupPage) {
    const signupPageRoot = createRoot(signupPage);
    signupPageRoot.render(<Signup />);
}

if (root && IS_VOTING_BOARD) {
    const rootRoot = createRoot(root);
    rootRoot.render(
        <Suspense fallback={null}>
            <VotingApp />
        </Suspense>,
    );
} else if (root && !CURRENT_USER_ID && window.is404) {
    const rootRoot = createRoot(root);
    rootRoot.render(<NotFoundApp />);
} else if (root) {
    const rootRoot = createRoot(root);
    rootRoot.render(
        <Suspense fallback={null}>
            <App />
        </Suspense>,
    );
}

const billing = document.getElementById('calcForm');
if (billing) {
    const billingRoot = createRoot(billing);
    billingRoot.render(<BillingApp />);
}
