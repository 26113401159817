import Axios from 'axios';
import { runInAction } from 'mobx';

import api from 'api';
import { REST } from 'constants/ApiPaths';

import failRequest from 'utils/failRequest';
import logEvent from 'utils/logEvent';
import toUrl from 'utils/toUrl';

export async function addDomain(domains) {
    logEvent('Add invite domain', { domains });

    const oldDomains = [...this.domains];
    runInAction(() => {
        Object.assign(this, { domains: [...oldDomains, ...domains] });
    });
    try {
        const requests = domains.map((domain) => api.post(REST.workspace.domain, toUrl({ domain })));
        await Axios.all(requests);
    } catch (error) {
        failRequest(error);
        runInAction(() => {
            Object.assign(this, { domains: oldDomains });
        });
    }
}
