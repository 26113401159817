import api from 'api';
import { failRequest } from 'utils';

export async function addFrameworkToBoard(framework) {
    try {
        return await api.post(`${this.apiEndpoint}/framework?framework=features_ice`, undefined, {
            params: { framework },
        });
    } catch (e) {
        failRequest(e);
    }
}
