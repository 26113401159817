import React from 'react';

import { isOSX } from 'hooks/useHotKey';

import Flex from 'components/Flex';

function getNormalizeKey(key) {
    switch (key) {
        case 'alt':
        case 'option':
            return isOSX ? '⌥' : 'Alt';
        case 'mod':
        case 'cmd':
        case 'meta':
            return isOSX ? '⌘' : 'Ctrl';
        case 'left':
            return '<-';
        case 'right':
            return '->';
        case 'shift':
            return '⇧';
        default:
            return key;
    }
}

export default function Kbd({ children }) {
    return <kbd className="ff-case ff-ss02 t-cap">{getNormalizeKey(children)}</kbd>;
}

export function KeyCombo({ children }) {
    return (
        <Flex as="span" inline gap={4} css={{ paddingLeft: 4 }}>
            {children}
        </Flex>
    );
}
