import React from 'react';

import formatPriceWithCurrency from 'utils/formatPriceWithCurrency';

import { CustomIcon, CustomIconName } from 'components/CustomIcon';
import Flex from 'components/Flex';
import Tooltip from 'components/Tooltip';

export default function CalcUsersOverLimit({ planM, quantity }) {
    if (planM.billing_scheme !== 'tiered' || quantity <= planM.tiers[0].up_to) {
        return null;
    }

    return (
        <Flex spaceBetween>
            <Tooltip
                content="If you have 5+ users in account you'll pay for each existing and new one."
                side="left"
                contentClassName="limit limit-300"
            >
                <span>
                    {planM.tiers[0].up_to}+ account users{' '}
                    <CustomIcon className="o-4" icon={CustomIconName.HELP_ALT} size={14} />
                </span>
            </Tooltip>
            <b>
                {`${quantity} users × ${formatPriceWithCurrency({
                    price: planM.tiers[1].unit_amount,
                    currencyCode: planM.currency,
                })}`}
            </b>
        </Flex>
    );
}
