import React from 'react';

import { INSTANCE_PREFIX } from 'constants/global';
import { ReactComponent as PrioplanLogo } from 'images/logo-prioplan-full.svg';
import { ReactComponent as SimpleLogo } from 'images/logo-product.svg';

export default function DucalisLogo({ width = 133, height = 29 }) {
    if (INSTANCE_PREFIX) {
        return <PrioplanLogo color="var(--colors-yellow11)" width={width} height={height} data-place="logo" />;
    }

    return <SimpleLogo color="var(--colors-yellow11)" width={width} height={height} data-place="logo" />;
}
