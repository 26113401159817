import api from 'api';

import failRequest from 'utils/failRequest';
import toUrl from 'utils/toUrl';

export async function deleteVotingEmails(id, params) {
    try {
        const requestData = { ...params };
        if (Array.isArray(id)) {
            requestData.id = id;
        }

        const url = Array.isArray(id)
            ? `${this.apiEndpoint}/voting-users/multiple-delete`
            : `${this.apiEndpoint}/voting-users/${id}`;

        return await api.delete(url, { data: toUrl(requestData) });
    } catch (error) {
        failRequest(error);
        return await Promise.reject();
    }
}
