import { runInAction } from 'mobx';

import api from 'api';
import { failRequest } from 'utils';

export async function exportVotersCsv() {
    try {
        runInAction(() => {
            this.voting_users_csv_export = { status: 'process' };
        });
        await api.post(`${this.apiEndpoint}/voting-users/export-csv`);
    } catch (e) {
        failRequest(e);
    }
}
