import { storageApi } from 'api';
import { failRequest } from 'utils';

import dictionaryStore from 'store/models/DictionaryStore';
import { mainStore } from 'store/models/MainStore';

export async function fetchBoards(params) {
    const config = params ? { params } : undefined;
    try {
        const { data } = await storageApi.get('/boards', config);
        if (params) {
            data.forEach((boardData) => {
                const boardId = boardData.id || boardData.public_id;
                const board = mainStore.boardsList.boardsIds.get(boardId);
                board?.fillModel(boardData);
            });
        } else {
            this.boardsList.set(data.map((board) => ({ ...board, id: board.public_id })));
        }
    } catch (error) {
        failRequest(error);
    }
}

export async function fetchBoardsDictionaries(params) {
    const config = params ? { params } : undefined;
    try {
        const { data } = await storageApi.get('/dictionaries', config);
        dictionaryStore.fillCollection(data);
    } catch (error) {
        failRequest(error);
    }
}
