import { runInAction } from 'mobx';

import api from 'api';

import failRequest from 'utils/failRequest';
import toUrl from 'utils/toUrl';

export async function fetchBackupData(id) {
    try {
        const url = `${this.apiEndpoint}/backups`;
        if (id) {
            const { data } = await api.post(url, toUrl({ id }));
            return data;
        } else {
            const { data } = await api.get(url);
            runInAction(() => {
                this.backupData = data;
            });
        }
    } catch (e) {
        failRequest(e);
    }
}
