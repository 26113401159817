/**
 * Not saved issue
 *
 * @param {Object} issue
 *
 * @returns {boolean}
 */
export default function isUnsavedIssue(issue) {
    return (issue && issue.id === -1) || false;
}
