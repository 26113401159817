import { runInAction } from 'mobx';

import api from 'api';
import { failRequest, toUrl } from 'utils';

export async function addUserToBoard(user_id) {
    try {
        runInAction(() => {
            this.boardUsers.push(user_id);
        });
        await api.post(`${this.apiEndpoint}/users`, toUrl({ user_id }));
    } catch (e) {
        runInAction(() => {
            this.boardUsers = this.boardUsers.filter((id) => id !== user_id);
        });
        failRequest(e);
    }
}

export async function removeUserFromBoard(user_id) {
    try {
        runInAction(() => {
            this.boardUsers = this.boardUsers.filter((id) => id !== user_id);
        });
        await api.delete(`${this.apiEndpoint}/users/${user_id}`);
    } catch (e) {
        runInAction(() => {
            this.boardUsers.push(user_id);
        });
        failRequest(e);
    }
}
