export default function getRandom(len = 1) {
    try {
        return getRandomIntInclusive(1, len);
    } catch {
        return Math.ceil(Math.random() * len);
    }
}

export function getRandomIntInclusive(min, max) {
    const randomBuffer = new Uint32Array(1);

    window.crypto.getRandomValues(randomBuffer);

    let randomNumber = randomBuffer[0] / (0xffffffff + 1);

    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(randomNumber * (max - min + 1)) + min;
}
