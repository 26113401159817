import React, { useMemo } from 'react';

import { useHotkeys } from '@blueprintjs/core';
import { Link, useNavigate } from 'react-router-dom';

import useLinkActive from 'hooks/useLinkActive';

import { Button } from 'components/Button';
import DotDivider from 'components/DotDivider';
import Kbd, { KeyCombo } from 'components/Kbd';
import Tooltip from 'components/Tooltip';

const ComboTooltipDescription = ({ combo, comboShift }) => {
    return (
        <>
            <DotDivider />
            <KeyCombo>
                <Kbd>alt</Kbd>
                {comboShift && <Kbd>shift</Kbd>}
                <Kbd>{combo}</Kbd>
            </KeyCombo>
        </>
    );
};
const useNavHotkey = (combo, title, to, comboShift, disabled = false) => {
    let navigate = useNavigate();

    const comboStr = combo ? (comboShift ? `alt+shift+${combo}` : `alt+${combo}`) : undefined;

    const hotkeys = useMemo(() => {
        if (!disabled && combo) {
            return [
                {
                    combo: comboStr,
                    global: true,
                    label: title,
                    group: 'Board',
                    allowInInput: true,
                    preventDefault: true,
                    onKeyDown: () => navigate(to),
                },
            ];
        }
        return [];
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [to, combo, comboStr, navigate, disabled]);

    useHotkeys(hotkeys);
};

const AsideLinkTooltip = ({ children, tooltip, combo, comboShift }) => {
    return (
        <Tooltip
            side="right"
            content={
                <>
                    {tooltip}
                    {combo && <ComboTooltipDescription combo={combo} comboShift={comboShift} />}
                </>
            }
        >
            {children}
        </Tooltip>
    );
};

/**
 *
 * @param {boolean} isActive
 * @param tooltip
 * @param {boolean} disabled
 * @param {string} combo
 * @param title
 * @param {boolean} slim
 * @param comboShift
 * @param {Object} props
 * @param {boolean} props.hasSub
 * @param {string} props.to
 *
 * @return {JSX.Element}
 */
export const ButtonLink = ({
    isActive,
    tooltip,
    disabled,
    combo,
    title,
    slim,
    comboShift,
    hasSub = false,
    to,
    ...props
}) => {
    useNavHotkey(combo, title || tooltip, to, comboShift, disabled);
    const active = useLinkActive(to, hasSub);

    const link = (
        <Button
            disabled={disabled}
            size={34}
            relative
            minimal
            as={disabled ? 'span' : Link}
            center={slim}
            active={isActive || active}
            to={disabled ? undefined : to}
            {...props}
        >
            {!slim && title}
        </Button>
    );

    if (tooltip) {
        return (
            <AsideLinkTooltip tooltip={tooltip} comboShift={comboShift} combo={!disabled && combo}>
                {link}
            </AsideLinkTooltip>
        );
    }

    return link;
};

ButtonLink.displayName = 'ButtonLink';
