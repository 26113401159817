/**
 * Get error text from error object
 *
 * @param {Object} error
 * @return {undefined|string}
 */
export default function getErrorText(error) {
    if (!error.response) {
        return undefined;
    }

    const { data } = error.response;

    if (Array.isArray(data)) {
        return data.map((e) => e.message).join(' ');
    } else if (typeof data === 'string') {
        return data;
    }

    return data.message || error.message;
}
