import { styled } from 'stitches.config';

export const StRewrittenCell = styled('div', {
    padding: '0 8px',
    display: 'flex',
    alignItems: 'center',
    minWidth: 0,
    gap: 10,
    flexGrow: 1,
    height: '100%',
    transition: 'all .2s',

    '&[data-interactive="true"]': {
        cursor: 'pointer',

        '&:hover': {
            boxShadow: '0 0 0 1px var(--colors-gray9) inset',
        },

        '&[data-active="true"]': {
            '&, &:hover': {
                boxShadow: '0 0 0 1px var(--colors-gray9) inset',
            },
        },
    },

    variants: {
        approved: {
            true: {
                fontWeight: 600,
                background: '$green3',
                color: '$green9',
                boxShadow: 'inset 0 -1px 0 var(--colors-grayA3), inset -1px 0 0 var(--colors-grayA3)',

                '&[data-interactive="true"]': {
                    '&:hover': {
                        boxShadow: '0 0 0 1px var(--colors-green9) inset',
                    },

                    '&[data-active="true"]': {
                        '&, &:hover': {
                            boxShadow: '0 0 0 1px var(--colors-green9) inset',
                        },
                    },
                },
            },
            false: {
                justifyContent: 'end',
            },
        },
    },

    defaultVariants: {
        approved: false,
    },
});
