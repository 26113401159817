import { runInAction } from 'mobx';

import api from 'api';
import { failRequest } from 'utils';

import { PLATFORM_FILTER_TYPE } from 'utils/consts';

export async function getCustomFilterView() {
    if (![PLATFORM_FILTER_TYPE.custom, PLATFORM_FILTER_TYPE.jql].includes(this.filterType)) return;

    try {
        const { data } = await api.post(`/board/filter/summary?board_id=${this.id}`);
        runInAction(() => {
            this.customFilterView = data;
        });
    } catch (e) {
        failRequest(e);
    }
}
