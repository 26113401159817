import api from 'api';

import failRequest from 'utils/failRequest';
import logEvent from 'utils/logEvent';
import toUrl from 'utils/toUrl';

export async function updateQuestionComment({ request, id, message }) {
    logEvent('Update comment');
    try {
        await api.put(
            `/requests/${request.id}/messages/${id}`,
            toUrl({ board_id: this.board.id, issue_id: this.id, message })
        );
    } catch (error) {
        failRequest(error);
    }
}
