import { useEffect } from 'react';

import { toast } from 'components/Toast';

export function useGlobalErrorNotifications() {
    useEffect(() => {
        if (window.server_error) {
            toast({
                type: 'background',
                description: window.server_error,
                color: 'tomato',
                duration: 0,
            });
            window.server_error = null;
        }

        if (window.invalid_invite_email || window.change_email_error) {
            toast({
                type: 'background',
                description: window.invalid_invite_email || window.change_email_error,
                color: 'tomato',
                duration: 0,
            });
            window.invalid_invite_email = null;
            window.change_email_error = null;
        }
    }, []);
}
