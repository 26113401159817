import { PRIMARY_VIOLET } from 'themes/primaryColors';

export const VIOLET_DARK = {
    ...PRIMARY_VIOLET,

    bg1: '#191A23',
    bg2: '#212333',
    bg3: '#191A23',
    bg4: 'hsl(233deg 26% 21% / 90%)',
    bg5: '$grayA4',
    bg6: '#212333',
    bg7: 'hsl(233deg 26% 21% / 90%)',
    bg8: '#212333',

    tc1: '$gray12',
    tc2: '$gray11',
    tc3: '#191A23',

    lc1: '#0099ff',
    lc2: '#0088ff',
    lc3: '#0088ff',

    bc1: '$gray3',

    success: '$green9',
    warning: '$orange9',
    danger: '$red9',

    'control-checked-background': '$primary7',

    'stripe-background': '$grayA2',
    'stripe-background-hover': '$grayA3',
    'stripe-border': '$grayA2',

    kanbanCardBg: '$violet2',
    kanbanCardBgHover: '$violet3',
    kanbanCardBgActive: '$violet3',

    issueCardBg: '$bg2',

    btnBg: '$grayA3',
    btnColor: '$grayA11',
    btnColorHover: '$grayA12',
    btnColorActive: '$grayA12',
    btnBgHover: '$grayA4',
    btnBgActive: '$grayA5',
    btnBorder: '$grayA3',
    btnBorderHover: '$grayA5',
};
