import React, { useContext } from 'react';

import { observer } from 'mobx-react-lite';

import { Button } from 'components/Button';
import { CustomIconName } from 'components/CustomIcon';
import DictionaryContext from 'components/DictionaryValueSelect/DictionaryContext';
import Tooltip from 'components/Tooltip';

function IdeaStatusSystemButtons({ status, onClose }) {
    const { board } = useContext(DictionaryContext);

    if (status.is_system) return null;

    function makeStatusStart() {
        board.changeIdeaSystemStatus({ status: 'under_review', statusId: status.id });
        onClose();
    }

    // INFO: don`t show actions https://concertwithme.atlassian.net/browse/DCLS-8633
    // function changeStatusFinal() {
    //     board.changeIdeaSystemStatus({ status: 'done', statusId: status.id });
    //     onClose();
    // }

    return (
        <>
            {status.system_status !== 'under_review' ? (
                <Tooltip
                    side="right"
                    contentClassName="limit limit-300"
                    content="<b>Start Column (Inbox)</b>: This is where all new ideas land. Think of it as an idea inbox."
                >
                    <Button
                        minimal
                        block
                        icon={CustomIconName.INBOX}
                        text="Set as Start Column"
                        onClick={makeStatusStart}
                    />
                </Tooltip>
            ) : null}
            {/* INFO: don`t show actions https://concertwithme.atlassian.net/browse/DCLS-8633 */}
            {/*{status.system_status !== 'done' ? (*/}
            {/*    <Tooltip*/}
            {/*        side="right"*/}
            {/*        contentClassName="limit limit-300"*/}
            {/*        content="<b>Final Column (Done)</b>: Completed ideas go here. Moving them here automatically adds them to the Changelog."*/}
            {/*    >*/}
            {/*        <Button*/}
            {/*            minimal*/}
            {/*            block*/}
            {/*            icon={CustomIconName.BULLHORN}*/}
            {/*            text="Set as Final Column"*/}
            {/*            onClick={changeStatusFinal}*/}
            {/*        />*/}
            {/*    </Tooltip>*/}
            {/*) : null}*/}
        </>
    );
}

export default observer(IdeaStatusSystemButtons);
