import { runInAction } from 'mobx';

import api from 'api';
import { REST } from 'constants/ApiPaths';

import failRequest from 'utils/failRequest';
import logEvent from 'utils/logEvent';

export async function deleteLogo(attribute) {
    logEvent('Logo delete');

    try {
        await api.delete(REST.workspace.uploadLogo, {
            params: { attribute },
        });
    } catch (e) {
        failRequest(e);
        await Promise.reject(e);
    } finally {
        runInAction(() => {
            this.public_voting_settings[attribute] = null;
        });
    }
}
