import React from 'react';

import Dot from 'components/Dot';

import * as StChecklistItem from './atoms';

export function ChecklistItem({ item, rightElement }) {
    const title = item.key ? `${item.key}: ${item.name}` : item.name;

    return (
        <StChecklistItem.Root
            as={item.url ? 'a' : 'div'}
            href={item.url}
            target={item.url ? '_blank' : undefined}
            completed={item.completed}
            title={title}
        >
            <StChecklistItem.Icon>
                <Dot />
            </StChecklistItem.Icon>
            {item.key && <StChecklistItem.Key>{item.key}</StChecklistItem.Key>}
            <StChecklistItem.Text className="t-crop">{item.name}</StChecklistItem.Text>
            {rightElement && <StChecklistItem.RightElement>{rightElement}</StChecklistItem.RightElement>}
        </StChecklistItem.Root>
    );
}
