import { APP_VERSION, IS_PUBLIC_BOARD } from 'constants/global';

import { utilsStore } from 'store/models/UtilsStore';

import { CustomIconName } from 'components/CustomIcon';
import { toast } from 'components/Toast';

import logEvent from './logEvent';

export const checkNewVersion = (response) => {
    const version = response.headers['x-version'];

    if (version && version !== APP_VERSION) {
        utilsStore.newVersionAlert();
    }
    return response;
};

export const checkErrorStatus = (error) => {
    switch (error.response?.status) {
        case 503:
            toast({
                type: 'background',
                icon: CustomIconName.WARNING,
                description: "We're undergoing a bit of scheduled maintenance. Please try again or reload the page",
                color: 'orange',
                duration: 7000,
            });
            return logEvent('Scheduled maintenance');
        case 401:
            if (IS_PUBLIC_BOARD) {
                return Promise.reject(error);
            }
            logEvent('Login Redirect');
            if (process.env.NODE_ENV !== 'production') {
                return Promise.reject(error);
            }
            return (window.location.href = '/login');
        default:
            return Promise.reject(error);
    }
};
