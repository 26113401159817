import React from 'react';

import { observer } from 'mobx-react-lite';

import { PROVIDER_DUCALIS } from 'constants/Providers';

import CellWrapper from 'components/CellWrapper';
import DictionaryValueSelect from 'components/DictionaryValueSelect';
import Dot from 'components/Dot';

export const IssueDictValue = observer(({ issue, dictionary }) => {
    if (issue.isEditable) {
        return <DictionaryValueSelect simple dictionary={dictionary} issue={issue} />;
    }

    const value = issue.getFieldValueByDictionary(dictionary);
    if (value && issue.provider === PROVIDER_DUCALIS && typeof value !== 'string') {
        return (
            <CellWrapper className="flex flex-a-center gap-6">
                {value.color && <Dot color={value.color} />}
                <span className="bp5-text-overflow-ellipsis">{value.name}</span>
            </CellWrapper>
        );
    }

    return (
        <CellWrapper>
            <span className="bp5-text-overflow-ellipsis">{value}</span>
        </CellWrapper>
    );
});
