import React, { useEffect, useState } from 'react';

import { FormattedMessage } from 'react-intl';

import { rootApi } from 'api';
import { ReactComponent as Google } from 'images/google.svg';

import failRequest from 'utils/failRequest';
import { getAuthButtonUrl } from 'utils/getAuthButtonUrl';
import toUrl from 'utils/toUrl';

import { AnchorButton } from 'components/Button';
import Space from 'components/Space';
import Spinner from 'components/Spinner';

function GoogleBtn({ isUpdater, isSignup, isLogin, setInviteError }) {
    const [showBtn, setShowBtn] = useState(!window.gClientId);
    const [scriptReady, setScriptReady] = useState(false);

    useEffect(() => {
        function callback(data) {
            if (data?.credential) {
                setScriptReady(false);
                rootApi
                    .post(
                        window.gLoginUrl,
                        toUrl({
                            credential: data.credential,
                            timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
                            lang: window.navigator.language || 'en-US',
                        }),
                    )
                    .then(() => window.location.reload())
                    .catch((e) => {
                        if (e.response.status === 409) {
                            setInviteError({
                                email: e.response.data.email,
                                type: 'google',
                                invitedEmail: e.response.data.invitedEmail,
                            });
                        } else {
                            failRequest(e);
                            setScriptReady(true);
                            setShowBtn(true);
                        }
                    });
            }
        }

        function googleInitialize() {
            !scriptReady &&
                window.google.accounts.id.initialize({
                    client_id: window.gClientId,
                    callback,
                    context: window.gContext,
                    prompt_parent_id: 'gId',
                    cancel_on_tap_outside: false,
                    ux_mode: 'popup',
                    state_cookie_domain: '.ducalis.io',
                });
            window.google.accounts.id.prompt((notification) => {
                if (notification.isNotDisplayed() || notification.isSkippedMoment()) {
                    setShowBtn(true);
                }
            });
            setScriptReady(true);
        }

        if (window.gClientId) {
            if (window.google) {
                googleInitialize();
            } else {
                const script = window.document.querySelector('script[src="https://accounts.google.com/gsi/client"]');
                script.addEventListener('load', googleInitialize);

                return () => script.removeEventListener('load', googleInitialize);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            {window.gClientId && <div id="gId" />}
            {!showBtn && !scriptReady && <Spinner size={30} />}
            {showBtn && (
                <div style={{ maxWidth: 375, width: '100%' }}>
                    <Space height={20} />
                    <AnchorButton
                        center
                        block
                        leftElm={<Google width={24} height={24} />}
                        size={42}
                        border
                        css={{
                            gap: 12,
                            fontSize: 15,
                            fontWeight: 700,
                        }}
                        href={getAuthButtonUrl({ isLogin })}
                        text={
                            isUpdater ? (
                                <FormattedMessage
                                    id="auth.change.sso_btn"
                                    defaultMessage="Change email via {serviceName}"
                                    values={{ serviceName: 'Google' }}
                                />
                            ) : isSignup ? (
                                <FormattedMessage
                                    id="auth.signup.google_btn"
                                    defaultMessage="Sign Up with {serviceName}"
                                    values={{ serviceName: 'Google' }}
                                />
                            ) : (
                                <FormattedMessage
                                    id="auth.google_btn"
                                    defaultMessage="Log In with {serviceName}"
                                    values={{ serviceName: 'Google' }}
                                />
                            )
                        }
                    />
                    <Space height={6} />
                </div>
            )}
        </>
    );
}

export default GoogleBtn;
