import { runInAction } from 'mobx';

import api from 'api';
import { failRequest, toUrl } from 'utils';

import criteriaStore from 'store/models/CriteriaStore';

export async function addUserToCriterion({ criterion, user_id }) {
    const boardCriterion = criterion.criterion_id
        ? criterion
        : criteriaStore.criteriaBoardsIds.get(this.getBoardCriterionId(criterion.id));

    try {
        runInAction(() => {
            boardCriterion.users.push(user_id);
        });
        await api.post(`${this.apiEndpoint}/criteria/${boardCriterion.criterion_id}/user`, toUrl({ user_id }));
    } catch (e) {
        runInAction(() => {
            boardCriterion.users = boardCriterion.users.filter((id) => id !== user_id);
        });
        failRequest(e);
    }
}

export async function removeUserFromCriterion({ criterion, user_id }) {
    const boardCriterion = criterion.criterion_id
        ? criterion
        : criteriaStore.criteriaBoardsIds.get(this.getBoardCriterionId(criterion.id));

    try {
        runInAction(() => {
            boardCriterion.users = boardCriterion.users.filter((id) => id !== user_id);
        });
        await api.delete(`${this.apiEndpoint}/criteria/${boardCriterion.criterion_id}/user`, {
            data: toUrl({ user_id }),
        });
    } catch (e) {
        runInAction(() => {
            boardCriterion.users.push(user_id);
        });
        failRequest(e);
    }
}
