import React from 'react';

import { observer } from 'mobx-react-lite';

import Avatar from 'components/Avatar';
import Flex from 'components/Flex';

export const UserView = observer(({ user, size = 18 }) => {
    if (!user) return null;
    return (
        <Flex gap={4}>
            <Avatar user={user} size={size} />
            <span className="t-crop">{user.name || user.email}</span>
        </Flex>
    );
});
