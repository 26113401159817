import { observer } from 'mobx-react-lite';

import paymentPlans from 'store/models/PaymentPlans';

import formatPriceWithCurrency from 'utils/formatPriceWithCurrency';

export const Total = observer(() => {
    return formatPriceWithCurrency({
        currencyCode: paymentPlans.active.currency,
        price: paymentPlans.planTotal.toLocaleString('en-US'),
    });
});
