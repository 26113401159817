import React from 'react';

import { observer } from 'mobx-react-lite';

import { mainStore } from 'store/models/MainStore';

import CriterionDesc from 'components/CriterionDesc/CriterionDesc';
import { CustomIcon, CustomIconName } from 'components/CustomIcon';
import Flex from 'components/Flex';
import Tooltip from 'components/Tooltip';

export const FinalScoreTableHeader = observer(({ criteria }) => {
    return criteria.map((criterion) => {
        return (
            <th key={criterion.id}>
                <Tooltip content={<CriterionDesc criterion={criterion} boardId={mainStore.activeBoard?.id} />}>
                    <Flex gap={4}>
                        <div className="t-crop limit-200">{criterion?.name}</div>
                        <CustomIcon icon={CustomIconName.HELP_ALT} className="o-6" size={14} />
                    </Flex>
                </Tooltip>
            </th>
        );
    });
});
