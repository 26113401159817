import React, { useMemo, useState } from 'react';

import { observer } from 'mobx-react-lite';

import { CustomIcon, CustomIconName } from 'components/CustomIcon';

import { StAvatarImage } from './StAvatarImage';

function AvatarImage({ user, ...props }) {
    const { name = '', avatar, is_member = false } = user;
    const [img, setImg] = useState(avatar);
    const initial = useMemo(() => {
        if (img) return '';
        if (!name) return '--';
        const stringName = String(name).replace(/[.\-_,+]/g, '');
        if (!stringName.includes(' ')) return stringName.slice(0, 2);
        return stringName
            .split(' ')
            .slice(0, 2)
            .map((name) => name[0])
            .join('');
    }, [img, name]);

    const fontSize = useMemo(() => Math.floor((props.width * 15) / 36), [props.width]);

    return (
        <>
            <StAvatarImage
                style={{ fontSize }}
                data-initial={initial || ''}
                data-place="avatar-img"
                src={avatar}
                alt={name}
                onError={() => setImg(null)}
                {...props}
            />
            {is_member && <CustomIcon color="primary" size={11} icon={CustomIconName.STAR} />}
        </>
    );
}

export default observer(AvatarImage);
