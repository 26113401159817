import restApi from 'api';
import { failRequest, logEvent, toUrl } from 'utils';

/**
 * Move Idea status to system status
 *
 * @param {'under_review'|'done'} status
 * @param {number} statusId
 */
export async function changeIdeaSystemStatus({ status, statusId }) {
    try {
        logEvent('Set Idea Status Column', { status, statusId });
        await restApi.post(`${this.apiEndpoint}/idea-statuses/${statusId}/set-as-system`, toUrl({ status }));
    } catch (e) {
        failRequest(e);
    }
}
