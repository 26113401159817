import React from 'react';

import Tooltip from 'components/Tooltip';

export default function EmptyUserTotalValue({ issue }) {
    const board = issue.board;

    if (!board) {
        return null;
    }

    return (
        <Tooltip side="top" content="No public idea for this issue">
            <div className="flex-grow" dangerouslySetInnerHTML={{ __html: '&nbsp;' }} />
        </Tooltip>
    );
}
