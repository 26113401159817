import { observer } from 'mobx-react-lite';

import { mainStore } from 'store/models/MainStore';

import SettingsLinkButton from 'components/SettingsLinkButton';
import Tooltip from 'components/Tooltip';

function getTooltipText(type, link, isFacilitator, isInput) {
    switch (type) {
        case 'facilitator':
            if (isInput && isFacilitator) return null;
            if (isInput && !isFacilitator && !link)
                return 'This prioritization result is changed manually. ONLY Facilitators can change final scores';
            if (!link && !isFacilitator)
                return 'This prioritization result is changed manually. ONLY Facilitators can change final scores';
            if (isFacilitator)
                return <>This prioritization result is changed manually. You can change it due to {link}</>;
            return <>This prioritization result is changed manually. You can’t change it due to {link}</>;
        case 'disabled':
            if (!link) return 'Set up after evaluation and discussions. Nobody can change final scores';
            return <>Set up after evaluation and discussions. It can’t be changed due to {link}</>;
        default:
            if (isInput) return null;
            if (!link) return 'Set up after evaluation and discussions';
            return <>This prioritization result is changed manually. Everybody can change it due to {link}</>;
    }
}

function FinalScoreTooltip({ children, board, isInput }) {
    let settingsLink;
    if (mainStore.currentUser?.isAdmin && mainStore.activeBoard) {
        settingsLink = (
            <SettingsLinkButton
                text="Sprint Planning settings"
                minimal
                active
                likeLink
                inline
                link={`/board/${mainStore.activeBoard.id}/sprint/final-score`}
            />
        );
    }
    const isFacilitator = board.facilitators.has(mainStore.currentUser?.id);
    const tooltip = getTooltipText(board?.settings?.customVoteMode, settingsLink, isFacilitator, isInput);

    if (!tooltip) {
        return children;
    }

    if (!settingsLink) {
        return (
            <Tooltip content={tooltip} side="left">
                {children}
            </Tooltip>
        );
    }

    return (
        <Tooltip disableHoverableContent={false} side="left" contentClassName="limit limit-240" content={tooltip}>
            {children}
        </Tooltip>
    );
}

export default observer(FinalScoreTooltip);
