import api from 'api';
import { failRequest } from 'utils';

export async function getReleaseNotesLabels() {
    try {
        const { data } = await api.get(`${this.apiEndpoint}/announces-labels`);
        return data;
    } catch (e) {
        failRequest(e);
    }
}
