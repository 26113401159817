import { makeAutoObservable, toJS } from 'mobx';

import restApi from 'api';
import { EMPTY_ARRAY } from 'constants/atoms';
import { CRITERION_SCALE_TYPE, CRITERION_SCALES } from 'constants/Criterion';
import { failRequest, toUrl } from 'utils';

import { settingsStore } from 'modules/SettingsDialog/SettingsStore';

import criteriaStore from 'store/models/CriteriaStore';
import { utilsStore } from 'store/models/UtilsStore';

import { UNSAVED_MODEL_ID } from 'utils/consts';

import { Button } from 'components/Button';
import { toast } from 'components/Toast';
import UndoButton from 'components/UndoButton/UndoButton';

import alertStore from './Alert';
import { mainStore } from './MainStore';

function prepareCriteriaForSaving(criterion) {
    const {
        name = '',
        description = '',
        scale_type,
        scale_min,
        scale_max,
        type,
        is_custom,
        custom_field_name,
        custom_platform_id,
    } = criterion;

    let scale_series;
    if (scale_type === CRITERION_SCALE_TYPE.series) {
        scale_series = String(criterion.scale_series).replace(/\s/g, '');
    } else {
        scale_series = criterion.scale_series;
    }

    return {
        name: String(name).trim(),
        description: String(description).trim(),
        scale_type,
        scale_min,
        scale_max,
        scale_series,
        type,
        is_custom,
        custom_field_name,
        custom_platform_id,
    };
}

class DialogCriteriaForm {
    userId = null;
    board_id = [];
    criterion = null;
    criterionId = null;
    errors = null;
    hasChanges = false;
    loading = false;

    constructor() {
        makeAutoObservable(this);
    }

    setErrors = (errors) => {
        this.errors = errors;
    };

    update = (objData) => {
        if (!this.criterion) return;

        const fields = Object.keys(objData);
        if (!this.errors || typeof objData.is_custom !== 'undefined') {
            this.errors = null;
        } else {
            const clearErrors = this.errors.filter((el) => !fields.includes(el.field));
            this.errors = clearErrors.length > 0 ? clearErrors : null;
        }

        Object.assign(this.criterion, objData);
        this.hasChanges = true;
    };

    getBoardIdsForCriteriaId(criterionId) {
        return mainStore.boardsList.activeBoards
            .filter((board) => board.criteriaIds.includes(criterionId))
            .map((board) => board.id);
    }

    setCriterion = (criterion, board_id = []) => {
        if (!criterion) {
            this.criterion = null;
            this.board_id = [];
        } else {
            this.criterion = criterion ? toJS(criterion) : criterion;
            this.board_id =
                board_id || criterion.id !== UNSAVED_MODEL_ID ? this.getBoardIdsForCriteriaId(criterion.id) : [];
        }

        this.errors = null;
        this.hasChanges = false;
    };

    setUser(userId) {
        this.userId = userId;
    }

    setCriterionId(criterionId) {
        this.criterionId = criterionId;
    }

    clear() {
        this.userId = null;
        this.criterionId = null;
        this.setCriterion(null);
    }

    clearChanges() {
        this.hasChanges = false;
    }

    onConfirmCloseForm(force) {
        if (!mainStore.activeBoard && !settingsStore.isOpen && !force) return;
        this.clear();
    }

    closeForm = (force) => {
        if (this.hasChanges) {
            return alertStore.confirm({
                message: 'All unsaved changes will be lost. Are you sure you want to leave?',
                confirmButtonText: 'Don’t save and leave',
                cancelButtonText: 'Stay',
                onConfirm: () => this.onConfirmCloseForm(force),
            });
        }
        this.onConfirmCloseForm(force);
    };

    setLoading(loading) {
        this.loading = loading;
    }

    setBoardId = (board_id = []) => {
        this.board_id = board_id;
    };

    getErrorsForm() {
        const { name = '', scale_type, scale_series, type, is_custom, custom_field_name } = this.criterion;

        const errors = [];

        if (!name) {
            errors.push({ field: 'name', message: 'Specify the name' });
        }
        if (is_custom && !custom_field_name) {
            errors.push({ field: 'custom_field_name', message: 'Select a Custom Field for this criterion' });
        }
        if (!type) {
            errors.push({ field: 'type', message: 'Select a Type for this criterion' });
        }
        if (!is_custom && scale_type === CRITERION_SCALE_TYPE.series && scale_series === '') {
            errors.push({ field: 'scale_series', message: 'Select a Score for this criterion' });
        }
        return errors.length === 0 ? EMPTY_ARRAY : errors;
    }

    beforeSaveCriterion = () => {
        const errors = this.getErrorsForm();
        if (errors.length > 0) {
            return this.setErrors(errors);
        }

        if (this.criterion.id && this.criterion.id !== UNSAVED_MODEL_ID && !this.isValidEstimation()) {
            alertStore.confirm({
                message:
                    'Do you really want to change the range? All scores that do not fall within the scale range will be deleted.',
                onConfirm: () => this.saveCriterion(),
                confirmButtonText: 'Confirm',
            });
            return;
        }
        this.saveCriterion();
    };

    async sendSaveRequest() {
        const criterion = prepareCriteriaForSaving(this.criterion);
        if (this.criterion.id === UNSAVED_MODEL_ID) {
            return await restApi.post(`/criteria`, toUrl(criterion));
        } else {
            return await restApi.put(`/criteria/${this.criterion.id}`, toUrl(criterion));
        }
    }

    async saveCriterion() {
        this.setLoading(true);
        const isNewCriterion = this.criterion.id === UNSAVED_MODEL_ID;
        try {
            const { data, headers } = await this.sendSaveRequest();

            this.setLoading(false);
            this.clearChanges();

            if (isNewCriterion) {
                if (settingsStore.isOpen && settingsStore.board) {
                    this.closeForm();
                    settingsStore.board.addCriterionToBoard(data.id);
                } else {
                    this.setLoading(false);
                    this.clearChanges();
                    this.setCriterion(data);

                    const showFindButton =
                        !utilsStore.settings.groupByCriteria || utilsStore.settings.hideUnlinkedCriteria;

                    if (!showFindButton) {
                        this.setCriterionId(data.id);
                    }

                    toast({
                        description: 'Criterion was created successfully',
                        action: showFindButton ? (
                            <Button
                                text="Show in Grid"
                                onClick={() => {
                                    utilsStore.fillSettings({
                                        groupByCriteria: true,
                                        hideUnlinkedCriteria: false,
                                    });
                                    this.setCriterionId(data.id);
                                }}
                            />
                        ) : undefined,
                        duration: 6000,
                    });
                }
            } else {
                const historyId = headers['criterion_history'];

                toast({
                    description: 'Criterion was saved successfully',
                    action: historyId ? <UndoButton onClick={() => this.undoAction(historyId)} /> : undefined,
                    duration: 6000,
                });

                if (settingsStore.isOpen && settingsStore.board) {
                    settingsStore.goToLink(`/board/${settingsStore.board.id}/criteria/${this.criterion.id}`);
                    this.closeForm();
                }
            }
        } catch (e) {
            if (e?.response?.status === 422) {
                this.setErrors(e.response.data);
            }
            this.setLoading(false);
        }
    }

    usingPreset = (estimation) => {
        if (this.criterion.id && estimation.scale_type !== this.criterion.scale_type) {
            alertStore.confirm({
                message:
                    'Are you sure you want to change the evaluation scores for the criterion? All existing scoring will be removed.',
                onConfirm: () => {
                    this.update(estimation);
                },
            });
        } else {
            this.update(estimation);
        }
    };

    undoAction = async (historyId) => {
        try {
            await restApi.put('criteria/restore', toUrl({ event_id: historyId.split(',') }));
            if (this.criterion.id && this.criterion.id !== UNSAVED_MODEL_ID) {
                const newCriterion = criteriaStore.criteriaIds.get(this.criterion.id);
                this.setCriterion(newCriterion);
            }
        } catch (e) {
            failRequest(e);
        }
    };

    restore = async (id = []) => {
        try {
            await restApi.put('criteria/restore', toUrl({ id }));
        } catch (e) {
            failRequest(e);
        }
    };

    isValidEstimation = () => {
        const currentCriterion = this.criterion;
        const oldCriterion = criteriaStore.criteriaIds.get(this.criterion.id);

        if (
            currentCriterion.scale_type !== oldCriterion.scale_type ||
            ![CRITERION_SCALE_TYPE.series, CRITERION_SCALE_TYPE.range].includes(currentCriterion.scale_type)
        ) {
            return true;
        }

        switch (currentCriterion.scale_type) {
            case CRITERION_SCALE_TYPE.series:
                const newSeries = (currentCriterion.scale_series || '').toString().split(',');
                return !(oldCriterion.scale_series || '')
                    .toString()
                    .split(',')
                    .some((el) => !newSeries.includes(el));
            case CRITERION_SCALE_TYPE.range:
                return (
                    currentCriterion.scale_max >= oldCriterion.scale_max &&
                    currentCriterion.scale_min <= oldCriterion.scale_min
                );
            default:
                return true;
        }
    };

    changeType({ id: type }) {
        if (type === this.criterion.scale_type) return;

        if (this.criterion.id !== UNSAVED_MODEL_ID) {
            alertStore.confirm({
                message:
                    'Are you sure you want to change the evaluation scores for the criterion? All existing scoring will be removed.',
                onConfirm: () => {
                    this.update({ scale_type: type });
                },
            });
        } else {
            this.update({ scale_type: type });
        }
    }

    // eslint-disable-next-line no-unused-vars
    suggestPopListHandler({ id, ...criterion }) {
        const newCriterion = Object.assign({}, this.criterion, criterion);
        this.update(newCriterion);
    }

    get estimation() {
        return !this.criterion ? null : CRITERION_SCALES.find((el) => el.id === this.criterion.scale_type);
    }

    get boards() {
        return this.criterion
            ? mainStore.boardsList.activeBoards.filter((board) => this.board_id.includes(board.id))
            : EMPTY_ARRAY;
    }
}

export const dialogCriteriaFormStore = new DialogCriteriaForm();
