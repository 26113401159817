import { styled } from 'stitches.config';

export const StAvatarStatus = styled('div', {
    position: 'absolute',
    color: 'transparent',
    pointerEvents: 'none',
    boxShadow: 'inset 0 0 0 2px currentColor',
    borderRadius: '50%',
    left: 0,
    top: 0,
    width: '100%',
    height: '100%',
    opacity: 0.7,
    zIndex: 0,

    variants: {
        online: {
            true: {
                color: '$success',
            },
        },

        small: {
            true: {
                boxShadow: 'inset 0 0 0 1px currentColor',
            },
        },
    },
});
