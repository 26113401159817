import React from 'react';

import styles from './DotDivider.module.sass';

export default function DotDivider({ text = '・', ...props }) {
    return (
        <span className={styles.dotDivider} {...props}>
            {text}
        </span>
    );
}
