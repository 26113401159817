import api from 'api';
import { failRequest } from 'utils';

export async function deleteWebhook(id) {
    try {
        await api.delete(`/organization-webhooks/${id}`);
    } catch (e) {
        failRequest(e);
    }
}
