import { styled } from 'stitches.config';

import { colorsVariant } from 'utils/colorsVariant';

export const StDot = styled('span', {
    display: 'inline-block',
    width: 5,
    height: 5,
    minWidth: 5,
    minHeight: 5,
    background: 'currentColor',
    borderRadius: '50%',
    variants: {
        color: {
            ...colorsVariant.solid,
        },
    },
});
