import { makeAutoObservable } from 'mobx';

import { collectionUtils } from 'store/models/utils/collectionsUtils';

import * as DictionaryStoreApi from './api/DictionaryStoreApi';
import { mainStore } from './MainStore';

class DictionaryStore {
    IssueStatus = new Map();
    IdeaLabel = new Map();
    IdeaStatus = new Map();
    IssueLabel = new Map();
    IssueType = new Map();
    IdeaAnnounceLabel = new Map();

    constructor() {
        makeAutoObservable(this);
    }

    clear() {
        this.IssueStatus.clear();
        this.IdeaLabel.clear();
        this.IdeaStatus.clear();
        this.IssueLabel.clear();
        this.IssueType.clear();
        this.IdeaAnnounceLabel.clear();
    }

    fillCollection(data) {
        if (!data) return;

        Object.entries(data).forEach(([dictionary, list]) => {
            collectionUtils.updateMap(this[dictionary], list);
        });
    }

    fillDB = async (data) => {
        await mainStore.db.saveList(data.IssueStatus, 'IssueStatus');
        await mainStore.db.saveList(data.IdeaLabel, 'IdeaLabel');
        await mainStore.db.saveList(data.IdeaStatus, 'IdeaStatus');
        await mainStore.db.saveList(data.IssueLabel, 'IssueLabel');
        await mainStore.db.saveList(data.IssueType, 'IssueType');
        await mainStore.db.saveList(data.IdeaAnnounceLabel, 'IdeaAnnounceLabel');
    };

    async addDictionaryItem(dictionary, item) {
        this[dictionary].set(item.id, item);
        await mainStore.db.updateRowDB(item, dictionary);
    }

    async removeDictionaryItem(dictionary, itemId) {
        this[dictionary].delete(itemId);
        await mainStore.db.removeRowDB(itemId, dictionary);
    }

    updateDictionaryItem(dictionary, item) {
        const value = this[dictionary].get(item.id);
        value && this[dictionary].set(item.id, { ...value, ...item });
    }

    get issueStatuses() {
        return Array.from(this.IssueStatus.values());
    }

    get ideaLabels() {
        return Array.from(this.IdeaLabel.values());
    }

    get ideaStatuses() {
        return Array.from(this.IdeaStatus.values());
    }

    get issueLabels() {
        return Array.from(this.IssueLabel.values());
    }

    get issueTypes() {
        return Array.from(this.IssueType.values());
    }

    get issueStatus() {
        return Array.from(this.IdeaAnnounceLabel.values());
    }

    get doneStatusId() {
        const status = this.issueStatuses?.find((el) => el.system_status === 'done');
        return status?.id ?? null;
    }

    get defaultIssueTypeId() {
        return this.issueTypes?.[0]?.id ?? null;
    }

    get todoStatusId() {
        const status = this.issueStatuses.find((el) => el.system_status === 'todo');
        return status?.id ?? null;
    }

    updateDictItem = DictionaryStoreApi.updateDictItem;
    createDictItem = DictionaryStoreApi.createDictItem;
    removeDictItem = DictionaryStoreApi.removeDictItem;
}

export default new DictionaryStore();
