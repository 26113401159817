import delay from 'utils/delay';

export default function hideLoadingScreen() {
    const el = document.querySelector('.MainLoader');
    delay(0).then(() => {
        el && (el.style.opacity = 0);
    });
    delay(600).then(() => {
        el && (el.style.display = 'none');
    });
}
