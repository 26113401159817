import React from 'react';

import formatPriceWithCurrency from 'utils/formatPriceWithCurrency';

import Flex from 'components/Flex';

export default function CalcYear({ active, type, planM, quantity }) {
    if (!active || active.id === 1 || type === 'monthly') {
        return null;
    }

    const price =
        planM.billing_scheme === 'tiered'
            ? quantity > planM.tiers[0].up_to
                ? quantity * planM.tiers[1].unit_amount
                : planM.tiers[0].flat_amount
            : quantity * planM.amount;

    return (
        <Flex spaceBetween>
            <span>Year</span>
            <b>{`12 months × ${formatPriceWithCurrency({ price, currencyCode: planM.currency })}`}</b>
        </Flex>
    );
}
