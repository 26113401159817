import { runInAction, toJS } from 'mobx';

import api from 'api';

import criteriaStore from 'store/models/CriteriaStore';

import failRequest from 'utils/failRequest';
import logEvent from 'utils/logEvent';
import { trackCrispEvent } from 'utils/trackCrispEvent';

export async function removeCriterion({ criterion }) {
    logEvent('TEAM_CRITERION_DELETE', { criterion });
    trackCrispEvent('Criteria Changed', { action: 'remove criterion' });

    const rawCriterion = toJS(criterion);
    runInAction(() => {
        criteriaStore.criteriaBoardsIds.delete(rawCriterion.id);
    });
    try {
        const { headers } = await api.delete(`${this.apiEndpoint}/criteria/${rawCriterion.criterion_id}`);
        return headers['boardcriterion_history'];
    } catch (e) {
        failRequest(e);
        criteriaStore.criteriaBoardsIds.set(rawCriterion.id, rawCriterion);
        return false;
    }
}
