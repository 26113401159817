import React, { useState } from 'react';

import EnglishWrapper from 'EnglishWrapper';

import InviteError from 'pages/InviteError/InviteError';

import AuthChild from 'modules/Auth/AuthChild';

import { Toaster } from 'components/Toast';

function Login() {
    const [inviteError, setInviteError] = useState({});

    if (inviteError.email && inviteError.type && inviteError.invitedEmail) {
        return (
            <InviteError
                email={inviteError.email}
                type={inviteError.type}
                invitedEmail={inviteError.invitedEmail}
                setInviteError={setInviteError}
            />
        );
    }

    return (
        <div className="login-form flex flex-center flex-a-center">
            <AuthChild isLogin setInviteError={setInviteError} />
        </div>
    );
}

function LoginApp() {
    return (
        <EnglishWrapper>
            <Login />
            <Toaster />
        </EnglishWrapper>
    );
}

export default LoginApp;
