import getAIText from 'api/getAIText';

export async function generateAI(ctrl) {
    if (ctrl?.signal?.aborted) return;

    const url =
        ['email', 'telegram'].includes(this.source) || !this.issue_id
            ? `/rest/boards/${this.board_id}/ideas/chatgpt-description?idea_id=${this.id}`
            : `/rest/boards/${this.board_id}/ideas/chatgpt-description?issue_id=${this.issue_id}`;
    this.clearAIText('');
    await getAIText(url, this.setAIText, ctrl);
}
