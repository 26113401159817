import { runInAction } from 'mobx';

import { rootApi } from 'api';
import { failRequest, logEvent, toUrl } from 'utils';

export async function subscribeOnReleaseNote(board_id) {
    try {
        runInAction(() => {
            this.release_note_subscribed_boards.push(board_id);
        });

        logEvent('User Subscribed on ReleaseNote by Banner', { board_id });

        await rootApi.post(`/notifications/release-notes-subscribe`, toUrl({ board_id }));
    } catch (e) {
        failRequest(e);
        runInAction(() => {
            this.release_note_subscribed_boards = this.release_note_subscribed_boards.filter((id) => id !== board_id);
        });
        return Promise.reject(e);
    }
}
