import React from 'react';

import { observer } from 'mobx-react-lite';

import criteriaStore from 'store/models/CriteriaStore';

import { EstimationDesc } from 'utils/estimation';

import DotDivider from 'components/DotDivider';
import Flex from 'components/Flex';
import Kbd, { KeyCombo } from 'components/Kbd';
import Space from 'components/Space';

function CriterionDesc({ needAction, canSkip, boardId, ...props }) {
    const criterionId = props.criterion?.id || props.criterionId?.id || props.criterionId;
    const criterion = props.criterion || criteriaStore.criteriaIds.get(criterionId);
    const boardCriterion = boardId
        ? criteriaStore.criteriaBoardsIds.get(`${boardId}_${criterionId}_${criterion.workspace_id}`)
        : undefined;

    return (
        <>
            {criterion.description && (
                <>
                    <p>
                        <b>{criterion.name}</b>
                    </p>
                    <div
                        className="criterion-description"
                        dangerouslySetInnerHTML={{ __html: criterion.description }}
                    />
                    <Space height={8} />
                </>
            )}
            {boardCriterion?.coefficient ? <p>Weight: {boardCriterion.coefficient}</p> : null}
            {!criterion.is_custom && (
                <Flex as="p" gap={4}>
                    Scores: <EstimationDesc criterion={criterion} />
                </Flex>
            )}
            {needAction && (
                <p>
                    Next cell
                    <DotDivider />
                    <KeyCombo>
                        <Kbd>tab</Kbd>
                        <span>or</span>
                        <Kbd>enter</Kbd>
                    </KeyCombo>
                </p>
            )}
            {canSkip && (
                <p>
                    Skip Issue from Evaluation
                    <DotDivider />
                    <KeyCombo>
                        <Kbd>ctrl</Kbd> <Kbd>shift</Kbd> <Kbd>→</Kbd>
                    </KeyCombo>
                </p>
            )}
        </>
    );
}

export default observer(CriterionDesc);
