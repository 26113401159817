import React from 'react';

import { observer } from 'mobx-react-lite';

import {
    GROUP_ITEM_AVERAGE,
    GROUP_ITEM_AVERAGE_ROUND_UP,
    MAIN_GROUP,
} from 'modules/SettingsDialog/SettingsDialogBoardPages/BoardCriteriaSettings/Criteria/Formulas/consts';

import { mainStore } from 'store/models/MainStore';

import SettingsLinkButton from 'components/SettingsLinkButton';
import Tooltip from 'components/Tooltip';

function FormulaTooltip({ board, isSlim }) {
    const formulaKey = board?.settings?.formulaRound ? GROUP_ITEM_AVERAGE_ROUND_UP : GROUP_ITEM_AVERAGE;
    const formula = MAIN_GROUP.find((el) => el.key === formulaKey);

    const value = <div>{isSlim ? formula?.shortName : formula?.name}</div>;

    if (!mainStore.currentUser?.isAdmin || !mainStore.activeBoard) {
        return (
            <Tooltip
                contentClassName="limit limit-240"
                content={
                    <>
                        Calculated as <b>{formula?.name}</b> formula across each teammate’s score
                    </>
                }
            >
                {value}
            </Tooltip>
        );
    }

    return (
        <Tooltip
            disableHoverableContent={false}
            content={
                <div className="limit limit-240">
                    Calculated as <b>{formula?.name}</b>{' '}
                    <SettingsLinkButton
                        likeLink
                        minimal
                        active
                        inline
                        text="formula"
                        link={`/board/${mainStore.activeBoard.id}/formula`}
                    />{' '}
                    across each teammate’s score
                </div>
            }
        >
            {value}
        </Tooltip>
    );
}

export default observer(FormulaTooltip);
