import { styled } from 'stitches.config';

export const StFinalScoreTable = styled('table', {
    borderSpacing: 0,
    borderTop: '1px solid var(--colors-grayA3)',
    overscrollBehaviorX: 'contain',

    td: {
        padding: 0,
    },

    'td, th': {
        height: 36,
        verticalAlign: 'center',
        boxShadow: 'inset 0 -1px 0 var(--colors-grayA3), inset -1px 0 0 var(--colors-grayA3)',

        '&:first-child': {
            boxShadow:
                'inset 1px 0 0 var(--colors-grayA3), inset 0 -1px 0 var(--colors-grayA3), inset -1px 0 0 var(--colors-grayA3)',
        },
    },

    'td:first-child, th': {
        textAlign: 'left',
    },

    'td:not(:has(input)), th': {
        padding: '0 10px 1px 6px',
    },

    'tr[data-separator] td': {
        borderBottom: '2px solid var(--colors-grayA3)',
    },

    variants: {
        fixed: {
            true: {
                tableLayout: 'fixed',
                width: '100%',

                td: {
                    '&:not(:first-child)': {
                        maxWidth: 100,
                        width: 100,
                    },
                },
            },
        },
    },
});
