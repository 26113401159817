import { useEffect, useState } from 'react';

import { mainStore } from 'store/models/MainStore';

export default function useDisabledStatus() {
    const disconnected = mainStore.disconnected;
    const paymentRequired = mainStore.organization.paymentRequired;

    const [isDisabled, setIsDisabled] = useState(() => disconnected && paymentRequired);

    useEffect(() => {
        setIsDisabled(() => disconnected || paymentRequired);
    }, [disconnected, paymentRequired]);

    return isDisabled;
}
