import { runInAction, toJS } from 'mobx';

import api from 'api';

import failRequest from 'utils/failRequest';
import toUrl from 'utils/toUrl';

export async function revokeToken(id) {
    const token = toJS(this.token);

    runInAction(() => {
        this.token = null;
    });
    try {
        const { data } = await api.delete(`/users/tokens`, { data: toUrl({ id }) });
        runInAction(() => {
            this.token = data;
        });
    } catch (e) {
        failRequest(e);
        runInAction(() => {
            this.token = token;
        });
    }
}
