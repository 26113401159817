import React, { useEffect, useState } from 'react';

import { observer } from 'mobx-react-lite';

import FinalScoreTooltip from 'modules/InsideIssue/components/FinalScore/components/FinalScoreTooltip';

import criteriaStore from 'store/models/CriteriaStore';
import { Idea } from 'store/models/Idea';
import { issuesList } from 'store/models/MainStore';
import { mainStore } from 'store/models/MainStore';

import { ENTER, PAYWALL_LIMITS } from 'utils/consts';
import delay from 'utils/delay';
import getKeyCode from 'utils/getKeyCode';
import validateVote, { getVoteError } from 'utils/validateVote';

import { Button } from 'components/Button';
import { CustomIconName } from 'components/CustomIcon';
import { DisabledScoreValueTooltip } from 'components/DisabledScoreValueTooltip/DisabledScoreValueTooltip';
import * as InputField from 'components/InputField';
import { useToast } from 'components/Toast';
import Tooltip from 'components/Tooltip';
import UpgradePlanBtn from 'components/UpgradePlanBtn';

function ValueInput({ value = '', isFinalScore, criterion, custom, disabled, autoFocus, canOpenDialog }) {
    const { toast } = useToast();
    const [vote, setVote] = useState(value);
    const [focus, setFocus] = useState(false);
    const [success, setSuccess] = useState(false);

    useEffect(() => {
        success && delay(1000).then(() => setSuccess(false));
    }, [success]);

    useEffect(() => {
        setVote((state) => (state !== value ? value : state));
    }, [value]);

    if (isFinalScore && !mainStore.organization.hasPaymentPlan(PAYWALL_LIMITS.CUSTOM_VOTE)) {
        return (
            <UpgradePlanBtn paywall={PAYWALL_LIMITS.CUSTOM_VOTE}>
                <InputField.Root fill cell>
                    <InputField.Input disabled type="number" value={vote} />
                </InputField.Root>
            </UpgradePlanBtn>
        );
    }

    if (!issuesList.activeIssue) {
        return null;
    }

    async function save(criterion, vote) {
        if (issuesList.activeIssue instanceof Idea) {
            await issuesList.activeIssue.parentIssue.vote({ criterion: criterion, vote, custom });
        } else {
            await issuesList.activeIssue.vote({ criterion: criterion, vote, custom });
        }
        setSuccess(true);
    }

    function onBlur(event) {
        delay(200).then(() => setFocus(false));
        if (validateVote(vote === null ? '' : vote, criterion, true, isFinalScore)) {
            if (vote !== value) {
                save(criterion, vote);
            }
        } else {
            toast({ description: getVoteError(criterion, isFinalScore), color: 'tomato', duration: 7000 });
            event.target.focus();
            return false;
        }
    }

    function keyDown(e) {
        if (getKeyCode(e) === ENTER) {
            e.target.blur();
        }
    }

    const canUserEditScore = isFinalScore
        ? issuesList.issue.board.userCanChangeCriteriaFinalScore(mainStore.currentUser.id, criterion.id)
        : !disabled;

    const input = (
        <InputField.Root fill cell>
            <InputField.Input
                autoFocus={autoFocus}
                disabled={!canUserEditScore}
                onClick={(e) => e.target.select()}
                type="number"
                onKeyDown={keyDown}
                value={vote}
                onBlur={onBlur}
                onChange={(e) => setVote(e.target.value)}
                onFocus={() => setFocus(true)}
            />
        </InputField.Root>
    );

    if (!canUserEditScore && isFinalScore) {
        return (
            <FinalScoreTooltip board={issuesList.activeIssue.board} isInput>
                {input}
            </FinalScoreTooltip>
        );
    }

    if (!canUserEditScore) {
        return (
            <DisabledScoreValueTooltip
                date={issuesList.activeIssue.board.sprint?.ends_at}
                isAdmin={mainStore.currentUser?.isAdmin}
            >
                {input}
            </DisabledScoreValueTooltip>
        );
    }

    if (canOpenDialog && criterion.id !== criteriaStore.alignCriterionId) {
        return (
            <Tooltip
                open={focus || undefined}
                disableHoverableContent={false}
                content={
                    <Button
                        iconProps={{ viewBox: 24 }}
                        block
                        border
                        color="primary"
                        text="Approve & Comment"
                        icon={CustomIconName.CHECK_SEAL}
                        onClick={() => criteriaStore.setAlignCriterionId(criterion?.id)}
                    />
                }
            >
                {input}
            </Tooltip>
        );
    }
    return input;
}

export default observer(ValueInput);
