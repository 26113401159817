import api from 'api';
import { REST } from 'constants/ApiPaths';
import { failRequest } from 'utils';

export async function getSecret() {
    try {
        const { data } = await api.get(REST.workspace.revealAppSecret);
        return data;
    } catch (e) {
        failRequest(e);
        return await Promise.reject();
    }
}
