import * as Toast from '@radix-ui/react-toast';

import { keyframes, styled } from 'stitches.config';

const VIEWPORT_PADDING = 25;

export const StToastViewport = styled(Toast.Viewport, {
    position: 'fixed',
    top: 0,
    right: 0,
    display: 'flex',
    flexDirection: 'column',
    padding: 13,
    gap: 10,
    width: '100vw',
    margin: 0,
    listStyle: 'none',
    zIndex: 2147483647,
    outline: 'none',
    alignItems: 'center',
    pointerEvents: 'none',
});

const hide = keyframes({
    '0%': { opacity: 1, marginTop: 0 },
    '100%': { opacity: 0, marginTop: -40 },
});

const slideIn = keyframes({
    from: { transform: `translateY(-${VIEWPORT_PADDING}px)` },
    to: { transform: 'translateY(0px)' },
});

const swipeOut = keyframes({
    from: { transform: 'translateX(var(--radix-toast-swipe-end-x))' },
    to: { transform: `translateX(calc(var(--radix-toast-swipe-end-x) + 500px))` },
});

export const StToastRoot = styled(Toast.Root, {
    backgroundColor: '$bg3',
    border: '1px solid',
    borderRadius: 6,
    boxShadow: 'hsl(206 22% 7% / 35%) 0px 10px 38px -10px, hsl(206 22% 7% / 20%) 0px 10px 20px -15px',
    padding: '6px 8px',
    display: 'flex',
    gap: 12,
    alignItems: 'center',
    color: '$tc1',
    pointerEvents: 'auto',

    minHeight: 38,
    maxWidth: 500,
    minWidth: 300,

    '&[data-state="open"]': {
        animation: `${slideIn} .2s cubic-bezier(0.16, 1, 0.3, 1)`,
    },
    '&[data-state="closed"]': {
        animation: `${hide} .2s ease-out`,
    },
    '&[data-swipe="move"]': {
        transform: 'translateX(var(--radix-toast-swipe-move-x))',
    },
    '&[data-swipe="cancel"]': {
        transform: 'translateY(0)',
        transition: 'transform .2s ease-out',
    },
    '&[data-swipe="end"]': {
        animation: `${swipeOut} .2s ease-out`,
    },

    variants: {
        center: {
            true: {
                justifyContent: 'center',
            },
        },
        color: {
            tomato: {
                borderColor: `$tomato5`,
                backgroundColor: `$tomato3`,
                color: `$tomato11`,
            },
            green: {
                borderColor: `$green5`,
                backgroundColor: `$green3`,
                color: `$green11`,
            },
            orange: {
                borderColor: `$orange5`,
                backgroundColor: `$orange3`,
                color: `$orange11`,
            },
            gray: {
                borderColor: '$gray5',
                bc: '$bg3',
                color: '$tc1',
            },
            primary: {
                borderColor: '$primary5',
                bc: '$primary3',
                color: '$primary11',
            },
        },
    },
});

export const StToastTitle = styled(Toast.Title, {
    gridArea: 'title',
    fontWeight: 500,
    fontSize: 15,
});

export const StToastDescription = styled(Toast.Description, {
    gridArea: 'description',
    fontSize: 13,
    lineHeight: 1.3,
    maxHeight: '40vh',
    overflow: 'auto',

    a: {
        color: 'inherit',
        opacity: 0.8,
        textDecoration: 'underline',

        '&:hover': {
            color: 'inherit',
            opacity: 1,
        },
    },
});

export const StToastAction = styled(Toast.Action, {
    flexShrink: 0,
    marginLeft: 20,
});

export const StToastClose = styled(Toast.Close, {
    flexShrink: 0,
    marginLeft: 20,
});
