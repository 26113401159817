import io from 'socket.io-client';

import { CURRENT_ORG_PUBLIC_ID, IS_PUBLIC_BOARD, IS_VOTING_BOARD, MAIN_404_PAGE } from 'constants/global';

export function getSocketNameSpace(workspace) {
    if (MAIN_404_PAGE || IS_VOTING_BOARD) {
        return null;
    }
    if (IS_PUBLIC_BOARD) {
        return `/pbl_${window.publicBoard.public_id}`;
    }
    if (workspace) {
        return `/ws_${workspace}`;
    }
    if (CURRENT_ORG_PUBLIC_ID) {
        return `/org_${CURRENT_ORG_PUBLIC_ID}`;
    }

    return null;
}

const voidFN = () => undefined;

export function getSocket(namespace) {
    if (namespace === undefined) return { emit: voidFN, on: voidFN };

    if (process.env.NODE_ENV !== 'production' && process.env.REACT_APP_WEBSOCKET_URL) {
        return io(`${process.env.REACT_APP_WEBSOCKET_URL}${namespace}`, { transports: ['websocket'] });
    }
    if (process.env.NODE_ENV !== 'production') {
        return io(`ws://${window.location.hostname}:3002${namespace}`, { transports: ['websocket'] });
    }
    return io(namespace, { transports: ['websocket'] });
}
