import { runInAction } from 'mobx';

import api from 'api';
import { failRequest } from 'utils';

import toUrl from 'utils/toUrl';

function setWebhookRequest(webhook, id) {
    if (id) {
        return api.put(`/organization-webhooks/${id}`, toUrl(webhook));
    }
    return api.post('/organization-webhooks', toUrl(webhook));
}

export async function setWebhook(webhook, id) {
    try {
        let { data } = await setWebhookRequest(webhook, id);
        runInAction(() => {
            this.openWebHook = data.id;
        });
    } catch (e) {
        failRequest(e);
    }
}

export async function getWebhookDate(id) {
    try {
        const { data } = await api.get(`/organization-webhooks/${id}/last-usage`);
        runInAction(() => {
            this.openWebHook = null;
        });
        return data;
    } catch (e) {
        failRequest(e);
        return null;
    }
}
