import React from 'react';

import { observer } from 'mobx-react-lite';

import { RewriteValue } from './RewriteValue';

export const AverageScoreItem = observer(({ issue, criterion, hideTooltip }) => {
    const rewritten = issue.externalData && issue.externalData.finalVotes.has(criterion.id);
    const value = issue.externalData && issue.externalData.cr_weightless.get(criterion.id);

    if (rewritten) {
        return (
            <RewriteValue
                hideTooltip={hideTooltip}
                value={value}
                criterion={criterion}
                vote={issue.externalData.finalVotes.get(criterion.id)}
            />
        );
    }
    return value ?? null;
});

function AverageScore({ issue, criteria }) {
    return criteria.map((criterion) => (
        <td key={criterion.id} className="t-r t-w-sb">
            <AverageScoreItem criterion={criterion} issue={issue} />
        </td>
    ));
}

export default observer(AverageScore);
