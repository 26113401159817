import { runInAction } from 'mobx';

import api from 'api';

import failRequest from 'utils/failRequest';
import logEvent from 'utils/logEvent';

export async function changeAvatar(formData, successCallback, errorCallback) {
    logEvent('Avatar change');
    try {
        const { data } = await api.post('/users/upload-avatar', formData);
        successCallback && successCallback();

        runInAction(() => {
            this.avatar = data.avatar;
        });
    } catch (e) {
        errorCallback && errorCallback();
        failRequest(e);
    }
}
