/**
 * Check query in text
 *
 * @param {string} [text='']
 * @param {string} [query='']
 */
export default function queryInText(text = '', query = '') {
    const normalizedText = (text ?? '').toString().toLowerCase();
    const normalizedQuery = (query ?? '').toString().toLowerCase();

    return normalizedText.indexOf(normalizedQuery) !== -1;
}
