import { CustomIcon, CustomIconName } from 'components/CustomIcon';

export const TEAM_ICON = <CustomIcon icon={CustomIconName.TEAM} className="o-6" />;
export const MEMBER_ICON = <CustomIcon icon={CustomIconName.MEMBER} className="o-6" />;
export const ROUND_UP = <CustomIcon icon={CustomIconName.ARROW_UP} />;

export const GROUP_ITEM_AVERAGE = 'Average';
export const GROUP_ITEM_AVERAGE_ROUND_UP = 'Average, RoundUp';

export const MAIN_GROUP = [
    {
        name: GROUP_ITEM_AVERAGE,
        key: GROUP_ITEM_AVERAGE,
        description: 'Most Popular',
        shortName: 'Avg.',
        tooltip:
            "Goal: Kickoff prioritization process. The easiest way for collaborative prioritization. Tolerant if your team doesn't evaluate 100% of tasks every sprint.",
    },
    {
        name: GROUP_ITEM_AVERAGE_ROUND_UP,
        key: GROUP_ITEM_AVERAGE_ROUND_UP,
        shortName: 'Avg.R',
        tooltip:
            "Goal: use the Fibonacci scale in Agile estimation. Even after collaborative evaluation, you can get only the number from the sequence you've set for the particular criterion.",
    },
];
