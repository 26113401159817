import { runInAction } from 'mobx';

export const collectionUtils = {
    updateMap(existingMap, newItems, getId = (item) => item.id) {
        runInAction(function updateMap() {
            const newItemIds = new Set(newItems.map(getId));
            // Removing elements that are not in the new collection
            for (const [key, value] of existingMap) {
                if (!newItemIds.has(getId(value))) {
                    existingMap.delete(key);
                }
            }
            // Updating current and adding new elements
            for (const newItem of newItems) {
                const id = getId(newItem);
                if (existingMap.has(id)) {
                    Object.assign(existingMap.get(id), newItem);
                } else {
                    existingMap.set(id, newItem);
                }
            }
        });
    },

    updateArray(existingArray, newItems, getId = (item) => item.id) {
        runInAction(function updateArray() {
            const newItemMap = new Map(newItems.map((item) => [getId(item), item]));
            // Updating current and removing unused elements
            for (let i = existingArray.length - 1; i >= 0; i--) {
                const id = getId(existingArray[i]);
                if (newItemMap.has(id)) {
                    Object.assign(existingArray[i], newItemMap.get(id));
                    newItemMap.delete(id);
                } else {
                    existingArray.splice(i, 1);
                }
            }
            // Adding new elements
            existingArray.push(...newItemMap.values());
        });
    },
};
