import { styled } from 'stitches.config';

export const StAvatarImage = styled('img', {
    height: '100%',
    width: '100%',
    objectFit: 'cover',
    borderRadius: '50%',
    minHeight: '100%',
    minWidth: '100%',
    margin: 0,
    display: 'inline-block',
    overflow: 'hidden',
    position: 'relative',

    '&:after': {
        zIndex: 0,
        content: 'attr(data-initial)',
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'center',
        position: 'absolute',
        textTransform: 'uppercase',
        left: 0,
        top: 0,
        width: '100%',
        height: '100%',
        background: '#dfe1e6',
        borderRadius: '50%',
        color: '#586376',
        fontSize: 'inherit',
        fontWeight: 500,
    },
});
