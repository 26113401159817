/**
 * Group an array of items by a key.
 *
 * @param {Array} arr
 * @param {Function} getKeyFromItem
 * @return {{}}
 */
export function groupBy(arr, getKeyFromItem) {
    if (typeof getKeyFromItem !== 'function') {
        throw new Error('getKeyFromItem must be a function');
    }

    if (!Array.isArray(arr)) {
        return {};
    }

    if (typeof Object.groupBy === 'function') {
        return Object.groupBy(arr, getKeyFromItem);
    }

    const result = {};

    for (const item of arr) {
        const key = getKeyFromItem(item);

        if (result[key] == null) {
            result[key] = [];
        }

        result[key].push(item);
    }

    return result;
}
