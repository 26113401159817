import api from 'api';
import { failRequest, toUrl } from 'utils';

export async function getCannyBoards(token) {
    try {
        const { data } = await api.post(`${this.apiEndpoint}/canny`, toUrl({ token }));
        return data;
    } catch (e) {
        failRequest(e);
    }
}
