export const TAB = 9;
export const DOWN = 40;
export const UP = 38;
export const RIGHT = 39;
export const ENTER = 13;
export const LEFT = 37;
export const ESC = 27;

export const UNSAVED_MODEL_ID = -1;

export const POPOVER_PROPS_DIV = {
    targetTagName: 'div',
    wrapperTagName: 'div',
};

export const POPOVER_AI_PROPS = {
    popoverClassName: 'ai-popover-text',
    canEscapeKeyClose: false,
    hasBackdrop: false,
    inheritDarkTheme: false,
    interactionKind: 'click',
    isControlled: true,
    minimal: true,
    modifiers: {
        arrow: {
            enabled: false,
        },
        flip: {
            enabled: false,
        },
        preventOverflow: {
            enabled: true,
        },
    },
    fill: true,
    position: 'bottom-left',
    usePortal: false,
};

// TODO: remove by https://concertwithme.atlassian.net/browse/DCLS-6215
export const WEEKDAYS_NAMES = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

export const WEEKDAYS_NAMES_ALT = ['', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
export const WEEKDAYS_NAMES_SHORT = ['', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];
export const WEEKDAYS_MENU = [1, 2, 3, 4, 5, 6, 7].map((id) => ({ id, label: WEEKDAYS_NAMES_ALT[id] }));

export const OWNER_REQUESTS = 'owner-requests';
export const AUTHOR_REQUESTS = 'author-requests';
export const ANOTHER_REQUESTS = 'another-requests';

export const IS_APP = !!document.getElementById('root');

export const DICTIONARY_TYPES = 'issue-types';
export const DICTIONARY_STATUSES = 'issue-statuses';
export const DICTIONARY_LABELS = 'issue-labels';
export const DICTIONARY_LABELS_VOTING = 'voting-issue-label';
export const DICTIONARY_STATUSES_VOTING = 'idea-statuses';

export const SHOW_TOOLTIPS = 'showTooltips';
export const FAST_INPUT = 'fastInput';
export const SLIM_BOARD = 'slimBoard';

export const PAYWALL_LIMITS = {
    PUSH_ISSUE: 'push_issue',
    USERS_LIMIT: 'users_limit',
    ISSUES_LIMIT: 'issues_limit',
    VOTING_APPEARANCE: 'voting_appearance',
    VOTING_DOMAIN: 'voting_domain',
    GRID_FILTER: 'grid_filter',
    BOARDS_LIMIT: 'boards_limit',
    MATRIX: 'matrix',
    ALIGNMENT: 'alignment',
    CUSTOMISE_COLUMNS: 'customise_columns',
    VOTES_RESET: 'votes_reset',
    VOTES_RESTORE: 'votes_restore',
    CHANGE_ROLE: 'change_role',
    PRIVATE_BOARDS: 'private_boards',
    PRIVATE_VOTING_BOARD: 'private_voting_board',
    IDEA_TEMPLATE: 'idea_template',
    INTEGRATION_ADMIN: 'integration_admin',
    SYNC_AVAILABLE: 'sync_available',
    REMOVE_DUCALIS_BRANDING: 'remove_ducalis_branding',
    VOTING_ADD_TO_BACKLOG: 'voting_add_to_backlog',
    VOTING_ANALYTICS: 'voting_analytics',
    TWO_WAY_SYNC_REPORTS: 'two_way_sync_reports',
    TWO_WAY_SYNC_BOARDS: 'two_way_sync_boards',
    TWO_WAY_SYNC_CUSTOM_FIELDS: 'two_way_sync_custom_fields',
    BOARDS_REPORTS: 'boards_reports',
    VOTES_TEAM_RESET: 'votes_team_reset',
    SKIP_ISSUE_VOTE: 'skip_issue_vote',
    VOTING_VOTE_BEHALF: 'voting_vote_behalf',
    CUSTOM_VOTE: 'custom_vote',
    EVALUATION_CHANGE: 'evaluation_change',
    RESTRICT_SCORES_RE_EVALUATION: 'restrict_scores_re_evaluation',
    VOTING_VOTES_LABELS: 'voting_votes_labels',
    VOTING_CUSTOM_STATUSES: 'voting_custom_statuses',
    IDEA_STATUS_SYNC: 'idea_status_sync',
    ISSUE_DEPENDENCIES: 'issue_dependencies',
    RELEASE_WEBHOOKS: 'release_webhooks',
    CRITERIA_SCORE_BACKUPS: 'criteria_score_backups',
    TWO_WAY_SYNC_FIELDS_MAP: 'two_way_sync_fields_map',
    VOTES_MANUAL_RESET: 'votes_manual_reset',
    VOTING_GTM: 'voting_gtm',
    CRITERIA_BULK_ACTIONS: 'criteria_bulk_actions',
    EXPORT_ACCESS: 'export_access',
    EXPORT_LOGS: 'export_logs',
    VOTING_EMAIL_DOMAIN: 'voting_email_domain',
};

export const ANOTHER_REASON = 'another-reason';

export const ERROR_TYPES = {
    'too-many-files': 'You can only upload one file',
    'file-invalid-type': 'Only *.jpeg and *.png images will be accepted',
    [ANOTHER_REASON]: 'Uploading error',
};

export const ACCEPT_FILES = {
    'image/png': [],
    'image/jpeg': [],
    'image/svg+xml': [],
    'image/webp': [],
};

export const FILTER_TYPES = {
    STRING: 'string',
    INT: 'int',
    TEXTAREA: 'textarea',
    DATETIME: 'datetime',
    DATE: 'date',
    CHECKBOX: 'checkbox',
    LIST: 'list',
    COMPLEX_SELECT: 'complex_select',
    USER: 'user',
    USER_LIST: 'user_list',
    TYPE_ATTACHMENT_SELECT: 'attachment_select',
};

export const FILTER_RULES = {
    all: 'all',
    any: 'any',
    neither: 'neither',
    notAll: 'notAll',
    before: 'before',
    after: 'after',
    gt: 'gt',
    lt: 'lt',
    gte: 'gte',
    lte: 'lte',
    contains: 'contains',
    notContains: 'notContains',
    is: 'is',
    not: 'not',
    empty: 'empty',
    notEmpty: 'notEmpty',
};

export const defaultPopoverProps = {
    minimal: true,
    boundary: 'window',
    position: 'bottom-left',
};

export const SORT_DIRECTION = { asc: 'ASC', desc: 'DESC' };

export const DEF_MAIN_COLOR = '#f1d17e';
export const DEF_TEXT_COLOR = '#282a2f';

export const FINAL_SCORE_EDIT_VARIANTS = {
    disabled: 'disabled',
    facilitator: 'facilitator',
    all: 'all',
};
export const FINAL_SCORE_EDIT_VARIANTS_DESC = {
    [FINAL_SCORE_EDIT_VARIANTS.disabled]: 'Nobody can change final scores',
    [FINAL_SCORE_EDIT_VARIANTS.facilitator]: 'Facilitators can change final scores',
    [FINAL_SCORE_EDIT_VARIANTS.all]: 'Everybody can change final scores',
};

export const AUTH_CODE_PLACEHOLDER = ['', '', '', '', '', ''];

export const USER_ROLE = {
    Member: 1,
    Viewer: 2,
    Admin: 3,
};

export const ROLES_GROUPS = {
    [USER_ROLE.Viewer]: 'Viewers',
    [USER_ROLE.Member]: 'Members',
    [USER_ROLE.Admin]: 'Admins',
};

export const ROLES = {
    [USER_ROLE.Viewer]: 'Viewer',
    [USER_ROLE.Member]: 'Member',
    [USER_ROLE.Admin]: 'Admin',
};

export const PAYMENT_STATUS = {
    cancelled: 'cancelled',
    stopped: 'stopped',
    trial_ended: 'trial_ended',
    trial: 'trial',
};

export const ANNOUNCE_STATUS = {
    DRAFT: 'draft',
    APPROVED: 'approved',
    AI_IN_PROGRESS: 'ai_in_progress',
    AI_READY: 'ai_ready',
    AI_ERROR: 'ai_error',
};

export const USER_STATUS = {
    pending: -1,
    active: 1,
    removed: 0,
};

export const SHORT_RELATIVE_TIME_OPTIONS = {
    style: 'short',
    unit: ['years', 'months', 'weeks', 'days', 'hours', 'minutes', 'seconds'],
};

export const EXPORT_ACCESS = {
    owner: 'owner',
    admins_members: 'admins_members',
    admins: 'admins',
};

export const MESSAGE_EMPTY = 'Nothing to prioritize! No issues found by the filter.';
export const MESSAGE_DONE =
    'Seems like you are going to import completed or test/review issues that no longer require prioritization.';

export const PLATFORM_FILTER_TYPE = {
    custom: 'custom', // ducalis filter
    platform: 'platform', // filter from tracker
    jql: 'jql', // jira jql filter
};
