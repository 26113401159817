/**
 * Computes the minimum value of array
 *
 * @param {Array} arr
 * @param {Function} iteratee
 * @return {*}
 */
export const minBy = (arr, iteratee) => {
    if (typeof iteratee !== 'function') {
        throw new Error('iteratee must be a function');
    }

    if (!Array.isArray(arr)) {
        return undefined;
    }

    return arr.reduce(
        (result, obj) => {
            const currentVal = iteratee(obj);
            if (currentVal === undefined) return result;

            const [minObj, minVal] = result;
            if (minObj === undefined || currentVal < minVal) {
                return [obj, currentVal];
            }

            return result;
        },
        [undefined, undefined],
    )[0];
};

/**
 * Computes the maximum value of array
 *
 * @param {Array} arr
 * @param {Function} iteratee
 * @return {*}
 */
export const maxBy = (arr, iteratee) => {
    if (typeof iteratee !== 'function') {
        throw new Error('iteratee must be a function');
    }

    if (!Array.isArray(arr)) {
        return undefined;
    }

    return arr.reduce(
        (result, obj) => {
            const currentVal = iteratee(obj);
            if (currentVal === undefined) return result;

            const [minObj, maxVal] = result;
            if (minObj === undefined || currentVal > maxVal) {
                return [obj, currentVal];
            }

            return result;
        },
        [undefined, undefined],
    )[0];
};
