import { storageApi } from 'api';
import { IS_VOTING_BOARD } from 'constants/global';
import { failRequest } from 'utils';

export async function fetchBoard() {
    const paramsField = IS_VOTING_BOARD ? 'public_boards' : 'boards';
    try {
        const { data } = await storageApi.get('/boards', { params: { [paramsField]: this.id } });
        this.fillModel(data[0]);
    } catch (e) {
        failRequest(e);
    }
}
