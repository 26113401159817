/**
 * Simple pluralization text
 *
 * @param {Number} cnt
 * @param {Array} text
 * @param {Boolean} onlyText
 *
 * @returns {string}
 */
export default function getCntText(cnt = 0, text = [], onlyText = false) {
    if (onlyText) return text[+(cnt === 0 || cnt > 1)];
    return `${cnt} ${text[+(cnt === 0 || cnt > 1)]}`;
}
