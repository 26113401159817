export default function getRequestParam(param) {
    try {
        const searchParams = new URLSearchParams(window.location.search);
        return searchParams.get(param);
    } catch (e) {
        return null;
    }
}

export function getBooleanRequestParam(param) {
    const value = parseInt(getRequestParam(param));
    return Boolean(value);
}
