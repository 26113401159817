import React from 'react';

import * as DropdownMenuPrimitive from '@radix-ui/react-dropdown-menu';
import { observer } from 'mobx-react-lite';
import { FormattedMessage } from 'react-intl';

import { EMPTY_ARRAY } from 'constants/atoms';

import ThemeMenuItems from 'modules/ThemeSelector/ThemeMenuItems';

import { mainStore } from 'store/models/MainStore';
import { utilsStore } from 'store/models/UtilsStore';

import { AnchorButton } from 'components/Button';
import { CustomIconName } from 'components/CustomIcon';
import { DropdownMenuItem, DropdownMenuSeparator } from 'components/DropdownMenu';
import SettingsLinkButton from 'components/SettingsLinkButton';

function googleLogout() {
    mainStore.db.dropDB();
    if (
        window.google &&
        window.google.accounts &&
        window.google.accounts.id &&
        typeof window.google.accounts.id.disableAutoSelect === 'function'
    ) {
        window.google.accounts.id.disableAutoSelect();
    }
}

const BUTTONS_PROPS = {
    block: true,
    minimal: true,
    css: { outline: 'none !important' },
};

export const UserMenuPopoverContent = observer(() => {
    const isAdmin = mainStore.currentUser?.isAdmin;

    const orgList = (mainStore.currentUser?.organizations || EMPTY_ARRAY).filter(
        (el) => el.id !== mainStore.organization.id,
    );

    return (
        <>
            {isAdmin && (
                <>
                    <DropdownMenuPrimitive.Item asChild>
                        <SettingsLinkButton
                            link="/organization"
                            icon={CustomIconName.ORGANIZATION}
                            text={mainStore.organization.name}
                            {...BUTTONS_PROPS}
                        />
                    </DropdownMenuPrimitive.Item>
                    <DropdownMenuPrimitive.Item asChild>
                        <SettingsLinkButton
                            text="Integrations"
                            link="/integrations"
                            icon={CustomIconName.INTEGRATION}
                            {...BUTTONS_PROPS}
                        />
                    </DropdownMenuPrimitive.Item>
                </>
            )}
            {!mainStore.currentUser?.isViewer && (
                <DropdownMenuPrimitive.Item asChild>
                    <SettingsLinkButton
                        text="Account users"
                        icon={CustomIconName.VOTING}
                        link="/account-users"
                        {...BUTTONS_PROPS}
                    />
                </DropdownMenuPrimitive.Item>
            )}
            {isAdmin && (
                <DropdownMenuPrimitive.Item asChild>
                    <SettingsLinkButton
                        link="/billing-plan"
                        icon={CustomIconName.CARD}
                        text="Plan & Billing"
                        {...BUTTONS_PROPS}
                    />
                </DropdownMenuPrimitive.Item>
            )}
            {orgList.length > 0 && (
                <>
                    <DropdownMenuSeparator />
                    <div style={{ margin: '0 8px 4px' }}>
                        <small className="o-4">Other Organizations</small>
                    </div>
                </>
            )}
            {orgList.map((el) => (
                <DropdownMenuItem
                    icon={CustomIconName.ORGANIZATION}
                    key={el.id}
                    onClick={() => mainStore.organization.changeOrg(el)}
                    text={el.name}
                />
            ))}

            <DropdownMenuSeparator />

            <div style={{ margin: '0 8px 4px' }}>
                <small className="o-4">Theme</small>
            </div>

            <ThemeMenuItems activeId={utilsStore.settings.theme} onItemSelect={utilsStore.setTheme} />

            <DropdownMenuSeparator />
            <DropdownMenuPrimitive.Item asChild>
                <SettingsLinkButton
                    text={<FormattedMessage id="page.profile" defaultMessage="Profile" />}
                    icon={CustomIconName.PROFILE}
                    link="/profile"
                    {...BUTTONS_PROPS}
                />
            </DropdownMenuPrimitive.Item>
            <DropdownMenuPrimitive.Item asChild>
                <SettingsLinkButton
                    text="Notifications"
                    icon={CustomIconName.NOTIFICATIONS}
                    link="/notifications"
                    {...BUTTONS_PROPS}
                />
            </DropdownMenuPrimitive.Item>
            <DropdownMenuPrimitive.Item asChild>
                <AnchorButton
                    onClick={googleLogout}
                    icon={CustomIconName.LOGOUT}
                    href="/login/logout"
                    text="Logout"
                    {...BUTTONS_PROPS}
                />
            </DropdownMenuPrimitive.Item>
        </>
    );
});
