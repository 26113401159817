import React from 'react';

import { mainStore } from 'store/models/MainStore';

import Tooltip from 'components/Tooltip';

import PaywallPopoverContent from './PaywallPopoverContent';

function UpgradePlanBtn({ paywall, children, ...props }) {
    if (mainStore.organization.hasPaymentPlan(paywall)) return null;

    return (
        <Tooltip
            side="top"
            align="end"
            {...props}
            disableHoverableContent={false}
            content={<PaywallPopoverContent paywall={paywall} />}
        >
            <div>{children}</div>
        </Tooltip>
    );
}

export default UpgradePlanBtn;
