import restApi from 'api';
import { failRequest } from 'utils';

import { settingsStore } from 'modules/SettingsDialog/SettingsStore';

import criteriaStore from 'store/models/CriteriaStore';

export async function addCriterionToBoard(criterionId) {
    try {
        const { data } = await restApi.post(`${this.apiEndpoint}/criteria/${criterionId}`);
        criteriaStore.addBoardCriteria({
            ...data,
            id: `${data.board_id}_${data.criterion_id}_${data.workspace_id}`,
        });
        settingsStore.goToLink(`/board/${this.id}/criteria/${criterionId}`);
    } catch (e) {
        failRequest(e);
    }
}
