import { styled } from 'stitches.config';

export const StHr = styled('hr', {
    margin: '3px 0',
    border: 0,
    height: 1,
    minHeight: 1,
    background: '$grayA4',

    variants: {
        position: {
            vertical: {
                height: 'auto',
                width: 1,
                minWidth: 1,
                margin: '0 3px',
            },
        },
    },
});
