import { styled } from 'stitches.config';

export const StTitleWrapper = styled('div', {
    boxShadow: '0 0 0 1px $colors$grayA4 inset',
    borderRadius: 4,
    padding: '0 8px',

    '&:hover, &:focus-within': {
        backgroundColor: '$blackA4',
        boxShadow: '0 0 0 1px $colors$grayA6 inset',
    },

    '&:focus-within': {
        outline: '1px solid $primary8',
    },

    variants: {
        simple: {
            true: {
                padding: '0 2px',
            },
        },
        error: {
            true: {
                '&, &:hover, &:focus-within': {
                    boxShadow: '0 0 0 1px $colors$tomato8 inset',
                    outline: 'none',
                },
            },
        },
    },
});
export const StTitle = styled('div', {
    padding: '0 !important',

    variants: {
        fill: {
            true: {
                width: '100%',
                flexGrow: 1,
            },
        },
        large: {
            true: {
                fontWeight: 600,
                fontSize: 24,
                lineHeight: '32px',
            },
        },
        middle: {
            true: {
                fontWeight: 700,
                fontSize: 20,
                lineHeight: 1.3,
            },
        },
        simple: {
            true: {
                fontWeight: 600,
                fontSize: 24,
                lineHeight: '32px',
            },
        },
    },
});
