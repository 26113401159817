import api from 'api';
import { REST } from 'constants/ApiPaths';

import { mainStore } from 'store/models/MainStore';

import failRequest from 'utils/failRequest';
import logEvent from 'utils/logEvent';
import toUrl from 'utils/toUrl';

export async function removeAll(isConfirm) {
    if (!isConfirm) {
        logEvent('User get Remove Data');
        return api.get(REST.organization.delete);
    }

    try {
        logEvent('User Send Remove Account');
        await api.delete(REST.organization.delete, { data: toUrl({ approved: 1 }) });
        mainStore.dropApp();
    } catch (e) {
        failRequest(e);
    }
}
