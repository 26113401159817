import React from 'react';

import { observer } from 'mobx-react-lite';
import { Virtuoso } from 'react-virtuoso';

import { PROVIDER_DUCALIS } from 'constants/Providers';

import BoardItem from 'pages/Board/Aside/BoardItem';
import { ReportItem } from 'pages/Board/Aside/ReportItem';
import { SidebarAddBoardButton } from 'pages/Board/Aside/SidebarAddBoardButton';

import { Board } from 'store/models/Board';
import { mainStore } from 'store/models/MainStore';

import ButtonLink from 'components/ButtonLink';
import FeatureRequestButton from 'components/FeatureRequestButton';
import HelpMenu from 'components/HelpMenu';
import Space from 'components/Space';
import UserMenu from 'components/UserMenu';

import styles from './Aside.module.sass';

function AsideBoardsList() {
    const dropIndex = mainStore.activeReports.length;
    return (
        <aside className={`${styles.aside} b-of flex flex-column`} data-place="boards-aside">
            <ButtonLink to="/" icon={PROVIDER_DUCALIS} tooltip="Dashboard" />

            <div className={`${styles.list} flex-grow flex-column`}>
                <Virtuoso
                    className="hide-v-scroll"
                    style={{ height: '100%', width: '100%' }}
                    data={[...mainStore.activeReports, ...mainStore.boardsList.activeBoardsWithAccess]}
                    components={{
                        Footer: () => {
                            return <SidebarAddBoardButton />;
                        },
                        // eslint-disable-next-line no-unused-vars
                        Item: ({ context, item, ...props }) => {
                            return <div {...props} data-report={!item.isBoard} />;
                        },
                    }}
                    itemContent={(index, model) =>
                        model instanceof Board ? (
                            <BoardItem index={index - dropIndex} board={model} />
                        ) : (
                            <ReportItem index={index} report={model} />
                        )
                    }
                />
            </div>

            <Space height={20} />
            <FeatureRequestButton iconOnly />
            <HelpMenu />
            <UserMenu />
        </aside>
    );
}

export default observer(AsideBoardsList);
