import { keyframes, styled } from 'stitches.config';

const spinnerAnimation = keyframes({
    '0%': { transform: 'rotate(0deg)' },
    '100%': { transform: 'rotate(1turn)' },
});
export const StSpinnerAnimation = styled('div', {
    animation: `${spinnerAnimation} .5s linear infinite`,

    variants: {
        disable: {
            true: {
                animation: 'none',
            },
        },
    },
});
export const StSpinner = styled('div', {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    overflow: 'visible',
    verticalAlign: 'middle',

    svg: {
        display: 'block',
        pointerEvents: 'none',
    },

    path: {
        fillOpacity: 0,
    },
});

export const StSpinnerHead = styled('path', {
    stroke: 'currentColor',
    strokeOpacity: 0.7,
    strokeLinecap: 'round',
    transformOrigin: 'center',
    transition: 'stroke-dashoffset .2s cubic-bezier(.4,1,.75,.9)',
});

export const StSpinnerTrack = styled('path', {
    stroke: '$grayA5',
});
