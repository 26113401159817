import { runInAction } from 'mobx';

import api from 'api';

import failRequest from 'utils/failRequest';
import logEvent from 'utils/logEvent';
import toUrl from 'utils/toUrl';

export async function removeSlack({ scenario }) {
    logEvent('DELETE_SLACK', { board: this.id, scenario });
    runInAction(() => {
        if (scenario === 'board') {
            this.slack_data = null;
        } else {
            this.voting_slack_data = null;
        }
    });
    try {
        await api.delete(`${this.apiEndpoint}/slack`, { data: toUrl({ scenario }) });
    } catch (error) {
        failRequest(error);
    }
}
