import { runInAction } from 'mobx';

import api from 'api';

import failRequest from 'utils/failRequest';
import logEvent from 'utils/logEvent';

export async function importFile(file, target) {
    logEvent('Upload CSV - start');

    runInAction(() => {
        this.dialogImport = true;
    });

    const formData = new FormData();
    formData.append('file', file);
    formData.append('target', target);

    try {
        const { data } = await api.post(`${this.apiEndpoint}/import/csv`, formData);
        runInAction(() => {
            this.importData = data;
        });
        logEvent('Upload CSV - complete');
    } catch (error) {
        failRequest(error);
        runInAction(() => {
            this.dialogImport = false;
        });
        logEvent('Upload CSV - fail');
    }
}
