if (!Object.values || !Object.entries) {
    const reduce = Function.bind.call(Function.call, Array.prototype.reduce);
    const isEnumerable = Function.bind.call(Function.call, Object.prototype.propertyIsEnumerable);
    const concat = Function.bind.call(Function.call, Array.prototype.concat);
    const keys = Reflect.ownKeys;

    if (!Object.entries) {
        Object.entries = function entries(O) {
            return reduce(
                keys(O),
                (e, k) => concat(e, typeof k === 'string' && isEnumerable(O, k) ? [[k, O[k]]] : []),
                [],
            );
        };
    }
    if (!Object.values) {
        Object.values = function values(O) {
            return reduce(keys(O), (v, k) => concat(v, typeof k === 'string' && isEnumerable(O, k) ? [O[k]] : []), []);
        };
    }
}

if (!Object.fromEntries) {
    Object.defineProperty(Object, 'fromEntries', {
        value(entries) {
            if (!entries || !entries[Symbol.iterator]) {
                throw new Error('Object.fromEntries() requires a single iterable argument');
            }

            const o = {};

            Object.keys(entries).forEach((key) => {
                const [k, v] = entries[key];

                o[k] = v;
            });

            return o;
        },
    });
}

if (!Array.isArray) {
    Array.isArray = function (arg) {
        return Object.prototype.toString.call(arg) === '[object Array]';
    };
}

if (!Array.prototype.at) {
    // eslint-disable-next-line no-extend-native
    Object.defineProperty(Array.prototype, 'at', {
        configurable: true,
        writable: true,
        value: function (index) {
            if (index >= 0) {
                return this[index];
            } else {
                return this[this.length + index];
            }
        },
    });
}

if (!Array.prototype.flat) {
    // eslint-disable-next-line no-extend-native
    Object.defineProperty(Array.prototype, 'flat', {
        configurable: true,
        writable: true,
        value: function () {
            const depth = typeof arguments[0] === 'undefined' ? 1 : Number(arguments[0]) || 0;
            const result = [];
            const forEach = result.forEach;

            const flatDeep = function (arr, depth) {
                forEach.call(arr, function (val) {
                    if (depth > 0 && Array.isArray(val)) {
                        flatDeep(val, depth - 1);
                    } else {
                        result.push(val);
                    }
                });
            };

            flatDeep(this, depth);
            return result;
        },
    });
}

if (!String.prototype.matchAll) {
    // eslint-disable-next-line no-extend-native
    String.prototype.matchAll = function (rx) {
        if (typeof rx === 'string') rx = new RegExp(rx, 'g'); // coerce a string to be a global regex
        rx = new RegExp(rx); // Clone the regex so we don't update the last index on the regex they pass us
        let cap = []; // the single capture
        let all = []; // all the captures (return this)
        while ((cap = rx.exec(this)) !== null) all.push(cap); // execute and add
        return all; // profit!
    };
}
