import api from 'api';
import { failRequest, logEvent } from 'utils';

export async function remove() {
    try {
        logEvent('User Removed Report', { id: this.id });
        await api.delete(this.apiEndpoint);
    } catch (error) {
        failRequest(error);
    }
}
