import React, { useCallback } from 'react';

import { observer } from 'mobx-react-lite';

import { settingsStore } from 'modules/SettingsDialog/SettingsStore';

import { Button } from 'components/Button';

function SettingsLinkButton({ link, ...props }, forwardedRef) {
    const onClick = useCallback(() => settingsStore.goToLink(link), [link]);

    return (
        <Button
            {...props}
            data-link={`/settings${link}`}
            btnRef={forwardedRef}
            onClick={props.disabled ? undefined : onClick}
        />
    );
}

/**
 * Button for going inside DialogSettings
 *
 * @component
 * @param {ButtonProperty} props - The component props.
 * @param {React.RefObject} props.forwardedRef - A reference to the component instance.
 * @param {string} props.link - The settings link.
 *
 * @returns {React.ReactElement} The rendered component.
 */
export default observer(React.forwardRef(SettingsLinkButton));
