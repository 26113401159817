import PlanCheckoutCalculation from 'components/SubscriptionsPlans/PlanCheckoutCalculation';
import { TooltipProvider } from 'components/Tooltip';

function BillingApp() {
    return (
        <TooltipProvider>
            <PlanCheckoutCalculation quantity={window.paid_users} plan={window.selectedPlan} plans={window.plans} />
        </TooltipProvider>
    );
}

export default BillingApp;
