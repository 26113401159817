import React from 'react';

import { observer } from 'mobx-react-lite';

import { CustomIconName } from 'components/CustomIcon';
import Space from 'components/Space';
import { StripeDotSubtitle } from 'components/Stripe';
import StripeWithSavedState from 'components/StripeWithSavedState/StripeWithSavedState';

import { SubTask } from './SubTask';

function SubTasks({ issue }) {
    const allCount = issue?.subtasks?.length;

    if (!allCount) return null;

    const activeCount = issue.subtasks.filter((item) => !item.completed).length;

    return (
        <StripeWithSavedState
            storeKey="SBTSK"
            icon={CustomIconName.LINK}
            title="Subtasks"
            subTitle={
                <StripeDotSubtitle>
                    {activeCount}/{allCount}
                </StripeDotSubtitle>
            }
        >
            <Space height={6} />
            {issue.subtasks.map((item, index) => (
                <SubTask key={index} subTask={item} />
            ))}
        </StripeWithSavedState>
    );
}

export default observer(SubTasks);
