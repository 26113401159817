import React from 'react';

import { observer } from 'mobx-react-lite';

import { IS_PUBLIC_BOARD } from 'constants/global';
import { VIEWS } from 'constants/Pages';

import { CriterionScore } from 'pages/Board/AlignmentList/components/CriterionScore';

import { mainStore } from 'store/models/MainStore';

import RewrittenCriterionCell from 'components/RewrittenCriterionCell';

const FinalScoreCriterionCell = observer(function FinalScoreCriterionCell({ criterion, votes }) {
    const rewritten = votes.get(criterion.id);

    const disableClick = IS_PUBLIC_BOARD || [VIEWS.EVALUATION, VIEWS.SCORES].includes(mainStore.page);

    if (rewritten?.approved) {
        return (
            <td style={{ padding: 0 }}>
                <RewrittenCriterionCell disableClick={disableClick} rewritten={rewritten} interactive={!disableClick} />
            </td>
        );
    }

    return (
        <td>
            <CriterionScore criterion={criterion} votes={votes} isFinalScore canOpenDialog={!disableClick} />
        </td>
    );
});

function FinalScoreTableSuperScore({ criteria, votes }) {
    return criteria.map((criterion) => (
        <FinalScoreCriterionCell key={criterion.id} criterion={criterion} votes={votes} />
    ));
}

export default observer(FinalScoreTableSuperScore);
