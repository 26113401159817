import restApi from 'api';
import { failRequest } from 'utils';

export async function getColumns() {
    try {
        const { data } = await restApi.get(`${this.apiEndpoint}/columns`);
        const list = this.isBoard
            ? data.filter((field) => !['row_number', 'board_name'].includes(field.field_id))
            : data;
        return list.map((field) => ({ ...field, id: field.field_id }));
    } catch (e) {
        failRequest(e);
    }
}
