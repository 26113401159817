export const Colors = {
    grayA: 'gray',
    tomatoA: 'tomato',
    redA: 'red',
    crimsonA: 'crimson',
    pinkA: 'pink',
    plumA: 'plum',
    purpleA: 'purple',
    violetA: 'violet',
    indigoA: 'indigo',
    blueA: 'blue',
    cyanA: 'cyan',
    tealA: 'teal',
    greenA: 'green',
    grassA: 'grass',
    orangeA: 'orange',
    brownA: 'brown',
    skyA: 'sky',
    mintA: 'mint',
    limeA: 'lime',
    yellowA: 'yellow',
    amberA: 'amber',
};

export const ColorsVariants = Object.values(Colors).filter((el) => el !== 'gray');
