import { DateTime } from 'luxon';
import { makeAutoObservable, observable, runInAction } from 'mobx';

import api, { storageApi } from 'api';
import { REST } from 'constants/ApiPaths';
import { getSocket, getSocketNameSpace } from 'socket/init';

import * as OrganizationApi from 'store/models/api/Organization';
import { educationBanners } from 'store/models/MainStore';
import { mainStore } from 'store/models/MainStore';
import { ORG_DB_STORES } from 'store/updateOrgDB';

import { EXPORT_ACCESS, PAYWALL_LIMITS, USER_ROLE } from 'utils/consts';
import debugLog from 'utils/debugLog';
import delay from 'utils/delay';
import failRequest from 'utils/failRequest';
import getCurrencySymbolByCode from 'utils/getCurrencySymbolByCode';
import logEvent from 'utils/logEvent';

/**
 * @typedef OrgVotingEmailSettings
 * @type {Object}
 * @property {number} limit
 * @property {boolean} sent
 * @property {string} plan_id
 * @property {string} emails_left
 */

export default class Organization {
    id = null;
    name = '';
    timezone = '';
    export_access = null;
    payment_subscription = null;
    last_updated = '';
    _links = null;
    platforms = [];
    inviteUsers = [];
    inviteUsersLoading = false;
    domains = [];
    has_admin_access = false;
    next_email_limit_reset = false;
    insights_enabled = false;
    created = '';
    webhooks = [];
    owner_id = null;

    /** @type {OrgVotingEmailSettings} */
    voting_email_settings = {};

    deal_paid_users = 0;
    deal_paid_viewers = 0;
    deal_next_billing_cycle_date = '';

    has_payment_methods = false;

    openWebHook = null;

    votingEmailStats = false;

    socket;

    constructor(data) {
        makeAutoObservable(this, {
            socket: false,

            payment_subscription: observable.struct,
            _links: observable.struct,
            webhooks: observable.struct,
            domains: observable.struct,
            voting_email_settings: observable.struct,
        });

        data && this.updateModel(data);

        delay(0).then(() => {
            const socketNamespace = getSocketNameSpace();
            this.socket = getSocket(socketNamespace);

            this.socketInit(this.socket);
        });
    }

    socketDrop() {
        this.socket.offAny();
    }

    socketInit(socket) {
        socket.on('connect', () => {
            process.env.REACT_APP_ENV !== 'prod' && debugLog('socket/ORG: connected', { id: socket.id });
        });

        socket.on('disconnect', () => {
            delay(500).then(() => {
                process.env.REACT_APP_ENV !== 'prod' && debugLog('socket/ORG: disconnected');
            });
        });
        socket.on('reconnect_error', () => {
            process.env.REACT_APP_ENV !== 'prod' && debugLog('socket/ORG: attempt to reconnect has failed');
        });

        socket.on('Organization.delete', async () => {
            mainStore.dropApp();
        });
        socket.on('Organization.update', async (data) => {
            this.updateModel(data.item);
            await mainStore.db.updateOrganization(data.item);
        });

        // PLATFORMS
        socket.on('PlatformFactory.create', (data) => {
            this.platformsList.update(data.item);
            this.db.updateRowDB(data.item, ORG_DB_STORES.platforms);
        });
        socket.on('PlatformFactory.update', (data) => {
            this.platformsList.update(data.item);
            this.db.updateCurrentRowById(data.item, ORG_DB_STORES.platforms);
        });
        socket.on('PlatformFactory.delete', (platformId) => {
            this.platformsList.removeSingle(platformId);
            this.db.removeRowDB(platformId, ORG_DB_STORES.platforms);
        });

        // Education Banners
        socket.on('OrganizationBanner.update', (data) => {
            const { user_id, ...banner } = data.item;
            if (!user_id || user_id === mainStore.currentUser?.id) {
                educationBanners.updateActiveBanner(banner);
            }
        });
        socket.on('OrganizationBanner.delete', (data) => {
            const { user_id, ...banner } = data.item;
            if (!user_id || user_id === mainStore.currentUser?.id) {
                educationBanners.removeActiveBanner(banner);
            }
        });
    }

    get canFree() {
        return this.paymentStatus === 'trial';
    }

    get emailPlan() {
        return this.voting_email_settings?.plan_id || null;
    }

    updateModel(fields) {
        Object.assign(this, fields);
    }

    canUserUseCsvExport(user) {
        switch (this.export_access) {
            case EXPORT_ACCESS.owner:
                return user.id === mainStore.organization.owner_id;
            case EXPORT_ACCESS.admins:
                return user.role === USER_ROLE.Admin;
            case EXPORT_ACCESS.admins_members:
                return user.role !== USER_ROLE.Viewer;
            default:
                return false;
        }
    }

    get date_updated() {
        return DateTime.fromISO(this.last_updated).toFormat('ff');
    }

    get paymentStatus() {
        return this.payment_subscription?.status || null;
    }

    get paymentRequired() {
        return ['trial_ended', 'cancelled', 'stopped'].indexOf(this.paymentStatus) !== -1;
    }

    get hideTrialBanner() {
        return this.paymentStatus !== 'trial';
    }

    get payments() {
        return {
            payment_status: this.paymentStatus,
            payment_plan: this.paymentPlanKey,
            payment_required: this.payment_required,
            trial_days_left: this.trialDaysLeft,
            trial_end: this.payment_subscription?.trial_end,
            trial_start: this.payment_subscription?.trial_start,
        };
    }

    get trialDaysLeft() {
        return this.payment_subscription?.trial_days_left || null;
    }

    get paymentPlan() {
        return this.payment_subscription?.plan || null;
    }

    get paymentQuantity() {
        return mainStore.users.activeUsersWithoutViewers?.length || 1;
    }

    get paymentPlanKey() {
        return this.paymentPlan?.key;
    }

    get paymentPlanName() {
        return this.paymentPlan?.name;
    }

    get paymentPlanLimits() {
        return (
            this.paymentPlan?.paywalls.filter((el) => el.value === null || el.value === true).map((el) => el.key) || []
        );
    }

    hasPaymentPlan(limit) {
        if (this.paymentRequired) {
            return false;
        }
        return this.paymentPlanLimits.includes(limit);
    }

    get canVotingDomain() {
        return this.hasPaymentPlan(PAYWALL_LIMITS.VOTING_DOMAIN);
    }

    get canVotingAppearance() {
        return this.hasPaymentPlan(PAYWALL_LIMITS.VOTING_APPEARANCE);
    }

    get canPushToTracker() {
        return this.hasPaymentPlan(PAYWALL_LIMITS.PUSH_ISSUE);
    }

    get needBlockBoard() {
        return this.paymentRequired;
    }

    get needBlockInvite() {
        return this.paymentRequired;
    }

    get currentPlanId() {
        return (this.paymentPlan && this.paymentPlan.id) || null;
    }

    get currency() {
        return getCurrencySymbolByCode(this.paymentPlan?.currency);
    }

    get extraBillingPage() {
        if (!this.payment_subscription) {
            return false;
        }
        return this.has_payment_methods || ['paying', 'stopped'].indexOf(this.paymentStatus) !== -1;
    }

    updateSettings(obj) {
        Object.entries(obj).forEach(([key, value]) => {
            this[key] = value;
        });
        this.pushChangeToServer(obj);
    }

    setName(name, save = false) {
        if (this.name !== name) {
            this.name = name;
        }
        if (save) {
            logEvent('CHANGE_ORG_NAME', { name: this.name });
            this.pushChangeToServer({ name: this.name });
        }
    }

    setTimezone = (timezone) => {
        if (timezone && this.timezone !== timezone) {
            this.timezone = timezone;
            this.pushChangeToServer({ timezone });
        }
    };

    changeAccountAccess() {
        const has_admin_access = !this.has_admin_access;
        this.has_admin_access = has_admin_access;
        logEvent('Change Account Access', { has_admin_access });
        this.pushChangeToServer({ has_admin_access });
    }

    // Api methods list

    fetchOrganization = async () => {
        try {
            const { data } = await storageApi.get(`/organization`);
            this.updateModel(data);
        } catch (e) {
            failRequest(e);
        }
    };

    getVotingEmailsStats = async () => {
        try {
            const { data } = await api.get(REST.organization.votingEmailStats);
            runInAction(() => {
                this.votingEmailStats = data;
            });
        } catch (e) {}
    };

    changeOrg = OrganizationApi.changeOrg;

    pushChangeToServer = OrganizationApi.pushChangeToServer;

    pullInviteUsers = OrganizationApi.pullInviteUsers;

    sendInviteUsers = OrganizationApi.sendInviteUsers;

    removeAll = OrganizationApi.removeAll;

    setWebhook = OrganizationApi.setWebhook;

    getWebhookDate = OrganizationApi.getWebhookDate;

    deleteWebhook = OrganizationApi.deleteWebhook;
}
