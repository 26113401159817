import React from 'react';

import { observer } from 'mobx-react-lite';

import { utilsStore } from 'store/models/UtilsStore';

import { Button } from 'components/Button';
import { CurrentUser } from 'components/CurrentUser';
import { DropdownMenu } from 'components/DropdownMenu';

import { UserMenuPopoverContent } from './UserMenuPopoverContent';

const UserMenu = observer(() => {
    return (
        <DropdownMenu
            content={<UserMenuPopoverContent />}
            side="right"
            align="end"
            onOpenChange={utilsStore.toggleTabNav}
            onCloseAutoFocus={(e) => e.preventDefault()}
        >
            <Button minimal size={34} leftElm={<CurrentUser className="interactive" size={18} />} />
        </DropdownMenu>
    );
});

export default UserMenu;
