import { makeAutoObservable } from 'mobx';

import restApi from 'api';
import { failRequest, toUrl } from 'utils';

import { mainStore } from 'store/models/MainStore';

import delay from 'utils/delay';

export const CSV_EXPORT_TYPES = {
    voting_users_csv: 'voting_users_csv',
    issues_csv: 'issues_csv',
    ideas_csv: 'ideas_csv',
    users_csv: 'users_csv',
};

class CsvExportStore {
    type = ''; // Required. Value: voting_users_csv, issues_csv, ideas_csv, users_csv
    board_id = null;
    report_id = null;

    loading = false;
    isOpen = false;

    url = null;
    status = null;
    created = null;
    history = [];
    rows_count = null;

    getAbortController = null;
    postAbortController = null;

    isEmptyState = false;

    constructor() {
        makeAutoObservable(this, { getAbortController: false, postAbortController: false });
    }

    open = (props) => {
        Object.entries(props).map(([field, value]) => (this[field] = value));
        this.isOpen = true;

        delay(0).then(() => this.getLogs());
    };

    close = () => {
        this.getAbortController?.abort();
        this.postAbortController?.abort();

        this.isOpen = false;
        this.board_id = null;
        this.report_id = null;
        this.loading = false;
        this.type = '';
        this.rows_count = null;
        this.setLogs();

        this.isEmptyState = false;
    };

    setLogs(data) {
        if (!data) {
            this.url = null;
            this.status = null;
            this.created = null;
            this.rows_count = null;
            this.history = [];
            return;
        }
        this.url = data.url;
        this.status = data.status;
        this.created = data.created;
        this.history = data.history;
        this.rows_count = data.rows_count;
        this.isEmptyState = false;
    }

    setLoading(loading) {
        this.loading = loading;
    }

    setEmptyState = () => {
        this.isEmptyState = true;
    };

    getRequestParams() {
        return {
            board_id: this.board_id,
            report_id: this.report_id,
            type: this.type,
        };
    }

    getLogs = async () => {
        if (!this.hasAccess) return;

        if (this.getAbortController) {
            this.getAbortController.abort();
        }
        this.getAbortController = new AbortController();
        try {
            const { data } = await restApi.get('/export/csv', {
                params: this.getRequestParams(),
                signal: this.getAbortController.signal,
            });
            this.setLogs(data);
            !data && this.setEmptyState();
        } catch (e) {
            failRequest(e);
        }
    };

    get title() {
        switch (this.type) {
            case CSV_EXPORT_TYPES.voting_users_csv:
                return 'Voters List';
            case CSV_EXPORT_TYPES.issues_csv:
                return 'Issues List';
            case CSV_EXPORT_TYPES.ideas_csv:
                return 'Ideas List';
            case CSV_EXPORT_TYPES.users_csv:
                return 'Users List';
            default:
                return '';
        }
    }

    runExport = async () => {
        if (this.postAbortController) {
            this.postAbortController.abort();
        }
        this.postAbortController = new AbortController();

        this.setLoading(true);
        try {
            await restApi.post('export/csv', toUrl(this.getRequestParams()), {
                signal: this.postAbortController.signal,
            });
        } catch (e) {
            failRequest(e);
        }
    };

    get hasAccess() {
        return mainStore.organization.canUserUseCsvExport(mainStore.currentUser);
    }

    updateStatus = async (data) => {
        if (!this.isOpen) return;
        if (data.item.type !== this.type) return;
        if (this.board_id && this.board_id !== data.item.board_id) return;
        if (this.report_id && this.report_id !== data.item.report_id) return;

        await this.getLogs();
        this.setLoading(false);
    };
}

export default new CsvExportStore();
