/**
 * Resort array by indexes
 *
 * @param {[]} array
 * @param {number} fromIndex
 * @param {number} toIndex
 * @return {[]}
 */
export default function arrayMoveMutable(array, fromIndex, toIndex) {
    const startIndex = fromIndex < 0 ? array.length + fromIndex : fromIndex;

    if (startIndex >= 0 && startIndex < array.length) {
        const endIndex = toIndex < 0 ? array.length + toIndex : toIndex;

        const [item] = array.splice(fromIndex, 1);
        array.splice(endIndex, 0, item);
    }

    return array;
}
