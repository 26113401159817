import React from 'react';

import { observer } from 'mobx-react-lite';

import { PROVIDER_DUCALIS, PROVIDER_TRELLO, PROVIDER_YT, PROVIDER_YT_CUSTOM } from 'constants/Providers';

import { issuesList } from 'store/models/MainStore';

import { DICTIONARY_STATUSES } from 'utils/consts';

import DictionaryValueItem from 'components/DictionaryValueItem';
import DictionaryValueSelect from 'components/DictionaryValueSelect';
import MetaItem from 'components/MetaItem';

function getStatusTitle(provider) {
    switch (provider) {
        case PROVIDER_TRELLO:
            return 'List';
        case PROVIDER_YT:
        case PROVIDER_YT_CUSTOM:
            return 'State';
        default:
            return 'Status';
    }
}

const Status = () => {
    const issue = issuesList.issue;

    const title = getStatusTitle(issue.platform?.provider);

    if (issue.isEditable) {
        return (
            <MetaItem title={title}>
                <DictionaryValueSelect dictionary={DICTIONARY_STATUSES} issue={issue} />
            </MetaItem>
        );
    }
    const status = issue.statusObj;

    if (status) {
        if (issue.provider === PROVIDER_DUCALIS && typeof status !== 'string') {
            return (
                <MetaItem title={title}>
                    <DictionaryValueItem {...status} tag />
                </MetaItem>
            );
        }
        return (
            <MetaItem title={title} fillFree={status?.length < 13}>
                <DictionaryValueItem tag name={status} />
            </MetaItem>
        );
    }

    return null;
};

export default observer(Status);
