import * as React from 'react';

import { HotkeysContext, HotkeysDialog2 } from '@blueprintjs/core';
import { shallowCompareKeys } from '@blueprintjs/core/lib/esm/common/utils';

const initialHotkeysState = {
    hasProvider: false,
    hotkeys: [],
    isDialogOpen: false,
};

const hotkeysReducer = (state, action) => {
    switch (action.type) {
        case 'ADD_HOTKEYS':
            // only pick up unique hotkeys which haven't been registered already
            const newUniqueHotkeys = [];
            for (const a of action.payload) {
                let isUnique = true;
                for (const b of state.hotkeys) {
                    isUnique &&= !shallowCompareKeys(a, b, {
                        exclude: ['onKeyDown', 'onKeyUp'],
                    });
                }
                if (isUnique) {
                    newUniqueHotkeys.push(a);
                }
            }
            return {
                ...state,
                hotkeys: [...state.hotkeys, ...newUniqueHotkeys],
            };
        case 'REMOVE_HOTKEYS':
            return {
                ...state,
                hotkeys: state.hotkeys.filter((key) => action.payload.indexOf(key) === -1),
            };
        case 'OPEN_DIALOG':
            return { ...state, isDialogOpen: true };
        case 'CLOSE_DIALOG':
            return { ...state, isDialogOpen: false };
        default:
            return state;
    }
};

/**
 * Hotkeys context provider, necessary for the `useHotkeys` hook.
 *
 * @see https://blueprintjs.com/docs/#core/context/hotkeys-provider
 */
export const HotkeysDialogProvider = ({ children, dialogProps, renderDialog, value }) => {
    const hasExistingContext = value != null;
    const [state, dispatch] =
        value ??
        // eslint-disable-next-line react-hooks/rules-of-hooks
        React.useReducer(hotkeysReducer, {
            ...initialHotkeysState,
            hasProvider: true,
        });
    const handleDialogClose = React.useCallback(() => dispatch({ type: 'CLOSE_DIALOG' }), [dispatch]);

    const dialog = renderDialog?.(state, { handleDialogClose }) ?? (
        <HotkeysDialog2
            {...dialogProps}
            isOpen={state.isDialogOpen}
            hotkeys={state.hotkeys}
            onClose={handleDialogClose}
        />
    );

    const reducedState = React.useMemo(() => ({ hasProvider: state.hasProvider }), [state.hasProvider]);

    const ctxValue = React.useMemo(() => [reducedState, dispatch], [reducedState, dispatch]);

    // if we are working with an existing context, we don't need to generate our own dialog
    return (
        <HotkeysContext.Provider value={ctxValue}>
            {children}
            {hasExistingContext ? undefined : dialog}
        </HotkeysContext.Provider>
    );
};
