import api from 'api';

import failRequest from 'utils/failRequest';
import toUrl from 'utils/toUrl';

export async function dropVotes() {
    try {
        await api.delete(`/boards/${this.board.id}/vote`, { data: toUrl({ issue_id: this.id }) });
    } catch (error) {
        failRequest(error);
    }
}
