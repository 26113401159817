import api from 'api';
import { failRequest } from 'utils';

export async function suggestVoters(q, abortController) {
    try {
        const { data } = await api.get(`${this.apiEndpoint}/ideas/voters-suggests`, {
            params: { q },
            signal: abortController.signal,
        });
        return data;
    } catch (e) {
        failRequest(e);
    }
}
