import React from 'react';

import { observer } from 'mobx-react-lite';

import { mainStore } from 'store/models/MainStore';

import UpgradePlanBtn from 'components/UpgradePlanBtn';

export const WithPaywallRule = (paywall, { ComponentUseFull, ComponentBlocked }) => {
    return observer((props) => {
        if (mainStore.organization.hasPaymentPlan(paywall)) {
            return <ComponentUseFull {...props} />;
        }
        return (
            <UpgradePlanBtn paywall={paywall}>
                <ComponentBlocked {...props} />
            </UpgradePlanBtn>
        );
    });
};
