import api from 'api';

import failRequest from 'utils/failRequest';
import toUrl from 'utils/toUrl';

export async function createVotingEmails(email) {
    try {
        const url = Array.isArray(email)
            ? `${this.apiEndpoint}/voting-users/multiple-create`
            : `${this.apiEndpoint}/voting-users`;
        await api.post(url, toUrl({ email }));
    } catch (error) {
        failRequest(error);
        await Promise.reject(error);
    }
}
