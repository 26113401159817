import { observer } from 'mobx-react-lite';
import { IntlProvider } from 'react-intl';

import english from './lang/en.json';

function EnglishWrapper({ children }) {
    return (
        <IntlProvider messages={english} locale="en">
            {children}
        </IntlProvider>
    );
}

export default observer(EnglishWrapper);
