import { runInAction } from 'mobx';

import api from 'api';
import { DISABLE_USERS_MANAGER, IS_PUBLIC_BOARD } from 'constants/global';
import { failRequest } from 'utils';

export async function getInviteSuggestions(abortController) {
    if (IS_PUBLIC_BOARD || DISABLE_USERS_MANAGER) return;

    try {
        const { data } = await api.get(`${this.apiEndpoint}/invite-suggestions`, {
            signal: abortController?.signal,
        });
        runInAction(() => {
            this.inviteSuggestions = data || [];
        });
    } catch (e) {
        if (e?.code === 'ERR_CANCELED') return;
        failRequest(e);
    }
}
