import React, { forwardRef } from 'react';

import classNames from 'classnames';

import { INSTANCE_PREFIX } from 'constants/global';
import { styled } from 'stitches.config';

import { colorsVariant } from 'utils/colorsVariant';

import { CustomIconName } from 'components/CustomIcon/index';

import { renderSvgPaths } from './renderSvgPaths';

import styles from './CustomIcon.module.sass';

const StIcon = styled('span', {
    variants: {
        color: {
            ...colorsVariant.solid,
            accent: {
                color: 'var(--colors-accent-text,  $yellowA11)',
            },
            primary: {
                color: '$primary11',
            },
        },
    },
});

/**
 * @typedef {Object} IconProps
 * @property {string} [id]
 * @property {string} icon
 * @property {number} [size]
 * @property {string} [className]
 * @property {string} [tagName]
 * @property {boolean} [rotate]
 * @property {Object} [style]
 * @property {string} [color]
 * @property {20|24} [viewBox]
 */

/**
 * @param {IconProps} props
 * @param {IconProps.id} props.id
 * @param {IconProps.icon} props.icon
 * @param {IconProps.size} props.size
 * @param {IconProps.className} props.className
 * @param {IconProps.tagName} props.tagName
 * @param {IconProps.rotate} props.rotate
 * @param {IconProps.style} props.style
 * @param {IconProps.color} props.color
 * @param {IconProps.viewBox} props.viewBox
 * @param {RefAttributes} forwardedRef
 * @return {React.JSX.Element|null}
 */
function CustomIcon(
    { id, icon, size = 18, className, tagName = 'span', rotate, style = {}, viewBox = 20, ...props },
    forwardedRef,
) {
    if (typeof icon !== 'string') {
        return null;
    }

    const iconName = INSTANCE_PREFIX && icon === CustomIconName.DUCALIS ? CustomIconName.PRIOPLAN : icon;
    const paths = renderSvgPaths(iconName);
    const classes = classNames(styles.icon, className, `bp5-icon--${iconName}`, {
        [styles.rotate]: rotate,
    });

    const svgViewBox = viewBox === 20 ? '0 0 20 20' : '0 0 24 24';

    return (
        <StIcon ref={forwardedRef} as={tagName} className={classes} icon={iconName} id={id} css={style} {...props}>
            <svg fill="currentColor" data-icon={iconName} width={size} height={size} viewBox={svgViewBox} role="img">
                {paths}
            </svg>
        </StIcon>
    );
}

export default forwardRef(CustomIcon);
