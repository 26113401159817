import api from 'api';
import { failRequest, toUrl } from 'utils';

import delay from 'utils/delay';

export async function syncFieldUpdate(fieldKey, id) {
    try {
        const { data } = await api.put(`${this.apiEndpoint}/sync-field/${fieldKey}`, toUrl({ id }));
        const fields = {
            selectedSyncFields: data.selected_fields,
            defaultSyncFields: data.default_fields,
        };
        delay(0).then(() => this.fillSyncFields(fields));
    } catch (e) {
        failRequest(e);
    }
}
