import React from 'react';

import { ColorsVariants } from 'atoms/Colors';

import { StDot } from './StDot';

export default function Dot({ color = 'gray', className, style = {} }) {
    const themeColor = ColorsVariants.includes(color) ? color : undefined;
    const css = { ...style };
    if (!themeColor) {
        css.color = color;
    }
    return <StDot className={className} color={themeColor} css={css} />;
}
