import { PRIMARY } from 'themes/primaryColors';

export const DEFAULT_THEME = {
    ...PRIMARY,

    bg1: '#2c3741',
    bg2: '#273139',
    bg3: '#1b2329',
    bg4: '#3d464d',
    bg5: 'rgba(255, 255, 255, 0.1)',
    bg6: 'rgba(0,0,0,.3)',
    bg7: '#51584e',
    bg8: 'rgba(255, 255, 255, 0.05)',

    tc1: '#f5f8fa',
    tc2: '#9ca3af',
    tc3: '#282a2f',

    lc1: '#48aff0',
    lc2: '#48aff0',
    lc3: '#48aff0',

    bc1: '#29333d',
    bc2: '#29333d',

    success: '$green9',
    warning: '$orange9',
    danger: '$red9',

    'control-checked-background': '$primary6',

    'stripe-background': '$bg8',
    'stripe-background-open': 'rgba(255, 255, 255, 0.2)',
    'stripe-background-hover': 'rgba(255, 255, 255, 0.1)',
    'stripe-border': '$grayA4',
    'stripe-border-hover': '$grayA6',

    kanbanCardBg: '$bg2',
    kanbanCardBgHover: '$bg3',
    kanbanCardBgActive: '$bg3',

    issueCardBg: '$bg2',

    btnColor: '$grayA11',
    btnColorHover: '$grayA12',
    btnColorActive: '$grayA12',
    btnBg: '$grayA3',
    btnBgHover: '$grayA4',
    btnBgActive: '$grayA5',
    btnBorder: '$grayA3',
    btnBorderHover: '$grayA5',

    'input-background': 'rgba(0,0,0,.3)',
    'input-background-hover': 'rgba(0,0,0,.4)',
    'input-background-focus': 'rgba(0,0,0,.4)',
};
