import React, { useState, useRef, useCallback, useEffect, useMemo } from 'react';

import { Menu, InputGroup, Spinner } from '@blueprintjs/core';
import { observer } from 'mobx-react-lite';

import { UP, DOWN, ENTER } from 'utils/consts';
import getKeyCode from 'utils/getKeyCode';
import queryInText from 'utils/queryInText';

import { Button } from 'components/Button';
import { CustomIconName } from 'components/CustomIcon';
import Flex from 'components/Flex';
import Space from 'components/Space';

import { DictionaryMultiSelectMenuItem } from './DictionaryMultiSelectMenuItem';

export const DictionaryMultiSelectMenu = observer(function ({
    list,
    blockClosed,
    setBlock,
    creatItem,
    saveProps,
    activeItem,
    editable,
    loading,
}) {
    const [query, setQuery] = useState('');
    const [cursor, setCursor] = useState(() => {
        if (activeItem) {
            const index = list.findIndex((el) => el.id === activeItem.id);
            return index >= 0 ? index : 0;
        }
        return 0;
    });
    const inputRef = useRef();
    const menuRef = useRef();

    let items = useCallback(() => {
        if (query) {
            return list.filter((el) => {
                return queryInText(el.name, query.trim());
            });
        }
        return list;
    }, [list, query])();

    useEffect(() => {
        editable && inputRef.current?.focus();
    }, [editable]);

    useEffect(() => {
        function handleKeyDown(e) {
            if (blockClosed) {
                return;
            }
            const keyCode = getKeyCode(e);
            if (keyCode === UP && cursor > 0) {
                setCursor((cursor) => cursor - 1);
            } else if (keyCode === DOWN && cursor < items.length - 1) {
                setCursor((cursor) => cursor + 1);
            } else if (keyCode === ENTER && items.length > 0) {
                saveProps(items[cursor]);
            }
        }

        window.addEventListener('keydown', handleKeyDown, true);
        return () => {
            window.removeEventListener('keydown', handleKeyDown, true);
        };
    }, [cursor, items.length, blockClosed, items, saveProps]);

    function changeFocus(state) {
        setBlock(state);
        if (!state) {
            inputRef.current && inputRef.current.focus();
        }
    }

    function saveCustom(e) {
        const keyCode = getKeyCode(e);
        if (keyCode === ENTER && items.length === 0) {
            creatItem(query);
        }
    }

    const clearBtn = loading ? (
        <Spinner size={16} />
    ) : (
        query && (
            <Flex center style={{ width: 30, height: 30, position: 'absolute', top: 0, right: 0 }} shrink>
                <Button size={24} icon={CustomIconName.CROSS_M} minimal onClick={() => setQuery('')} />
            </Flex>
        )
    );

    const hasQueryItem = useMemo(() => {
        return query ? items.some((el) => String(el.name).toLowerCase() === String(query).toLowerCase().trim()) : true;
    }, [query, items]);

    return (
        <Menu ulRef={menuRef}>
            <li>
                <div data-query={!!query} style={{ padding: 2 }}>
                    <InputGroup
                        autoFocus
                        inputRef={inputRef}
                        onKeyDown={saveCustom}
                        value={query}
                        rightElement={clearBtn}
                        onChange={(event) => setQuery(event.target.value || '')}
                        placeholder="Select an option or create one"
                        style={{ minWidth: 225 }}
                    />
                </div>
                <Space height={4} />
            </li>
            {items.map((el, i) => (
                <DictionaryMultiSelectMenuItem
                    key={el.id}
                    item={el}
                    modifiers={{ active: cursor === i }}
                    setActive={() => setCursor(i)}
                    setBlock={changeFocus}
                    handleClick={saveProps}
                    query={query}
                />
            ))}
            {!hasQueryItem && (
                <li>
                    <Button
                        block
                        minimal
                        icon={CustomIconName.PLUS}
                        text={`Create "${query}"`}
                        onClick={() => creatItem(query)}
                    />
                </li>
            )}
        </Menu>
    );
});
