import React from 'react';

import { observer } from 'mobx-react-lite';

import AsideBoardsList from 'pages/Board/Aside/AsideBoardsList';

function LayoutWithAsideBoardsList({ children }) {
    return (
        <div className="flex b-of flex-grow">
            <AsideBoardsList />
            {children}
        </div>
    );
}

export default observer(LayoutWithAsideBoardsList);
