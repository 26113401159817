import { runInAction } from 'mobx';

import api from 'api';
import { failRequest, toUrl } from 'utils';

export async function removeFacilitator(user_id, criterion_id) {
    !criterion_id &&
        runInAction(() => {
            this.facilitators.delete(user_id);
        });

    try {
        await api.delete(`${this.apiEndpoint}/facilitator/${user_id}`, { data: toUrl({ criterion_id }) });
    } catch (e) {
        failRequest(e);
    }
}
