import { styled } from 'stitches.config';

import * as InputField from 'components/InputField';

const StInput = styled('div', {
    height: '100% !important',
    width: '100% !important',
    minWidth: '0 !important',

    input: {
        fontWeight: 'bold',
        fontSize: '60px !important',
        padding: 0,
        textAlign: 'center',
        appearance: 'none',
        MozAppearance: 'textfield',
        height: '100% !important',
        width: '100% !important',
        minWidth: '0 !important',

        '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
            appearance: 'none',
            MozAppearance: 'textfield',
            margin: 0,
        },
    },
});

/**
 * Component for entering the code
 *
 * @param {function} onPaste
 * @param {number} index
 * @param {number} value
 * @param {function} onChange
 * @param {function} setRef
 * @param {boolean} disabled
 * @returns {JSX.Element}
 */
export default function CodeInput({ onPaste, index, value, onChange, setRef, disabled }) {
    return (
        <InputField.Root fill as={StInput}>
            <InputField.Input
                ref={setRef}
                autoFocus={index === 0}
                data-place={`auth-code-${index}`}
                onChange={(e) => onChange(e, index)}
                onPaste={onPaste}
                type="number"
                tabIndex={index + 1}
                value={value}
                disabled={disabled}
            />
        </InputField.Root>
    );
}
