import api from 'api';

import failRequest from 'utils/failRequest';
import logEvent from 'utils/logEvent';

export async function remove() {
    logEvent('REMOVE_BOARD', { board: this.id });
    try {
        await api.delete(this.apiEndpoint);
    } catch (error) {
        failRequest(error);
    }
}
