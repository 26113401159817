import { runInAction } from 'mobx';

import api from 'api';

import failRequest from 'utils/failRequest';

export async function getTokens() {
    try {
        const { data } = await api.get('/users/tokens');
        runInAction(() => {
            this.token = data;
        });
    } catch (e) {
        failRequest(e);
    }
}
