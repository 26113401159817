import Axios from 'axios';
import { runInAction } from 'mobx';

import api from 'api';
import { toUrl } from 'utils';

let cancelValidationRequest;

export async function validate(validateCallback, ids) {
    cancelValidationRequest && cancelValidationRequest();
    const CancelToken = Axios.CancelToken;
    const { name, emoji, show_only_top, boardIds } = this;
    const fn = this.id !== -1 ? api.put : api.post;
    runInAction(() => (this.loader = true));
    try {
        await fn(this.apiEndpoint, toUrl({ name, emoji, board_ids: ids || boardIds, show_only_top }), {
            cancelToken: new CancelToken(function executor(c) {
                cancelValidationRequest = c;
            }),
            headers: { Validation: 1 },
        });
        validateCallback(null);
    } catch (error) {
        if (error.response?.status === 422) {
            const message = error.response.data.map((el) => el.message).join(' ');
            validateCallback(message);
        }
    } finally {
        runInAction(() => (this.loader = false));
    }
}
