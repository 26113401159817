import React from 'react';

import { USER_STATUS } from 'utils/consts';

export function TooltipContent({ user }) {
    return (
        <>
            <div>{user.name || user.email}</div>
            {user.status === USER_STATUS.pending && <div>Invited: {user.invitedDate}</div>}
        </>
    );
}
