export function findIndexFrom(array, predicate, fromIndex = 0) {
    const index = array.slice(fromIndex).findIndex(predicate);
    return index === -1 ? -1 : index + fromIndex;
}

export function findLastIndexFrom(array, predicate, fromIndex = array.length - 1) {
    if (fromIndex >= array.length) fromIndex = array.length - 1;

    for (let i = fromIndex; i >= 0; i--) {
        if (predicate(array[i], i, array)) {
            return i;
        }
    }
    return -1;
}
