import { autorun, makeAutoObservable, runInAction } from 'mobx';

import { rootApi } from 'api';

import { mainStore } from 'store/models/MainStore';

import failRequest from 'utils/failRequest';
import logEvent from 'utils/logEvent';

const YEAR_MONTHS = 12;

class PaymentPlans {
    plans = [];
    paywallGroups = [];
    active = undefined;
    interval = 'annual';
    people = 0;
    emailPackagePlans = [];
    emailPackage = null;
    descriptions = [];
    total = 0;

    downgradeTypeform = false;
    loading = false;

    constructor(data) {
        if (data) {
            Object.entries(data).forEach(([field, value]) => {
                value && (this[field] = value);
            });
        }
        makeAutoObservable(this);
    }

    getRequiredPlan(plan, required) {
        if (plan.interval !== 'annual') return false;

        return plan.paywalls.some(
            (paywall) => (paywall.value === true || paywall.value === null) && paywall.key === required,
        );
    }

    getAnnualPlan(plan) {
        return plan.interval === 'annual';
    }

    get subtotal() {
        if (paymentPlans.active.id === 1) return null;
        if (this.monthPlan.billing_scheme === 'tiered') {
            return this.people > this.monthPlan.tiers[0].up_to
                ? YEAR_MONTHS * this.people * this.monthPlan.tiers[1].unit_amount
                : YEAR_MONTHS * this.monthPlan.tiers[0].flat_amount;
        }

        return YEAR_MONTHS * this.people * this.monthPlan.amount;
    }

    get discount() {
        if (this.active?.id === 1) return null;

        if (this.monthPlan.billing_scheme === 'tiered') {
            if (this.people > this.monthPlan.tiers[0].up_to) {
                return (
                    this.people * this.monthPlan.tiers[1].unit_amount * YEAR_MONTHS -
                    this.people * this.annualPlan.tiers[1].unit_amount
                );
            }
            return this.monthPlan.tiers[0].flat_amount * YEAR_MONTHS - this.annualPlan.tiers[0].flat_amount;
        }

        return this.people * this.monthPlan.amount * YEAR_MONTHS - this.people * this.annualPlan.amount;
    }

    getMonthPrice(plan) {
        const currentIntervalPlan = this.getPlanByType(plan) || plan;

        if (currentIntervalPlan.billing_scheme === 'tiered') {
            const price = currentIntervalPlan.tiers.find((el) => el.unit_amount).unit_amount;
            return currentIntervalPlan.interval === 'annual' ? price / YEAR_MONTHS : price;
        }

        return currentIntervalPlan.monthly_amount;
    }

    fillData(data, required, queryEmailPlanId) {
        const plans = (this.plans = data.main.plans);
        this.paywallGroups = data.main.paywall_groups;
        this.emailPackagePlans = data.email_package.plans
            .filter((el) => el.package)
            .sort((a, b) => a.amount - b.amount);

        if (required) {
            this.active = plans.find((plan) => this.getRequiredPlan(plan, required)) || plans.find(this.getAnnualPlan);
        } else if (mainStore.organization.paymentPlan.is_custom) {
            this.active = mainStore.organization.paymentPlan;
            this.interval = mainStore.organization.paymentPlan.interval;
        } else {
            const currentPlan =
                plans.find((plan) => plan.id === mainStore.organization.currentPlanId) ||
                plans.find(this.getAnnualPlan);
            if (currentPlan) {
                this.active = currentPlan;
                this.interval = currentPlan.interval;
            }
        }

        if (queryEmailPlanId) {
            this.emailPackage = this.emailsPackages.find((el) => el.id === queryEmailPlanId);
        }
    }

    get hasUpgradePlan() {
        return this.activePlan ? mainStore.organization.currentPlanId !== this.activePlan?.id : false;
    }

    get hasUpgradeEmailPackage() {
        return this.emailPackage ? this.emailPackage.id !== this.currentEmailPlanId : false;
    }

    get plansList() {
        return this.plans.filter((plan) => plan.interval === 'monthly' || plan.id === 1);
    }

    get currentPlan() {
        if (mainStore.organization.paymentPlan?.is_custom) return mainStore.organization.paymentPlan;

        return this.plans.find((plan) => plan.id === mainStore.organization.currentPlanId);
    }

    get freeEmailPackage() {
        const freeEmails = mainStore.workspace.public_voting_settings.emails_free_limit;

        return { id: 'free', amount: 0, package: freeEmails, currency: mainStore.organization.paymentPlan?.currency };
    }

    get emailsPackages() {
        return [this.freeEmailPackage, ...this.emailPackagePlans];
    }

    get currentEmailPlanId() {
        return mainStore.organization.emailPlan || 'free';
    }

    get currentEmailPackage() {
        return this.emailsPackages.find((el) => el.id === this.currentEmailPlanId);
    }

    get monthPlan() {
        if (!this.active) return null;
        if (this.active?.id === 1) return this.active;
        return this.plans.find((el) => el.name === this.active.name && el.interval === 'monthly');
    }

    get annualPlan() {
        if (this.active?.id === 1) return this.active;
        return this.active
            ? this.plans.find((el) => el.name === this.active.name && el.interval === 'annual')
            : undefined;
    }

    getPlanByType(plan) {
        if (plan.key === 'free' || this.interval === plan.interval) return plan;
        return this.plans.find((el) => el.name === plan.name && el.interval === this.interval) || null;
    }

    setType = (type) => {
        this.interval = type;
    };

    toggleType = () => {
        this.interval = this.interval === 'annual' ? 'monthly' : 'annual';
    };

    setActive(plan) {
        this.active = plan;
    }

    setEmailActivePackage(emailPackage) {
        this.emailPackage = emailPackage;
    }

    setDowngradeTypeform(downgradeTypeform = false) {
        this.downgradeTypeform = downgradeTypeform;
    }

    setLoading(loading = false) {
        this.loading = loading;
    }

    get activePlan() {
        return this.interval === 'annual' ? this.annualPlan : this.monthPlan;
    }

    setPeople(people) {
        this.people = people;
    }

    selectPlan(plan) {
        logEvent('Select plan', {
            id: plan.id,
            key: plan.key,
            name: plan.name,
            currentPlanId: mainStore.organization.currentPlanId,
        });
        if (plan.id === 1) return this.setActive(plan);

        const newPlan = this.plans.find((el) => el.name === plan.name && el.interval === this.interval);
        this.setActive(newPlan);
    }

    get planTotal() {
        if (this.active?.id === 1) return 0;
        if (this.activePlan && this.activePlan.billing_scheme === 'tiered') {
            return this.people > this.activePlan.tiers[0].up_to
                ? this.people * this.activePlan.tiers[1].unit_amount
                : this.activePlan.tiers[0].flat_amount;
        }
        return this.people * this.activePlan.amount;
    }

    get discountPercent() {
        if (this.active?.id === 1) return 0;
        return Math.floor((this.discount * 100) / this.subtotal);
    }

    get hasChange() {
        return this.hasUpgradePlan || this.hasUpgradeEmailPackage;
    }

    isTypeForm(plan) {
        if (this.hasUpgradeEmailPackage && this.emailPackage?.id !== 'free') return false;

        if (plan.key === 'free') return true;

        const isLowLevel =
            plan.paywalls.filter((el) => el.value === false).length >
            (this.currentPlan?.paywalls || []).filter((el) => el.value === false).length;

        if (isLowLevel) {
            return plan.name === this.currentPlan?.name ? plan.interval === 'monthly' : true;
        }

        return false;
    }

    async fetchData(required, queryEmailPlanId) {
        if (this.plans.length) return;

        try {
            const { data } = await rootApi.get('/billing/plans');
            this.fillData(data, required, queryEmailPlanId);
        } catch (e) {
            failRequest(e);
        }
    }

    async getCalcDescription({ plan_id, quantity, package_plan_id }) {
        if (!plan_id || !quantity) return;

        if (process.env.NODE_ENV !== 'production') return;

        try {
            const { data } = await rootApi.get('/billing/switch-preview', {
                params: { plan_id, quantity, package_plan_id },
            });
            runInAction(() => {
                this.descriptions = data.lines;
                this.total = data.total;
            });
        } catch (e) {
            failRequest(e);
        }
    }
}

let serverData;
if (window.plans && window.selectedPlan) {
    serverData = {
        plans: window.plans.main?.plans,
        active: window.selectedPlan,
        interval: window.selectedPlan.interval,
        people: window.paid_users && Number(window.paid_users),
        emailPackagePlans: window.plans.email_package?.plans.filter((el) => el.package),
        emailPackage: window.selectedPackagePlan,
    };
}

const paymentPlans = new PaymentPlans(serverData);

autorun(() => {
    if ((!paymentPlans.currentPlan && !paymentPlans.activePlan) || paymentPlans.currentPlan?.is_custom) return;

    const requestData = {
        plan_id: paymentPlans.activePlan?.id || paymentPlans.currentPlan?.id,
        quantity: paymentPlans.people,
        package_plan_id: paymentPlans.emailPackage?.id || paymentPlans.currentEmailPlanId,
    };
    paymentPlans.getCalcDescription(requestData);
});

export default paymentPlans;
