import api from 'api';
import { failRequest, toUrl } from 'utils';

export async function setIdeaStatusTriggers({ platformId, issueStatusName, ideaStatusId, webhookId }) {
    try {
        const requestData = {
            platform_id: platformId,
            issue_status: issueStatusName,
            idea_status_id: ideaStatusId,
            organization_webhook_id: webhookId,
        };
        await api.post(`${this.apiEndpoint}/idea-status-triggers`, toUrl(requestData));
    } catch (e) {
        failRequest(e);
    }
}
