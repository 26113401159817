import { runInAction } from 'mobx';

import api from 'api';
import { failRequest } from 'utils';

import { mainStore } from 'store/models/MainStore';

/**
 * Get issues/ideas ids from server for current filter
 *
 * @return {Promise<void>}
 */
export async function getIds() {
    if (!this.hasCompanyFields) return;

    const requestData = this.companyFilter;
    if (!requestData) return;

    try {
        if (this.loader === null) {
            runInAction(() => {
                this.loader = true;
            });
        }
        const { data } = await api.post(
            `${mainStore.activeModel.apiEndpoint}/search/search-company-ideas`,
            requestData
        );
        runInAction(() => {
            this.ids = data;
        });
    } catch (e) {
        failRequest(e);
    } finally {
        if (this.loader === true) {
            runInAction(() => {
                this.loader = false;
            });
        }
    }
}
