import alertStore from 'store/models/Alert';

import { Button } from 'components/Button';
import { CustomIconName } from 'components/CustomIcon';
import Tooltip from 'components/Tooltip';

export default function DropVotesButton({ onClick, disabled }) {
    function handleClick() {
        alertStore.confirm({
            message:
                "All the users' scores assigned to this issue will be erased, and it will be moved to the <b>Evaluation</b> page.<br /><br />Are you sure you want to reset the scores?",
            onConfirm: onClick,
            confirmButtonText: 'Reset scores',
        });
    }

    return (
        <Tooltip side="top" content="All users have to evaluate the issue anew">
            <Button
                icon={CustomIconName.RESET}
                disabled={disabled}
                size={24}
                text="Reset All scores"
                onClick={handleClick}
                border
            />
        </Tooltip>
    );
}
