import * as React from 'react';

import { Button } from 'components/Button';
import { CustomIcon, CustomIconName } from 'components/CustomIcon';
import Flex from 'components/Flex';

import { fakeBtnLinkHandler } from './fakeBtnLinkHandler';
import { Toast, ToastClose, ToastDescription, ToastProvider, ToastTitle, ToastViewport } from './Toast';
import { useToast } from './useToast';

const Description = ({ description }) => {
    if (!description) return null;
    if (typeof description === 'string')
        return <ToastDescription onClick={fakeBtnLinkHandler} dangerouslySetInnerHTML={{ __html: description }} />;

    return <ToastDescription>{description}</ToastDescription>;
};

const ToastItem = ({
    duration = 5000,
    color = 'gray',
    icon = null,
    title,
    description,
    action,
    // eslint-disable-next-line no-unused-vars
    onDismiss,
    ...props
}) => {
    const hasCancel = !duration;
    const twoLines = !!title && !!description;
    const center = !twoLines && !hasCancel;

    return (
        <Toast color={color} center={center} duration={duration} {...props}>
            {icon && <CustomIcon className="o-6" icon={icon} />}

            <Flex grow={!center} column align={!center ? 'none' : undefined} gap={2}>
                {title && <ToastTitle>{title}</ToastTitle>}
                <Description description={description} />
            </Flex>

            {action}

            {!action && hasCancel && (
                <ToastClose asChild css={{ marginTop: 0, marginBottom: 'auto' }}>
                    <Button minimal size={24} color={color} border icon={CustomIconName.X_CROSS} />
                </ToastClose>
            )}
        </Toast>
    );
};

export default function Toaster() {
    const { toasts } = useToast();

    return (
        <ToastProvider duration={0}>
            {toasts.map((props) => (
                <ToastItem key={props.id} {...props} />
            ))}
            <ToastViewport />
        </ToastProvider>
    );
}
