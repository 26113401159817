import { keyframes, styled } from 'stitches.config';

const pulse = keyframes({
    '0%': { opacity: 1 },
    '50%': { opacity: 0.3 },
    '100%': { opacity: 0.9 },
});

export const StAvatar = styled('div', {
    display: 'flex',
    flexShrink: 0,
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
    background: '$tc2',
    borderRadius: '50%',

    '[icon]': {
        position: 'absolute',
        zIndex: 1,
        color: '$primary11',
        border: '1px solid $bg2',
        right: '-4px',
        bottom: '-2px',
        background: '$bg2',
        borderRadius: '50%',
        transform: 'translate(0,-1px) scale(1.1)',
    },

    variants: {
        admin: {
            true: {
                '&:before': {
                    borderRadius: '50%',
                    overflow: 'hidden',
                    content: '',
                    position: 'absolute',
                    zIndex: 1,
                    top: 0,
                    bottom: 0,
                    left: 0,
                    right: 0,
                    background: '$red8',
                    animation: `${pulse} 1.5s infinite`,
                },
            },
        },
    },
});
