import api from 'api';

import failRequest from 'utils/failRequest';
import toUrl from 'utils/toUrl';

export async function setProps(objField) {
    this.updatePropFields(objField);
    try {
        await api.put(this.apiEndpoint, toUrl(objField));
    } catch (e) {
        failRequest(e);
    }
}
