import { storageApi } from 'api';
import { failRequest } from 'utils';

export async function getReleaseNotes() {
    try {
        const { data } = await storageApi.get(`/release-notes`, { params: { publicId: this.public_id } });
        return data;
    } catch (e) {
        failRequest(e);
        return [];
    }
}
