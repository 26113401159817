import { makeAutoObservable, runInAction } from 'mobx';

import api from 'api';

import logEvent from 'utils/logEvent';
import toUrl from 'utils/toUrl';

import { mainStore } from './MainStore';

export class UnvotedUsers {
    list = [];
    boardId = null;

    constructor(boardId) {
        this.boardId = boardId;

        makeAutoObservable(this);
    }

    get count() {
        return this.list.reduce((res, el) => res + el.count, 0);
    }

    pingAll(params) {
        const ids = this.list.filter((el) => el.counts && el.counts.sum > 0 && el.has_subscription).map((el) => el.id);

        this.ping({ ...params, ids });
    }

    get users() {
        if (this.list.length === 0) return [];

        const users = mainStore.users.users;
        return this.list.map((unUser) => {
            const user = users.find((u) => unUser.id === u.id);
            return { ...unUser, ...user };
        });
    }

    get votedUsers() {
        return this.list.filter((user) => !user.counts || user.counts.unvoted === 0);
    }

    get unVotedUsers() {
        return this.list
            .filter((user) => user.counts?.unvoted > 0)
            .sort((a, b) => (b.counts?.unvoted ?? 0) - (a.counts?.unvoted ?? 0));
    }

    async fetch() {
        try {
            const { data = [] } = await api.get(`/boards/${this.boardId}/users/vote-report`);
            runInAction(() => {
                this.list = data;
            });
        } catch (e) {
            console.error('UnvotedUsers > fetch', e);
        }
    }

    async ping({ ids = [], onDone, onFail }) {
        logEvent('PING_UNVOTED_USERS');
        try {
            await api.post(`/boards/${this.boardId}/users/ping-unvoted`, toUrl({ ids }));
            onDone();
        } catch (e) {
            console.error('UnvotedUsers > ping', e);
            onFail();
        }
    }
}
