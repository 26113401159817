import { runInAction } from 'mobx';

import api from 'api';

import failRequest from 'utils/failRequest';
import logEvent from 'utils/logEvent';
import toUrl from 'utils/toUrl';

export async function setReleaseNoteSettings(settings) {
    const requestData = toUrl(settings);
    // default_digest_intro
    runInAction(() => {
        this.release_note_settings = { ...this.release_note_settings, ...settings };
    });
    logEvent('Board change Release Note', { ...settings });

    try {
        const { data } = await api.put(`${this.apiEndpoint}/release-note-settings`, requestData);
        this.updateReleaseNoteSettings(data.release_note_settings);
    } catch (error) {
        failRequest(error);
    }
}
