import React from 'react';

import { observer } from 'mobx-react-lite';

import { mainStore } from 'store/models/MainStore';

import AddBoardButton from 'components/AddBoardButton/AddBoardButton';

import styles from './Aside.module.sass';

export const SidebarAddBoardButton = observer(() => {
    if (!mainStore.currentUser?.isAdmin) return null;

    return <AddBoardButton isSidebar className={styles.mainLink} />;
});
