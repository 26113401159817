import { computed, makeAutoObservable } from 'mobx';

import getBoardTaskParams from 'utils/getBoardTaskParams';
import logEvent from 'utils/logEvent';
import runTaskWorker from 'utils/runTaskWorker';
import { sendToSentry } from 'utils/sentry';

export class PlatformSync {
    model = null;
    id = null;
    loading = false;
    syncing = false;
    blocking = false;

    constructor(id, model = 'board') {
        makeAutoObservable(this, { disableAction: computed });

        this.id = id;
        this.model = model;
    }

    get disableAction() {
        return this.loading || this.blocking || this.syncing;
    }

    syncLoadingClear = () => {
        this.loading = false;
        this.syncing = false;
        this.blocking = false;
    };

    setSyncing(syncing) {
        this.syncing = syncing;
    }

    setLoading(loading) {
        this.loading = loading;
    }

    changeSyncFieldType(type, onFail) {
        logEvent('Change Sync Field Type - run Task', { type });

        this.loading = true;
        this.blocking = true;

        const task = new runTaskWorker({
            data: {
                method: 'changeSyncFieldType',
                params: { model: this.model, id: this.id, type },
            },
            onComplete: this.syncLoadingClear,
            onFail: () => {
                onFail();
                this.syncLoadingClear();
            },
        });
        if (task && typeof task.run === 'function') {
            task.run();
        } else {
            sendToSentry('Fail Task Generator', { task });
        }
    }

    runSync({ withSync = true }) {
        this.loading = false;
        this.syncing = true;

        const data = getBoardTaskParams(this.id, withSync, this.model);
        const task = new runTaskWorker({
            data,
            onComplete: this.syncLoadingClear,
            onFail: this.syncLoadingClear,
        });
        if (task && typeof task.run === 'function') {
            task.run();
        } else {
            sendToSentry('Fail Task Generator', { task });
        }
    }

    setBlocking(blocking) {
        this.blocking = blocking;
    }

    async changeSync(sync) {
        logEvent('CHANGE_SYNC', { sync, model: this.model });

        this.setLoading(true);

        const task = new runTaskWorker({
            data: {
                method: 'toggleSync',
                params: { model: this.model, id: this.id, enabled: sync },
            },
            onComplete: () => {
                sync ? this.runSync({ withSync: true }) : this.syncLoadingClear();
            },
            onFail: () => {
                this.syncLoadingClear();
            },
        });
        if (task && typeof task.run === 'function') {
            task.run();
        } else {
            sendToSentry('Fail Task Generator', { task });
        }
    }
}
