import React from 'react';

import { observer } from 'mobx-react-lite';

import Avatar from 'components/Avatar';
import Flex from 'components/Flex';

function UnevaluatedUsersTooltipContent({ issue }) {
    const users = issue.getUnevaluatedUsers();

    return (
        <Flex column align="none" gap={10}>
            <div className="t-mutted">Board members with unevaluated criteria</div>
            {users.map((user) => (
                <Flex key={user.id} gap={6}>
                    <Avatar user={user} size={20} /> {user.name}
                </Flex>
            ))}
        </Flex>
    );
}

export default observer(UnevaluatedUsersTooltipContent);
