import React, { useCallback, useState } from 'react';

import { MenuItem } from '@blueprintjs/core';
import { getCreateNewItem, Select } from '@blueprintjs/select';
import { observer } from 'mobx-react-lite';

import useUndoNotification from 'hooks/useUndoNotification';

import dictionaryStore from 'store/models/DictionaryStore';
import { mainStore } from 'store/models/MainStore';

import {
    DICTIONARY_LABELS_VOTING,
    DICTIONARY_STATUSES,
    DICTIONARY_STATUSES_VOTING,
    PAYWALL_LIMITS,
} from 'utils/consts';
import highlightText from 'utils/highlightText';
import logEvent from 'utils/logEvent';
import queryInText from 'utils/queryInText';

import { Button } from 'components/Button';
import CellWrapper from 'components/CellWrapper';
import { CustomIcon, CustomIconName } from 'components/CustomIcon';
import Dot from 'components/Dot';
import { SystemStatusIcon } from 'components/SystemStatusIcon/SystemStatusIcon';

import { SELECTOR_POPOVER_PROPS } from './constants';
import { CreateNewItemBlocked, CreateNewItemUseFull } from './CreateNewItem';
import DictionaryColorsMenu from './DictionaryColorsMenu';
import DictionaryContext from './DictionaryContext';

import DictionaryValueItem from '../DictionaryValueItem';

const createNewItemFromQuery = (query) => ({ id: -1, name: String(query).trim() });

function DictionaryValueSelect({ issue, dictionary, simple }) {
    const [open, setOpen] = useState(false);
    const active = issue.getFieldValueByDictionary(dictionary);
    // TODO: Если проблема останется - https://concertwithme.atlassian.net/browse/DCLS-4857
    // const [loading, setLoading] = useState(false);

    const [activeItem, setActiveItem] = useState(active);
    const [query, setQuery] = useState('');
    const [block, setBlock] = useState(false);
    const notify = useUndoNotification();

    const isIdeaStatus = dictionary === DICTIONARY_STATUSES_VOTING;

    const disableCreateNew =
        !mainStore.organization.hasPaymentPlan(PAYWALL_LIMITS.VOTING_CUSTOM_STATUSES) &&
        dictionary === DICTIONARY_STATUSES_VOTING;

    let btnProps;
    if (active) {
        btnProps = {
            text: active.name,
            leftElm: isIdeaStatus ? undefined : <Dot color={active.color} style={{ marginRight: 4 }} />,
        };
    } else {
        btnProps = {
            text: <em className="o-4">Choose status...</em>,
        };
    }

    const btn = simple ? (
        <CellWrapper editable isButton tabIndex={0}>
            <span className="bp5-text-overflow-ellipsis">{btnProps.text}</span>
            {/* TODO: Если проблема останется - https://concertwithme.atlassian.net/browse/DCLS-4857 */}
            {/*<span className="bp5-text-overflow-ellipsis">{loading ? <Spinner size={14} /> : btnProps.text}</span>*/}
        </CellWrapper>
    ) : (
        <Button
            // TODO: Если проблема останется - https://concertwithme.atlassian.net/browse/DCLS-4857
            // loading={loading}
            size={24}
            border
            active={open}
            {...btnProps}
            block
            rightElm={<CustomIcon icon={CustomIconName.CHEVRON_DOWN} size={14} />}
            data-place={dictionary}
        />
    );

    const list = issue.getDictByName(dictionary);

    async function saveProps(value) {
        if (!value || block) {
            return false;
        }
        setQuery('');
        if (value.id === -1) {
            if (disableCreateNew) return false;
            // TODO: Если проблема останется - https://concertwithme.atlassian.net/browse/DCLS-4857
            // setLoading(true);
            logEvent(`Create ${dictionary} item`);
            let item;
            if ([DICTIONARY_STATUSES_VOTING, DICTIONARY_LABELS_VOTING].includes(dictionary)) {
                item = await issue.board.createDictItem({ issue, dictionary, name: value.name });
            } else {
                item = await dictionaryStore.createDictItem({ issue, dictionary, name: value.name });
            }
            setActiveItem(item);
            // TODO: Если проблема останется - https://concertwithme.atlassian.net/browse/DCLS-4857
            // setLoading(false);
        } else {
            logEvent(`Add ${dictionary} item to issue`);
            const prevValue = { ...active };

            await issue.setProps({ dictionary, value });

            if (dictionary === DICTIONARY_STATUSES && value.id === dictionaryStore.doneStatusId) {
                notify('Issue moved to Done', () => issue.setProps({ dictionary, value: prevValue }));
            }
        }
    }

    let items = useCallback(() => {
        if (query) {
            return list.filter((el) => {
                return queryInText(el.name, String(query).trim());
            });
        }
        return list;
    }, [list, query])();

    function itemRenderer(item, { query, modifiers, handleClick }) {
        return (
            <MenuItem
                style={{ height: 28, paddingRight: 2 }}
                intent=""
                tagName="button"
                icon={isIdeaStatus && item.is_system ? <SystemStatusIcon item={item} /> : undefined}
                className="flex flex-a-center"
                onClick={block ? undefined : handleClick}
                shouldDismissPopover={!block}
                key={item.id}
                active={modifiers.active}
                label={<DictionaryColorsMenu setBlock={setBlock} item={item} />}
                text={
                    <>
                        <DictionaryValueItem
                            hideColor={isIdeaStatus}
                            color={item.color}
                            name={highlightText(item.name, query)}
                        >
                            {[dictionaryStore.todoStatusId, dictionaryStore.defaultIssueTypeId].includes(item.id) && (
                                <span className="o-4">(Default)</span>
                            )}
                        </DictionaryValueItem>
                    </>
                }
            />
        );
    }

    const queryIsItem =
        query && items?.length === 1 && String(query).toLowerCase().trim() === String(items[0].name).toLowerCase();

    return (
        <DictionaryContext.Provider value={{ dictionary, count: list.length, board: issue.board }}>
            <Select
                activeItem={activeItem}
                onActiveItemChange={(item, isNew) => setActiveItem(() => (isNew ? getCreateNewItem() : item))}
                noResults={<MenuItem disabled={true} text="No results." />}
                onItemSelect={saveProps}
                query={query}
                onQueryChange={(q) => setQuery(q)}
                itemRenderer={itemRenderer}
                items={items}
                createNewItemRenderer={
                    queryIsItem
                        ? undefined
                        : (query, active, onItemSelect) =>
                              disableCreateNew ? (
                                  <CreateNewItemBlocked query={query} />
                              ) : (
                                  <CreateNewItemUseFull active={active} query={query} onClick={onItemSelect} />
                              )
                }
                createNewItemFromQuery={createNewItemFromQuery}
                inputProps={{
                    placeholder: 'Select an option or create one',
                    style: { minWidth: 225 },
                    className: 'custom-select-clear',
                    leftIcon: <CustomIcon icon={CustomIconName.SEARCH} />,
                }}
                popoverProps={{
                    ...SELECTOR_POPOVER_PROPS,
                    onOpening: () => setOpen(true),
                    onClosing: () => setOpen(false),
                }}
                resetOnQuery={false}
            >
                {btn}
            </Select>
        </DictionaryContext.Provider>
    );
}

export default observer(DictionaryValueSelect);
