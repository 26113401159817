import { captureMessage, setUser, setContext, captureException } from '@sentry/react';

import { INSTANCE_PREFIX } from 'constants/global';

function isError(what) {
    return Object.prototype.toString.call(what) === '[object Error]' || what instanceof Error;
}

/**
 * Send captureMessage for Sentry
 * @param  {string} title
 * @param  {object|null} extra
 * @param  {string} level
 */
export const sendToSentry = (title, extra = null, level = 'info') => {
    let extraData;
    if (extra) {
        extraData = {
            extra: extra,
            level: level || 'info',
        };
    }
    captureMessage(title, extraData || level);
};

/**
 * Set user for sentry
 * @param  {Object} data
 * @param  {Object} extra
 */
export const setSentryUser = (data, extra) => {
    if (!INSTANCE_PREFIX) {
        setUser(data);
    } else {
        setUser({ id: data.id });
    }
    if (extra) {
        setContext('extra-data', extra);
    }
};

/**
 * Set user for sentry
 * @param  {string} name
 * @param  {Object} data
 */
export const setSentryContext = (name, data) => {
    if (!INSTANCE_PREFIX && data && name) {
        setContext(name, data);
    }
};

/**
 * Capturing Errors / Exceptions for Sentry
 *
 * @param {Error|Exception} err
 */
export const ravenCaptureException = (err) => {
    if (isError(err)) {
        captureException(err);
    } else {
        throw err;
    }
};
