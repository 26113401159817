import restApi from 'api';
import { failRequest, toUrl } from 'utils';

export async function copyMoveToBoard({ move, validation, targetBoard, ...params }) {
    const requestData = {
        move,
        targetBoard,
        validation: validation || null,
    };
    if (!validation) {
        Object.assign(requestData, params);
    }
    try {
        const { data } = await restApi.post(`${this.apiCurrentEndpoint}/copy`, toUrl(requestData));
        return data;
    } catch (e) {
        failRequest(e);
        if (validation) throw e;
    }
}
