import { makeAutoObservable, observable } from 'mobx';

class ColumnsStore {
    columns = new Map();

    constructor() {
        makeAutoObservable(this, {
            columns: observable.struct,
        });
    }

    fillColumns(columns, ignoreIfExist) {
        columns.forEach((column) => {
            if (!this.columns.has(String(column.field_id))) {
                this.columns.set(String(column.field_id), { ...column, id: column.field_id });
            } else if (!ignoreIfExist) {
                Object.assign(this.columns.get(String(column.field_id)), column);
            }
        });
    }

    getColumnById(columnId) {
        return this.columns.get(String(columnId));
    }
}

const columnsStore = new ColumnsStore();

export default columnsStore;
