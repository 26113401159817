import { observer } from 'mobx-react-lite';

import NotFound from 'pages/NotFound';

import AppWrapper from 'components/AppWrapper';

function NotFoundApp() {
    return (
        <AppWrapper contentOnly>
            <NotFound mainSite contentOnly />
        </AppWrapper>
    );
}

export default observer(NotFoundApp);
