import api from 'api';
import { failRequest } from 'utils';

export async function fetchVoters() {
    try {
        const { data } = await api.get(`${this.apiCurrentEndpoint}/users`);
        const users = data.voting_users.map((el) => {
            const user = {
                count: el.count,
                reactions: el.reactions,
                read_at: el.read_at,
                voting_issue_id: el.voting_issue_id,
            };

            Object.entries(el.voting_user).forEach(([field, value]) => {
                if (field === 'company') {
                    user.companyId = value;
                    user.company = data.companies.find((company) => company.id === value);
                } else {
                    user[field] = value;
                }
            });

            return user;
        });
        return { users, companies_custom_fields: data.companies_custom_fields };
    } catch (e) {
        failRequest(e);
        return [];
    }
}
