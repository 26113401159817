import { styled } from 'stitches.config';

const StIcon = styled('div', {
    width: '18px',
    height: '18px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexShrink: 0,
});

const StName = styled('span', {
    lineHeight: '14px',
    color: '$tc1',
    flexGrow: 1,
});

const StChild = styled('div', {
    display: 'flex',
    alignItems: 'center',
    gap: 8,
    flexShrink: 0,
    opacity: 0.6,
    transition: 'opacity 0.2s',
});

const StItemKey = styled('div', {
    color: '$tc2',
    flexShrink: 0,
});

const StItem = styled('div', {
    lineHeight: '24px',
    marginBottom: '4px',
    color: '$tc2',
    textDecoration: 'none',
    display: 'flex',
    alignItems: 'center',
    gap: 4,

    '&:is(a)': {
        cursor: 'pointer',

        '&:hover': {
            [StName]: {
                textDecoration: 'underline',
            },
        },
    },

    '&:hover': {
        color: '$tc1',
        textDecoration: 'none',

        [`${StChild}`]: {
            opacity: 1,
        },
    },

    '&[aria-checked="true"]': {
        [StItemKey]: {
            textDecoration: 'line-through',
        },
    },

    variants: {
        completed: {
            true: {
                [StItemKey]: {
                    textDecoration: 'line-through',
                },
                [StName]: {
                    textDecoration: 'line-through',
                },
                [`${StItemKey} + ${StName}`]: {
                    textDecoration: 'none',
                },
                '&:is(a):hover': {
                    [StName]: {
                        textDecoration: 'line-through underline',
                    },
                    [`${StItemKey} + ${StName}`]: {
                        textDecoration: 'underline',
                    },
                },
            },
        },
    },
});

export { StIcon as Icon, StName as Text, StItem as Root, StChild as RightElement, StItemKey as Key };
