import api from 'api';

import failRequest from 'utils/failRequest';
import toUrl from 'utils/toUrl';

export async function pushChangeToServer(params) {
    if (!this.id || this.id === -1) return;
    try {
        await api.put(`/users/${this.id}`, toUrl(params));
    } catch (e) {
        failRequest(e);
    }
}
