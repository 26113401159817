/**
 * Params for task runner for Single board or Report
 *
 * @param {int} id - report/board id
 * @param {boolean} sync - sync tasks score with tracker (back sync)
 * @param {string} model - report or board
 */
export default function getBoardTaskParams(id, sync = false, model = 'board') {
    return {
        method: 'updateBoardIssues',
        params: { sync, id, model },
    };
}
