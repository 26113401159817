import { CRITERION_SCALE_TYPE } from 'constants/Criterion';

export function isSingleNumberCriterion(estimation) {
    switch (estimation.type) {
        case CRITERION_SCALE_TYPE.range:
            const { max, min } = estimation;
            return -10 < min && 10 > max && min === Math.ceil(min) && max === Math.ceil(max);
        case CRITERION_SCALE_TYPE.series:
            const { series } = estimation;
            const list = String(series)
                .split(',')
                .filter((el) => {
                    const number = parseFloat(el);
                    return number !== Math.ceil(number) || number >= 10 || number <= -10;
                });
            return list.length === 0;
        default:
            return false;
    }
}
