export const APP_STORAGE_KEYS = {
    board_sort: 'board.sort.',
    report_sort: 'report.sort.',
    columns_width_old: 'columns.width.v1',
    columns_width: 'columns.width.v2',
    issue_widths: 'issue.widths.v1',
    score_widths: 'score.widths.v1',
    issue_sides: 'issue.sides.v1',
    kanban_column: 'kanban.column.',
    timezones: 'timezones',
    video_banner: 'video.banner.',
    user_colors: 'user.colors',
    stripe_open: 'stripe.open.',
    dashboard_folder: 'dashboard.folder.',
    board_form: 'board.form.',
    settings_path: 'settings.path',
    config: 'config',
    aside_banner: 'aside.banner.',
    voting_action: 'voting.action',
    force: 'force',
    board_sync: 'board.sync',
    board_init: 'board.init',
    backup: 'backup.',
    templates: 'templates',
    trash: 'trash',
    insights_aggr: 'insights.aggr',
    insights_columns: 'insights.columns',
};
