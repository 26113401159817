export function sortBoards(boards) {
    return boards.sort((a, b) => {
        // Sort by `hasAccess` in descending order
        if (a.hasAccess !== b.hasAccess) {
            return b.hasAccess - a.hasAccess;
        }

        // Sort by `currentUserInBoard` in descending order
        if (a.currentUserInBoard !== b.currentUserInBoard) {
            return b.currentUserInBoard - a.currentUserInBoard;
        }

        // Sort by `name` in ascending order
        return a.name > b.name ? 1 : -1;
    });
}
