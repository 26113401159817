import { makeAutoObservable, runInAction } from 'mobx';

import api from 'api';

import { FILTER_TYPES } from 'utils/consts';
import failRequest from 'utils/failRequest';
import logEvent from 'utils/logEvent';
import toUrl, { VALUE_NULL } from 'utils/toUrl';

import { convertDataToArray } from 'components/FormField/convertDataToArray';

export const emptyValue = (value) => value === null || (Array.isArray(value) && value.length === 0) || false;

export const hasRequired = (item) =>
    (item.rules || []).find((el) => Array.isArray(el) && el.indexOf('required') !== -1);

export function isEmptyValue(value) {
    return ['', null, undefined].includes(value);
}

const parseFieldsByType = (el) => {
    if (isEmptyValue(el.value)) {
        return VALUE_NULL;
    }

    switch (true) {
        case el.type === FILTER_TYPES.LIST:
            const dataKeys = el.data.map((item) => item.id);

            if (el.multiple) {
                const listValue = Array.isArray(el.value) ? el.value : [];
                const valueStr = listValue.map((el) => String(el));
                if (el.suggestUrl) {
                    return valueStr;
                }
                return dataKeys.filter((id) => valueStr.includes(String(id))) || [];
            }
            const value =
                el.value.id || (el.suggestUrl && el.value) || (dataKeys.includes(String(el.value)) && el.value);

            return isEmptyValue(value) ? VALUE_NULL : value;
        default:
            return el.value;
    }
};

export const prepareFormData = (form) => {
    return form.fields.reduce((result, el) => {
        if (el.type === 'info') {
            return result;
        }
        result[el.name] = parseFieldsByType(el);

        if (el.multiple === true && result[el.name].length === 0) {
            result[el.name] = VALUE_NULL;
        }

        return result;
    }, {});
};

class ProviderForm {
    form = true;
    fields = null;
    errors = null;
    loading = false;

    exportedIssue = null;
    pushCode = 200;
    exportIssue = null;

    abortController;

    constructor() {
        makeAutoObservable(this, { abortController: false });
    }

    update(data) {
        Object.assign(this, data);
    }

    togglePushIssue(exportIssue) {
        this.exportIssue = exportIssue || null;
        !exportIssue && providerForm.clearFields();
    }

    async fetchPushIssue(issue) {
        runInAction(() => {
            this.fields = null;
            this.form = true;
        });

        try {
            const { data } = await api.get(`${issue.apiCurrentEndpoint}/export`);

            data.fields = data.fields.map((field) => {
                field.data = convertDataToArray(field.data);
                return field;
            });

            this.update({ ...data, form: 1 });
        } catch (e) {
            this.togglePushIssue();
            failRequest(e);
            runInAction(() => {
                this.form = null;
            });
        }
    }

    async pushToJira({ issue, reloadForm }) {
        let config;
        if (reloadForm) {
            if (this.abortController) {
                this.abortController.abort();
            }
            this.abortController = new AbortController();
            config = {
                headers: { 'X-Reload-Form': 1, Validation: 1 },
                signal: this.abortController.signal,
            };
        }

        try {
            runInAction(() => {
                this.loading = true;
            });

            const form = prepareFormData(this);
            const { data, status } = await api.post(`${issue.apiCurrentEndpoint}/export`, toUrl(form), config);

            const exportedIssue = data.exportedIssue;

            if (exportedIssue) {
                runInAction(() => {
                    this.exportedIssue = exportedIssue;
                    this.pushCode = status;
                });
            }

            if (status === 206) {
                return { data: exportedIssue, code: 206 };
            }
            if (reloadForm) {
                data.fields = data.fields?.map((field) => {
                    field.data = convertDataToArray(field.data);
                    return field;
                });
                this.update(data);
            }
            return { data: exportedIssue, code: 200 };
        } catch (e) {
            if (e?.code === 'ERR_CANCELED') return await Promise.reject(e);
            if (e.response?.status === 422) {
                const responseData = e.response.data;
                responseData.fields = responseData.fields?.map((field) => {
                    field.data = convertDataToArray(field.data);
                    return field;
                });

                this.update(responseData);
            } else {
                logEvent('Fail - Create Push to Tracker', { e });
                failRequest(e);
            }

            return await Promise.reject(e);
        } finally {
            runInAction(() => {
                this.loading = false;
            });
        }
    }

    clearFields() {
        this.fields = null;
        this.exportedIssue = null;
        this.pushCode = 200;
    }
}

export const providerForm = new ProviderForm();
