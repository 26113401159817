import { makeAutoObservable } from 'mobx';

import { IS_VOTING_BOARD } from 'constants/global';

import windowSize from 'utils/windowSize';

const SCREEN_SIZE = {
    mobile: 810,
    tablet: 810,
    phone: 520,
};

const { width } = windowSize();

class ScreenStore {
    isMobile = IS_VOTING_BOARD && width < SCREEN_SIZE.mobile;
    isTablet = IS_VOTING_BOARD && width < SCREEN_SIZE.tablet;
    isPhone = IS_VOTING_BOARD && width < SCREEN_SIZE.phone;

    constructor() {
        makeAutoObservable(this);
    }

    setWidth = ({ width }) => {
        if (!IS_VOTING_BOARD) {
            return;
        }
        this.isMobile = width < SCREEN_SIZE.mobile;
        this.isTablet = width < SCREEN_SIZE.tablet;
        this.isPhone = width < SCREEN_SIZE.phone;
    };
}

const screenStore = new ScreenStore();

export default screenStore;
