import { CURRENT_USER_ID } from 'constants/global';

export function orderUsersByMe(users) {
    return users.slice().sort((a, b) => {
        if (a.id === CURRENT_USER_ID) {
            return -1;
        }
        if (b.id === CURRENT_USER_ID) {
            return 1;
        }
        return a.name > b.name ? 1 : -1;
    });
}
