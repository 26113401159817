import { forwardRef } from 'react';

import classNames from 'classnames';

import { styled } from 'stitches.config';

const ALIGNS = {
    center: 'center',
    end: 'end',
    bottom: 'bottom',
    top: 'top',
    none: 'none',
};

const StyledFlex = styled('div', {
    variants: {
        inline: {
            true: {
                display: 'inline-flex !important',
            },
        },
    },
});

/**
 * Flex-box component
 *
 * @param {String} tagName
 * @param {String} className
 * @param {JSX.Element} children
 * @param {Object} attributes
 * @param {String} align - from ALIGNS
 * @param {Boolean} spaceBetween
 * @param {Number} gap
 * @param {Boolean} column
 * @param {Boolean} grow
 * @param {Boolean} wrap
 * @param {Boolean} top
 * @param {Boolean} center
 * @param {Object} style
 * @param {Boolean} shrink
 * @param {function} onClick
 * @param {Object} props
 * @returns {JSX.Element}
 */
const Flex = forwardRef(function Flex(
    {
        className,
        children,
        attributes,
        align,
        spaceBetween,
        gap,
        column,
        grow,
        wrap,
        center,
        shrink,
        onClick,
        tagName,
        css = {},
        ...props
    },
    forwardedRef
) {
    const classes = classNames(className, 'flex', {
        'flex-a-center': align === ALIGNS.center,
        'flex-end': align === ALIGNS.end,
        'flex-sbetween': spaceBetween,
        'flex-column': column,
        'flex-grow': grow,
        'flex-wrap': wrap,
        'flex-center': center,
        'flex-shrink': shrink,
        'flex-a-bottom': align === ALIGNS.bottom,
        'flex-a-top': align === ALIGNS.top,
    });

    const cssStyle = { ...css };
    if (gap) {
        cssStyle.gap = gap;
    }

    return (
        <StyledFlex
            ref={forwardedRef}
            as={tagName}
            css={cssStyle}
            className={classes}
            {...attributes}
            onClick={onClick}
            {...props}
        >
            {children}
        </StyledFlex>
    );
});

Flex.defaultProps = {
    align: ALIGNS.center,
    attributes: {},
    spaceBetween: false,
    column: false,
    wrap: false,
    grow: false,
    tagName: 'div',
};

export default Flex;
