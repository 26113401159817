import { ColorsVariants } from 'atoms/Colors';

export const colorsVariant = ColorsVariants.reduce(
    (res, color) => {
        res.colors[color] = { btnIntent: color };
        res.solid[color] = { color: `$${color}9` };
        res.active.push({
            color,
            active: true,
            css: {
                background: `$${color}5`,
                color: `$${color}11`,
                '@hover': {
                    '&:hover': {
                        background: `$${color}5`,
                    },
                },
            },
        });
        res.minimal.push({
            color,
            minimal: true,
            active: false,
            css: {
                background: 'none',
                '@hover': {
                    '&:enabled, &:is(a)': {
                        '&:hover': {
                            background: `$${color}4`,
                            color: `$${color}11`,
                        },
                    },
                },
            },
        });
        return res;
    },
    { colors: {}, active: [], minimal: [], solid: {} },
);
