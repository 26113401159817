export default function flatObject(obj = {}, prefix = null) {
    return Object.entries(obj).reduce((res, [key, value]) => {
        const name = prefix ? `${prefix}_${key}` : key;
        if (value === null || ['boolean', 'number', 'string'].includes(typeof value)) {
            res[name] = value === null ? 'null' : value;
        } else if (value) {
            const prevRes = flatObject(value, name);
            Object.assign(res, prevRes);
        }
        return res;
    }, {});
}
