import api from 'api';
import { failRequest, logEvent, toUrl } from 'utils';

export async function resetScores(requestParams) {
    try {
        logEvent('Reset all issues scores - send request', { requestParams });
        const { data } = await api.post(`${this.apiEndpoint}/clear-votes`, toUrl(requestParams));
        return data;
    } catch (e) {
        failRequest(e);
        return await Promise.reject(e);
    }
}
