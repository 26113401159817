import { observer } from 'mobx-react-lite';

import { mainStore } from 'store/models/MainStore';

import getDateTimeForTimezone from 'utils/getDateTimeForTimezone';

import Avatar from 'components/Avatar';
import { CustomIcon, CustomIconName } from 'components/CustomIcon';
import Flex from 'components/Flex';

function RewrittenCriterionCellTooltip({ rewritten }) {
    const user = mainStore.users.usersIds.get(rewritten.user_id);
    const updatedDate = getDateTimeForTimezone(rewritten.updated, mainStore.organization.timezone);

    return (
        <Flex column gap={8} align="none">
            <Flex gap={6}>
                <Avatar user={user} size={20} />
                <span>
                    Approved by {user.name} at {updatedDate.toFormat('DD t')}
                </span>
            </Flex>
            {rewritten.comment ? (
                <Flex gap={6} align="start">
                    <Flex center style={{ width: 20, height: 20 }} shrink>
                        <CustomIcon icon={CustomIconName.COMMENT} className="o-4" />
                    </Flex>
                    <div className="typography" dangerouslySetInnerHTML={{ __html: rewritten.comment }} />
                </Flex>
            ) : null}
        </Flex>
    );
}

export default observer(RewrittenCriterionCellTooltip);
