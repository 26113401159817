import api from 'api';
import { failRequest } from 'utils';

export async function deleteIdeaStatusTriggers(id) {
    try {
        await api.delete(`${this.apiEndpoint}/idea-status-triggers/${id}`);
    } catch (e) {
        failRequest(e);
    }
}
