import { R } from './constants';

export const clamp = (val, min, max) => {
    if (val == null) {
        return val;
    }
    if (max < min) {
        throw new Error('clamp: max cannot be less than min');
    }
    return Math.min(Math.max(val, min), max);
};

export const getViewBox = (strokeWidth) => {
    const radius = R + strokeWidth / 2;
    const viewBoxX = (50 - radius).toFixed(2);
    const viewBoxWidth = (radius * 2).toFixed(2);
    return `${viewBoxX} ${viewBoxX} ${viewBoxWidth} ${viewBoxWidth}`;
};
