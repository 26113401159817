import React, { useCallback, useRef } from 'react';

import { useNavigate } from 'react-router-dom';

import { Button } from 'components/Button';
import { toast, ToastAction } from 'components/Toast';

export default function useUndoNotification() {
    const toastRef = useRef(null);
    let navigate = useNavigate();

    return useCallback(
        (message, undoAction = undefined, linkAction = undefined, onDismiss) => {
            function linkHandleAction(e) {
                const link = e.target.getAttribute('href');
                if (link && /^\//.test(link) && !e.metaKey) {
                    e.preventDefault();
                    navigate(link);
                    toastRef.current?.dismiss();
                    linkAction && linkAction(link);
                    return false;
                }
            }

            function undoHandleClick() {
                undoAction && undoAction();
                toastRef.current?.dismiss();
            }

            const content =
                typeof message === 'string' ? (
                    <div
                        className="flex-grow"
                        onClick={linkHandleAction}
                        dangerouslySetInnerHTML={{ __html: message }}
                    />
                ) : (
                    <div onClick={linkHandleAction}>{message}</div>
                );

            toastRef.current = toast({
                description: content,
                onDismiss: onDismiss,
                action: undoAction ? (
                    <ToastAction altText="Undo">
                        <Button className="flex-shrink" size={24} text="Undo" onClick={undoHandleClick} />
                    </ToastAction>
                ) : undefined,
            });
        },
        [navigate],
    );
}
