import React, { useState } from 'react';

import { Popover } from '@blueprintjs/core';
import { observer } from 'mobx-react-lite';

import { POPOVER_PROPS_DIV } from 'utils/consts';

import { Button } from 'components/Button';
import { CustomIconName } from 'components/CustomIcon';

import DictionaryMultiSelectActiveItems from './DictionaryMultiSelectActiveItems';
import { DictionaryMultiSelectMenu } from './DictionaryMultiSelectMenu';

import DictionaryContext from '../DictionaryContext';

function DictionaryMultiSelect({ board, list, activeList, dictionary, onAdd, onCreate, onRemove, btnProps }) {
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [blockClosed, setBlock] = useState(false);

    function saveProps(value) {
        if (list.some((el) => el.id === value.id)) {
            onAdd(value);
            setOpen(false);
        }
    }

    async function creatItem(name) {
        setLoading(true);
        await onCreate(name);
        setLoading(false);
        setOpen(false);
    }

    return (
        <DictionaryContext.Provider value={{ dictionary, setOpen, count: null, board }}>
            <div className="tag-list">
                <DictionaryMultiSelectActiveItems list={activeList} onRemove={onRemove} />
                <Popover
                    {...POPOVER_PROPS_DIV}
                    onClose={() => !blockClosed && setOpen(false)}
                    isOpen={open}
                    position="bottom-left"
                    content={
                        <DictionaryMultiSelectMenu
                            loading={loading}
                            list={list}
                            blockClosed={blockClosed}
                            setBlock={setBlock}
                            saveProps={saveProps}
                            creatItem={creatItem}
                        />
                    }
                    minimal
                    boundary="window"
                    popoverClassName="bp5-select-popover"
                    captureDismiss={true}
                >
                    <Button
                        active={open}
                        block
                        border
                        data-place="add-label"
                        icon={CustomIconName.PLUS}
                        size={24}
                        onClick={() => setOpen(true)}
                        {...btnProps}
                    />
                </Popover>
            </div>
        </DictionaryContext.Provider>
    );
}

export default observer(DictionaryMultiSelect);
