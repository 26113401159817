export default function debounce(callback, wait) {
    let timeoutId = null;

    function debounced(...args) {
        clearTimeout(timeoutId);
        timeoutId = setTimeout(() => {
            callback.apply(null, args);
        }, wait);
    }

    // Add the cancel method to the debounced function
    debounced.cancel = () => {
        clearTimeout(timeoutId);
        timeoutId = null; // Optional: clear the timeoutId
    };

    return debounced;
}
