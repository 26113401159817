import React, { useContext, useState } from 'react';

import { Popover } from '@blueprintjs/core';

import alertStore from 'store/models/Alert';
import dictionaryStore from 'store/models/DictionaryStore';

import {
    DICTIONARY_LABELS,
    DICTIONARY_LABELS_VOTING,
    DICTIONARY_STATUSES_VOTING,
    POPOVER_PROPS_DIV,
} from 'utils/consts';
import logEvent from 'utils/logEvent';

import { Button } from 'components/Button';
import { CustomIconName } from 'components/CustomIcon';

import { DictionaryColorsMenuContent } from './DictionaryColorsMenuContent';

import DictionaryContext from '../DictionaryContext';

export const DictionaryColorsMenu = ({ item, setBlock, children }) => {
    const { dictionary, board } = useContext(DictionaryContext);
    const [open, setOpen] = useState(false);
    const [loader, setLoader] = useState(false);

    async function removeLabel(item) {
        logEvent(`Remove ${dictionary} item`);
        if ([DICTIONARY_LABELS_VOTING, DICTIONARY_LABELS].includes(dictionary)) {
            alertStore.confirm({
                message: 'Are you sure you want to remove this label? This label will be deleted from all issues',
                onConfirm: () => {
                    setBlock(false);
                    if ([DICTIONARY_STATUSES_VOTING, DICTIONARY_LABELS_VOTING].includes(dictionary)) {
                        board.removeDictItem({ dictionary, id: item.id });
                    } else {
                        dictionaryStore.removeDictItem({ dictionary, id: item.id });
                    }
                },
                confirmButtonText: 'Remove',
            });
        } else {
            setLoader(true);
            setBlock(false);
            if ([DICTIONARY_STATUSES_VOTING, DICTIONARY_LABELS_VOTING].includes(dictionary)) {
                await board.removeDictItem({ dictionary, id: item.id });
            } else {
                await dictionaryStore.removeDictItem({ dictionary, id: item.id });
            }

            setLoader(false);
        }
    }

    function toggleView(e) {
        e.preventDefault();
        e.stopPropagation();
        setOpen((state) => !state);
        return false;
    }

    return (
        <Popover
            {...POPOVER_PROPS_DIV}
            isOpen={open}
            onClose={() => setOpen(false)}
            onOpened={() => setBlock?.(true)}
            onClosed={() => setBlock?.(false)}
            content={
                <DictionaryColorsMenuContent onClose={() => setOpen(false)} onRemove={removeLabel} item={item}>
                    {children}
                </DictionaryColorsMenuContent>
            }
            minimal
            position="right-top"
            captureDismiss
            boundary="window"
        >
            <Button
                border
                active={open}
                size={24}
                block
                onClick={toggleView}
                minimal
                loading={loader}
                icon={CustomIconName.DOTES_H}
            />
        </Popover>
    );
};
