import { styled } from 'stitches.config';

export const StCardStripeHiddenActions = styled('div', {
    opacity: 0,
    visibility: 'hidden',
    transition: 'all .2s',
    pointerEvents: 'none',
    variants: {
        visible: {
            true: {
                visibility: 'visible',
                pointerEvents: 'auto',
                opacity: 1,
            },
        },
    },
});

export const StCardStripeOpacityElement = styled('div', {
    opacity: 0.3,
    transition: 'opacity .2s',
});

export const StCardStripeMainCounter = styled('b', {
    fontWeight: '$3',
    lineHeight: '20px',
});

export const StCardStripeIcon = styled('div', {
    transition: 'color .2s',

    variants: {
        active: {
            true: {
                color: '$tc1',
            },
            false: {
                color: '$gray9',
                '[data-state="open"]': {
                    color: '$gray11',
                },
                '&:hover': {
                    color: '$gray11',
                },
            },
        },
    },
});

export const StCardStripeHeader = styled('div', {
    cursor: 'pointer',
    padding: '0 4px 0 8px',
    height: 32,
    transition: 'background-color .2s',
    borderRadius: '5px 5px 0 0',
    width: '100%',

    variants: {
        readOnly: {
            true: {
                cursor: 'default',
                '&:hover': {
                    background: '$stripe-background-open !important',
                },
            },
        },
        open: {
            true: {
                '&:hover': {
                    background: '$stripe-background-hover',
                },
            },
        },
        sticky: {
            true: {
                position: 'sticky',
                top: -0.5,
                zIndex: 1,
                background: 'inherit',
            },
        },
    },
});

export const StCardStripe = styled('div', {
    borderRadius: 6,
    border: '1px solid $stripe-border',
    transition: 'border-color .2s',
    scrollMarginTop: 30,

    h2: {
        fontSize: '16px !important',
        lineHeight: 1.3,
    },

    '&:hover:not([aria-disabled="true"])': {
        borderColor: '$stripe-border-hover',
        [`& ${StCardStripeHiddenActions}`]: {
            opacity: 1,
            visibility: 'visible',
            pointerEvents: 'auto',
        },
        [StCardStripeOpacityElement]: {
            opacity: 1,
        },
    },

    '&[aria-disabled="true"]': {
        cursor: 'not-allowed',
        '> *': {
            pointerEvents: 'none',
        },
    },

    variants: {
        open: {
            true: {
                borderColor: '$stripe-border-hover',
                backgroundColor: '$stripe-background-open',
                [`& ${StCardStripeOpacityElement}`]: {
                    opacity: 1,
                },
                zIndex: 1,
            },
            false: {
                '&:hover': {
                    background: '$stripe-background-hover',
                },
            },
        },
        intent: {
            yellow: {
                '&:hover': {
                    background: '$bg7',
                },
            },
            error: {
                background: '$tomato3',
                borderColor: '$tomato7',
                color: '$tomato11',
                '&:hover': {
                    background: '$tomato4',
                    borderColor: '$tomato8',
                    color: '$tomato12 !important',
                },
            },
            errorAlt: {
                background: '$tomato2',
                borderColor: '$tomato7',
                '&:hover': {
                    background: '$tomato3',
                    borderColor: '$tomato8',
                },
            },
        },
        disabled: {
            true: {
                pointerEvents: 'none',
                opacity: 0.5,
            },
        },
    },
    compoundVariants: [{ open: true, intent: 'yellow', css: { background: '$bg7' } }],
});

export const StCardStripeBody = styled('div', {
    padding: '0 8px',

    '&[aria-disabled="true"]': {
        '& > *': {
            pointerEvents: 'none',
            userSelect: 'none',
        },
        cursor: 'not-allowed',
        opacity: 0.7,
    },

    '.bp5-input': {
        minHeight: 30,
        lineHeight: '30px',
    },
});
