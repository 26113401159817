import { logEvent, sendToSentry } from 'utils';

import { fetchEventSource } from 'utils/fetch-event-source';

import { getParsedData } from './getParsedData';

/**
 * Get AI response for text generation
 *
 *
 * @param {string} url
 * @param {function} onUpdate
 * @param {AbortController} ctrl
 * @return {Promise<*>}
 */
export default async function getAIText(url, onUpdate, ctrl) {
    return await fetchEventSource(url, {
        method: 'GET',
        signal: ctrl.signal,
        onopen(res) {
            if (res.ok && res.status === 200) {
            } else if (res.status >= 400 && res.status < 500 && res.status !== 429) {
                ctrl.abort();
            }
        },
        onclose() {
            logEvent('AI Connection closed by the server');
        },
        onerror(err) {
            logEvent('AI There was an error from server', err);
            ctrl.abort();
        },
        onmessage(event) {
            if (event.data === '[DONE]') {
                return;
            }

            if (!event.data) return;

            const parsedData = getParsedData(event.data);
            if (parsedData === null) return;

            if (parsedData.choices) {
                const text = parsedData.choices[0].delta.content || '';
                text && onUpdate(text.replace(/\n/gim, ''));
            } else {
                sendToSentry('AI Empty parsedData.choices', parsedData);
            }
        },
    });
}
