let H = 0;
let W = 0;

export default function windowSize(force = false) {
    if (!force && H > 0 && W > 0) {
        return { height: H, width: W };
    }

    const documentElement = window.documentElement || window.body;

    H = window.innerHeight || (documentElement && documentElement.clientHeight);
    W = window.innerWidth || (documentElement && documentElement.clientWidth);

    return { height: H || 0, width: W || 0 };
}
