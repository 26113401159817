import React from 'react';

import { DateTime } from 'luxon';

import { mainStore } from 'store/models/MainStore';

import SettingsLinkButton from 'components/SettingsLinkButton';
import Tooltip from 'components/Tooltip';

export const DisabledScoreValueTooltip = React.memo(({ date, children, isAdmin }) => {
    const dateTime = DateTime.fromISO(date).toFormat('DDD t');

    if (isAdmin && mainStore.activeBoard) {
        return (
            <Tooltip
                disableHoverableContent={false}
                contentClassName="limit limit-200"
                side="top"
                content={
                    <>
                        Due to sprint planning{' '}
                        <SettingsLinkButton
                            text="settings"
                            likeLink
                            active
                            link={`/board/${mainStore.activeBoard.id}/sprint/disable-evaluation`}
                        />
                        , nobody can update the estimations till {dateTime}
                    </>
                }
            >
                {children}
            </Tooltip>
        );
    }

    return (
        <Tooltip
            contentClassName="limit limit-200"
            side="top"
            content={`Due to sprint planning nobody can update the estimations till ${dateTime}`}
        >
            {children}
        </Tooltip>
    );
});
