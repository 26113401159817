import React from 'react';

import { observer } from 'mobx-react-lite';

import { mainStore } from 'store/models/MainStore';

import ButtonLink from 'components/ButtonLink';
import Emoji from 'components/Emoji';

export const ReportItem = observer(({ report, index }) => {
    const isActive = String(mainStore.report?.id) === String(report.id);

    return (
        <ButtonLink
            aria-selected={isActive}
            combo={index < 9 ? index + 1 : undefined}
            comboShift
            to={`/reports/${report.id}`}
            isActive={isActive}
            slim
            tooltip={report.fullName}
            leftElm={<Emoji emoji={report.emoji} className="t-size-l" />}
        />
    );
});
