export const hasOwnProperty =
    typeof Object.hasOwn === 'function'
        ? Object.hasOwn
        : function (obj, property) {
              try {
                  return obj.hasOwnProperty(property);
              } catch (e) {
                  return false;
              }
          };
