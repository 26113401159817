import { runInAction } from 'mobx';

import restApi from 'api';

import filtersCollection from 'store/models/Filters';

import failRequest from 'utils/failRequest';
import logEvent from 'utils/logEvent';
import toUrl from 'utils/toUrl';

export async function deleteFilter() {
    logEvent('Remove Filter - Confirm');

    const id = this.filter.id;
    const report_id = this.filter.report_id;
    const board_id = this.filter.board_id;

    runInAction(() => {
        this.filter = null;
    });

    filtersCollection.onRemoveFilter(id);

    try {
        await restApi.delete(`search/save`, { data: toUrl({ id, report_id, board_id }) });
    } catch (e) {
        failRequest(e);
    }
}
