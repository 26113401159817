import React from 'react';

import { observer } from 'mobx-react-lite';

import { mainStore } from 'store/models/MainStore';

import { getReleaseNoteAutoCreationPeriodString } from 'utils/getReleaseNoteAutoCreationPeriodString';

export const AutoDigestTooltipContent = observer(() => {
    const board = mainStore.activeBoard;
    const settings = board?.release_note_settings;

    return (
        <div className="limit limit-300">
            <p>Automatically created release note. It triggers by Voting ideas updates within chosen time period.</p>
            Will be sent: {getReleaseNoteAutoCreationPeriodString(settings)}
        </div>
    );
});
