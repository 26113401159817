import axios from 'axios';

import { DUCALIS_SESSION_ID } from 'constants/global';

import { checkErrorStatus, checkNewVersion } from 'utils/apiIntereptors';

axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

const headers = { 'X-Requested-With': 'XMLHttpRequest' };
if (DUCALIS_SESSION_ID) headers['Ducalis-Session'] = DUCALIS_SESSION_ID;

export const rootApi = axios.create({ baseURL: '/', headers });
export const storageApi = axios.create({ baseURL: '/storage/', headers });
const restApi = axios.create({ baseURL: '/rest/', headers });

restApi.interceptors.response.use(checkNewVersion, checkErrorStatus);
storageApi.interceptors.response.use(checkNewVersion, checkErrorStatus);
rootApi.interceptors.response.use(checkNewVersion, checkErrorStatus);

export default restApi;
