import { DateTime } from 'luxon';

import { PERIOD, PERIOD_MANY } from 'pages/Board/ReleaseNotes/constants';

import { WEEKDAYS_NAMES_SHORT } from './consts';
import { getDateFromTime } from './getDateFromTime';

export function getReleaseNoteAutoCreationPeriodString({ time, day, interval, interval_type }) {
    const dayName = WEEKDAYS_NAMES_SHORT[day];
    const dateTime = DateTime.fromJSDate(getDateFromTime(time));
    const timeString = !dateTime.get('minute') ? dateTime.toFormat('h a') : dateTime.toFormat('t');

    const prefix = interval > 1 ? 'First' : 'Every';
    const period = interval > 1 ? `Every ${interval} ${PERIOD_MANY[interval_type]}` : PERIOD[interval_type];

    return `${prefix} ${dayName} at ${timeString.toLowerCase()}, ${period}`;
}
