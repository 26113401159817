import { storageApi } from 'api';
import { failRequest } from 'utils';

import { issuesList } from 'store/models/MainStore';
import { mainStore } from 'store/models/MainStore';
import { ORG_DB_STORES } from 'store/updateOrgDB';

import { prepareServerIssuesResponse } from 'utils/prepareServerIssuesResponse';

export async function updateIssues(publicBoardId) {
    const boardId = mainStore.getRealBoardIdByPublicId(publicBoardId);
    try {
        const { data } = storageApi.get('/issues', { params: { board: boardId } });
        const preparedList = prepareServerIssuesResponse(data);

        issuesList.fillData(preparedList, boardId);

        const dbIndex = boardId ? 'boardId' : null;
        const query = boardId || undefined;

        await this.db.saveList(preparedList, ORG_DB_STORES.issues, dbIndex, query);
    } catch (error) {
        failRequest(error);
    }
}
