import React, { useState } from 'react';

import EnglishWrapper from 'EnglishWrapper';

import InviteError from 'pages/InviteError';

import AuthChild from 'modules/Auth/AuthChild';

import { StHr } from 'atoms/StHr';

import Flex from 'components/Flex';
import { Toaster } from 'components/Toast';

function checkError() {
    if (window.invalid_invite_email_signup) {
        const { email, invitedEmail } = window.invalid_invite_email_signup;

        if (email && invitedEmail) {
            return { email, type: 'google', invitedEmail };
        }
    }

    return {};
}

function Signup() {
    const [inviteError, setInviteError] = useState(checkError());

    if (inviteError.email && inviteError.type && inviteError.invitedEmail) {
        return (
            <InviteError
                email={inviteError.email}
                type={inviteError.type}
                invitedEmail={inviteError.invitedEmail}
                setInviteError={setInviteError}
            />
        );
    }

    return (
        <Flex gap={60}>
            <AuthChild isSignup setInviteError={setInviteError} />
            <StHr position="vertical" css={{ height: '100%' }} />
            <div className="flex flex-center signup-desc">
                <ul
                    className="t-mutted login-list flex-vc bp5-list bp5-list-unstyled"
                    style={{ paddingBottom: 90, fontSize: 15 }}
                >
                    <li>No credit card required</li>
                    <li>Free plan available</li>
                    <li>No need to connect trackers</li>
                    <li>Customer support with real human beings</li>
                </ul>
            </div>
        </Flex>
    );
}

function SignupApp() {
    return (
        <EnglishWrapper>
            <Signup />
            <Toaster />
        </EnglishWrapper>
    );
}

export default SignupApp;
