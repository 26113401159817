import { blackA, whiteA } from '@radix-ui/colors';
import { createStitches } from '@stitches/react';

import { BaseStyleConfig } from 'themes/baseStyleConfig';
import { DEFAULT_THEME } from 'themes/default';
import { NEW_THEME } from 'themes/new';
import { PRIMARY_DARK, PRIMARY_LIGHT } from 'themes/primaryColors';
import { VIOLET_DARK } from 'themes/violetDark';

export const { styled, css, createTheme, theme, keyframes } = createStitches({
    theme: {
        colors: {
            ...blackA,
            ...whiteA,

            ...DEFAULT_THEME,
        },
        ...BaseStyleConfig,
    },
    utils: {
        // An abbreviated property for border-radius
        br: (value) => ({
            borderRadius: value,
        }),
        // An abbreviated property for background-color
        bc: (value) => ({
            backgroundColor: value,
        }),
        // A property to apply color theme Button
        btnIntent: (value) => ({
            $$mainColor: `$colors-${value}11`,
            $$bgColor: `$colors-${value}4`,
            $$borderColor: `$colors-${value}5`,
            $$borderColorHover: `$colors-${value}6`,
            background: `$${value}3`,
            color: `$${value}11`,
            '@hover': {
                '&:enabled, &:is(a)': {
                    '&:hover': {
                        background: `$${value}4`,
                        color: `$${value}11`,
                    },
                    '&:active': {
                        background: `$${value}5`,
                        color: `$${value}11`,
                    },
                },
            },
        }),
    },
    media: {
        tablet: '(max-width: 810px)',
        phone: '(max-width: 520px)',
        mobile: '(max-width: 810px)',
        desktop: '(min-width: 810px)',
        hover: '(hover: hover) and (pointer: fine)',
    },
});

export const newLightTheme = createTheme({
    colors: {
        ...NEW_THEME,
        ...PRIMARY_LIGHT,

        btnColor: '$grayA11',
        btnColorHover: '$grayA12',
        btnColorActive: '$grayA12',
        btnBg: '$grayA3',
        btnBgHover: '$grayA4',
        btnBgActive: '$grayA5',
        btnBorder: '$grayA4',
        btnBorderHover: '$grayA6',
    },
    filter: {
        filterInvert: 'invert(1)',
    },
});

export const newDarkTheme = createTheme({ colors: { ...NEW_THEME, ...PRIMARY_DARK } });

export const customDarkTheme = createTheme({ colors: VIOLET_DARK });
