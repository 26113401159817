import { forwardRef } from 'react';

import * as ToastPrimitives from '@radix-ui/react-toast';

import { StToastAction, StToastClose, StToastDescription, StToastRoot, StToastTitle, StToastViewport } from './atoms';

const ToastProvider = ToastPrimitives.Provider;

StToastViewport.displayName = ToastPrimitives.Viewport.displayName;
StToastRoot.displayName = ToastPrimitives.Root.displayName;

const ToastAction = forwardRef((props, ref) => <StToastAction asChild ref={ref} {...props} />);
StToastAction.displayName = ToastPrimitives.Action.displayName;

StToastClose.displayName = ToastPrimitives.Close.displayName;
StToastTitle.displayName = ToastPrimitives.Title.displayName;
StToastDescription.displayName = ToastPrimitives.Description.displayName;

export {
    ToastProvider,
    StToastViewport as ToastViewport,
    StToastRoot as Toast,
    StToastTitle as ToastTitle,
    StToastDescription as ToastDescription,
    StToastClose as ToastClose,
    ToastAction,
};
