import { runInAction } from 'mobx';

import api from 'api';
import { DISABLE_USERS_MANAGER } from 'constants/global';

import failRequest from 'utils/failRequest';

const abortController = { default: null };

export async function pullInviteUsers(query) {
    if (DISABLE_USERS_MANAGER) return;

    if (abortController.default) {
        abortController.default.abort();
    }

    try {
        runInAction(() => {
            this.inviteUsersLoading = true;
        });

        abortController.default = new AbortController();

        const { data } = await api.get('/invite/users', {
            params: { q: query },
            signal: abortController.default.signal,
        });

        runInAction(() => {
            this.inviteUsers = data || [];
        });
    } catch (e) {
        failRequest(e);
    } finally {
        runInAction(() => {
            this.inviteUsersLoading = false;
        });
    }
}
