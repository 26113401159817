import api from 'api';

import failRequest from 'utils/failRequest';
import logEvent from 'utils/logEvent';
import toUrl from 'utils/toUrl';

export async function sendInviteUsers({ crId, boardId = null, users, onDone, role = null, onFail }) {
    logEvent('SEND_INVITE_USERS', { cnt: users.length, boardId, crId, role });

    try {
        const { data } = await api.post('/invite/send', toUrl({ users, role, boardId, crId }));

        onDone && onDone();
        return data;
    } catch (e) {
        failRequest(e);
        onFail && onFail();
    }
}
