import { styled } from 'stitches.config';

export const StLineThrough = styled('span', {
    position: 'relative',
    color: '$grayA10',
    fontWeight: 400,
    '&:before': {
        content: '',
        display: 'block',
        position: 'absolute',
        top: '50%',
        translateY: '-50%',
        marginTop: 1,
        left: -2,
        right: -2,
        height: 1,
        background: '$grayA8',
    },
});
