import { DateTime } from 'luxon';

import getCntText from 'utils/getCntText';

import { RELEASE_NOTE_STATUS } from './constants';

export function getStatusTooltip(note) {
    switch (note.status) {
        case RELEASE_NOTE_STATUS.PUBLISHED:
            return `${DateTime.fromISO(note.sent_date).toRelative()} for ${getCntText(note.audience || 0, [
                'subscriber',
                'subscribers',
            ])}.`;
        case RELEASE_NOTE_STATUS.DRAFT:
            return 'Won’t be sent.';
        case RELEASE_NOTE_STATUS.PRE_SCHEDULED:
            return `Will be sent on time if any Release Notes added.`;
        case RELEASE_NOTE_STATUS.IN_PROGRESS:
            return 'You can’t undo it.';
        case RELEASE_NOTE_STATUS.SCHEDULED:
            return (
                <>
                    Will be sent on time for <span className="t-w-sb">{note.audience || 0}</span>{' '}
                    {getCntText(note.audience || 0, ['subscriber', 'subscribers'], true)}.
                </>
            );
        case RELEASE_NOTE_STATUS.EMPTY:
            return note?.status_message ?? '';
        default:
            return undefined;
    }
}
