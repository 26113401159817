import { CustomIcon, CustomIconName } from 'components/CustomIcon';
import Tooltip from 'components/Tooltip';

export default function PriorityIcon({ is_top = false }) {
    if (!is_top) {
        return null;
    }
    return (
        <Tooltip content="Top Priority Issues" side="top">
            <CustomIcon className="o-6" icon={CustomIconName.STAR} />
        </Tooltip>
    );
}
