import api from 'api';
import { failRequest } from 'utils';

export async function duplicateReleaseNote(note) {
    try {
        const { data } = await api.post(`${this.apiEndpoint}/release-notes/${note.id}/duplicate`);
        return data;
    } catch (e) {
        failRequest(e);
        return null;
    }
}
