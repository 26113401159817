import { IS_IFRAME, IS_IFRAME_MY_IDEAS, IS_IFRAME_MY_VOTES, IS_IFRAME_WIDGET } from 'constants/global';

import { toUrl } from './index';

export default function locationReload(widget_session_id) {
    if (IS_IFRAME || IS_IFRAME_WIDGET || IS_IFRAME_MY_IDEAS || IS_IFRAME_MY_VOTES) {
        const params = toUrl({
            iframe: IS_IFRAME || null,
            widget: IS_IFRAME_WIDGET || null,
            myIdeas: IS_IFRAME_MY_IDEAS || null,
            myVotes: IS_IFRAME_MY_VOTES || null,
            session_id: widget_session_id || null,
        });
        window.location.href = `${window.location.href.replace(/\?.+/, '')}?${params}`;
    } else {
        window.location.reload();
    }
}
