import { observer } from 'mobx-react-lite';
import { FormattedMessage } from 'react-intl';

import { ENTER } from 'utils/consts';
import getKeyCode from 'utils/getKeyCode';
import { isEmail } from 'utils/validators';

import { Button } from 'components/Button';
import Flex from 'components/Flex';
import * as InputField from 'components/InputField';

import LoginError from '../LoginError';

const EmailInput = ({ inputRef, isMobile, defaultValue = '', onSend, clearError, errorText = '', label }) => {
    function emailOnKeyDown(e) {
        if (getKeyCode(e) === ENTER) handleSend();
    }

    function handleSend() {
        const value = inputRef.current.value;
        if (isEmail(value)) {
            onSend(value);
        }
    }

    return (
        <div style={{ width: '100%', maxWidth: 375 }}>
            <Flex column align="none" gap={4} style={{ marginBottom: 8 }}>
                <label htmlFor="email">
                    {label || <FormattedMessage id="global.email" defaultMessage="Email Address" />}
                </label>
                <InputField.Root>
                    <InputField.Input
                        type="email"
                        name="email"
                        data-place="auth-email"
                        ref={inputRef}
                        placeholder="name@example.com"
                        fill
                        autoFocus={!isMobile}
                        defaultValue={defaultValue}
                        onKeyDown={emailOnKeyDown}
                        onChange={clearError}
                    />
                </InputField.Root>
            </Flex>
            <LoginError errorText={errorText} />
            <Button
                onClick={handleSend}
                disabled={!!errorText}
                center
                border
                color="accent"
                block
                text={<FormattedMessage id="auth.with_email" defaultMessage="Continue with Email" />}
            />
        </div>
    );
};

export default observer(EmailInput);
