import { observer } from 'mobx-react-lite';

import { CRITERION_SCALE_TYPE } from 'constants/Criterion';

export function getEstimationDesc(criterion, isString) {
    if (!criterion) return null;

    switch (criterion.scale_type) {
        case CRITERION_SCALE_TYPE.range:
            return `${criterion.scale_min} — ${criterion.scale_max}`;
        case CRITERION_SCALE_TYPE.series:
            const list = String(criterion.scale_series !== null ? criterion.scale_series : '').split(',');
            if (isString) return list.join(', ');

            return (
                <span
                    dangerouslySetInnerHTML={{
                        __html: list.join('<span class="t-mutted">,  </span>'),
                    }}
                />
            );
        case CRITERION_SCALE_TYPE.positive:
            return '0 · 1 · 2 · ... · ∞';
        case CRITERION_SCALE_TYPE.percent:
            return '0 · 1 · 2 · ... · 100 %';
        default:
            return;
    }
}

export const EstimationDesc = observer(({ criterion, isString }) => {
    return getEstimationDesc(criterion, isString);
});
