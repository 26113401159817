import { memo, useEffect } from 'react';

import { styled } from 'stitches.config';

import ButtonLink from 'components/ButtonLink';
import { CustomIconName } from 'components/CustomIcon';

const addBubble = () => {
    if (typeof window.dclsPxl !== 'undefined' && typeof window.dclsPxl.addBubble === 'function') {
        window.dclsPxl.addBubble();
    }
};

const StDiv = styled('div', {
    '[data-ducalis-bubble="true"]': {
        pointerEvents: 'none',
        zIndex: 1,
    },
});

const FeatureRequestButtonWrapper = memo(function FeatureRequestButtonWrapper({ children }) {
    useEffect(() => {
        addBubble();
    }, []);

    return (
        <StDiv className="p-r" id="embedBoardBubble">
            {children}
        </StDiv>
    );
});

const FeatureRequestButton = memo(function FeatureRequestButton({ iconOnly = false }) {
    return (
        <FeatureRequestButtonWrapper>
            <ButtonLink
                tooltip="Public Roadmap & Feature request"
                icon={CustomIconName.LAMP}
                to="/public-roadmap"
                text={iconOnly ? undefined : 'Public Roadmap'}
                data-place="feature-request"
            />
        </FeatureRequestButtonWrapper>
    );
});

export default FeatureRequestButton;
