import { rootApi } from 'api';

import { mainStore } from 'store/models/MainStore';

import appStorage from 'utils/AppStorage';
import debugLog from 'utils/debugLog';
import failRequest from 'utils/failRequest';
import logEvent from 'utils/logEvent';
import toUrl from 'utils/toUrl';

import { toast } from 'components/Toast';

/* Status of Task Manager response */
const STATUS_SUCCESS = 1;
const STATUS_ERROR = 3;

export const getHistoryKey = (data) => {
    return toUrl({ method: data.method, params: data.params });
};

const TASKS = [];

export default function runTaskWorker(props) {
    const lsKey = getHistoryKey(props.data);
    const historyTaskId = appStorage.get(lsKey);

    if (historyTaskId && TASKS.indexOf(historyTaskId) !== -1) {
        return;
    }

    let isExist = false;
    let taskId = props.taskId || historyTaskId || null;
    let timer = null;
    let abortRequest;
    let loading = !!props.taskId || !!historyTaskId || props.loading || false;

    function clear() {
        clearTimeout(timer);
        typeof abortRequest === 'function' && abortRequest();
        isExist = false;
    }

    function run() {
        loading = true;
        if (typeof props.onStart === 'function') {
            props.onStart();
        }
        isExist = true;
        sendRequest();
    }

    function sendRequest() {
        if (!isExist) {
            return;
        }
        const data = props.data || {};
        logEvent('Start refresh', { props: { ...data } });
        data.class = '\\common\\components\\TaskManager';
        abortRequest = rootApi.post('/task/schedule', toUrl(data)).then(successTask).catch(errorFn);
    }

    function successTask(resp) {
        logEvent('Get Task Id', { response: resp.data });
        if (isExist) {
            taskId = resp.data.id;
            TASKS.push(taskId);
            setTimerRequest();
            appStorage.set(lsKey, resp.data.id);
        }
    }

    function setTimerRequest() {
        timer = setTimeout(getUpdate, props.timeout || 1300);
    }

    function getUpdate() {
        rootApi
            .get('/task/status', { params: { id: taskId } })
            .then(success)
            .catch(errorFn);
    }

    function success(resp) {
        if (!isExist) {
            return;
        }

        clearTimeout(timer);
        timer = null;
        process.env.REACT_APP_ENV !== 'prod' && debugLog('Refresh response', { status: resp.data.status });
        switch (resp.data.status) {
            case STATUS_ERROR:
                const error = resp.data;
                error.message = error.error || null;
                return errorFn(error);
            case STATUS_SUCCESS:
                return complete(resp.data);
            default:
                return setTimerRequest();
        }
    }

    function complete(resp) {
        logEvent('Refresh complete', { taskId, lsKey });
        appStorage.remove(lsKey);
        taskId = null;
        loading = false;
        if (typeof props.onComplete === 'function') {
            props.onComplete(resp);
        }
        try {
            const taskParams = new URLSearchParams(lsKey);
            const id = +taskParams.get('params[board_id]');
            if (id) {
                mainStore.boardsList.removeBoardTask(id);
                const board = mainStore.activeBoard;
                if (board && board.id === id) {
                    board.platformSync.syncLoadingClear();
                }
            }
        } catch (error) {
            console.error('Fail Task complete', error);
        }
    }

    function errorFn(error = null) {
        if (!isExist) {
            return;
        }
        appStorage.remove(lsKey);
        taskId = null;
        loading = false;

        if (typeof props.onFail === 'function') {
            props.onFail(error);
        }
        logEvent('Refresh fail');

        return error.response
            ? failRequest(error)
            : toast({
                  description: error.message,
                  color: 'tomato',
                  duration: 7000,
              });
    }

    if (taskId && timer === null && !isExist) {
        isExist = true;
        setTimerRequest();
    }

    return { run, clear, loading, check: setTimerRequest };
}
