import { runInAction } from 'mobx';

import api from 'api';

import { Idea } from 'store/models/Idea';
import { Issue } from 'store/models/Issue';

import failRequest from 'utils/failRequest';
import logEvent from 'utils/logEvent';
import toUrl, { VALUE_NULL } from 'utils/toUrl';

export async function updateImportFields({
    field = null,
    column = null,
    preview = null,
    save = null,
    delimiter = ',',
    target,
}) {
    const columns = this.importData.columns
        .filter((el) => el.field)
        .reduce((res, el) => {
            if (field && el.field.name === field.name && field.isUsed && !field.canBeMerged) {
                return res;
            }
            res[el.header] = el.field.name;
            return res;
        }, {});

    if (column) {
        columns[column.header] = field.name || VALUE_NULL;
    }

    const formData = {
        file: this.importData.file,
        columns,
        preview,
        import: save,
        delimiter,
        target,
    };

    runInAction(() => {
        this.importData.column = column;
        this.importData.loading = true;
    });

    try {
        const { data } = await api.put(`${this.apiEndpoint}/import/csv`, toUrl(formData));

        if (save) {
            runInAction(() => {
                this.importData = null;
                this.dialogImport = false;
            });
            logEvent('Import was successfully completed');
        } else {
            const Model = target === 'issues' ? Issue : Idea;
            const preview = data.preview.map(
                (el, i) => new Model({ ...el, id: i + 1, boardId: this.id, isPreview: true })
            );
            runInAction(() => {
                this.importData = { ...data, preview, loading: false };
            });
            logEvent('Update Import Fields', formData);
        }
    } catch (error) {
        failRequest(error);
    }
}
