import React, { forwardRef } from 'react';

import { styled } from 'stitches.config';

const StyledSpace = styled('div', {
    flexShrink: 0,

    variants: {
        width: {
            true: {
                display: 'inline-block',
            },
        },
    },
});

export const Space = forwardRef(({ width, height }, forwardedRef) => (
    <StyledSpace width={!!width} css={{ width, height, flexBasis: width || height }} ref={forwardedRef} />
));
