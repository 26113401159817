import { runInAction } from 'mobx';

import api from 'api';
import { failRequest, toUrl } from 'utils';

export async function update(object) {
    runInAction(() => {
        Object.entries(object).forEach(([field, value]) => {
            this[field] = value;
        });
    });

    try {
        const { data } = await api.put(this.apiEndpoint, toUrl(object));
        return data;
    } catch (error) {
        failRequest(error);
        return false;
    }
}
