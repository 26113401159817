import React from 'react';

import { observer } from 'mobx-react-lite';

import { Button } from 'components/Button';
import { CustomIconName } from 'components/CustomIcon';
import Dot from 'components/Dot';

function DictionaryMultiSelectActiveItems({ list = [], onRemove }) {
    return list.map((el) => (
        <Button
            as="span"
            canRemove
            border
            size={24}
            key={el.id}
            leftElm={<Dot color={el.color} style={{ marginRight: 4 }} />}
            rightElm={<Button size={18} icon={CustomIconName.CROSS_M} minimal onClick={() => onRemove(el)} />}
        >
            {el.name}
        </Button>
    ));
}

export default observer(DictionaryMultiSelectActiveItems);
