import { runInAction } from 'mobx';

import api from 'api';

import failRequest from 'utils/failRequest';
import toUrl from 'utils/toUrl';

export async function updateDictItem({ item, dictionary, id, data }) {
    item &&
        data.color &&
        runInAction(() => {
            item.color = data.color;
        });

    try {
        await api.put(`${this.apiEndpoint}/${dictionary}/${id}`, toUrl(data));
        this.filter?.form && this.filter?.getForm();
    } catch (error) {
        failRequest(error);
    }
}
