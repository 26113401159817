export const NEW_THEME = {
    bg1: '$gray1',
    bg2: '$gray2',
    bg3: '$gray1',
    bg4: '$gray3',
    bg5: '$gray4',
    bg6: '$gray2',
    bg7: '$yellow3',
    bg8: '$gray2',

    tc1: '$gray12',
    tc2: '$gray11',
    tc3: '$gray1',

    lc1: '#0099ff',
    lc2: '#0088ff',
    lc3: '#0088ff',

    bc1: '$gray3',

    success: '$green9',
    warning: '$orange9',
    danger: '$red9',

    'control-checked-background': '$primary7',
    'control-background': '$primary7',

    'stripe-background': '$gray2',
    'stripe-background-open': '$gray3',
    'stripe-background-hover': '$gray2',
    'stripe-border': '$grayA4',
    'stripe-border-hover': '$grayA6',

    kanbanCardBg: '$grayA2',
    kanbanCardBgHover: '$grayA3',
    kanbanCardBgActive: '$grayA3',

    issueCardBg: '$bg2',

    btnColor: '$gray11',
    btnColorHover: '$gray12',
    btnColorActive: '$gray12',
    btnBg: '$gray3',
    btnBgHover: '$gray4',
    btnBgActive: '$gray5',
    btnBorder: '$gray4',
    btnBorderHover: '$gray6',

    'input-background': '$blackA1',
    'input-background-hover': '$blackA4',
    'input-background-focus': '$blackA5',
};
