function unsecuredCopyToClipboard(text) {
    const textArea = document.createElement('textarea');
    textArea.setAttribute('type', 'hidden');
    textArea.style.position = 'absolute';
    textArea.value = text;
    document.body.appendChild(textArea);
    textArea.select();
    try {
        document.execCommand('copy');
    } catch (err) {
        console.error('Unable to copy to clipboard', err);
    } finally {
        document.body.removeChild(textArea);
    }
}

export default async function copyToClipboard(text) {
    try {
        await window.navigator.clipboard.writeText(text);
    } catch (e) {
        unsecuredCopyToClipboard(text);
    }
}
