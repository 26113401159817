import { runInAction } from 'mobx';

import api from 'api';

import arrayMoveMutable from 'utils/arrayMoveMutable';
import { DICTIONARY_STATUSES_VOTING } from 'utils/consts';
import failRequest from 'utils/failRequest';
import toUrl from 'utils/toUrl';

export async function sortStatuses({ status, position }) {
    const postData = { position };

    runInAction(() => {
        arrayMoveMutable(this.ideaStatuses, status.position - 1, position - 1).forEach((item, index) => {
            item.position = index + 1;
        });
    });

    try {
        await api.post(`${this.apiEndpoint}/${DICTIONARY_STATUSES_VOTING}/${status.id}/shift `, toUrl(postData));
    } catch (e) {
        failRequest(e);
    }
}
