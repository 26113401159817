import api from 'api';
import { toUrl } from 'utils';

export async function countIdeaStatusTriggers({ platformId, issueStatusName, ideaStatusId, webhookId }) {
    const requestData = {
        platform_id: platformId,
        issue_status: issueStatusName,
        idea_status_id: ideaStatusId,
        organization_webhook_id: webhookId,
    };
    return api.post(`${this.apiEndpoint}/idea-status-triggers/count`, toUrl(requestData));
}
