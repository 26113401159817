import { runInAction } from 'mobx';

import restApi from 'api';
import { failRequest } from 'utils';

import { mainStore } from 'store/models/MainStore';

import { ColorsVariants } from 'atoms/Colors';

import getRandom from 'utils/getRandom';
import toUrl from 'utils/toUrl';

function updateFilterForm() {
    try {
        mainStore.activeModel.filter?.form && mainStore.activeModel.filter?.getForm();
    } catch {}
}

export async function updateDictItem({ item, dictionary, id, data }) {
    if (item && data.color && item.color !== data.color) {
        runInAction(() => {
            item.color = data.color;
        });
    }

    try {
        await restApi.put(`${dictionary}/${id}`, toUrl(data));
        updateFilterForm();
    } catch (error) {
        failRequest(error);
    }
}

export async function createDictItem({ dictionary, name, issue }) {
    const index = getRandom(ColorsVariants.length);
    const data = { name, color: ColorsVariants[index - 1] };

    try {
        const { data: value } = await restApi.post(`${dictionary}`, toUrl(data));
        issue?.setProps({ dictionary, value });
        updateFilterForm();
        return value;
    } catch (error) {
        failRequest(error);
    }
}

export async function removeDictItem({ dictionary, id, new_id = null }) {
    try {
        await restApi.delete(`${dictionary}/${id}`, { data: toUrl({ new_id }) });
        runInAction(() => {
            mainStore.activeModel.alertBox = null;
        });
        updateFilterForm();
    } catch (error) {
        if (error.response && error.response.status === 405) {
            runInAction(() => {
                mainStore.activeModel.alertBox = { dictionary, id };
            });
        } else {
            failRequest(error);
        }
    }
}
