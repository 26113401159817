import { autorun, makeAutoObservable, observable, when } from 'mobx';

import { storageApi } from 'api';
import { STORAGE } from 'constants/ApiPaths';
import { INSTANCE_PREFIX, IS_VOTING_BOARD } from 'constants/global';
import { switchTheme, THEMES, THEMES_IDS } from 'themes';
import { failRequest, sendToSentry } from 'utils';

import { mainStore } from 'store/models/MainStore';
import { ORG_DB_STORES } from 'store/updateOrgDB';

import { USER_ROLE } from 'utils/consts';
import logEvent from 'utils/logEvent';

import * as Api from './api/workspace';

function updateRootStyle(props, value) {
    if (!value) return;
    document.body.style.setProperty(props, value);
}

class Workspace {
    list = [];

    id = null;
    ideas_bots_settings = null;
    domains = [];
    invite_role = USER_ROLE.Member;
    invite_type = '';
    links = null;
    owner_id = null;
    name = '';
    public_id = '';
    public_voting_settings = {};
    voting_email_settings = {};

    disposer;
    parent;

    constructor(data, parent) {
        Object.assign(this, data);

        this.parent = parent;

        makeAutoObservable(this, {
            voting_email_settings: observable.struct,
            public_voting_settings: observable.struct,
            _links: observable.struct,
            domains: observable.struct,
        });

        if (IS_VOTING_BOARD) {
            autorun(() => {
                switchTheme(this.theme);
            });

            autorun(() => {
                const colors = this.mainColors;
                if (!colors) return;

                updateRootStyle('--colors-accent', colors.mainColor);
                updateRootStyle('--colors-accent-text', colors.secondaryColor);
            });
        } else {
            this.init();

            this.disposer = when(
                () => this.parent.ready && this.public_id,
                () => this.runSocket(),
            );
        }
    }

    runSocket() {
        this.disposer();
        this.parent.runSocket(this.public_id);
    }

    init = async () => {
        if (!IS_VOTING_BOARD) {
            await this.getFromStore();
        }
        await this.fetchList();
    };

    get mainColors() {
        if (!this.public_voting_settings) return null;
        return {
            mainColor: this.public_voting_settings.mainColor,
            secondaryColor: this.public_voting_settings.secondaryColor,
        };
    }

    getFromStore = async () => {
        try {
            const data = await mainStore.db.getListData({ fieldName: ORG_DB_STORES.workspaces });
            this.fillData(data);
        } catch (e) {
            sendToSentry('Fail get DB WS', e);
        }
    };

    fillData(workspaces) {
        const current = workspaces.find((workspace) => workspace.id === this.id);
        if (!current) return;

        Object.assign(this, current);
        this.fillList(workspaces);
    }

    fillList(workspaces) {
        this.list = workspaces
            .filter((workspace) => workspace.id !== this.id)
            .map((workspace) => ({
                id: workspace.id,
                name: workspace.name,
                owner_id: workspace.owner_id,
            }));
    }

    fetchList = async () => {
        try {
            const { data = [] } = await storageApi.get(STORAGE.workspaces);
            this.fillData(data);
            await mainStore.db.saveList(data, ORG_DB_STORES.workspaces);
        } catch (e) {
            failRequest(e);
        }
    };

    removeWorkspace = (itemId) => {
        if (this.id === itemId) {
            mainStore.db.dropDB();
            window.location.reload();
        } else {
            this.list = this.list.filter((workspase) => workspase.id !== itemId);
            mainStore.db.removeRowDB(itemId, ORG_DB_STORES.workspaces);
        }
    };

    addWorkspace = (data) => {
        if (this.list.some((workspace) => workspace.id === data.item.id)) {
            return;
        }
        this.list.push({
            id: data.item.id,
            name: data.item.name,
            owner_id: data.item.owner_id,
        });
        mainStore.db.updateRowDB(data.item, ORG_DB_STORES.workspaces);
    };

    updateWorkspace = (data) => {
        if (this.id === data.item.id) {
            Object.assign(this, data.item);
        } else {
            const index = this.list.findIndex((workspace) => workspace.id === data.item.id);
            this.list[index] = {
                id: data.item.id,
                name: data.item.name,
                owner_id: data.item.owner_id,
            };
        }
        mainStore.db.updateRowDB(data.item, ORG_DB_STORES.workspaces);
    };

    setInviteType = (invite_type) => {
        if (invite_type && this.invite_type !== invite_type) {
            logEvent('Change invite type', { invite_type });
            this.saveWorkspace({ invite_type });
        }
    };

    get publicName() {
        return this.public_voting_settings?.publicName || this.name || '';
    }

    get language() {
        if (!IS_VOTING_BOARD) {
            return 'en';
        }
        return this.languageSettings;
    }

    get languageSettings() {
        return this.public_voting_settings?.language ?? 'en';
    }

    /**
     * @return {THEMES_IDS}
     */
    get theme() {
        return this.public_voting_settings?.theme || THEMES_IDS.dark;
    }

    get isDarkTheme() {
        if (!IS_VOTING_BOARD) {
            return true;
        }
        return this.theme !== 2;
    }

    /**
     * @return {string}
     */
    get themeName() {
        return THEMES.get(this.theme)?.name ?? '';
    }

    get ducalisBranding() {
        if (!IS_VOTING_BOARD) {
            return true;
        }
        return this.public_voting_settings?.ducalis_branding ?? true;
    }

    get logo() {
        return this.public_voting_settings?.logo || this.public_voting_settings?.smallLogo;
    }

    get votingLink() {
        return `https://${this.votingDomain}`;
    }

    get votingDomain() {
        if (this.public_voting_settings.is_subdomain) {
            return `${this.public_voting_settings.subdomain}${INSTANCE_PREFIX ? '-' : '.'}${window.location.hostname}`;
        }
        return this.public_voting_settings.custom_domain;
    }

    setDomain = Api.setDomain;

    addDomain = Api.addDomain;

    deleteLogo = Api.deleteLogo;

    changeLogo = Api.changeLogo;

    changePublicName = Api.changePublicName;

    setColor = Api.setColor;

    removeDomain = Api.removeDomain;

    setVotingSettings = Api.setVotingSettings;

    setVotingEmailSettings = Api.setVotingEmailSettings;

    runDnsCheck = Api.runDnsCheck;

    setBotSettings = Api.setBotSettings;

    getSecret = Api.getSecret;

    saveWorkspace = Api.saveWorkspace;
}

export default Workspace;
