import { runInAction } from 'mobx';

import restApi from 'api';
import { failRequest, toUrl } from 'utils';

export async function saveWorkspace(requestData) {
    runInAction(() => {
        Object.assign(this, requestData);
    });

    try {
        await restApi.put(`workspaces/${this.id}`, toUrl(requestData));
    } catch (e) {
        failRequest(e);
    }
}
