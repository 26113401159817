import { CustomIconName } from 'components/CustomIcon';

export const VIEWS = {
    TOP: 'top',
    EVALUATION: 'ag',
    MATRIX: 'matrix',
    TOTAL_ALIGNMENT: 'total-alignment',
    USERS_ALIGNMENT: 'users-alignment',
    CRITERIA_ALIGNMENT: 'criteria-alignment',
    ALIGNMENT: 'alignment',
    QUESTIONS: 'questions',
    REPORT: 'report',
    IDEAS: 'ideas',
    VOTING: 'voting',
    SCORES: 'scores',
    FOCUS_MODE: 'focus-mode',

    ERROR_404: 404,
    ERROR_403: 403,
    DASHBOARD: 'dashboard',
    INTEGRATIONS: 'integrations',
    CONNECT_TRACKER: 'connect-tracker',
    SYNC: 'sync',
    TEMPLATES: 'templates',
    RELEASE_NOTES: 'release-notes',
    DESCRIPTION: 'description',

    PUBLIC_ANNOUNCES: 'public-announces',
    PUBLIC_ANNOUNCE: 'public-announce',
    PUBLIC_RELEASE_NOTES: 'public-release-notes',
    PUBLIC_RELEASE_NOTE: 'public-release-note',

    PROFILE: 'profile',
    NOTIFICATIONS: 'notifications',
    CRITERIA: 'criteria',
};
export const VIEWS_DATA = {
    [VIEWS.TOP]: { icon: CustomIconName.TOP_PRIORITY, title: 'Backlog' },
    [VIEWS.EVALUATION]: { icon: CustomIconName.EVALUATION, title: 'Evaluation', tooltip: 'Score issues here' },
    [VIEWS.IDEAS]: { icon: CustomIconName.LAMP, title: 'Voting Board' },
    [VIEWS.VOTING]: { icon: CustomIconName.LAMP, title: 'Voting Board' },
    [VIEWS.SCORES]: { icon: CustomIconName.SCORES, title: 'Scores', tooltip: 'All rated issues' },
    [VIEWS.QUESTIONS]: { icon: CustomIconName.QUESTIONS, title: 'Questions' },
    [VIEWS.MATRIX]: { icon: CustomIconName.MATRIX, title: 'Matrix' },
    [VIEWS.ALIGNMENT]: {
        icon: CustomIconName.ALIGN_TOTAL,
        title: 'Team Alignment',
    },
    [VIEWS.USERS_ALIGNMENT]: {
        icon: CustomIconName.ALIGN_USERS,
        title: 'Users Alignment',
        tooltip: 'See the differences of opinion by teammates',
    },
    [VIEWS.CRITERIA_ALIGNMENT]: {
        icon: CustomIconName.ALIGN_CRITERIA,
        title: 'Criteria Alignment',
        tooltip: 'See the differences of opinion in criteria',
    },
    [VIEWS.FOCUS_MODE]: { icon: CustomIconName.FOCUS_MODE, title: 'Focus Mode' },
    [VIEWS.ERROR_404]: { title: '404 - Not Found' },
    [VIEWS.ERROR_403]: { title: '403 - Forbidden' },
    [VIEWS.REPORT]: { title: 'Report' },
    [VIEWS.DASHBOARD]: { title: 'Dashboard' },
    [VIEWS.INTEGRATIONS]: { title: 'Integrations' },
    [VIEWS.CONNECT_TRACKER]: { title: 'Connect issue tracker' },
    [VIEWS.SYNC]: { title: 'Sync Settings' },
    [VIEWS.TEMPLATES]: { title: 'Prioritization Templates' },
    [VIEWS.RELEASE_NOTES]: { title: 'Release notes', icon: CustomIconName.CHANGELOG },
    [VIEWS.DESCRIPTION]: { title: 'Description', icon: CustomIconName.INFO },
    [VIEWS.PROFILE]: { title: 'Profile', icon: CustomIconName.PROFILE },
    [VIEWS.NOTIFICATIONS]: { title: 'Notifications', icon: CustomIconName.NOTIFICATIONS },
    [VIEWS.CRITERIA]: { title: 'Global Criteria Management', icon: CustomIconName.CRITERIA },
};

export const BOARD_PAGE_PATHNAME = {
    [VIEWS.TOP]: 'summary',
    [VIEWS.EVALUATION]: 'unvoted',
    [VIEWS.IDEAS]: 'public-ideas',
    [VIEWS.SCORES]: 'unvoted/scores',
    [VIEWS.QUESTIONS]: 'questions',
    [VIEWS.MATRIX]: 'matrix',
    [VIEWS.ALIGNMENT]: 'alignment',
    [VIEWS.USERS_ALIGNMENT]: 'alignment/users-alignment',
    [VIEWS.CRITERIA_ALIGNMENT]: 'alignment/criteria-alignment',
    [VIEWS.FOCUS_MODE]: 'focus-mode',
    [VIEWS.RELEASE_NOTES]: 'release-notes',
    [VIEWS.DESCRIPTION]: 'description',
};
