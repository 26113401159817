import React, { forwardRef } from 'react';

import { styled } from 'stitches.config';

import { CustomIcon, CustomIconName } from 'components/CustomIcon';
import Flex from 'components/Flex';
import Tooltip from 'components/Tooltip';

const StHelpIcon = styled('div', {
    opacity: 0,
    transition: 'opacity .2s',
});

const StMetaItem = styled(Flex, {
    '&:hover': {
        [StHelpIcon]: {
            opacity: 0.6,
        },
    },
});

function MetaItem({ title, children, inline, fillFree, tooltip, ...props }, forwardedRef) {
    const titleElm = title ? <div className="t-mutted">{title}</div> : null;

    const style = {};
    if (!inline) {
        style.height = 24;
        style.lineHeight = '24px';
    }

    return (
        <StMetaItem
            ref={forwardedRef}
            {...props}
            column={!inline}
            gap={8}
            align="none"
            css={{ minWidth: fillFree ? 'auto' : '41px !important' }}
        >
            {tooltip ? (
                <Flex gap={2}>
                    {titleElm}{' '}
                    <Tooltip contentClassName="limit limit-300" side="top" content={tooltip}>
                        <StHelpIcon as={CustomIcon} icon={CustomIconName.HELP_ALT} size={14} />
                    </Tooltip>
                </Flex>
            ) : (
                titleElm
            )}
            {inline ? children : <div style={style}>{children}</div>}
        </StMetaItem>
    );
}

export default forwardRef(MetaItem);
