import { action, makeAutoObservable, observable } from 'mobx';

import { CURRENT_USER_ID, IS_PUBLIC_BOARD } from 'constants/global';
import { VIEWS } from 'constants/Pages';
import { PROVIDER_DUCALIS } from 'constants/Providers';

import criteriaStore from 'store/models/CriteriaStore';
import dictionaryStore from 'store/models/DictionaryStore';
import { Idea } from 'store/models/Idea';
import { utilsStore } from 'store/models/UtilsStore';
import { votingComment } from 'store/models/VotingComment';

import { UNSAVED_MODEL_ID } from 'utils/consts';
import delay from 'utils/delay';
import { findIndexFrom, findLastIndexFrom } from 'utils/findIndexFrom';
import { sendToSentry } from 'utils/sentry';

import { Issue } from './Issue';
import IssuesData from './IssuesData';

export class IssuesList {
    /**
     * @type {Map<number, Idea>}
     */
    ideasIds = new Map();
    /**
     * @type {Map<string, Issue>}
     */
    issuesIds = new Map();
    /**
     * @type {Map<string, IssuesData>}
     */
    issuesData = new Map();
    loading = false;
    type = null;
    getNext = false;
    row = null;
    col = null;

    /**
     * @type {Issue|Idea}
     */
    activeIssue = null;
    questionIssue = null;
    hasQuery = false;
    params = {};

    collator = new Intl.Collator(undefined, { numeric: true, sensitivity: 'base' });

    parent;

    constructor(mainStore) {
        this.mainStore = mainStore;

        makeAutoObservable(this, {
            parent: false,
            setActiveIssue: action,
            setActiveIssueById: action,
            setIdeas: action,
            updateIdea: action,
            clearLoading: action,

            collator: false,
            issuesData: observable.shallow,
        });
    }

    get allIssues() {
        return Array.from(this.issuesIds.values());
    }

    get ideas() {
        return Array.from(this.ideasIds.values());
    }

    get hasActiveIssue() {
        return !!this.activeIssue?.id;
    }

    /**
     * @param {number=} board
     * @return {Set<string>}
     */
    getActiveIds(board) {
        if (!board) return new Set(Array.from(this.issuesData.keys()));
        return new Set(
            Array.from(this.issuesData.values())
                .filter((data) => board === data.boardId)
                .map((data) => `${data.id}-${data.boardId}`),
        );
    }

    /**
     * @param {[]} list
     * @param {number=} board
     */
    fillIssuesData(list, board) {
        const activeIds = this.getActiveIds(board);

        list.forEach((data) => {
            const key = `${data.id}-${data.boardId}`;
            if (this.issuesData.has(key)) {
                this.issuesData.get(key).fillModel(data, true);
                activeIds.delete(key);
            } else {
                this.issuesData.set(key, new IssuesData(data));
            }
        });

        activeIds.forEach((id) => this.issuesData.delete(id));
    }

    getInitialRow(count) {
        if (this.row) {
            return this.row < count ? this.row : count - 1;
        }
        return 0;
    }

    setIdeas(list) {
        list.forEach((el) => this.ideasIds.set(el.id, new Idea(el)));
    }

    clearIdeasByBoardId(boardId) {
        const removedIds = this.ideas.filter((idea) => String(idea.board_id) === String(boardId)).map((el) => el.id);
        removedIds.forEach((id) => this.ideasIds.delete(id));
    }

    updateIdea(data, isNew) {
        let idea = this.ideasIds.get(data.id);
        if (idea) {
            idea.set(data);
        } else if (isNew) {
            idea = new Idea(data);
            this.ideasIds.set(data.id, idea);
        }
        if (idea && this.activeIssue instanceof Idea && this.activeIssue?.id === idea.id) {
            this.setActiveIssue(idea);
        }
    }

    fillIdeas(list, boards, singleBoard) {
        if (!boards) return;

        const ideasIds = singleBoard
            ? this.ideas.filter((idea) => boards[0] === idea.board_id).map((idea) => idea.id)
            : Array.from(this.ideasIds.keys());
        const activeIds = new Set(ideasIds);

        list.forEach((data) => {
            if (activeIds.has(data.id)) {
                this.ideasIds.get(data.id).set(data);
                activeIds.delete(data.id);
            } else {
                this.ideasIds.set(data.id, new Idea(data));
            }
        });

        activeIds.forEach((id) => this.ideasIds.delete(id));
    }

    getIssuesIds(boardId) {
        if (boardId) {
            return new Set(this.allIssues.filter((el) => el.boardId !== boardId).map((el) => `${el.id}-${el.boardId}`));
        }
        return new Set(Array.from(this.issuesIds.keys()));
    }

    fillData(issues, boardId) {
        const activeIds = this.getIssuesIds(boardId);

        issues.forEach(async (data) => {
            const issueKey = `${data.id}-${data.boardId}`;
            if (this.issuesIds.has(issueKey)) {
                this.issuesIds.get(issueKey).fillModel(data);
                activeIds.delete(issueKey);
            } else {
                this.issuesIds.set(issueKey, new Issue(data));
            }
        });

        activeIds.forEach((issueKey) => {
            this.issuesIds.delete(issueKey);
        });
    }

    async createIssue(index) {
        if (this.issuesIds.has(UNSAVED_MODEL_ID)) return;

        const assignee_id =
            this.mainStore.activeBoard.filter && this.mainStore.activeBoard.filter.key === 'assignee.id'
                ? this.mainStore.activeBoard.filter.value
                : null;

        const issue = new Issue({
            id: UNSAVED_MODEL_ID,
            provider: PROVIDER_DUCALIS,
            tempid: Date.now(),
            boardId: this.mainStore.activeBoard.id,
            name: '',
            description: '',
            assignee_id: assignee_id || null,
            reporter_id: null,
            status_id: dictionaryStore.todoStatusId,
            type_id: dictionaryStore.defaultIssueTypeId,
            needFocus: true,
        });

        this.issuesIds.set(UNSAVED_MODEL_ID, issue);

        const activeIssue = this.issuesIds.get(UNSAVED_MODEL_ID);
        const row = index || this.mainStore.activeBoard.topPriorityListLength;

        this.changeFocus({ row, activeIssue });

        if (this.mainStore.page === VIEWS.EVALUATION) utilsStore.toggleCardHidden(false);
    }

    createIdea(statusId) {
        if (!CURRENT_USER_ID) {
            utilsStore.setHistoryAction({ create: null });
            return;
        }

        this.activeIssue = new Idea({
            id: UNSAVED_MODEL_ID,
            issue_id: null,
            tempId: Date.now(),
            boardId: this.mainStore.activeBoard.id,
            board_id: this.mainStore.activeBoard.id,
            allow_voting: IS_PUBLIC_BOARD,
            status_id: statusId || this.mainStore.activeBoard.reviewIdeaStatusId,
            name: '',
            description: '',
            author: this.mainStore.currentUser,
        });

        if (!IS_PUBLIC_BOARD) {
            this.activeIssue.save(this.activeIssue);
        } else {
            this.activeIssue.board.setUserVote(true, this.activeIssue.id, 1);
        }

        utilsStore.toggleCardHidden(false);
    }

    clearNavigation() {
        this.hasQuery = false;
        this.row = null;
        this.col = null;
        this.activeIssue = null;
    }

    changeFocus = ({ row, col, activeIssue, callback }) => {
        this.row = row;
        this.col = col;
        this.setActiveIssue(!activeIssue ? null : activeIssue);

        if (typeof callback === 'function') {
            callback();
        }
    };

    setActiveIssue(issue = null) {
        if (issue?.is_fake) return;

        if ((!issue && !this.activeIssue?.id) || this.activeIssue?.id !== issue?.id) {
            votingComment.clear();
        }
        if (!issue) {
            criteriaStore.setAlignCriterionId(null);
        }

        if (issue?.id > 0 && this.activeIssue?.id === UNSAVED_MODEL_ID) {
            this.clearUnsaved();
        }

        this.activeIssue = issue;
    }

    setActiveIssueById(issueId) {
        if (this.activeIssue?.id !== issueId && this.mainStore.activeModel?.issues) {
            this.activeIssue = this.mainStore.activeModel.issues.find((el) => el.id === Number(issueId));
        }
        return this.activeIssue;
    }

    setActiveVotingIssueById(issueId, isInit) {
        if (isInit && window.idea) {
            this.updateIdea(window.idea, true);
        }

        let idea = this.ideasIds.get(Number(issueId)) || null;

        if (!idea) this.activeIssue = null;

        if (idea?.parent_id) {
            this.activeIssue = this.mainStore.activeBoard.allIdeas.find((el) => el.id === idea.parent_id) || null;
        } else {
            this.activeIssue = idea;
        }
    }

    fetchIssue({ issueId, boardId }) {
        let issue = this.issuesIds.get(`${issueId}-${boardId}`);
        if (!issue) {
            sendToSentry('Not found issue', { issueId, boardId });
            return false;
        }
        if (issue.isDone) {
            sendToSentry('Try Open Done Issue', { id: issue.id });
            return false;
        }
        const row = this.mainStore.activeModel.topPriorityList.findIndex((el) => el.id === issue.id);
        this.changeFocus({ row, activeIssue: issue });
        delay(100).then(() => utilsStore.toggleCardHidden(false));
        return issue;
    }

    setQuestion({ issue, row = null }) {
        if (issue) {
            this.activeIssue = issue;
        }
        this.questionIssue = issue;
        this.row = row || this.row;
    }

    clearUnsaved() {
        this.issuesIds.delete(UNSAVED_MODEL_ID);
    }

    updateSingle(data, boardId, oldId = -1) {
        const needUpdateActiveIssue =
            [oldId, data.id].includes(this.activeIssue?.id) && this.activeIssue?.boardId === boardId;

        const issueDataKey = `${data.id}-${boardId}`;
        let issue = this.issuesIds.get(issueDataKey);

        if (issue) {
            issue.fillModel(data);
        } else {
            issue = new Issue({ ...data, boardId });
            this.issuesIds.set(issueDataKey, issue);
            if (!this.issuesData.has(issueDataKey)) {
                this.issuesData.set(issueDataKey, new IssuesData({ boardId }));
            }
        }

        if (needUpdateActiveIssue) {
            issue.tempid = this.activeIssue?.tempid;
            issue.needFocus = this.activeIssue?.needFocus;
            this.activeIssue = issue;
        }
    }

    removeSingle(issueId, boardId) {
        const issueKey = `${issueId}-${boardId}`;

        if (this.activeIssue && issueId === this.activeIssue.id && this.activeIssue.boardId === boardId) {
            this.setActiveIssue(null);
        }

        this.issuesIds.delete(issueKey);
        this.issuesData.delete(issueKey);

        // Clear from DB
        this.mainStore.db.removeRowDB([boardId, issueId], 'issues');
    }

    removeIssuesByBoard(boardId) {
        if (this.activeIssue?.boardId === boardId) {
            this.setActiveIssue(null);
        }

        const removeIssues = this.allIssues
            .filter((issue) => issue.boardId === boardId)
            .map((issue) => `${issue.id}-${issue.boardId}`);
        removeIssues.forEach((issueKey) => {
            this.issuesIds.delete(issueKey);
            this.issuesData.delete(issueKey);
        });
    }

    removeSingleVotingIssue(ideaId) {
        const removingIdea = this.ideasIds.get(ideaId);
        if (!removingIdea) return;

        // change activeIssue to next row
        if (ideaId === this.idea?.id) {
            const boardIdeas = this.ideas.filter(
                (idea) => idea.id !== ideaId && removingIdea.board_id === idea.board_id && !idea.parent_id,
            );
            const nextIndex = this.getInitialRow(boardIdeas.length);
            const newIssue = boardIdeas[nextIndex];
            this.changeFocus({ activeIssue: newIssue || null });
        }

        // Clear from collection
        this.ideasIds.delete(ideaId);
    }

    getIdeaById(ideaId) {
        return this.ideasIds.get(ideaId);
    }

    get uniqIssues() {
        const uniqMap = new Map();
        this.allIssues.forEach((issue) => {
            if (issue.id !== UNSAVED_MODEL_ID) uniqMap.set(issue.id, issue.uuid);
        });

        return Array.from(uniqMap.values())
            .map((uuid) => this.issuesIds.get(uuid))
            .filter((el) => el)
            .sort((a, b) => this.collator.compare(a.name, b.name));
    }

    /**
     * @return {Issue|null}
     */
    get issue() {
        if (this.activeIssue instanceof Idea) {
            return this.activeIssue.parentIssue;
        }
        return this.activeIssue;
    }

    /**
     * @return {Idea|null}
     */
    get idea() {
        if (!this.activeIssue) return null;

        if (this.activeIssue instanceof Idea) {
            return this.activeIssue;
        }

        return this.activeIssue.ideas.find((idea) => idea.board_id === this.activeIssue.boardId);
    }

    findNormalIssue(issue) {
        return issue && !issue.isRemoved;
    }

    findNextPrevNotRemovedIssue(list, index = 0, findLast = false) {
        if (findLast) {
            return findLastIndexFrom(list, this.findNormalIssue, index);
        }
        return findIndexFrom(list, this.findNormalIssue, index);
    }
}
