import React from 'react';

import { observer } from 'mobx-react-lite';

import { FORMULA_RICE, getFormulaNameByKey } from 'constants/Formula';

import { mainStore } from 'store/models/MainStore';

import Tooltip from 'components/Tooltip';

export const TotalScoreTitle = observer(({ disabled }) => {
    if (mainStore.activeBoard) {
        const formula = mainStore.activeBoard?.settings?.formula || FORMULA_RICE;

        const boardFormula = `The formula used to calculate: ${getFormulaNameByKey(formula)}`;

        return (
            <Tooltip disabled={disabled} content={boardFormula}>
                <span className="ReactVirtualized__Table__headerTruncatedText">Score</span>
            </Tooltip>
        );
    }

    return (
        <Tooltip disabled={disabled} content="Total scores for issues">
            <span className="ReactVirtualized__Table__headerTruncatedText">Score</span>
        </Tooltip>
    );
});
