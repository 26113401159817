import { makeAutoObservable } from 'mobx';

import { IS_IFRAME_MY_IDEAS, IS_IFRAME_MY_VOTES } from 'constants/global';

export class KanbanFilter {
    labels = [];
    sub = IS_IFRAME_MY_IDEAS ? 'my-ideas' : IS_IFRAME_MY_VOTES ? 'my-votes' : null;
    board = null;
    filterLabels = new Set();

    constructor(board) {
        makeAutoObservable(this, { board: false });

        this.labels = board.ideaLabels.slice().sort((a, b) => (a.name > b.name ? 1 : -1));
        this.board = board;
    }

    clearAll = () => {
        this.filterLabels.clear();
        this.sub = null;
    };

    setFilterLabels = (labelId) => {
        this.sub = null;
        if (this.filterLabels.has(labelId)) {
            this.filterLabels.delete(labelId);
        } else {
            this.filterLabels.clear();
            this.filterLabels.add(labelId);
        }
    };

    setSub(sub) {
        this.filterLabels.clear();
        this.sub = sub;
    }

    get query() {
        return this.board.query;
    }

    get filterIds() {
        return Array.from(this.filterLabels.values());
    }

    get userFilter() {
        switch (this.sub) {
            case 'my-ideas':
                return { author: 1 };
            case 'my-votes':
                return { voter: 1 };
            default:
                return null;
        }
    }

    get request() {
        if (this.filterCounter === 0 && this.query.length < 2 && !this.sub) {
            return null;
        }
        const userFilter = this.userFilter || {};
        return {
            labels: this.filterIds.join(','),
            name: this.query,
            ...userFilter,
        };
    }

    get filterCounter() {
        let count = 0;
        this.sub && count++;
        return count + this.filterLabels.size;
    }
}
