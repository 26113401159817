import React from 'react';

import * as DropdownMenuPrimitive from '@radix-ui/react-dropdown-menu';

import { utilsStore } from 'store/models/UtilsStore';

import openChat from 'utils/openChat';

import { AnchorButton, Button } from 'components/Button';
import { CustomIconName } from 'components/CustomIcon';
import { DropdownMenu, DropdownMenuItem } from 'components/DropdownMenu';
import HotkeysHelper from 'components/Hotkeys';
import QuickStartLink from 'components/QuickStartLink';

const BUTTONS_PROPS = {
    block: true,
    minimal: true,
    css: { outline: 'none !important' },
};

const HelpMenuContent = () => {
    return (
        <>
            <HotkeysHelper />
            <DropdownMenuPrimitive.Item asChild>
                <AnchorButton
                    target="_blank"
                    href="https://help.ducalis.io/en/ "
                    text="Help Center"
                    icon={CustomIconName.BOOK}
                    {...BUTTONS_PROPS}
                />
            </DropdownMenuPrimitive.Item>
            <DropdownMenuPrimitive.Item asChild>
                <QuickStartLink {...BUTTONS_PROPS} />
            </DropdownMenuPrimitive.Item>
            <DropdownMenuPrimitive.Item asChild>
                <AnchorButton
                    target="_blank"
                    href="https://help.ducalis.io/en/articles/4231408-data-protection"
                    text="Data Protection"
                    icon={CustomIconName.SHIELD}
                    {...BUTTONS_PROPS}
                />
            </DropdownMenuPrimitive.Item>
            {window.$crisp && (
                <DropdownMenuItem onClick={openChat} icon={CustomIconName.COMMENT} text="Chat to support" />
            )}
        </>
    );
};

export default function HelpMenu({ text }) {
    return (
        <DropdownMenu
            content={<HelpMenuContent />}
            side="right"
            align="end"
            onOpenChange={utilsStore.toggleTabNav}
            onCloseAutoFocus={(e) => e.preventDefault()}
        >
            <Button minimal size={34} icon={CustomIconName.HELP} text={text} />
        </DropdownMenu>
    );
}
