import { observer } from 'mobx-react-lite';

import usePaywallUpgrade from 'hooks/usePaywallUpgrade';

import { mainStore } from 'store/models/MainStore';

import { Button } from 'components/Button';
import { CustomIconName } from 'components/CustomIcon';
import Flex from 'components/Flex';
import HelpArticleLink from 'components/HelpArticleLink';
import YouTube from 'components/YouTube';

import { PAYWALLS } from './constants';
const PaywallPopoverContent = ({ paywall }) => {
    const handleClick = usePaywallUpgrade(paywall);
    const paywallData = PAYWALLS[paywall];

    function openPlans(e) {
        e.preventDefault();
        handleClick();
    }

    let textAction;
    let subText;
    if (mainStore.organization.paymentRequired) {
        textAction = 'Add billing info';
    } else if (mainStore.organization.canFree) {
        textAction = 'Try this feature';
        subText = 'for free';
    } else {
        textAction = 'Upgrade';
        subText = 'to unlock this feature';
    }

    if (!paywallData) {
        return (
            <>
                <b className="interactive t-intent-yellow" onClick={openPlans}>
                    {textAction}
                </b>
                {subText ? ` ${subText}` : ''}
            </>
        );
    }

    let text;
    if (paywallData.link) {
        text = (
            <>
                "
                <HelpArticleLink article={paywallData.link} className="t-w-sb" data-btn="none">
                    {paywallData.name}
                </HelpArticleLink>
                " {paywallData.text}
            </>
        );
    } else {
        text = (
            <>
                "<span className="t-w-sb">{paywallData.name}</span>" {paywallData.text}
            </>
        );
    }

    return (
        <Flex align="none" column gap={12} className="limit limit-300" css={{ paddingBottom: 4, paddingTop: 4 }}>
            <div>{text}</div>
            {paywallData.youtube && <YouTube active videoId={paywallData.youtube} link="Watch how it works" />}
            {paywallData.img && <img alt={paywallData.name} src={paywallData.img} />}
            {paywallData.video && (
                <video autoPlay loop muted playsInline>
                    <source src={paywallData.video} type="video/mp4" />
                </video>
            )}
            <div>
                {mainStore.currentUser?.isAdmin ? (
                    <Button
                        onClick={openPlans}
                        border
                        block
                        center
                        icon={CustomIconName.UPGRADE}
                        color="primary"
                        text={`${textAction}${subText ? ` ${subText}` : ''}`}
                    />
                ) : (
                    'Disable for current plan.'
                )}
            </div>
        </Flex>
    );
};

export default observer(PaywallPopoverContent);
