import React, { forwardRef } from 'react';

import { SERVICE_NAME } from 'constants/global';

import { AnchorButton } from 'components/Button';
import { CustomIconName } from 'components/CustomIcon';

function QuickStartLink(props, ref) {
    return (
        <AnchorButton
            ref={ref}
            block
            minimal
            icon={CustomIconName.VIDEO}
            target="_blank"
            href="https://youtu.be/fCWUjRoTBoI"
            text={`${SERVICE_NAME} Quick Start`}
            {...props}
        />
    );
}

export default forwardRef(QuickStartLink);
