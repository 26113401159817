import { runInAction } from 'mobx';

import api from 'api';
import { REST } from 'constants/ApiPaths';

import failRequest from 'utils/failRequest';
import logEvent from 'utils/logEvent';
import toUrl from 'utils/toUrl';

export async function setColor(colors) {
    runInAction(() => {
        Object.entries(colors).forEach(([key, value]) => (this.public_voting_settings[key] = value));
    });

    logEvent('Color change');

    try {
        await api.put(REST.workspace.votingSettings, toUrl(colors));
    } catch (e) {
        failRequest(e);
        await Promise.reject(e);
    }
}
