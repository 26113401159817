import { getBooleanRequestParam } from 'utils/getRequestParam';
import getSessionId from 'utils/getSessionId';
import pageInIframe from 'utils/pageInIframe';

export const APP_VERSION = process.env.REACT_APP_VERSION || window.version;
export const INSTANCE_PREFIX = window.instancePrefix || '';
export const SERVICE_NAME = INSTANCE_PREFIX ? 'PrioPlan' : 'Ducalis';
export const SERVICE_NAMESPACE = INSTANCE_PREFIX ? 'prioplan' : 'ducalis';
export const SERVICE_DOMAIN_NAME = INSTANCE_PREFIX ? 'Prioplan.app' : 'Ducalis.io';
export const DB_NAME = INSTANCE_PREFIX || 'ducalis_';
export const ENV = process.env.REACT_APP_ENV || window.env || 'localhost';
export const IS_DEV =
    process.env.REACT_APP_ENV === 'dev' ||
    process.env.REACT_APP_ENV === 'localhost' ||
    process.env.REACT_APP_ENV === 'stage';
export const CURRENT_USER_ID = window.uID || (window.isWidgetUser ? -1 : undefined);
export const ANALYTICS_CURRENT_USER_ID = `${INSTANCE_PREFIX}${CURRENT_USER_ID}`;
export const IS_WIDGET_USER = window.isWidgetUser || false;
export const CURRENT_USER = window.user;
export const CURRENT_USER_IS_ADMIN = window.isA || false;
export const CURRENT_USER_IS_OWNER = window.isOwner || false;
export const CURRENT_ORG_ID = window.oId || null;
export const CURRENT_ORG_PUBLIC_ID = window.opId || null;
export const AMPLITUDE_KEY = window.amplKey || null;
export const IS_PUBLIC_BOARD = !!window.publicBoard || !!window.votingWorkspace;
export const IS_VOTING_BOARD =
    IS_PUBLIC_BOARD && window.votingWorkspace && (!window.publicBoard || window.publicBoard.stage === 'voting');
export const IS_SHARING_BOARD = IS_PUBLIC_BOARD && !IS_VOTING_BOARD;
export const MAIN_SITE_URL = window.mainSiteUrl || 'https://ducalis.io';
export const IS_WEB_BOT = window.isBot || false;
export const DOMAIN_NAME = window.location.hostname.split('.').slice(-2).join('.');
export const REMOVE_NOFOLLOW_LINK = IS_VOTING_BOARD && !document.querySelector('[content="noindex,nofollow"]');
export const FREE_DOWNGRADE_TYPEFORM_ID = window.freeDowngradeSurveyId;
export const ANY_DOWNGRADE_TYPEFORM_ID = window.anyDowngradeSurveyId;
export const KEYCLOAK_AUTH = window.keycloakAuth || false;
export const ADFS_AUTH = window.adfsAuth || false;
export const DISABLE_SURVEY = window.disableSurvey || false;
export const ALLOW_AI = window.allowAI || false;
export const SSO_LOGIN_ONLY = window.ssoLoginOnly || false;
export const SELF_HOSTED = window.selfHosted || false;
export const ALLOW_RELATIVE_URLS = window.allowEditorRelUrls || false;
export const WORKSPACE_ID = window.wsId || false;

// EMBED BOARD + WIDGET
export const IS_IFRAME =
    (IS_VOTING_BOARD && !getBooleanRequestParam('preview') && (getBooleanRequestParam('iframe') || pageInIframe())) ||
    false;

export const IS_IFRAME_TRANSPARENT = (IS_IFRAME && getBooleanRequestParam('transparent')) || false;
export const IS_IFRAME_CUSTOM_AUTH = (IS_IFRAME && getBooleanRequestParam('customAuth')) || false;
export const IS_IFRAME_WIDGET = (IS_IFRAME && getBooleanRequestParam('widget')) || false;
export const IS_IFRAME_NEW_IDEA = (IS_IFRAME && getBooleanRequestParam('newIdea')) || false;
export const IS_IFRAME_MY_IDEAS = (IS_IFRAME && getBooleanRequestParam('myIdeas')) || false;
export const IS_IFRAME_MY_VOTES = (IS_IFRAME && getBooleanRequestParam('myVotes')) || false;
export const DUCALIS_SESSION_ID = (IS_IFRAME && getSessionId()) || null;

// use Keycloak for user management
export const DISABLE_USERS_MANAGER = window.keycloakDataOnly || false;

// Open remote page for auth on Voting board
export const REMOTE_AUTH_PAGE = window.keycloakEnabled || false;

// When 404 on subdomain
export const MAIN_404_PAGE = window.is404?.isDomain404 || false;
