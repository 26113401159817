import { useEffect, useRef } from 'react';

import delay from 'utils/delay';

const useScrollTo = (scrollTo, block = 'start', behavior = 'smooth') => {
    const ref = useRef(null);

    useEffect(() => {
        scrollTo && delay(300).then(() => ref.current?.scrollIntoView({ behavior, block }));
    }, [ref, scrollTo, block, behavior]);

    return ref;
};

export default useScrollTo;
