import React from 'react';

import { DateTime } from 'luxon';
import { observer } from 'mobx-react-lite';

import { mainStore } from 'store/models/MainStore';
import paymentPlans from 'store/models/PaymentPlans';

import { StPriceTableTotal } from 'atoms/StPriceTableTotal';

import { Total } from 'components/SubscriptionsPlans/Total';

const MainPlanTotal = () => {
    const next_payment_on = mainStore.organization.payment_subscription?.next_payment_on;

    const total = (
        <>
            <StPriceTableTotal>
                <Total />
            </StPriceTableTotal>{' '}
            <span>{paymentPlans.activePlan?.amount_interval_text}</span>
        </>
    );

    const isPayNow = mainStore.organization.trialDaysLeft > 0 || paymentPlans.hasUpgradePlan;
    const nextPayDate = !isPayNow && next_payment_on;

    return (
        <div className="t-r">
            {total}
            {nextPayDate && <p>Next charge is on {DateTime.fromISO(next_payment_on).toFormat('DD')}</p>}
            {isPayNow && <p>Pay Now</p>}
        </div>
    );
};

export default observer(MainPlanTotal);
