import { styled } from 'stitches.config';

import Flex from 'components/Flex';

export const StPriceCalcWrapper = styled(Flex, {
    position: 'sticky',
    top: 0,
    minWidth: 300,
    width: 300,
    background: '$yellow6',
    borderRadius: 8,
    marginBottom: 24,
    variants: {
        disabled: {
            true: {
                pointerEvents: 'none',
                '& > *': {
                    opacity: 0.6,
                },
            },
        },
    },
});
