import React from 'react';

import formatPriceWithCurrency from 'utils/formatPriceWithCurrency';

import Flex from 'components/Flex';

export default function CalcPlanName({ active, planM, quantity }) {
    if (!active && !planM) return null;
    const plan = planM || active;

    const currencyCode = plan?.currency;

    const price =
        active.billing_scheme === 'tiered'
            ? formatPriceWithCurrency({
                  currencyCode,
                  price: quantity > planM.tiers[0].up_to ? null : planM.tiers[0].flat_amount,
              })
            : `${quantity} users × ${formatPriceWithCurrency({ currencyCode, price: plan?.amount })}`;

    return (
        <Flex spaceBetween>
            <span>{plan?.name} plan</span>
            <b>{price}</b>
        </Flex>
    );
}
