/**
 * Convert data Object to Array for Select dropdown
 *
 * @param {Object|Array} data - fields data for select list
 * @returns [Object]
 */
export function convertDataToArray(data) {
    if (!data) return [];

    if (Array.isArray(data)) {
        return data.map((item, index) => {
            if (typeof item === 'object') return item;
            return { id: String(index), name: item };
        });
    }

    return Object.entries(data)
        .map(([id, name]) => {
            if (name === null) return null;
            if (typeof name.name !== 'undefined') {
                return { id, ...name };
            }
            return { id, name };
        })
        .filter((el) => el);
}
