import React from 'react';

import { observer } from 'mobx-react-lite';

import Tooltip from 'components/Tooltip';

import { ChecklistItem } from '../IssueChecklists/ChecklistItem';

export const SubTask = observer(({ subTask }) => {
    if (!subTask) return null;

    const rightElement = subTask.status ? (
        <Tooltip content="Status" side="top" align="start">
            <span className="t-crop">{subTask.status}</span>
        </Tooltip>
    ) : null;

    return <ChecklistItem item={subTask} rightElement={rightElement} />;
});
