import { observer } from 'mobx-react-lite';

import CellWrapper from 'components/CellWrapper';
import Tooltip from 'components/Tooltip';

import HiddenContent from '../HiddenContent/HiddenContent';

export const ScoreCell = observer(({ issue }) => {
    if (issue?.board?.hideScores) {
        return (
            <CellWrapper>
                <HiddenContent revealAt={issue.board.revealDateString} />
            </CellWrapper>
        );
    }

    return (
        <Tooltip
            side="right"
            content={
                <>
                    <ScoreCellTooltipTitle issue={issue} />
                    <ScoreCellTooltipFormula issue={issue} />
                    <ScoreCellTooltipContentTable issue={issue} />
                </>
            }
        >
            <CellWrapper isNumber>{issue.totalValue}</CellWrapper>
        </Tooltip>
    );
});

const ScoreCellTooltipContentTable = observer(({ issue }) => {
    if (!issue.boardCriteriaScores.length) return null;

    return (
        <div className="table-wrpp">
            <table className="cr-table">
                <tbody>
                    {issue.boardCriteriaScores.map((criterionScore) => (
                        <CriterionScoreRow criterionScore={criterionScore} issue={issue} key={criterionScore.id} />
                    ))}
                </tbody>
            </table>
        </div>
    );
});

const ScoreCellTooltipFormula = observer(({ issue }) => {
    if (
        !issue.totalValue &&
        issue.board.settings.formula === 'wsjf' &&
        issue.voting_percent &&
        issue.voting_percent < 100
    ) {
        return (
            <>
                <p>
                    The task lacks an ‘effort’ score, but your formula divides by it.
                    <br />
                    Update the formula or rate by ‘effort’.
                </p>
            </>
        );
    }

    return null;
});
const ScoreCellTooltipTitle = observer(({ issue }) => {
    const evaluationStatus = issue.all_voted
        ? 'Task is evaluated by all teammates'
        : 'Task is not evaluated yet by all teammates';

    return <p>{evaluationStatus}</p>;
});

const CriterionScoreRow = observer(({ criterionScore }) => {
    return (
        <tr>
            <td>{criterionScore.name}</td>
            <td className="t-r table-numbers t-nw">{criterionScore.value}</td>
        </tr>
    );
});
