import { runInAction } from 'mobx';

import api from 'api';
import { REST } from 'constants/ApiPaths';

import failRequest from 'utils/failRequest';
import logEvent from 'utils/logEvent';
import toUrl, { VALUE_NULL } from 'utils/toUrl';

export async function setDomain(objData) {
    logEvent('Change Domain Settings', objData);

    runInAction(() => {
        this.public_voting_settings = { ...this.public_voting_settings, ...objData, error: null };
    });

    const data = Object.entries(objData).reduce((res, [key, value]) => {
        res[key] = !value && value !== false ? VALUE_NULL : value;
        return res;
    }, {});

    try {
        await api.put(REST.workspace.votingSettings, toUrl(data));
    } catch (e) {
        if (e?.response?.status === 422) {
            runInAction(() => {
                this.public_voting_settings.error = e.response.data.map(({ message }) => message).join('. ');
            });
        } else {
            failRequest(e);
        }
    }
}
