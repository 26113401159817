export function getUrlByPage({ headers, url }) {
    const maxCount = +headers['x-pagination-page-count'];
    let page = 1;
    let urls = [];
    while (maxCount > page++) {
        urls.push(`${url}?page=${page}`);
    }
    return urls;
}

export function getPages({ headers }) {
    const maxCount = (headers && +headers['x-pagination-page-count']) || 0;
    let page = 1;
    let pages = [];
    while (maxCount > page++) {
        pages.push(page);
    }
    return pages;
}
