import React from 'react';

import { observer } from 'mobx-react-lite';

import Avatar from 'components/Avatar';
import Tooltip from 'components/Tooltip';

import { TooltipContent } from './TooltipContent';

function AvatarTooltip({ user, tooltipProps, size = 50, hideStatus = false, onClick }) {
    return (
        <Tooltip side="top" {...tooltipProps} content={<TooltipContent user={user} />}>
            <Avatar
                onClick={onClick ? () => onClick(user) : undefined}
                user={user}
                size={size}
                hideStatus={hideStatus}
            />
        </Tooltip>
    );
}

export default observer(AvatarTooltip);
