export const PROVIDER_JIRA = 'jira';
export const PROVIDER_JIRA_CUSTOM = 'jira_custom';
export const PROVIDER_TRELLO = 'trello';
export const PROVIDER_ASANA = 'asana';
export const PROVIDER_DUCALIS = 'ducalis';
export const PROVIDER_YT = 'youtrack';
export const PROVIDER_YT_CUSTOM = 'youtrack_custom';
export const PROVIDER_CLICKUP = 'clickup';
export const PROVIDER_LINEAR = 'linear';
export const PROVIDER_GITHUB = 'github';
export const PROVIDER_KAITEN = 'kaiten';

export const isJira = (provider) => provider === PROVIDER_JIRA || provider === PROVIDER_JIRA_CUSTOM;
export const isYT = (provider) => provider === PROVIDER_YT || provider === PROVIDER_YT_CUSTOM;

export const YT_PLATFORM = window.loadedPlatforms && window.loadedPlatforms.find((el) => isYT(el.provider));

export const PLATFORM_CREATION_YT = (YT_PLATFORM && YT_PLATFORM.id) || null;
export const PLATFORM_CREATION_ID = window.loadedPlatforms && window.loadedPlatforms.map((el) => el.id);
