import React from 'react';

import { EditableText } from '@blueprintjs/core';

import { StTitle, StTitleWrapper } from 'atoms/StTitle';

import { PAYWALL_LIMITS } from 'utils/consts';

import { WithPaywallRule } from 'components/WithPaywallRule/WithPaywallRule';

const ComponentUseFull = (props) => (
    <StTitleWrapper css={{ padding: '2px 6px' }}>
        <StTitle as={EditableText} {...props} ref={props.inputRef} />
    </StTitleWrapper>
);

const ComponentBlocked = ({ defaultValue }) => <EditableText isEditing fill defaultValue={defaultValue} disabled />;
export const EditItemName = WithPaywallRule(PAYWALL_LIMITS.VOTING_CUSTOM_STATUSES, {
    ComponentUseFull,
    ComponentBlocked,
});
