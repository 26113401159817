import { runInAction } from 'mobx';

import api from 'api';
import { REST } from 'constants/ApiPaths';

import failRequest from 'utils/failRequest';
import logEvent from 'utils/logEvent';
import toUrl from 'utils/toUrl';

export async function removeDomain(domain) {
    logEvent('Remove invite domain', { domain });

    runInAction(() => {
        this.domains = this.domains.filter((el) => el !== domain);
    });

    try {
        await api.delete(REST.workspace.domain, {
            data: toUrl({ domain }),
        });
    } catch (error) {
        failRequest(error);
        runInAction(() => {
            this.domains = [...this.domains, domain];
        });
    }
}
