import { PAYWALL_LIMITS } from 'utils/consts';

import { Button } from 'components/Button';
import RemoveTooltip from 'components/DictionaryValueSelect/DictionaryColorsMenu/RemoveStatusTooltips';
import Tooltip from 'components/Tooltip';
import { WithPaywallRule } from 'components/WithPaywallRule/WithPaywallRule';

const ComponentBlocked = ({ icon, text }) => <Button minimal size={28} block icon={icon} text={text} disabled />;
export const ComponentUseFull = ({ isSystem, systemStatus, name, isIdeaStatus, count, ...props }) => {
    const removeBtn = <Button minimal size={28} block {...props} />;

    if (isSystem) {
        return (
            <Tooltip
                side="right"
                contentClassName="limit limit-300"
                content={<RemoveTooltip isDone={systemStatus === 'done'} name={name} isIdeaStatus={isIdeaStatus} />}
            >
                {removeBtn}
            </Tooltip>
        );
    } else if (count === 1) {
        return <Tooltip content="You can't remove the last item">{removeBtn}</Tooltip>;
    }

    return removeBtn;
};

export const RemoveItemBtn = WithPaywallRule(PAYWALL_LIMITS.VOTING_CUSTOM_STATUSES, {
    ComponentUseFull,
    ComponentBlocked,
});
