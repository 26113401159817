import React from 'react';

import { observer } from 'mobx-react-lite';

import { utilsStore } from 'store/models/UtilsStore';

import { StFinalScoreTable } from 'atoms/StFinalScoreTable';

import AverageScore from './components/AverageScore';
import CriteriaValues from './components/CriteriaValues';
import FinalScoreTooltip from './components/FinalScoreTooltip';
import FormulaTooltip from './components/FormulaTooltip';
import SlimButton from './components/SlimButton';
import { FinalScoreTableHeader } from './FinalScoreTableHeader';
import FinalScoreTableSuperScore from './FinalScoreTableSuperScore';

export const ScoreTable = observer(({ issue }) => {
    if (!issue.board) return null;

    const limitUsers = utilsStore.settings.limitUsers;
    const criteria = issue.board.scoreTableCriteria;
    const currentUserCriteriaIds = issue.board.currentUserCriteria.map(({ id }) => id);
    let users;
    if (limitUsers) {
        users = issue.board.activeUsersWithCurrentUserCriteria;
    } else {
        users = issue.board.activeUsersWithCriteria;
    }

    const body = users.map((user) => (
        <CriteriaValues
            limit={limitUsers}
            key={user.id}
            criteria={criteria}
            issue={issue}
            currentUserCriteriaIds={currentUserCriteriaIds}
            user={user}
        />
    ));

    return (
        <div className="b-oax">
            <StFinalScoreTable className="bp5-html-table--fixedleft">
                <thead>
                    <tr>
                        <SlimButton currentUserInBoard={issue.board.currentUserInBoard} />
                        <FinalScoreTableHeader criteria={criteria} />
                    </tr>
                </thead>
                <tbody>
                    <tr style={{ fontWeight: 600 }}>
                        <td>
                            <FinalScoreTooltip board={issue.board}>
                                <span className="t-nw">
                                    {utilsStore.settings.alignmentSlim ? 'FS' : 'Final scores'}
                                </span>
                            </FinalScoreTooltip>
                        </td>
                        <FinalScoreTableSuperScore votes={issue.externalData?.finalVotes} criteria={criteria} />
                    </tr>
                    <tr style={{ fontWeight: 600 }}>
                        <td>
                            <FormulaTooltip board={issue.board} isSlim={utilsStore.settings.alignmentSlim} />
                        </td>
                        <AverageScore issue={issue} criteria={criteria} />
                    </tr>
                </tbody>
                <tfoot>{body}</tfoot>
            </StFinalScoreTable>
        </div>
    );
});

ScoreTable.displayName = 'ScoreTable';
