import { observer } from 'mobx-react-lite';

import paymentPlans from 'store/models/PaymentPlans';

const CalcDescription = () => {
    return (
        <div className="typography">
            <ul>
                {paymentPlans.descriptions.map((el, index) => (
                    <li key={index}>{el}</li>
                ))}
            </ul>
        </div>
    );
};

export default observer(CalcDescription);
