import { makeAutoObservable, toJS } from 'mobx';

import { mainStore } from 'store/models/MainStore';

import { FILTER_TYPES, FILTER_RULES } from 'utils/consts';
import isEmptyValue from 'utils/isEmptyValue';

function isEmpty(value) {
    return (
        isEmptyValue(value) || value?.length === 0 || (Array.isArray(value) && value.filter((el) => el).length === 0)
    );
}

function getIssueFieldValueLikeArray(value) {
    if (typeof value === 'string' || typeof value === 'number') return [String(value)];
    if (!value) return [];
    return value.map((el) => String(el));
}

export class FilterField {
    type = 'string';
    name = '';
    value = null;
    rule = null;
    votingField = null;
    ducalis_field = null;
    dataType = 'string';
    fieldType = '';
    criterion_id = undefined;
    label = '';
    provider = undefined;

    constructor(field) {
        if (field.value === undefined) {
            switch (field.type) {
                case FILTER_TYPES.DATE:
                case FILTER_TYPES.DATETIME:
                    field.value = null;
                    break;
                case FILTER_TYPES.LIST:
                case FILTER_TYPES.USER_LIST:
                    field.value = [];
                    break;
                case FILTER_TYPES.INT:
                    field.value = '0';
                    break;
                default:
                    field.value = '';
                    break;
            }
        }

        this.fillProps(field);

        makeAutoObservable(this);
    }

    get isActive() {
        if (FILTER_TYPES.CHECKBOX === this.type || [FILTER_RULES.empty, FILTER_RULES.notEmpty].includes(this.compare)) {
            return true;
        }
        return this.value !== null && String(this.value).length > 0;
    }

    get compare() {
        return this.rule || this.defaultRule;
    }

    get defaultRule() {
        switch (this.type) {
            case FILTER_TYPES.LIST:
            case FILTER_TYPES.USER_LIST:
                if (![FILTER_TYPES.LIST, FILTER_TYPES.USER_LIST].includes(this.dataType)) {
                    return FILTER_RULES.is;
                }
                return FILTER_RULES.any;
            case FILTER_TYPES.DATE:
            case FILTER_TYPES.DATETIME:
                return FILTER_RULES.before;
            case FILTER_TYPES.INT:
                return FILTER_RULES.is;
            case FILTER_TYPES.STRING:
                return FILTER_RULES.contains;
            default:
                return FILTER_RULES.is;
        }
    }

    get dateValue() {
        const day = new Date();
        day.setDate(day.getDate() - this.value);
        return day;
    }

    get isUserField() {
        return [FILTER_TYPES.USER_LIST, FILTER_TYPES.USER].includes(this.dataType);
    }

    getRulesByType(type, value = []) {
        switch (type) {
            case FILTER_TYPES.LIST:
            case FILTER_TYPES.USER_LIST:
                if (![FILTER_TYPES.LIST, FILTER_TYPES.USER_LIST].includes(this.dataType)) {
                    return [
                        {
                            label: value.length > 1 ? 'is either of' : 'is',
                            value: FILTER_RULES.is,
                        },
                        {
                            label: 'is not',
                            value: FILTER_RULES.not,
                        },
                        {
                            label: 'is empty',
                            value: FILTER_RULES.empty,
                        },
                        {
                            label: 'not empty',
                            value: FILTER_RULES.notEmpty,
                        },
                    ];
                }
                return [
                    {
                        label: value.length > 1 ? 'includes any' : 'includes',
                        value: FILTER_RULES.any,
                    },
                    {
                        label: value.length > 1 ? 'includes all' : 'includes',
                        value: FILTER_RULES.all,
                        useful: value.length > 1,
                    },
                    {
                        label: value.length > 1 ? 'includes neither' : 'does not include',
                        value: FILTER_RULES.neither,
                    },
                    {
                        label: 'does not include all',
                        value: FILTER_RULES.notAll,
                        useful: value.length > 1,
                    },
                    {
                        label: 'is empty',
                        value: FILTER_RULES.empty,
                    },
                    {
                        label: 'not empty',
                        value: FILTER_RULES.notEmpty,
                    },
                ];
            case FILTER_TYPES.DATE:
            case FILTER_TYPES.DATETIME:
                return [
                    {
                        label: 'before',
                        value: FILTER_RULES.before,
                    },
                    {
                        label: 'after',
                        value: FILTER_RULES.after,
                    },
                    {
                        label: 'less than days ago',
                        value: FILTER_RULES.lt,
                    },
                    {
                        label: 'more than days ago',
                        value: FILTER_RULES.gt,
                    },
                    {
                        label: 'is empty',
                        value: FILTER_RULES.empty,
                    },
                    {
                        label: 'not empty',
                        value: FILTER_RULES.notEmpty,
                    },
                ];
            case FILTER_TYPES.INT:
                return [
                    { value: FILTER_RULES.is, label: 'Equal to' },
                    { value: FILTER_RULES.gt, label: 'Greater than' },
                    { value: FILTER_RULES.lt, label: 'Less than' },
                    { value: FILTER_RULES.gte, label: 'Greater than or equal to' },
                    { value: FILTER_RULES.lte, label: 'Less than or equal to' },
                    { value: FILTER_RULES.not, label: 'Not equal to' },
                    {
                        label: 'is empty',
                        value: FILTER_RULES.empty,
                    },
                    {
                        label: 'not empty',
                        value: FILTER_RULES.notEmpty,
                    },
                ];
            case FILTER_TYPES.STRING:
                return [
                    {
                        label: 'contains',
                        value: FILTER_RULES.contains,
                    },
                    {
                        label: 'does not contain',
                        value: FILTER_RULES.notContains,
                    },
                    {
                        label: 'is empty',
                        value: FILTER_RULES.empty,
                    },
                    {
                        label: 'not empty',
                        value: FILTER_RULES.notEmpty,
                    },
                ];
            default:
                return [
                    {
                        label: value.length > 1 ? 'is either of' : 'is',
                        value: FILTER_RULES.is,
                    },
                    {
                        label: 'is not',
                        value: FILTER_RULES.not,
                    },
                    {
                        label: 'is empty',
                        value: FILTER_RULES.empty,
                    },
                    {
                        label: 'not empty',
                        value: FILTER_RULES.notEmpty,
                    },
                ];
        }
    }

    filterValues(issueFieldValue) {
        const empty = isEmpty(issueFieldValue);

        if (FILTER_RULES.empty === this.compare) {
            return empty;
        }

        if (empty) {
            return [FILTER_RULES.neither, FILTER_RULES.notAll, FILTER_RULES.not, FILTER_RULES.notContains].includes(
                this.compare,
            );
        }

        if (FILTER_RULES.notEmpty === this.compare) {
            return !empty;
        }

        switch (this.type) {
            case FILTER_TYPES.CHECKBOX:
                return +issueFieldValue === +this.value;
            case FILTER_TYPES.LIST:
            case FILTER_TYPES.USER_LIST:
                if (this.dataType === FILTER_TYPES.LIST || this.isUserField) {
                    const issueFieldValueArray = getIssueFieldValueLikeArray(issueFieldValue);

                    const fieldValue =
                        this.isUserField && this.value.includes('current_user_cuid')
                            ? this.value.map((item) =>
                                  item === 'current_user_cuid'
                                      ? [mainStore.currentUser.name, String(mainStore.currentUser.email).toLowerCase()]
                                      : String(item),
                              )
                            : this.value;

                    switch (this.compare) {
                        case FILTER_RULES.all:
                            return fieldValue.every((value) =>
                                Array.isArray(value)
                                    ? value.some((valueItem) => issueFieldValueArray.includes(valueItem))
                                    : issueFieldValueArray.includes(value),
                            );
                        case FILTER_RULES.any:
                            return fieldValue.flat().some((value) => issueFieldValueArray.includes(value));
                        case FILTER_RULES.neither:
                            return fieldValue.every((value) =>
                                Array.isArray(value)
                                    ? !value.some((valueItem) => issueFieldValueArray.includes(valueItem))
                                    : !issueFieldValueArray.includes(value),
                            );
                        case FILTER_RULES.is:
                            return fieldValue.flat().some((value) => issueFieldValueArray.includes(value));
                        case FILTER_RULES.not:
                            return !fieldValue.flat().some((value) => issueFieldValueArray.includes(value));
                        default:
                            // FILTER_RULES.notAll
                            return !fieldValue.every((value) =>
                                Array.isArray(value)
                                    ? value.some((valueItem) => issueFieldValueArray.includes(valueItem))
                                    : issueFieldValueArray.includes(value),
                            );
                    }
                }
                switch (this.compare) {
                    case FILTER_RULES.is:
                        return issueFieldValue && this.value.includes(String(issueFieldValue));
                    default:
                        // FILTER_RULES.not
                        return !issueFieldValue || !this.value.includes(String(issueFieldValue));
                }
            case FILTER_TYPES.STRING:
                const stringValue = String(this.value).toLowerCase();
                const stringIssueValue = String(issueFieldValue).toLowerCase();

                return this.compare === FILTER_RULES.contains
                    ? stringIssueValue.includes(stringValue)
                    : !stringIssueValue.includes(stringValue);

            case FILTER_TYPES.DATE:
            case FILTER_TYPES.DATETIME:
                if (!issueFieldValue) {
                    return false;
                }
                const dateTimeFieldValue = new Date(issueFieldValue);
                switch (this.compare) {
                    case FILTER_RULES.lt:
                        return dateTimeFieldValue > this.dateValue;
                    case FILTER_RULES.gt:
                        return dateTimeFieldValue < this.dateValue;
                    case FILTER_RULES.before:
                        return dateTimeFieldValue < new Date(this.value);
                    default:
                        return dateTimeFieldValue > new Date(this.value);
                }

            case FILTER_TYPES.INT:
                const intValue = Number(this.value);
                switch (this.compare) {
                    case FILTER_RULES.is:
                        return issueFieldValue === intValue;
                    case FILTER_RULES.gt:
                        return issueFieldValue > intValue;
                    case FILTER_RULES.lt:
                        return issueFieldValue < intValue;
                    case FILTER_RULES.gte:
                        return issueFieldValue >= intValue;
                    case FILTER_RULES.lte:
                        return issueFieldValue <= intValue;
                    default:
                        //FILTER_RULES.not
                        return issueFieldValue !== intValue;
                }
            default:
                return true;
        }
    }

    get rules() {
        return this.getRulesByType(this.type, this.value);
    }

    getJS() {
        const json = toJS(this);
        json.rule = json.rule || this.compare;
        return json;
    }

    fillProps(obj) {
        Object.entries(obj).forEach(([key, value]) => {
            if (key === 'value' && [FILTER_TYPES.LIST, FILTER_TYPES.USER_LIST].includes(this.type)) {
                this[key] = Array.isArray(value) ? value.map((el) => String(el)) : [];
            } else {
                this[key] = value === 'undefined' ? undefined : value;
            }
        });
    }
}
