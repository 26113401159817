import { makeAutoObservable, toJS } from 'mobx';

import { IS_VOTING_BOARD } from 'constants/global';
import { VIEWS } from 'constants/Pages';
import { logEvent } from 'utils';

import alertStore from 'store/models/Alert';
import { dialogCriteriaFormStore } from 'store/models/DialogCriteriaForm';
import { mainStore } from 'store/models/MainStore';

import appStorage, { APP_STORAGE_KEYS } from 'utils/AppStorage';

export class SettingsStore {
    path = '';
    routes = {};
    params = {};
    route = '';
    query = '';
    isOpen = false;

    constructor(routes) {
        this.routes = this.makeRoutes(routes);

        const storeData = appStorage.get(APP_STORAGE_KEYS.settings_path, false);
        if (storeData) {
            Object.assign(this, storeData);
        }

        makeAutoObservable(this);
    }

    /**
     * @return {Board|undefined}
     */
    get board() {
        if (this.route !== 'board' || !this.params.id) return undefined;
        return mainStore.boardsList.boardsIds.get(IS_VOTING_BOARD ? this.params.id : Number(this.params.id));
    }

    /**
     * @return {Report|undefined}
     */
    get report() {
        if (this.route !== 'report' || !this.params.id) return undefined;
        return mainStore.reportsIds.get(Number(this.params.id));
    }

    get title() {
        const title = [];
        if (this.activeModel) {
            title.push(this.activeModel?.name);
        }
        title.push(this.params?.page);
        this.params?.section && title.push(this.params?.section);

        return title.join(' / ');
    }

    get activeModel() {
        if (this.route === 'board') return this.board;
        if (this.route === 'report') return this.report;
        return undefined;
    }

    checkPageUrl = () => {
        const path = this.getPathFromPage();
        if (path) this.goToLink(path);
    };

    setQuery = (query) => {
        this.query = query;
    };

    get hasQuery() {
        return this.query.length > 0;
    }

    getPathFromPage = () => {
        if (window.is404) return undefined;

        const location = window.location;
        const searchParams = new URLSearchParams(location.search);

        const dialogPath = searchParams.get('dialog')?.toLowerCase();
        if (dialogPath && /^\/settings\//.test(dialogPath)) {
            return dialogPath.replace(/^\/settings/, '');
        }

        const boardId = location.pathname.match(/\/board\/(\d+)/);

        const settingsPath = searchParams.get('settings')?.toLowerCase();

        if (!boardId) {
            if (searchParams.has('settings')) {
                return `/${settingsPath}`;
            }
            return undefined;
        }

        if (window.location.hash === '#habit') {
            return `/board/${boardId}/sprint`;
        }

        if (searchParams.has('Domain')) {
            return `${boardId[0]}/voting/domain`;
        }

        if (settingsPath) {
            if (settingsPath === 'import') {
                return `${boardId[0]}/import-export`;
            }

            if (settingsPath === 'appearance') {
                return `/voting/${settingsPath}`;
            }

            if (mainStore.page === VIEWS.IDEAS || /public-ideas/.test(location.pathname)) {
                return `${boardId[0]}/voting/${settingsPath}`;
            }
            if (mainStore.page === VIEWS.TOP || /summary/.test(location.pathname)) {
                return `${boardId[0]}/sprint/${settingsPath}`;
            }
            if (mainStore.page === VIEWS.RELEASE_NOTES || /release-notes/.test(location.pathname)) {
                return `${boardId[0]}/voting/release-notes`;
            }
            return undefined;
        }

        if (searchParams.get('criteria') === 'formula') {
            return `${boardId[0]}/formula`;
        }
        if (searchParams.has('criteria')) {
            return `${boardId[0]}/criteria`;
        }
        if (searchParams.has('sync')) {
            return `${boardId[0]}/sync`;
        }
        if (searchParams.has('members')) {
            return `${boardId[0]}/users`;
        }
        if (searchParams.has('users')) {
            return `${boardId[0]}/users`;
        }
        return undefined;
    };

    getNames(value) {
        try {
            const regexp = new RegExp('(?<=\\/:)\\w+', 'g');
            return value.match(regexp) || [];
        } catch (e) {
            return [...value.matchAll(/\/:(\w+)/g)].map((el) => el[1]);
        }
    }

    makeRoutes(routes) {
        return Object.keys(routes).map((name) => {
            let value = routes[name];
            if (typeof value === 'string') {
                value = value.replace(/\/$/g, '') || '/';
                let names = this.getNames(value);
                let pattern = value
                    .replace(/[\s!#$()+,.:<=?[\\\]^{|}]/g, '\\$&')
                    .replace(/\/\\:\w+\\\?/g, '/?([^/]*)')
                    .replace(/\/\\:\w+/g, '/([^/]+)');

                return [
                    name,
                    RegExp('^' + pattern + '$', 'i'),
                    (...matches) =>
                        matches.reduce((params, match, index) => {
                            params[names[index]] = decodeURIComponent(match).toLowerCase();
                            return params;
                        }, {}),
                    value,
                ];
            } else {
                return [name, ...value];
            }
        });
    }

    goToLink(path = '') {
        if (this.hasUnsavedData(() => this.goToLink(path))) return;

        const data = this.parse(path);
        if (data) {
            logEvent('Open: Global Settings', data);

            this.path = data.path;
            this.params = data.params;
            this.route = data.route;

            appStorage.set(APP_STORAGE_KEYS.settings_path, data);

            this.isOpen = true;
        }
    }

    hasUnsavedData = (callbackFn) => {
        if (this.path === '/voting' && !!mainStore.workspace.public_voting_settings.error) {
            alertStore.confirm({
                message: mainStore.workspace.public_voting_settings.error,
                cancelButtonText: 'Ok',
                hideConfirm: true,
            });
            return true;
        }
        if (dialogCriteriaFormStore.criterion && dialogCriteriaFormStore.hasChanges) {
            alertStore.confirm({
                message: 'All unsaved changes will be lost. Are you sure you want to leave?',
                confirmButtonText: 'Don’t save and leave',
                cancelButtonText: 'Stay',
                onConfirm: () => {
                    dialogCriteriaFormStore.setCriterion(null);
                    callbackFn();
                },
            });
            return true;
        }
        dialogCriteriaFormStore.criterion && dialogCriteriaFormStore.setCriterion(null);
        return false;
    };

    onClose = () => {
        if (this.hasUnsavedData(this.onClose)) return;
        logEvent('Close: Global Settings', { path: this.path, params: toJS(this.params), route: this.route });

        this.isOpen = false;
        this.query = '';

        if (window.location.search) {
            const url = `${window.location.origin}${window.location.pathname}`;
            window.history.replaceState({ url }, '', url);
        }
    };

    parse = (path) => {
        path = path.replace(/\/($|\?)/, '$1') || '/';

        let url = new URL(path, 'http://a');
        path = url.pathname;

        for (let [route, pattern, cb] of this.routes) {
            let match = path.match(pattern);
            if (match) {
                return { params: cb(...match.slice(1)), path, route };
            }
        }
    };

    isActive(path) {
        return this.path === path;
    }
}

export const settingsStore = new SettingsStore({
    page: '/:page/:section?',
    board: '/board/:id/:page/:section?',
    report: '/report/:id/:page',
});
