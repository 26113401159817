import { forwardRef } from 'react';

import Button from './Button';

export const AnchorButton = forwardRef(({ disabled, as, css, ...props }, forwardedRef) => {
    return (
        <Button
            role={null}
            type={null}
            aria-disabled={disabled}
            data-btn="true"
            {...props}
            as={disabled ? 'span' : as || 'a'}
            ref={forwardedRef}
            css={{ '>*': { pointerEvents: 'none' }, ...css }}
        />
    );
});

AnchorButton.displayName = 'AnchorButton';
