import { observer } from 'mobx-react-lite';

import criteriaStore from 'store/models/CriteriaStore';

import { StRewrittenCell } from 'atoms/StRewrittenCell';

import { CustomIcon, CustomIconName } from 'components/CustomIcon';
import Tooltip from 'components/Tooltip';

import RewrittenCriterionCellTooltip from './RewrittenCriterionCellTooltip';

function RewrittenCriterionCell({ rewritten, interactive, active }) {
    const cell = (
        <StRewrittenCell
            data-active={active}
            data-interactive={interactive}
            approved={rewritten.approved}
            onClick={interactive ? () => criteriaStore.setAlignCriterionId(rewritten.criterion_id) : undefined}
        >
            <div className="flex-grow" />
            {rewritten.comment ? (
                <CustomIcon icon={CustomIconName.COMMENT} />
            ) : rewritten.approved ? (
                <CustomIcon viewBox={24} icon={CustomIconName.CHECK_SEAL} />
            ) : null}
            <span>{rewritten.value}</span>
        </StRewrittenCell>
    );

    if (!interactive) return cell;

    return (
        <Tooltip
            disableHoverableContent={false}
            side="top"
            content={<RewrittenCriterionCellTooltip rewritten={rewritten} />}
        >
            {cell}
        </Tooltip>
    );
}

export default observer(RewrittenCriterionCell);
