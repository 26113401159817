import React from 'react';

import { observer } from 'mobx-react-lite';

import CellWrapper from 'components/CellWrapper';
import UserBlock from 'components/UserBlock';

export const IssueAssignee = observer(({ issue }) => {
    if (issue.isEditable) {
        return (
            <UserBlock
                simple
                issue={issue}
                minimal
                field="assignee"
                editable
                user={issue.assignee}
                platform={issue.platform}
            />
        );
    }
    return (
        <CellWrapper>
            <span className="bp5-text-overflow-ellipsis">{issue.assignee?.name || issue.assignee?.email || ' - '}</span>
        </CellWrapper>
    );
});
