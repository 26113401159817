import React from 'react';

import classNames from 'classnames';
import { observer } from 'mobx-react-lite';

import { styled } from 'stitches.config';

import { StCellWrapperBtnDropdown } from 'atoms/StCellWrapperBtnDropdown';

import { CustomIcon, CustomIconName } from 'components/CustomIcon';

import styles from './CellWrapper.module.sass';

const StCellWrapper = styled('div', {
    '&:hover': {
        [StCellWrapperBtnDropdown]: {
            button: {
                display: 'flex',
                opacity: 1,
            },
        },
    },
});

StCellWrapper.displayName = 'StCellWrapper';

function CellWrapper(
    {
        children,
        editable,
        button,
        className,
        fill,
        isButton,
        htmlProps = {},
        disabled = false,
        isNumber,
        showBtn,
        ...props
    },
    forwardedRef,
) {
    const classes = classNames(styles.wrapper, className, {
        [styles.fill]: fill,
        [styles.editable]: editable,
        [styles.wrapper__number]: isNumber,
    });

    if (isButton) {
        return (
            <button {...props} className={classes} {...htmlProps} ref={forwardedRef}>
                {children}
                <CustomIcon className={styles.chevron} icon={CustomIconName.CHEVRON_DOWN} size={14} />
            </button>
        );
    }

    const extraProps = {};
    if (button) {
        extraProps.role = 'button';
    }

    return (
        <StCellWrapper
            {...props}
            aria-disabled={disabled}
            className={classes}
            {...extraProps}
            {...htmlProps}
            ref={forwardedRef}
        >
            {children}
            {(button || showBtn) && <div className={styles.btn}>{button}</div>}
        </StCellWrapper>
    );
}

export default observer(React.forwardRef(CellWrapper));
