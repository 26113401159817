import { blue, mint, tomato, yellow } from '@radix-ui/colors';

export const POSITIONS_DO_IT = 'quick_wins';
export const POSITIONS_SCHEDULE_IT = 'major_projects';
export const POSITIONS_DELEGATE_IT = 'fill_ins';
export const POSITIONS_DONT_DO_IT = 'thankless_tasks';

export const MATRIX_TITLES = {
    [POSITIONS_DO_IT]: 'Quick Wins',
    [POSITIONS_SCHEDULE_IT]: 'Major Projects',
    [POSITIONS_DELEGATE_IT]: 'Fill-Ins',
    [POSITIONS_DONT_DO_IT]: 'Thankless Tasks',
};

export const MATRIX_EMOJI = {
    [POSITIONS_DO_IT]: '⚡️',
    [POSITIONS_SCHEDULE_IT]: '🗓',
    [POSITIONS_DONT_DO_IT]: '🗑',
    [POSITIONS_DELEGATE_IT]: '📦',
};

export const MATRIX_TIPS = {
    [POSITIONS_DO_IT]: 'High Value, Low Effort issues',
    [POSITIONS_SCHEDULE_IT]: 'High Value, High Effort issues',
    [POSITIONS_DELEGATE_IT]: 'Low Value, Low Effort issues',
    [POSITIONS_DONT_DO_IT]: 'Low Value, High Effort issues',
};

export const MATRIX_SETTINGS_DEFAULT = [
    POSITIONS_DO_IT,
    POSITIONS_SCHEDULE_IT,
    POSITIONS_DONT_DO_IT,
    POSITIONS_DELEGATE_IT,
].reduce((res, el) => {
    res[el] = { name: MATRIX_TITLES[el], emoji: MATRIX_EMOJI[el] };
    return res;
}, {});

export const QUADRANTS = [POSITIONS_DO_IT, POSITIONS_SCHEDULE_IT, POSITIONS_DELEGATE_IT, POSITIONS_DONT_DO_IT];

export const COLORS = {
    [POSITIONS_DO_IT]: [tomato.tomato1, tomato.tomato6, tomato.tomato11],
    [POSITIONS_SCHEDULE_IT]: [blue.blue1, blue.blue6, blue.blue11],
    [POSITIONS_DELEGATE_IT]: [yellow.yellow1, yellow.yellow6, yellow.yellow11],
    [POSITIONS_DONT_DO_IT]: [mint.mint1, mint.mint6, mint.mint11],
};
