import { makeAutoObservable, toJS } from 'mobx';

import api from 'api';
import restApi from 'api';
import { VIEWS } from 'constants/Pages';

import { IssuesFilter } from 'store/models/IssuesFilter';
import { mainStore } from 'store/models/MainStore';
import { ORG_DB_STORES } from 'store/updateOrgDB';

import arrayMoveMutable from 'utils/arrayMoveMutable';
import failRequest from 'utils/failRequest';
import logEvent from 'utils/logEvent';
import toUrl from 'utils/toUrl';

import { getFilterGrid } from 'components/TableFilter/getFilterGrid';

class Filters {
    searches = new Map();

    constructor() {
        makeAutoObservable(this);
    }

    setQuery = (query) => {
        this.query = query;
    };

    fillCollection(list) {
        if (this.searches.size === 0) {
            list.forEach((filter) => {
                this.searches.set(filter.id, filter);
            });
            mainStore.db.saveList(list, ORG_DB_STORES.filters);
            return;
        }
        const activeIds = new Set(Array.from(this.searches.keys()));

        list.forEach((filter) => {
            if (this.searches.has(filter.id)) {
                activeIds.delete(filter.id);
                Object.assign(this.searches.get(filter.id), filter);
            } else {
                this.searches.set(filter.id, filter);
            }
        });

        activeIds.forEach((id) => this.searches.delete(id));
        mainStore.db.saveList(list, ORG_DB_STORES.filters);
    }

    get globalFilters() {
        return Array.from(this.searches.values())
            .filter((filter) => filter.is_global)
            .sort((a, b) => a.position - b.position);
    }

    getGlobalFiltersByView(view) {
        return this.globalFilters.filter((filter) => filter.grid === view);
    }

    getBoardFilterByView(boardId, view) {
        const global = this.getGlobalFiltersByView(view);
        const local = Array.from(this.searches.values())
            .filter((filter) => filter.board_id === boardId && filter.grid === view)
            .sort((a, b) => a.position - b.position);

        return { global, local };
    }

    getReportFilter(reportId) {
        const global = this.getGlobalFiltersByView(VIEWS.TOP);
        const local = Array.from(this.searches.values())
            .filter((filter) => filter.report_id === reportId)
            .sort((a, b) => a.position - b.position);

        return { global, local };
    }

    onRemoveFilter = (filterId) => {
        this.searches.delete(filterId);
        mainStore.db.removeRowDB(filterId, ORG_DB_STORES.filters);
    };

    onAddFilter = (filter) => {
        if (this.searches.has(filter.id)) {
            Object.assign(this.searches.get(filter.id), filter);
        } else {
            this.searches.set(filter.id, filter);
        }
        mainStore.db.updateRowDB(filter, ORG_DB_STORES.filters);
    };

    onUpdateFilter = (filter) => {
        if (!this.searches.has(filter.id)) return;

        Object.assign(this.searches.get(filter.id), filter);
        const updatedFilter = this.searches.get(filter.id);
        mainStore.db.updateRowDB(toJS(updatedFilter), ORG_DB_STORES.filters);
    };

    saveFilterApi(isNew) {
        if (isNew) return restApi.post;
        return restApi.put;
    }

    saveFilter = async (filter) => {
        const isNew = !filter.id;

        const apiRequest = this.saveFilterApi(isNew);

        const requestData = filter instanceof IssuesFilter ? filter?.getClearJson() : filter;

        try {
            const { data } = await apiRequest(`search/save`, toUrl(requestData));

            filter.clearForm();

            if (isNew) {
                filter.setId(data.id);
            }

            this.onAddFilter(data);

            return data.id;
        } catch (e) {
            failRequest(e);
        }
    };

    /**
     * @param {Object} params
     * @param {string} params.grid
     * @param {boolean} params.is_global
     * @param {number=} params.report_id
     * @param {number=}params.board_id
     * @return {IssuesFilter}
     */
    createFilter = (params) => {
        logEvent('Click - Custom Filter', { ...params });
        const filter = new IssuesFilter({
            id: null,
            name: 'New Filter Name',
            fields: [],
            report_id: null,
            board_id: null,
            grid: VIEWS.TOP,
            ...params,
        });
        filter.getForm();
        return filter;
    };

    createGlobalFilter = () => {
        const grid = getFilterGrid(mainStore.page);
        const filter = filtersCollection.createFilter({ grid, is_global: true });
        mainStore.activeModel.setFilter(filter);
    };

    createLocalFilter = () => {
        const grid = getFilterGrid(mainStore.page);
        const filter = this.createFilter({
            board_id: mainStore.activeModel.isBoard ? mainStore.activeModel.id : null,
            report_id: !mainStore.activeModel.isBoard ? mainStore.activeModel.id : null,
            is_global: false,
            grid,
        });
        mainStore.activeModel.setFilter(filter);
    };

    togglePinned = (filter) => {
        filter.is_pinned = !filter.is_pinned;
        this.saveFilter(filter);
    };

    addUserToFilter = (filter) => {
        filter.favorites.push(mainStore.currentUser.id);
    };

    removeUserToFilter = (filter) => {
        filter.favorites = filter.favorites.filter((id) => id !== mainStore.currentUser.id);
    };

    getFiltersByFilter(filter) {
        if (filter.is_global) {
            return this.getGlobalFiltersByView(filter.grid);
        }
        if (filter.report_id) {
            return this.getReportFilter(filter.report_id).local;
        }
        if (filter.board_id) {
            return this.getBoardFilterByView(filter.board_id, filter.grid).local;
        }
        return undefined;
    }

    sortFilters = (movedItem, movingItem) => {
        const items = this.getFiltersByFilter(movedItem);
        const fromIndex = items.findIndex((filter) => filter.id === movedItem.id);
        const toIndex = items.findIndex((filter) => filter.id === movingItem.id);
        arrayMoveMutable(items, fromIndex, toIndex).forEach((item, index) => {
            item.position = index + 1;
        });
        return [movedItem.id, movedItem.position];
    };

    async shiftFilter(movedItem, movingItem) {
        const [filterId, position] = this.sortFilters(movedItem, movingItem);
        try {
            await restApi.put(`/search/shift`, toUrl({ position }), { params: { id: filterId } });
        } catch (e) {
            failRequest(e);
            this.sortFilters(movingItem, movedItem);
        }
    }

    async apiFavoriteFilterRequest(filter, isRemove) {
        const params = {};
        if (filter.board_id) {
            params.board_id = filter.board_id;
        } else {
            params.report_id = filter.report_id;
        }
        const data = toUrl({ id: filter.id });

        if (isRemove) {
            return api.delete('/search/favorite', { data, params });
        }
        return api.post('/search/favorite', data, { params });
    }

    async toggleFavoriteFilter(filter, isRemove) {
        isRemove ? this.removeUserToFilter(filter) : this.addUserToFilter(filter);

        try {
            await this.apiFavoriteFilterRequest(filter, isRemove);
        } catch (error) {
            failRequest(error);

            isRemove ? this.addUserToFilter(filter) : this.removeUserToFilter(filter);
        }
    }
}

const filtersCollection = new Filters();

export default filtersCollection;
