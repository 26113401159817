import { openDB } from 'idb';

export function openButRejectOnBlocking(name, version, upgrade, blocking) {
    return new Promise((resolve, reject) => {
        openDB(name, version, {
            upgrade,
            blocked() {
                reject(Error('Blocked'));
            },
            blocking,
        }).then(resolve, reject);
    });
}
