import { observer } from 'mobx-react-lite';
import { FormattedMessage } from 'react-intl';

import { getAuthButtonUrl } from 'utils/getAuthButtonUrl';

import { AnchorButton } from 'components/Button';

function RemoteAuthButton({ isLogin }) {
    const url = getAuthButtonUrl({ isLogin, authClient: 'keycloak' });

    return (
        <div style={{ maxWidth: 200, width: '100%', padding: '20px 0 6px', margin: '0 auto' }}>
            <AnchorButton
                center
                block
                border
                size={32}
                color="primary"
                href={url}
                text={<FormattedMessage id="auth.login.btn" />}
            />
        </div>
    );
}

export default observer(RemoteAuthButton);
