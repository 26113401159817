import { styled } from 'stitches.config';

export const StLogoCustomImage = styled('img', {
    objectFit: 'contain',
    objectPosition: 'left',
    maxWidth: '100%',

    variants: {
        full: {
            true: {
                maxHeight: 50,
            },
        },

        isHeader: {
            true: {
                '@tablet': {
                    maxWidth: '70%',
                    display: 'block',
                },
            },
        },
    },
});
