import { runInAction } from 'mobx';

import api from 'api';

import { DICTIONARY_LABELS_VOTING } from 'utils/consts';
import failRequest from 'utils/failRequest';
import toUrl from 'utils/toUrl';

export async function removeDictItem({ dictionary, id, new_id = null }) {
    try {
        await api.delete(`${this.apiEndpoint}/${dictionary}/${id}`, { data: toUrl({ new_id }) });
        runInAction(() => {
            this.alertBox = null;
        });
        this.filter?.form && this.filter?.getForm();
        if (dictionary === DICTIONARY_LABELS_VOTING) {
            this.announces.forEach((el) => el.updateLabels({ label: { id }, remove: true }));
        }
    } catch (error) {
        if (error.response && error.response.status === 405) {
            runInAction(() => {
                this.alertBox = { dictionary, id };
            });
        } else {
            failRequest(error);
        }
    }
}
