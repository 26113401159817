import { makeAutoObservable } from 'mobx';

import delay from 'utils/delay';

const defaultConfirmButtonText = 'OK';
const defaultCancelButtonText = 'Cancel';

class AlertStore {
    isOpen = false;

    /**
     * @type {null|Function}
     */
    confirmMethod = null;

    /**
     * @type {null|Function}
     */
    cancelMethod = null;

    confirmButtonColor = 'red';

    message = '';
    title = null;
    confirmButtonText = defaultConfirmButtonText;
    cancelButtonText = defaultCancelButtonText;

    hideConfirm = false;

    constructor() {
        makeAutoObservable(this);
    }

    close() {
        this.isOpen = false;

        this.message = '';
        this.title = null;
        this.confirmButtonText = defaultConfirmButtonText;
        this.cancelButtonText = defaultCancelButtonText;

        this.confirmMethod = null;
        this.cancelMethod = null;
        this.confirmButtonColor = 'red';

        this.hideConfirm = false;
    }

    onConfirm = () => {
        typeof this.confirmMethod === 'function' && this.confirmMethod();
        this.close();
    };

    onCancel = () => {
        typeof this.cancelMethod === 'function' && this.cancelMethod();
        delay(100).then(() => this.close());
    };

    confirm({
        message = '',
        onConfirm,
        onCancel,
        confirmButtonText = defaultConfirmButtonText,
        cancelButtonText = defaultCancelButtonText,
        confirmButtonColor,
        hideConfirm = false,
        title = null,
    }) {
        this.message = message;
        this.title = title;
        this.confirmButtonText = confirmButtonText;
        this.cancelButtonText = cancelButtonText;

        this.confirmMethod = onConfirm || this.confirmMethod;
        this.cancelMethod = onCancel || this.cancelMethod;

        this.confirmButtonColor = confirmButtonColor || this.confirmButtonColor;

        this.isOpen = true;
        this.hideConfirm = hideConfirm;
    }
}

const alertStore = new AlertStore();

export default alertStore;
