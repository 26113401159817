import { observer } from 'mobx-react-lite';

import { mainStore } from 'store/models/MainStore';

import { StCardStripeMainCounter, StCardStripeOpacityElement } from 'atoms/StCardStripe';

import { PAYWALL_LIMITS } from 'utils/consts';

import { CustomIcon, CustomIconName } from 'components/CustomIcon';
import Flex from 'components/Flex';
import Tooltip from 'components/Tooltip';
import UpgradePlanBtn from 'components/UpgradePlanBtn';
import VotingPercent from 'components/VotingPercent';

const TaskMetrics = ({ icon, value, tooltip }) => {
    const content = (
        <Flex gap={4}>
            <CustomIcon icon={icon} />
            <div style={{ lineHeight: '20px' }}>{value ?? '-'}</div>
        </Flex>
    );
    if (tooltip) {
        return (
            <Tooltip content={tooltip} side="top">
                {content}
            </Tooltip>
        );
    }

    return content;
};

export const BlockTitle = observer(({ issue }) => {
    const alignmentPywall = !mainStore.organization.hasPaymentPlan(PAYWALL_LIMITS.ALIGNMENT);
    const hideScores = issue.board?.hideScores;
    return (
        <>
            <Tooltip content="Priority Rank" side="top">
                <Flex gap={4} css={{ marginRight: 10 }}>
                    <CustomIcon icon={CustomIconName.HASHTAG} />
                    <StCardStripeMainCounter>{hideScores ? '-' : (issue.row_number ?? '-')}</StCardStripeMainCounter>
                </Flex>
            </Tooltip>
            <StCardStripeOpacityElement as={Flex} gap={18}>
                <TaskMetrics
                    tooltip="Total Score"
                    icon={CustomIconName.GRAPH}
                    value={hideScores ? '-' : issue.externalData?.total}
                />
                {alignmentPywall ? (
                    <UpgradePlanBtn paywall={PAYWALL_LIMITS.ALIGNMENT}>
                        <TaskMetrics icon={CustomIconName.ALIGNMENT} value={hideScores ? '-' : issue.alignment} />
                    </UpgradePlanBtn>
                ) : (
                    <TaskMetrics
                        tooltip="Criteria Alignment"
                        icon={CustomIconName.ALIGNMENT}
                        value={hideScores ? '-' : issue.alignment}
                    />
                )}
                <VotingPercent minimal issue={issue} />
            </StCardStripeOpacityElement>
        </>
    );
});
