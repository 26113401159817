import { CustomIconName } from 'components/CustomIcon';

export const CRITERION_TYPE = {
    effort: 'effort',
    value: 'value',
    ignore: 'ignore',
    multiplier: 'multiplier',
};

export const CRITERION_TYPE_NAME = {
    [CRITERION_TYPE.ignore]: 'Ignore',
    [CRITERION_TYPE.multiplier]: 'Multiplier',
    [CRITERION_TYPE.effort]: 'Effort',
    [CRITERION_TYPE.value]: 'Value',
};

export const CRITERION_TYPE_TOOLTIP = {
    [CRITERION_TYPE.ignore]: 'Ignore Criteria for Priority Score calculation',
    [CRITERION_TYPE.multiplier]: 'Multiplier Criteria for Priority Score calculation',
    [CRITERION_TYPE.effort]: `Effort — Decrease a task’s total score`,
    [CRITERION_TYPE.value]: `Value — Increase a task’s total score'`,
};

export const CRITERION_SCALE_TYPE = {
    range: 'range',
    series: 'series',
    positive: 'positive',
    percent: 'percent',
};

const CRITERION_SCALE_NAME = {
    [CRITERION_SCALE_TYPE.range]: 'Range',
    [CRITERION_SCALE_TYPE.series]: 'Series',
    [CRITERION_SCALE_TYPE.positive]: 'Positive',
    [CRITERION_SCALE_TYPE.percent]: 'Percent',
};

export const CRITERION_SCALE_ICON = {
    [CRITERION_SCALE_TYPE.range]: CustomIconName.RANGE,
    [CRITERION_SCALE_TYPE.series]: CustomIconName.SERIES,
    [CRITERION_SCALE_TYPE.positive]: CustomIconName.POSITIVE,
    [CRITERION_SCALE_TYPE.percent]: CustomIconName.PERCENT,
};

export const CRITERION_SCALES = [
    CRITERION_SCALE_TYPE.range,
    CRITERION_SCALE_TYPE.series,
    CRITERION_SCALE_TYPE.positive,
    CRITERION_SCALE_TYPE.percent,
].map((type) => ({ label: CRITERION_SCALE_NAME[type], id: type, icon: CRITERION_SCALE_ICON[type] }));

export const INITIAL_CRITERIA_STATE = {
    name: '',
    description: '',
    id: -1,
    type: undefined,
    is_custom: false,
    custom_platform_id: null,
    custom_field_name: null,
    scale_min: 1,
    scale_max: 4,
    scale_type: CRITERION_SCALE_TYPE.series,
    scale_series: '1,2,3,5,8,13,21',
};
