import { observer } from 'mobx-react-lite';

import { utilsStore } from 'store/models/UtilsStore';

import logEvent from 'utils/logEvent';

import { Button } from 'components/Button';
import { CustomIconName } from 'components/CustomIcon';
import { DropdownMenu, DropdownMenuItem } from 'components/DropdownMenu';
import Flex from 'components/Flex';
import Tooltip from 'components/Tooltip';

const FinalScorePopoverMenu = ({ currentUserInBoard }) => {
    function toggleSection() {
        !utilsStore.settings.alignmentSlim && logEvent('Slim Issue Alignment');
        utilsStore.updateSettings({ field: 'alignmentSlim', value: !utilsStore.settings.alignmentSlim });
    }

    function toggleUsersLimit() {
        logEvent('Toggle Users Limit', { value: !utilsStore.settings.limitUsers });
        utilsStore.updateSettings({ field: 'limitUsers', value: !utilsStore.settings.limitUsers });
    }

    return (
        <>
            <DropdownMenuItem
                onClick={toggleSection}
                text={utilsStore.settings.alignmentSlim ? 'Expand Users column' : 'Collapse Users column'}
                icon={utilsStore.settings.alignmentSlim ? CustomIconName.EXPAND_H : CustomIconName.COLLAPSE_LEFT}
            />
            <Tooltip
                content={
                    utilsStore.settings.limitUsers ? undefined : 'Show team members only in teams I also assigned to'
                }
            >
                <DropdownMenuItem
                    disabled={!currentUserInBoard}
                    onClick={toggleUsersLimit}
                    text={utilsStore.settings.limitUsers ? 'Display all board Members' : 'Display less board members'}
                    icon={!utilsStore.settings.limitUsers ? CustomIconName.PERSONAL : CustomIconName.TEAM_ALT}
                />
            </Tooltip>
        </>
    );
};

function SlimButton({ currentUserInBoard }) {
    return (
        <th width={utilsStore.settings.alignmentSlim ? 57 : undefined}>
            <Flex gap={4}>
                {!utilsStore.settings.alignmentSlim && <span className="flex-grow">Users</span>}
                <DropdownMenu align="start" content={<FinalScorePopoverMenu currentUserInBoard={currentUserInBoard} />}>
                    <Button minimal border size={20} icon={CustomIconName.SETTINGS} />
                </DropdownMenu>
            </Flex>
        </th>
    );
}

export default observer(SlimButton);
