import { makeAutoObservable, observable } from 'mobx';

import restApi from 'api';
import { failRequest } from 'utils';

const BannerPlacement = {
    top: 'top',
    left: 'left',
};

export class EducationBanners {
    bannersIds = new Map();
    activeBannersIds = new Map();

    abortController = null;

    mainStore;

    constructor(mainStore) {
        this.mainStore = mainStore;

        makeAutoObservable(this, {
            mainStore: false,
            abortController: false,

            bannersIds: observable.shallow,
            activeBannersIds: observable.shallow,
        });
    }

    get activeBanners() {
        return Array.from(this.activeBannersIds.values());
    }

    get topBanner() {
        return this.activeBoardBannersIds
            .map((id) => this.bannersIds.get(id))
            .find((banner) => banner?.placement === BannerPlacement.top);
    }

    get activeBoardBannersIds() {
        const boardId = this.mainStore.activeBoard?.id;
        return this.activeBanners
            .filter((item) => item.board_id === null || item.board_id === boardId)
            .map((item) => item.education_banner_id);
    }

    get activeBoardBanners() {
        return this.activeBoardBannersIds
            .map((id) => this.bannersIds.get(id))
            .filter((banner) => banner?.placement === BannerPlacement.left);
    }

    updateActiveBanner = (banner) => {
        this.activeBannersIds.set(banner.id, banner);
        if (!this.bannersIds.has(banner.education_banner_id)) {
            this.fetchBanners();
        }
    };

    removeActiveBanner = (banner) => {
        this.activeBannersIds.delete(banner.id);
    };

    updateEducationBanner = ({ item }) => {
        if (item.role && item.role !== this.mainStore.currentUser?.role) return;

        this.bannersIds.set(item.id, item);
    };

    removeEducationBanner = ({ item }) => {
        this.bannersIds.delete(item.id);
    };

    fillBannersCollection({ banners, activeBanners }) {
        if (banners) {
            banners.forEach((banner) => {
                if (!banner.role || banner.role === this.mainStore.currentUser?.role)
                    this.bannersIds.set(banner.id, banner);
            });
        }

        if (activeBanners) {
            activeBanners.forEach((el) => {
                this.activeBannersIds.set(el.id, el);
            });
        }
    }

    async fetchBanners() {
        if (this.abortController) {
            this.abortController.abort();
        }

        this.abortController = new AbortController();

        try {
            const { data } = await restApi('education-banners', {
                signal: this.abortController.signal,
            });
            this.fillBannersCollection({ banners: data });
        } catch (e) {}
    }

    async fetchAll() {
        try {
            const activeBannersResponse = await restApi('user/banners');
            if (activeBannersResponse.data?.length) {
                const bannersResponse = await restApi('education-banners');
                this.fillBannersCollection({
                    banners: bannersResponse.data,
                    activeBanners: activeBannersResponse.data,
                });
            }
        } catch (e) {
            failRequest(e);
        }
    }
}
