import api from 'api';
import { failRequest, toUrl } from 'utils';

export async function generateAIAnnounceName(abortController, sendDescription) {
    try {
        const requestData = sendDescription ? toUrl({ description: this.aiText }) : undefined;
        const { data } = await api.post(`/boards/${this.board.id}/announces/chatgpt-title`, requestData, {
            signal: abortController.signal,
            params: { idea_id: this.id },
        });
        if (data !== this.aiName) {
            this.fillWithInterval(data, 'aiName', abortController);
        }
    } catch (e) {
        failRequest(e);
        return e.name;
    }
}
