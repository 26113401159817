import React from 'react';

import { DateTime } from 'luxon';

import { AutoDigestTooltipContent } from 'pages/Board/ReleaseNotes/AutoDigestTooltipContent';
import { getStatusTooltip } from 'pages/Board/ReleaseNotes/getStatusTooltip';

import { mainStore } from 'store/models/MainStore';

import CellWrapper from 'components/CellWrapper';
import { CustomIcon, CustomIconName } from 'components/CustomIcon';
import Spinner from 'components/Spinner';
import Tooltip from 'components/Tooltip';

export const RELEASE_NOTE_STATUS = {
    WEBHOOK: 'webhook',
    DRAFT: 'draft',
    SCHEDULED: 'scheduled',
    PRE_SCHEDULED: 'pre_scheduled',
    IN_PROGRESS: 'in_progress',
    PUBLISHED: 'published',
    EMPTY: 'empty',
};

export const RELEASE_NOTE_TYPE = {
    INTRO: 'intro',
    CALL_TO_ACTION: 'call_to_action',
    MOST_UPVOTED: 'most_upvoted',
    ANNOUNCE: 'announce',
    STATUS: 'status',
};

export const STATUS_NAMES = {
    [RELEASE_NOTE_STATUS.WEBHOOK]: 'Webhook',
    [RELEASE_NOTE_STATUS.DRAFT]: 'Draft',
    [RELEASE_NOTE_STATUS.PUBLISHED]: 'Published',
    [RELEASE_NOTE_STATUS.EMPTY]: 'Empty',
    [RELEASE_NOTE_STATUS.PRE_SCHEDULED]: 'Pre-Scheduled',
    [RELEASE_NOTE_STATUS.SCHEDULED]: 'Scheduled',
    [RELEASE_NOTE_STATUS.IN_PROGRESS]: 'Sending In Progress',
};

export const STATUS_ICON = {
    [RELEASE_NOTE_STATUS.WEBHOOK]: <CustomIcon icon={CustomIconName.WEBHOOK} />,
    [RELEASE_NOTE_STATUS.DRAFT]: <CustomIcon icon={CustomIconName.CALENDAR_BLANK} />,
    [RELEASE_NOTE_STATUS.PUBLISHED]: <CustomIcon icon={CustomIconName.TICK_CIRCLE} />,
    [RELEASE_NOTE_STATUS.EMPTY]: <CustomIcon icon={CustomIconName.STOP} />,
    [RELEASE_NOTE_STATUS.PRE_SCHEDULED]: <CustomIcon icon={CustomIconName.SAND_CLOCK} />,
    [RELEASE_NOTE_STATUS.SCHEDULED]: <CustomIcon icon={CustomIconName.CALENDAR_TICK} />,
    [RELEASE_NOTE_STATUS.IN_PROGRESS]: <Spinner size={20} />,
};

export const COLUMNS = [
    {
        name: 'Name',
        id: 'title',
        width: 300,
        disableSort: true,
    },
    {
        name: (
            <Tooltip side="top" content={'Date/time when the release note will be sent'}>
                <div className="ReactVirtualized__Table__headerTruncatedText">Scheduled on</div>
            </Tooltip>
        ),
        id: 'schedule_date',
        width: 180,
        disableSort: true,
        format: (item) => {
            const date = item.schedule_date ? DateTime.fromISO(item.schedule_date) : null;
            return (
                <CellWrapper style={{ gap: 4 }}>
                    {date ? (
                        <>
                            {date.toFormat('DD')}
                            <span className="o-6">at</span>
                            {date.toFormat('t').toLowerCase()}
                        </>
                    ) : (
                        '-'
                    )}
                </CellWrapper>
            );
        },
    },
    {
        name: 'Status',
        id: 'status',
        width: 100,
        disableSort: true,
        format: (item) => {
            return (
                <CellWrapper>
                    <Tooltip side="top" content={getStatusTooltip(item)}>
                        <div className="t-crop">{STATUS_NAMES?.[item.status] ?? item.status}</div>
                    </Tooltip>
                    {item.status_message ? (
                        <Tooltip content={item.status_message}>
                            <CustomIcon icon={CustomIconName.HELP_ALT} className="o-4 o-4--hover" />
                        </Tooltip>
                    ) : null}
                </CellWrapper>
            );
        },
    },
    {
        name: 'Audience',
        id: 'audience',
        width: 75,
        disableSort: true,
        format: (item) => <CellWrapper isNumber>{item.audience}</CellWrapper>,
    },
    {
        name: 'Author',
        id: 'author_id',
        width: 140,
        disableSort: true,
        format: (item) => (
            <CellWrapper>
                {mainStore.users.usersIds.get(item.author_id)?.name || (
                    <Tooltip content={<AutoDigestTooltipContent />}>
                        <span className="t-crop">Auto Release Note</span>
                    </Tooltip>
                )}
            </CellWrapper>
        ),
    },
    {
        name: 'Date Range',
        id: 'date_range',
        width: 180,
        disableSort: true,
        format: (item) => (
            <CellWrapper>
                {DateTime.fromISO(item.time_frame_start).toFormat('MMM d')}
                <span style={{ padding: '0 4px' }}>-</span>
                {DateTime.fromISO(item.time_frame_end).toFormat('DD')}
            </CellWrapper>
        ),
    },
    {
        name: 'Created',
        id: 'created',
        width: 100,
        disableSort: true,
        format: (item) => <CellWrapper>{DateTime.fromISO(item.created).toFormat('DD')}</CellWrapper>,
    },
];
export const PERIOD = {
    week: 'Weekly',
    month: 'Monthly',
};
export const PERIOD_MANY = {
    week: 'weeks',
    month: 'months',
};
