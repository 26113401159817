import { runInAction } from 'mobx';

import api from 'api';

import failRequest from 'utils/failRequest';
import logEvent from 'utils/logEvent';
import toUrl from 'utils/toUrl';

export async function changeRole(user, role) {
    logEvent('CHANGE_USER_ROLE', { userId: user.id, role });

    runInAction(() => (user.role = role));

    try {
        await api.put(`/users/${user.id}/change-role`, toUrl({ role }));
    } catch (e) {
        failRequest(e);
    }
}
