import { CURRENT_USER_IS_ADMIN } from 'constants/global';

import * as crisp from 'utils/crisp';

export function trackCrispEvent(eventName, metadata) {
    if (CURRENT_USER_IS_ADMIN) {
        return;
    }
    crisp.sendSessionEvent(eventName, metadata, 'green');
}
