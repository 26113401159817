import { IS_VOTING_BOARD } from 'constants/global';

import { Idea } from 'store/models/Idea';

import DictionaryValueItem from 'components/DictionaryValueItem';
import Flex from 'components/Flex';

import CustomLabel from './CustomLabel';

export default function Labels({ withWrapper, labels, editable, issue, className, small, noWrap = false }) {
    if (issue.id === -1) {
        return null;
    }

    const isVoting = issue instanceof Idea;

    if (editable) {
        if (withWrapper) {
            return (
                <Flex gap={8} wrap>
                    <CustomLabel isVoting={isVoting} editable={editable} issue={issue} />
                </Flex>
            );
        }
        return <CustomLabel isVoting={isVoting} editable={editable} issue={issue} />;
    }

    if (!labels || labels.length === 0) {
        return null;
    }

    const list = labels.map((label, i) => (
        <DictionaryValueItem
            isVoting={isVoting}
            provider={issue.provider}
            tag
            {...label}
            key={i}
            isLabel
            small={small}
        />
    ));

    if (IS_VOTING_BOARD || isVoting) {
        if (withWrapper) {
            return (
                <Flex gap={8} wrap={!noWrap} className={className}>
                    {list}
                </Flex>
            );
        }
        return list;
    }

    return (
        <Flex gap={8} wrap>
            <span className="o-6">Labels:</span>
            {list}
        </Flex>
    );
}
