export const REST = {
    workspace: {
        domain: 'workspace/domain',
        revealAppSecret: 'workspace/reveal-app-secret',
        votingSettings: 'workspace/voting-settings',
        votingEmailSettings: 'workspace/voting-email-settings',
        votingEmailCheck: 'workspace/voting-email-check',
        botSettings: 'workspace/bot-settings',
        uploadLogo: 'workspace/upload-logo',
    },
    organization: {
        planMetrics: 'organization/plan-metrics',
        importNotify: 'organization/import-notify',
        votingEmailStats: 'organization/voting-email-stats',
        delete: 'organization/delete',
        change: 'organization/change',
        timezones: 'organization/timezones',
    },
};

export const STORAGE = {
    workspaces: 'workspaces',
};
