import { runInAction } from 'mobx';

import api from 'api';

import failRequest from 'utils/failRequest';
import logEvent from 'utils/logEvent';
import toUrl from 'utils/toUrl';

export async function setSettings(settings) {
    const requestData = toUrl(settings);

    runInAction(() => {
        Object.assign(this.settings, settings);
    });
    logEvent('Board change Settings', { settings });

    try {
        await api.put(`${this.apiEndpoint}/settings`, requestData);
    } catch (error) {
        failRequest(error);
    }
}
